<template>
    <LaBaseCard>
        <LaBaseTable v-if="headers.length" :headers="headers" :items="data" v-bind="$attrs" data-cy="website-table">
            <template #header-domain-second v-if="$mq.max.tablet"> Detected</template>
            <template #item-domain="{ item }">
                <div class="is-flex is-justify-content-center">
                    <LaStatusLabel v-if="$mq.max.tablet" :type="item.verified ? 'success' : 'error'" onlyDot />
                    <span>{{ formatSiteName(item) }}</span>
                </div>
            </template>
            <template #item-fee="{ item }">
                <LaFeeLabel v-if="isSuperAdmin" :web="item" />
            </template>
            <template #item-cpm="{ item }">
                {{ formatCell(item.cpm * exchangeRate, true) }} {{ currencySymbol }}
            </template>
            <template #item-status="{ item }">
                <LaStatusLabel
                    :type="item.verified ? 'success' : 'error'"
                    :label="item.verified ? 'detected' : 'not detected'"
                />
            </template>
            <template #item-expiration="{ item }">
                <span v-if="isSuperAdmin" :class="item.number_of_hours_of_verification < 0 ? 'text-color-red' : ''">
                    {{ expiration(item.number_of_hours_of_verification) }}
                </span>
            </template>
            <template #item-monetize="{ item }">
                <LaMonetizeField :website="item" />
            </template>

            <template #item-zoho_id="{ item }">
                <div class="is-flex is-flex-direction-column">
                    <span>{{ item.zoho_id }}</span>
                </div>
            </template>

            <template #item-performax_id="{ item }">
                <div class="is-flex is-flex-direction-column">
                    <span>{{ item.performax_id }}</span>
                </div>
            </template>

            <template #item-actions="{ item }">
                <LaActionIcons :item="item" item-name="website">
                    <template #center-icons>
                        <LaActionIcon type="monetize" @click="monetize(item)" v-if="$mq.max.mobile" />
                    </template>
                </LaActionIcons>
            </template>
        </LaBaseTable>
    </LaBaseCard>
</template>

<script>
import { computed } from "vue";
import LaActionIcon from "@/components/button/ActionIcon";
import LaActionIcons from "@/components/button/ActionIcons";
import LaBaseCard from "@/components/BaseCard";
import LaBaseTable from "@/components/BaseTable";
import LaMonetizeField from "@/components/monetize/MonetizeField";
import LaStatusLabel from "@/components/StatusLabel";
import { formatDate, formatCell, formatSiteName } from "@/helpers/filters";
import LaFeeLabel from "@/components/websites/FeeLabel";
import store from "@/store/store";
import { getterTypes } from "@/store/LoggedUser/types";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { getCurrencySymbol, getExchangeRateFromEur } from "@/helpers/data";

export default {
    name: "WebsitesTable",
    components: {
        LaActionIcon,
        LaActionIcons,
        LaBaseCard,
        LaBaseTable,
        LaMonetizeField,
        LaStatusLabel,
        LaFeeLabel,
    },
    props: {
        data: {
            required: true,
        },
    },
    computed: {
        headers() {
            const userRights = computed(() => store.getters[getterTypes.getUserRights]);
            let headers = [
                {
                    text: "Domain",
                    value: "domain",
                    direction: 1,
                    flex: 2,
                },
            ];
            if (userRights.value && userRights.value["super_admin"]) {
                headers.push({
                    text: "",
                    value: "fee",
                });
            }
            headers = headers.concat([
                {
                    text: "rpm",
                    value: "cpm",
                    width: this.$mq.max.tablet ? "50px" : false,
                    isNumber: true,
                },
                {
                    text: "detected",
                    value: "status",
                    hide: this.$mq.max.tablet,
                    sortable: false,
                    flex: 2,
                },
            ]);

            if (userRights.value && userRights.value["super_admin"]) {
                headers.push({
                    text: "Expires in",
                    value: "expiration",
                });
            }

            headers.push({
                text: "monetize",
                value: "monetize",
                hide: this.$mq.max.mobile,
                sortable: false,
                flex: 4,
                justifyContentCenter: true,
            });

            if (userRights.value && userRights.value["super_admin"]) {
                headers.push({
                    text: "status",
                    value: "site_status",
                    hide: this.$mq.max.mobile,
                    sortable: false,
                    flex: 2,
                });

                headers.push({
                    text: "Performax ID",
                    value: "performax_id",
                    hide: this.$mq.max.mobile,
                    isNumber: false,
                    justifyContentCenter: true,
                    flex: 1,
                });

                headers.push({
                    text: "Zoho ID",
                    value: "zoho_id",
                    hide: this.$mq.max.mobile,
                    isNumber: false,
                    justifyContentCenter: true,
                    flex: 1,
                });
            }
            return headers.concat([
                {
                    text: "actions",
                    value: "actions",
                    justifyContentCenter: true,
                    flex: 2,
                },
            ]);
        },
    },
    setup() {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromEur(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));

        const userRights = computed(() => store.getters[getterTypes.getUserRights]);
        const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);
        return { formatDate, formatCell, formatSiteName, isSuperAdmin, exchangeRate, currencySymbol };
    },
    methods: {
        expiration(hours) {
            if (hours === null) {
                return "-";
            }
            if (hours < 1 && hours > -1) {
                return Math.round(hours * 60) + " minutes";
            }

            hours = Math.floor(hours);
            if (hours === 1) {
                return "1 hour";
            }
            if (hours === -1) {
                return "-1 hour";
            }

            if (hours > 1 && hours < 24) {
                return hours + " hours";
            }
            if (hours < -1 && hours > -24) {
                return hours + " hours";
            }

            if (hours === 24) {
                return "1 day";
            }
            if (hours === -24) {
                return "-1 day";
            }

            if (hours > 0) {
                return Math.floor(hours / 24) + " days";
            }
            return Math.ceil(hours / 24) + " days";
        },
    },
};
</script>

<style lang="scss" scoped>
.text-color-red {
    color: #d4330b;
}
</style>
