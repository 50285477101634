<template>
    <div class="columns">
        <div class="column">
            <SimpleCard
                data-test="card"
                title="Last month earned"
                :value="revenue_data[revenue_data.length - 1]"
                title_2="Last month paid"
                :value_2="payout_data[payout_data.length - 1]"
                :postfix="' ' + currencySymbol"
                :is-decimal="true"
                color="success"
            />
        </div>
        <div class="column">
            <SimpleCard
                data-test="card"
                title="Year to date earned"
                :value="year_earned"
                title_2="Year to date paid"
                :value_2="year_paid"
                :postfix="' ' + currencySymbol"
                :is-decimal="true"
                color="success"
            />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <BaseCard>
                <div class="columns graph">
                    <div class="column">
                        <LaBarGraph
                            :key="combinedDatasets"
                            :labels="lineLabels"
                            :chartOptions="combinedChartOptions"
                            :datasets="combinedDatasets"
                        />
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <h2>Payouts</h2>
                        <LaPayoutTable :data="payouts" :reward_data="revenue" />
                    </div>
                </div>
            </BaseCard>
        </div>
    </div>
</template>

<script>
import BaseCard from "@/components/BaseCard";
import SimpleCard from "@/components/SimpleCard";
// import LaEarningsTable from "@/components/payouts/EarningTable";
import LaPayoutTable from "@/components/payouts/PayoutTable";
import { formatMonthDate } from "@/helpers/filters.js";
import LaBarGraph from "@/components/graphs/BarGraph";
import { ExtendedTooltip } from "../../helpers/graphs";
import { getCurrencySymbol, getExchangeRateFromCzk } from "../../helpers/data";
import { computed } from "vue";
import store from "@/store/store";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export default {
    name: "LaPayoutDataCard",
    components: {
        BaseCard,
        SimpleCard,
        LaPayoutTable,
        // LaEarningsTable,
        LaBarGraph,
    },
    props: {
        payouts: {
            type: Array,
            required: true,
        },
        revenue: {
            type: Array,
            required: true,
        },
        websites: {
            type: Array,
        },
    },
    setup() {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));
        return { exchangeRate, currencySymbol };
    },
    computed: {
        filename() {
            return `${this.website}_earnings_%from_%to`;
        },

        payout_data() {
            return this.payouts
                .slice()
                .reverse()
                .map((item) => {
                    if (item["status"] == "pending" || item["status"] == "approved") {
                        return Math.round((item["sum"] / this.exchangeRate) * 100) / 100;
                    }
                    return 0;
                });
        },

        revenue_data() {
            return this.revenue
                .slice()
                .reverse()
                .map((item) => Math.round((item["sum"] / this.exchangeRate) * 100) / 100);
        },

        year_earned() {
            let ret = 0;
            //const year = this.revenue[0].date.split("-")[0];
            const year = "" + new Date().getFullYear();
            for (let i = 0; i < this.revenue.length; i++) {
                if (this.revenue[i].date.startsWith(year)) {
                    console.log("adding", this.revenue[i].date);
                    ret += Math.round((this.revenue[i].sum / this.exchangeRate) * 100) / 100;
                }
            }
            return ret;
        },

        year_paid() {
            let ret = 0;
            //const year = this.payouts[0].date.split("-")[0];
            const year = "" + new Date().getFullYear();
            console.log("payout year", year);
            for (let i = 0; i < this.payouts.length; i++) {
                if (
                    this.payouts[i].status != "retained" &&
                    this.payouts[i].status != "rejected" &&
                    this.payouts[i].date.startsWith(year)
                ) {
                    ret += Math.round((this.payouts[i].sum / this.exchangeRate) * 100) / 100;
                }
            }
            return ret;
        },

        lineLabels() {
            return this.revenue
                .slice()
                .reverse()
                .map((item) => formatMonthDate(new Date(item.date)));
        },

        combinedDatasets() {
            return [
                {
                    label: "Revenue",
                    data: this.revenue_data,
                    fill: false,
                    borderColor: "#1fad82",
                    tension: 0.1,
                    borderWidth: 3,
                    yAxisID: "revenue",
                },
                {
                    label: "Payout",
                    data: this.payout_data,
                    fill: false,
                    borderColor: "#a8ef4f",
                    tension: 0.1,
                    borderWidth: 3,
                    yAxisID: "revenue",
                },
            ];
        },

        combinedChartOptions() {
            return {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                color: "#dadbe3",
                                backdropPadding: 8,
                            },
                            gridLines: {
                                borderColor: "#e5efff",
                                drawBorder: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            id: "revenue",
                            type: "linear",
                            position: "left",
                        },
                    ],
                },
                elements: {
                    point: {
                        radius: 1,
                    },
                },
                tooltips: {
                    mode: "index",
                    intersect: false,
                    enabled: false,
                    custom: ExtendedTooltip,
                },
                responsive: true,
                maintainAspectRatio: false,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.columns.graph .column > div {
    height: 200px;
}
</style>
