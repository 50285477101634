import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/store";
import axios from "@/plugins/axios";
import Mq from "vue3-mq-lite";
import Portal from "vue3-portal";
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import "@mdi/font/css/materialdesignicons.css";

const app = createApp({
    axios,
    render: () => h(App),
});

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        app,
        dsn: "https://ad87a45cb04145bfac9e6705a957511d@o467217.ingest.sentry.io/6175748",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["dashboard.antiadblock.com", "api.antiadblock.com"],
            }),
        ],
        tracesSampleRate: 1.0,
    });
    app.use(
        VueGtag,
        {
            includes: [{ id: "G-3YTGTF0N52" }],
            config: { id: "UA-77276748-3" },
        },
        router
    );
}

app.use(router);
app.use(store);
app.use(Portal);
app.use(Mq, {
    mobile: 650,
    tablet: 850,
    bigTablet: 975,
    desktop: 1250,
    large: Infinity,
});
app.mount("#app");
