import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "./types";

export const actions = {
    async [actionTypes.getList]({ commit }) {
        commit(mutationTypes.payoutsCsvGetListStart);
        return axios({
            method: "get",
            url: "la/payouts-csv",
        }).then(
            (data) => {
                //console.log("payouts csv list", data);
                commit(mutationTypes.payoutsCsvGetListSuccess, data.data);
                return data.data;
            },
            () => {
                commit(mutationTypes.payoutsCsvGetListFailure);
            }
        );
    },

    async [actionTypes.getItem]({ commit }, { id }) {
        commit(mutationTypes.payoutsCsvGetItemStart);
        return axios({
            method: "get",
            url: "la/payouts-csv/" + id.replace("payouts_csv_", ""),
        }).then(
            (data) => {
                //console.log("payouts csv data", id, data);
                commit(mutationTypes.payoutsCsvGetItemSuccess, data.data);
                return data.data;
            },
            () => {
                commit(mutationTypes.payoutsCsvGetItemFailure);
            }
        );
    },
};
