<template>
    <LaContentLoader v-if="loading" />

    <div v-else>
        <div class="columns">
            <div class="column is-flex">
                <LaBaseButton
                    @click="approveChecked()"
                    :disabled="loading"
                    type="button"
                    buttonType="success"
                    text="Approve checked"
                />

                <LaBaseButton
                    @click="rejectChecked()"
                    :disabled="loading"
                    type="button"
                    buttonType="error"
                    text="Reject checked"
                />
            </div>
            <div class="column is-flex is-justify-content-right">
                <div v-if="showMinPayments">
                    <LaBaseButton
                        @click="toggleMinPaymentsButton()"
                        :disabled="loading"
                        type="button"
                        buttonType="neutral"
                        text="Show all payments"
                    />
                </div>

                <div v-else>
                    <LaBaseButton
                        @click="toggleMinPaymentsButton()"
                        :disabled="loading"
                        type="button"
                        buttonType="neutral"
                        text="Show payments over 500 €"
                    />
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column" v-if="paymentApprovals">
                <PaymentApprovalDataCard :paymentApprovals="paymentApprovals" heading="Payment Approvals" />
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store/store";
import { computed } from "vue";
import { getterTypes as PayoutGetterTypes } from "@/store/Payouts/types";
import LaContentLoader from "@/components/loaders/ContentLoader";
import PaymentApprovalDataCard from "@/components/payment-approval/PaymentApprovalDataCard.vue";
import {
    actionTypes as PaymentApprovalActionTypes,
    getterTypes as PaymentApprovalGetterTypes,
} from "@/store/PaymentApproval/types";
import LaBaseButton from "@/components/button/BaseButton";

export default {
    name: "LaApprovingView",
    components: {
        LaContentLoader,
        PaymentApprovalDataCard,
        LaBaseButton,
    },

    props: {
        defaultMinValue: {
            type: Number,
            default: 500,
        },
    },

    data() {
        return {
            showMinPayments: true,
        };
    },

    setup(props) {
        const loading = computed(() => store.getters[PayoutGetterTypes.isLoading]);
        const paymentApprovals = computed(() => store.getters[PaymentApprovalGetterTypes.getPaymentApproval]);
        const hasData = computed(() => !!paymentApprovals.value);

        if (!paymentApprovals.value) {
            store.dispatch(PaymentApprovalActionTypes.getData, { min: props.defaultMinValue });
        }

        return {
            loading,
            hasData,
            paymentApprovals,
        };
    },
    methods: {
        getMinValue() {
            return this.showMinPayments ? this.defaultMinValue : 0;
        },
        toggleMinPaymentsButton() {
            this.showMinPayments = !this.showMinPayments;
            store.dispatch(PaymentApprovalActionTypes.getData, { min: this.getMinValue() });
        },
        async approveChecked() {
            const publisherIds = store.getters[PaymentApprovalGetterTypes.getPaymentApprovalValuesSelected];
            await store.dispatch(PaymentApprovalActionTypes.approveChecked, {
                status: "approved",
                ids: publisherIds,
            });
            await store.dispatch(PaymentApprovalActionTypes.getData, { min: this.getMinValue() });
        },
        async rejectChecked() {
            const publisherIds = store.getters[PaymentApprovalGetterTypes.getPaymentApprovalValuesSelected];
            await store.dispatch(PaymentApprovalActionTypes.approveChecked, {
                status: "rejected",
                ids: publisherIds,
            });
            await store.dispatch(PaymentApprovalActionTypes.getData, { min: this.getMinValue() });
        },
    },
};
</script>

<style scoped></style>
