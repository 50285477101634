<template>
    <div>
        <h1>Add website</h1>
        <p>
            Lorem ipsum dolor sit amet, habeo porro et sit, in per brute oportere. Lucilius postulant iudicabit quo ad,
            ex mea labitur interpretaris. Te sit autem interpretaris.
        </p>
        <!-- TODO muzeme pouzit toto nebo to bude mit jiny styly? Taky to ma placehoder misto labelu -->
        <LaWebsiteForm />
        <a href="/" data-test="skip">Skip</a>
    </div>
</template>

<script>
import LaWebsiteForm from "@/components/dialog/WebsiteForm";
export default {
    name: "LaAddWebsiteView",
    components: { LaWebsiteForm },
};
</script>

<style scoped></style>
