<template>
    <div class="status-label" :class="[{ simple: onlyDot }, type]">
        <div class="status-label-container">
            <span v-if="!noDot" class="status-label-dot" />
            <span v-if="label">{{ label }}</span>
            <slot v-else name="label" />

            <span class="tooltip" v-if="!!$slots.tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#24355a" viewBox="0 0 16 16">
                    <path
                        d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                    />
                </svg>

                <div class="tooltip-container">
                    <slot name="tooltip" />
                </div>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "LaStatusLabel",
    props: {
        label: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: "success",
            validator: (value) => {
                return !value ? true : ["success", "warning", "error", "info", "none"].indexOf(value) !== -1;
            },
        },
        onlyDot: {
            type: Boolean,
            required: false,
            default: false,
        },
        noDot: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.status-label {
    font-size: 0.9rem;
    padding: 0.3rem 0.7rem;
    display: inline-block;
    border-radius: $normal-radius;
    @include --tablet-viewport {
        padding: 0.3rem 0.5rem;
    }
    &-container {
        display: flex;
        align-items: center;
    }
    &-dot {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 90%;
        margin-right: 0.5rem;
        @include --tablet-viewport {
            width: 0.8rem;
            height: 0.8rem;
        }
    }
    .success {
        background-color: $light-green;
    }
}
.status-label.simple {
    display: flex;
    justify-content: center;
    align-items: center;
    background: None !important;
    padding: 0;
}
.status-label.success {
    //background-color: $light-green;
    color: $green;
    .status-label-dot {
        background-color: $green;
    }
}
.status-label.error {
    //background-color: $light-red;
    color: $red;
    .status-label-dot {
        background-color: $red;
    }
}
.status-label.info {
    //background-color: $grey;
    color: $blue;
    .status-label-dot {
        background-color: $blue;
    }
}
.status-label.none {
    padding: 0 0 0 0;
    color: $blue;
    .status-label-dot {
        background-color: $blue;
    }
}

body.dark {
    .status-label.success {
        background: inherit;
        color: $white;
        .status-label-dot {
            background: $green;
        }
    }
    .status-label.error {
        background: inherit;
        color: $white;
        .status-label-dot {
            background-color: $red;
        }
    }

    .status-label.info {
        background: none;
        color: $white;
    }
}
</style>
