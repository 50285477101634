<template>
    <i class="mdi" :class="'mdi-' + name" :name="name" />
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
    },
};
</script>
