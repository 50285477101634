<template>
    <template v-if="blockRateMetadata && currencySymbol">
        <div class="columns">
            <div class="column">
                <SimpleCard data-test="card" title="Total pageviews" :value="numberFormat(pageViews)" />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="card"
                    title="Allowing ads"
                    :value="allowingAds"
                    postfix="%"
                    :is-decimal="true"
                    color="success"
                />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="card"
                    title="Blocking ads"
                    :value="blockingAds"
                    postfix="%"
                    :is-decimal="true"
                    color="warning"
                />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="card"
                    title="Recovered revenue"
                    :value="recoverRevenue"
                    :postfix="' ' + currencySymbol"
                    :is-decimal="true"
                    color="success"
                />
            </div>
        </div>
        <div class="columns is-flex is-align-content-stretch">
            <div class="column">
                <SimpleCard
                    data-test="card"
                    title="Revenue yesterday"
                    :value="revenueYesterday"
                    :postfix="' ' + currencySymbol"
                    :is-decimal="true"
                    color="success"
                />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="card"
                    title="Revenue month to date"
                    :value="revenueMonth"
                    :postfix="' ' + currencySymbol"
                    :is-decimal="true"
                    color="success"
                />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="card"
                    title="Grand total"
                    :value="revenueGrandTotal"
                    :postfix="' ' + currencySymbol"
                    :is-decimal="true"
                    color="success"
                />
            </div>
        </div>

        <div
            v-if="isSuperAdmin && blockRateMetadata && blockRateData && impressionsTotal && currencySymbol"
            class="columns is-flex is-align-content-stretch"
        >
            <div class="column">
                <SimpleCard
                    data-test="rpm-blocked-pageviews"
                    title="Blocked pageviews"
                    :value="numberFormat(blockingAdsTotal)"
                />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="impresion-total"
                    title="Impressions total"
                    :value="numberFormat(impressionsTotal)"
                />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="rpm-total"
                    title="Impressions RPM"
                    :value="recoverRevenue / (impressionsTotal / 1000)"
                    :postfix="' ' + currencySymbol"
                    :is-decimal="true"
                />
            </div>

            <div class="column">
                <SimpleCard
                    data-test="rpm-total"
                    title="Pageviews RPM"
                    :value="pageViews ? recoverRevenue / (blockingAdsTotal / 1000) : 0"
                    :postfix="' ' + currencySymbol"
                    :is-decimal="true"
                />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="rpm-ads-per-page"
                    title="Ads per page"
                    :value="blockingAdsTotal ? impressionsTotal / blockingAdsTotal : 0"
                    :is-decimal="true"
                />
            </div>
        </div>
    </template>
    <LaContentLoader v-else-if="earningsLoading && blockRateLoading" />

    <div class="columns" v-if="earningsData?.length">
        <div class="column">
            <LaEarningDataCard
                :earnings-data="earningsData"
                :earnings-metadata="earningsMetadata"
                :earnings-csv-data="earningsCsvData"
                :earnings-loading="earningsLoading"
                :domains="domains"
            />
        </div>
    </div>
    <LaContentLoader v-else-if="earningsLoading" />

    <div class="columns" v-if="blockRateData?.length">
        <div class="column">
            <LaBlockRateDataCard
                :block-rate-data="blockRateData"
                :block-rate-metadata="blockRateMetadata"
                :block-rate-loading="blockRateLoading"
                :domains="domains"
                @device-change="deviceChange"
            />
        </div>
    </div>
    <LaContentLoader v-else-if="blockRateLoading" />

    <LaNoData
        v-if="!(blockRateMetadata || earningsMetadata)"
        description="Check if you added the measurement script correctly to your page or contact our support."
    />
</template>

<script>
import LaNoData from "@/components/NoData";
import LaContentLoader from "@/components/loaders/ContentLoader";
import LaBlockRateDataCard from "@/components/dashboard/BlockRateDataCard";
import LaEarningDataCard from "@/components/earnings/EarningDataCard";
import SimpleCard from "@/components/SimpleCard";
import { getExchangeRateFromCzk, getCurrencySymbol, numberFormat } from "@/helpers/data";
import store from "@/store/store";
import { PeriodsEnum } from "@/helpers/data";
import { actionTypes as blockRateActionTypes, getterTypes as blockRateGetterTypes } from "@/store/BlockRate/types";
import { actionTypes as earningsActionTypes, getterTypes as earningsGetterTypes } from "@/store/Earnings/types";
import { ref, computed, watch } from "vue";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { getterTypes } from "@/store/LoggedUser/types";

let device = "desktop";

export default {
    name: "LaEarningsCards",
    methods: { numberFormat },
    components: {
        SimpleCard,
        LaEarningDataCard,
        LaBlockRateDataCard,
        LaContentLoader,
        LaNoData,
    },
    props: {
        payload: {
            type: Object,
            required: true,
        },
        websites: {
            required: true,
            type: Array,
        },
    },
    setup(props) {
        const userRights = computed(() => store.getters[getterTypes.getUserRights]);
        const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);

        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));

        const blockRateLoading = computed(() => store.getters[blockRateGetterTypes.isLoading]);
        const earningsLoading = computed(() => store.getters[earningsGetterTypes.isLoading]);
        const loading = computed(() => blockRateLoading.value || earningsLoading.value);
        const blockRateData = computed(() => store.getters[blockRateGetterTypes.getBlockRateData]);
        const blockRateMetadata = computed(() => store.getters[blockRateGetterTypes.getBlockRateMetadata]);
        const domains = computed(() => props.websites?.map(({ domain }) => domain) || null);
        const earningsData = computed(() => store.getters[earningsGetterTypes.getEarningsData]);
        const earningsMetadata = computed(() => store.getters[earningsGetterTypes.getEarningsMetadata]);
        const earningsCsvData = computed(() => store.getters[earningsGetterTypes.getEarningsCsvData]);
        const revenueGrandTotal = computed(
            () => (store.getters[earningsGetterTypes.getGrandTotal] || 0) / exchangeRate.value
        );
        const recoverRevenue = computed(() => (earningsMetadata.value?.["total_revenue"] || 0) / exchangeRate.value);
        const pageViews = computed(() => blockRateMetadata.value?.["total_pageview"]);
        const allowingAds = computed(() => blockRateMetadata.value?.["percent"]["non_blocking"]);
        const blockingAds = computed(() => blockRateMetadata.value?.["percent"]["blocking"]);
        const impressionsTotal = computed(() => earningsMetadata.value?.["total_impressions"]);
        //const clicks = computed(() => earningsMetadata["total_clicks"]||0);

        const blockingAdsTotal = computed(() => {
            let blockingAdsTotal = 0;
            blockRateData.value.map((item) => {
                blockingAdsTotal += item.blocking;
            });
            return blockingAdsTotal;
        });

        const revenueYesterday = ref(0);
        const revenueMonth = ref(0);

        const fetchBlockrateData = (payload) => {
            payload.device = device;
            store.dispatch(blockRateActionTypes.getData, payload);
        };

        const fetchData = (payload) => {
            payload.device = device;
            store.dispatch(blockRateActionTypes.getData, payload);
            store.dispatch(earningsActionTypes.getGrandTotal, payload);
            store.dispatch(earningsActionTypes.getData, payload).then((data) => {
                if (payload.period === PeriodsEnum.Yesterday) {
                    revenueYesterday.value = (data?.metadata?.["total_revenue"] || 0) / exchangeRate.value;
                }
                if (payload.period === PeriodsEnum.ThisMonth) {
                    revenueMonth.value = (data?.metadata?.["total_revenue"] || 0) / exchangeRate.value;
                }
            });
            if (payload.period !== PeriodsEnum.Yesterday) {
                store
                    .dispatch(earningsActionTypes.getData, {
                        ...payload,
                        period: PeriodsEnum.Yesterday,
                        noCommit: true,
                    })
                    .then((data) => {
                        revenueYesterday.value = (data?.metadata?.["total_revenue"] || 0) / exchangeRate.value;
                    });
            }
            if (payload.period !== PeriodsEnum.ThisMonth) {
                store
                    .dispatch(earningsActionTypes.getData, {
                        ...payload,
                        period: PeriodsEnum.ThisMonth,
                        noCommit: true,
                    })
                    .then((data) => {
                        revenueMonth.value = (data?.metadata?.["total_revenue"] || 0) / exchangeRate.value;
                    });
            }
        };

        const deviceChange = (dev) => {
            device = dev;
            //console.log("listener", device);
            fetchBlockrateData(props.payload);
        };

        watch(
            () => props.payload,
            () => fetchData(props.payload),
            { immediate: true }
        );
        return {
            loading,
            domains,
            blockRateData,
            blockRateMetadata,
            blockRateLoading,
            earningsData,
            earningsMetadata,
            earningsCsvData,
            earningsLoading,
            revenueGrandTotal,
            recoverRevenue,
            pageViews,
            allowingAds,
            blockingAds,
            revenueYesterday,
            revenueMonth,
            deviceChange,
            currencySymbol,
            impressionsTotal,
            blockingAdsTotal,
            isSuperAdmin,
        };
    },
};
</script>

<style lang="scss" scoped>
.small-label {
    font-size: 0.8rem;
}
</style>
