<template>
    <form ref="form" data-test="base-submit" @submit.prevent="save(formData)" :data-cy="$attrs.id || 'dialog-form'">
        <slot name="default" />
        <portal-target name="defaultForm" />

        <!-- Kvuli podpore odesilani enterem, samotny viditelny button nemusi byt vzdy ve formu (kvuli portalu) -->
        <button type="submit" style="display: none"></button>

        <portal :to="buttonPortalTo">
            <LaBaseButton
                data-test="base-button"
                :disabled="disabled || isPending"
                @click="save(formData)"
                data-cy="submit-dialog-form"
                :text="button"
                :style="hideButton ? { display: 'none' } : ''"
                :stretch="buttonPortalTo !== 'defaultForm'"
            />
        </portal>
    </form>
</template>

<script>
import { ref } from "vue";
import LaBaseButton from "@/components/button/BaseButton";
import store from "@/store/store";

export default {
    name: "LaBaseForm",
    components: {
        LaBaseButton,
    },
    props: {
        formData: {
            type: Object,
            required: true,
        },
        vuexActionMutation: {
            type: String,
            required: true,
        },
        button: {
            type: String,
            default: "Save",
        },
        buttonPortalTo: {
            type: String,
            required: false,
            default: "defaultForm",
        },
        dontValidate: {
            type: Array,
            required: false,
            default: () => [],
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        errorBag: {},
        disabled: false,
    }),
    methods: {
        setStatus(id, status) {
            this.errorBag[id] = status;
        },
        isDisabled() {
            for (let status in this.errorBag) {
                if (this.dontValidate.includes(status)) {
                    this.errorBag[status] = false;
                    this.disabled = false;
                }
                if (this.errorBag[status] !== false) return (this.disabled = true);
            }
            this.disabled = false;
        },
    },
    provide() {
        return {
            setStatus: this.setStatus,
        };
    },
    watch: {
        errorBag: {
            deep: true,
            handler() {
                this.isDisabled();
            },
        },
        dontValidate: {
            deep: true,
            handler() {
                this.isDisabled();
            },
        },
    },
    setup(props) {
        let isPending = ref(false);
        const save = (data) => {
            if (isPending.value) return;
            isPending.value = true;

            store.dispatch(props.vuexActionMutation, data).finally(() => {
                isPending.value = false;
            });
        };

        return {
            save,
            isPending,
        };
    },
};
</script>

<style lang="scss" scoped>
.base-form-button-container {
    display: inline-block;
}
</style>
