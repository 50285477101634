<template>
    <LaBaseForm
        v-if="publisher"
        :form-data="{
            pub_id: publisher.id,
            is_eu: !isOther,
            vat_number: vatNumber,
            company_name: companyName,
            contact_email: contactEmail,
            street1: street1,
            street2: street2,
            city: city,
            zip: zip,
            identification_number: identificationNumber,
            country: country,
        }"
        :vuex-action-mutation="editCompanyAction"
        :hide-button="false"
    >
        <LaBaseRadioButton
            v-model="selectedRadioOption"
            name="region"
            :button-options="vatRegionOptions"
            data-test="vat-region-radio-button"
            class="mb-5"
            @update:model-value="changeRadio()"
        ></LaBaseRadioButton>
        <span v-if="!isOther" class="vat-number-container">
            <LaBaseInput
                :label="vatLabel"
                type="text"
                v-model="vatNumber"
                name="vatNumber"
                :rules="rules.vatNumber"
                :error="errors.vat_number"
                @update:model-value="changeVat()"
            />
            <LaBaseButton
                buttonType="success"
                type="button"
                :text="'Load company info from ' + vatLabel"
                class="check-vat-button"
                @click.prevent="checkVat($event)"
                :disabled="checkVATinProgress"
            />
        </span>
        <span v-if="checkVATinProgress">
            <h3 class="no-data">Loading information from VAT number...</h3>
            <LaFormLoader />
        </span>
        <span v-else>
            <LaBaseInput
                v-if="isCz"
                label="IČO"
                type="text"
                v-model="identificationNumber"
                name="identificationNumber"
                :rules="rules.identificationNumber"
                :error="errors.identificationNumber"
            />
            <LaBaseInput
                label="Company name"
                type="text"
                v-model="companyName"
                name="companyName"
                :rules="rules.companyName"
                :error="errors.company_name"
            />

            <LaBaseInput
                label="Contact e-mail"
                type="text"
                v-model="contactEmail"
                name="contactEmail"
                :rules="rules.contactEmail"
                :error="errors.contact_email"
            />
            <LaBaseInput
                label="Street 1"
                type="street1"
                v-model="street1"
                name="street1"
                :rules="rules.street1"
                :error="errors.street1"
            />
            <LaBaseInput label="Street 2" type="street2" v-model="street2" name="street2" :error="errors.street2" />
            <LaBaseInput label="City" type="city" v-model="city" name="city" :rules="rules.city" :error="errors.city" />
            <LaBaseInput
                label="Postal code"
                type="zip"
                v-model="zip"
                name="zip"
                :rules="rules.zip"
                :error="errors.zip"
            />
            <LaCountrySelect
                v-if="countries && computedCountry"
                :countries="countries"
                :country="computedCountry"
                :disabled="isCz"
                v-model="country"
            ></LaCountrySelect>
        </span>
    </LaBaseForm>
</template>

<script>
//import cloneDeep from "lodash.clonedeep";
import LaBaseForm from "@/components/BaseForm";
import LaFormLoader from "@/components/loaders/FormLoader";
import { computed, ref } from "vue";
import LaBaseInput from "@/components/inputs/BaseInput";
import {
    validateIdentificationNumber,
    validateDIC,
    hasMinLength,
    hasMaxLength,
    isRequired,
    isValidEmail,
} from "@/helpers/rules.js";
import store from "@/store/store";
import {
    actionTypes as publisherActionTypes,
    getterTypes as publisherGetterTypes,
    mutationTypes,
} from "@/store/Publisher/types";
import LaBaseRadioButton from "@/components/inputs/BaseRadioButton";
import LaBaseButton from "@/components/button/BaseButton";
import LaCountrySelect from "@/components/filters/CountrySelect";
import { actionTypes as countryActionTypes, getterTypes as countryGetterTypes } from "@/store/Country/types";

// nescopovane deklarace maji stabilni referenci => nezpusobuji rerender
const vatRegionOptions = [
    { name: "cz", label: "CZ" },
    { name: "eu", label: "EU" },
    { name: "others", label: "Other countries" },
];

const vatNumberValidatorEU = [validateDIC(false), hasMinLength(4), hasMaxLength(16)];

const defaultRules = {
    companyName: [isRequired()],
    street1: [isRequired()],
    city: [isRequired()],
    zip: [isRequired()],
    contactEmail: [isValidEmail()],
};

const getRegionOption = (code, isEu) => {
    return vatRegionOptions[code === "CZ" ? 0 : isEu ? 1 : 2];
};

const getRules = (selectedRegion) => {
    switch (selectedRegion) {
        case "cz":
            return {
                ...defaultRules,
                vatNumber: vatNumberValidatorEU,
                identificationNumber: [validateIdentificationNumber(true)],
            };
        case "eu":
            return { ...defaultRules, vatNumber: vatNumberValidatorEU };
        default:
            return defaultRules;
    }
};

export default {
    name: "LaCompanyAccount",
    components: {
        LaCountrySelect,
        LaBaseButton,
        LaBaseRadioButton,
        LaBaseInput,
        LaBaseForm,
        LaFormLoader,
    },
    async setup() {
        const publisher = computed(() => store.getters[publisherGetterTypes.getPublisher]);
        const errors = computed(() => store.getters[publisherGetterTypes.getCompanyAccountErrors] || {});
        const computedCountry = computed(() => store.getters[countryGetterTypes.getPublisherCountry]);
        const countries = computed(() => store.getters[countryGetterTypes.getCountries]);
        const noErrors = computed(() => Object.keys(errors.value).length === 0);
        if (!publisher.value) {
            await store.dispatch(publisherActionTypes.getData);
        }
        if (!countries.value) {
            await store.dispatch(countryActionTypes.getData);
        }
        const vatNumber = ref(publisher.value.vat_number);
        const companyName = ref(publisher.value.company_name);
        const contactEmail = ref(publisher.value.contact_email);
        const street1 = ref(publisher.value.street1);
        const street2 = ref(publisher.value.street2);
        const city = ref(publisher.value.city);
        const zip = ref(publisher.value.zip);
        const identification_number = ref(publisher.value.identification_number);
        const country = ref(computedCountry.value);
        const checkVATinProgress = ref(false);
        const selectedRadioOption = ref(getRegionOption(country.value?.code, publisher.value?.is_eu));
        const isCz = computed(() => selectedRadioOption.value.name === "cz");
        const isOther = computed(() => selectedRadioOption.value.name === "others");
        const vatLabel = computed(() => (isCz.value ? "DIČ" : "VAT number"));
        const vatValidated = ref(!!store.getters[publisherGetterTypes.getVatNumber]);
        const getCountryByCode = () =>
            store
                .dispatch(countryActionTypes.getCountry, {
                    isPublisher: true,
                    code: publisher.value.country || "USA",
                })
                .finally(() => {
                    country.value = store.getters[countryGetterTypes.getPublisherCountry];
                    selectedRadioOption.value = getRegionOption(country.value?.code, publisher.value?.is_eu);
                });

        if (!country.value) {
            await getCountryByCode();
        }

        const rules = ref(getRules(selectedRadioOption.value.name));

        const editCompanyAction = publisherActionTypes.editCompanyData;

        const changeVat = () => {
            vatValidated.value = false;
        };

        const checkVat = () => {
            checkVATinProgress.value = true;
            store
                .dispatch(publisherActionTypes.checkVatNumber, {
                    pub_id: publisher.value.id,
                    vat_number: vatNumber.value,
                })
                .finally(() => {
                    if (noErrors.value) {
                        companyName.value = publisher.value.company_name;
                        contactEmail.value = publisher.value.contact_email;
                        street1.value = publisher.value.street1;
                        city.value = publisher.value.city;
                        zip.value = publisher.value.zip;
                        identification_number.value = publisher.value.identification_number;
                        getCountryByCode();
                        vatValidated.value = true;
                    }

                    checkVATinProgress.value = false;
                });
        };

        const changeRadio = () => {
            store.commit(mutationTypes.companyEditDataErrors, {});
            identification_number.value = null;
            rules.value = getRules(selectedRadioOption.value.name, vatValidated.value);
        };

        return {
            publisher,
            isOther,
            isCz,
            vatNumber,
            companyName,
            contactEmail,
            street1,
            street2,
            city,
            zip,
            identificationNumber: identification_number,
            country,
            countries,
            editCompanyAction,
            rules,
            vatRegionOptions,
            selectedRadioOption,
            checkVat,
            checkVATinProgress,
            errors,
            vatLabel,
            changeRadio,
            changeVat,
            computedCountry,
        };
    },
};
</script>

<style lang="scss" scoped>
.upload-button {
    margin-left: 2rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $grey;
    color: $blue;
    border-radius: $normal-radius;
}
.vat-number-container {
    display: flex;
    align-items: flex-start;
}

.check-vat-button {
    margin-left: 2rem;
}
</style>
