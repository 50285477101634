<template>
    <div class="columns">
        <div class="column" v-for="index in 3" :key="index">
            <LaCardLoader class="small-card" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <LaCardLoader class="regular-card" />
        </div>
        <div class="column" />
    </div>
</template>

<script>
import LaCardLoader from "@/components/loaders/CardLoader";

export default {
    name: "LaContentLoader",
    components: {
        LaCardLoader,
    },
};
</script>

<style lang="scss" scoped>
.regular-card {
    height: 14.55rem;
}
</style>
