export const actionTypes = {
    getWebsiteDatesfromLS: "[website dates] Get website dates from local storage",
    setWebsiteDatesToLS: "[website dates] Set website dates to local storage",
};

export const mutationTypes = {
    setData: "[website dates] Set website dates",
    getDataStart: "[website dates] Get website dates starts",
};

export const getterTypes = {
    getData: "[website dates] Get data",
    getPeriod: "[website dates] Get dates",
    getAabSiteIds: "[website dates] Get website aab site ids",
};
