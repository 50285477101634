<template>
    <div ref="chart" style="width: 100%; height: 400px"></div>
</template>
<script>
import { onMounted, ref, onBeforeUnmount, watch, computed } from "vue";
import * as echarts from "echarts";
import { getItem } from "@/store/persistantStorage";
import { formatCell } from "@/helpers/filters.js";

export default {
    name: "BlockRateStackedChart",
    props: {
        dates: {
            type: Array,
            required: true,
        },
        allowedPageviews: {
            type: Array,
            required: true,
        },
        blockedPageviews: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const isLightTheme = computed(() => {
            return getItem("theme") == "light";
        });

        const pageviewsSummary = computed(() => {
            const summary = {};
            props.dates.forEach((date, index) => {
                summary[date] = props.allowedPageviews[index] + props.blockedPageviews[index];
            });
            return summary;
        });

        const chart = ref(null);
        let myChart = null;

        const renderChart = () => {
            if (myChart) {
                const option = {
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                        },
                        textStyle: {
                            color: isLightTheme.value ? "#000" : "#000",
                        },
                        formatter: (params) => {
                            let result = "";

                            let i = 0;
                            params.forEach((item) => {
                                i++;
                                if (i == 1) {
                                    result += `${item.axisValue} <br/>`;
                                } else {
                                    i = 0;
                                }

                                let total = pageviewsSummary.value[item.axisValue];
                                let percent = item.value / (total / 100);
                                result += `${item.marker} ${item.seriesName}: ${formatCell(
                                    item.data
                                )} (${percent.toFixed(1)}%)<br/>`;
                            });
                            return result;
                        },
                    },
                    legend: {
                        top: "5%",
                        left: "center",
                        textStyle: {
                            color: isLightTheme.value ? "#5a5959" : "#fff",
                        },
                    },
                    grid: {
                        left: "1%",
                        right: "1%",
                        bottom: "3%",
                        containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        data: props.dates,
                        axisLabel: {
                            color: isLightTheme.value ? "#5a5959" : "#fff",
                        },
                        axisLine: {
                            lineStyle: {
                                color: isLightTheme.value ? "#5a5959" : "#fff",
                            },
                        },
                    },
                    yAxis: {
                        type: "value",
                        name: "Pageviews",
                        position: "left",
                        nameTextStyle: {
                            color: isLightTheme.value ? "#5a5959" : "#fff",
                        },
                        axisLabel: {
                            color: isLightTheme.value ? "#5a5959" : "#fff",
                            formatter: formatCell,
                        },
                        axisLine: {
                            lineStyle: {
                                color: isLightTheme.value ? "#5a5959" : "#fff",
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: isLightTheme.value ? "#5a5959" : "#fff",
                            },
                        },
                    },
                    series: [
                        {
                            name: "Allowed pageviews",
                            type: "bar",
                            stack: "pageviews",
                            itemStyle: {
                                color: isLightTheme.value ? "#1dce80" : "#a8ef4f",
                            },
                            data: props.allowedPageviews,
                        },
                        {
                            name: "Blocked pageviews",
                            type: "bar",
                            stack: "pageviews",
                            itemStyle: {
                                color: isLightTheme.value ? "#de350b" : "#c10000",
                            },
                            data: props.blockedPageviews,
                        },
                    ],
                };
                myChart.setOption(option);
            }
        };

        onMounted(() => {
            myChart = echarts.init(chart.value);
            renderChart();
        });

        watch(
            () => [props.dates, props.allowedPageviews, props.blockedPageviews, isLightTheme.value],
            () => {
                renderChart();
            }
        );

        onBeforeUnmount(() => {
            if (myChart) {
                myChart.dispose();
            }
        });

        return {
            chart,
        };
    },
};
</script>
<style scoped>
/* Můžete přidat vlastní styly zde */
</style>
