export const actionTypes = {
    getData: "[payment-approval] Get data",
    getSelectValues: "[payment-approval] Get select values",
    addSelectValue: "[payment-approval] Add select value",
    approveChecked: "[payment-approval] Approve checked",
};

export const mutationTypes = {
    paymentApprovalGetDataStart: "[payment-approval] payment-approval get data start",
    paymentApprovalGetDataSuccess: "[payment-approval] payment-approval get data success",
    paymentApprovalGetDataFailure: "[payment-approval] payment-approval get data failure",
    paymentApprovalAddSelectValue: "[payment-approval] payment-approval add select value",
    paymentApprovalApproveChecked: "[payment-approval] payment-approval approve checked:",

    paymentApprovalGetValuesSelectedStart: "[payment-approval] payment-approval get values selected start",
    paymentApprovalGetValuesSelectedSuccess: "[payment-approval] payment-approval get values selected success",
    paymentApprovalGetValuesSelectedFailure: "[payment-approval] payment-approval get values selected failure",
};

export const getterTypes = {
    getPaymentApproval: "[payment-approval] Get payment-approval",
    getPaymentApprovalValuesSelected: "[payment-approval] Get payment-approval ",
};
