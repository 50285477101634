<template>
    <div ref="chart" style="width: 100%; height: 500px"></div>
</template>
<script>
import * as echarts from "echarts";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { getItem } from "@/store/persistantStorage";
import { formatImpressions } from "@/helpers/graphs";
import { dateFormatAsText, numberFormatImpressions, numberFormatWithSpace } from "@/helpers/data";

export default {
    name: "WebsImpressionsStackedBarChart",
    props: {
        earningsCsvData: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const chart = ref(null);
        let myChart = null;
        const isLightTheme = computed(() => {
            return getItem("theme") == "light";
        });
        const getWebsites = computed(() => {
            return [...new Set(props.earningsCsvData.map((item) => item.website))].sort();
        });
        const getDates = computed(() => {
            const allDates = props.earningsCsvData.map((item) => item.date);
            return [...new Set(allDates)].sort((a, b) => new Date(a) - new Date(b));
        });
        const transformData = computed(() => {
            const websites = getWebsites.value;
            const groupedData = websites.reduce((acc, website) => {
                acc[website] = props.earningsCsvData
                    .filter((item) => item.website === website)
                    .sort((a, b) => new Date(a.date) - new Date(b.date));
                return acc;
            }, {});
            const series = websites.map((website) => {
                const websiteData = groupedData[website] || [];
                return {
                    name: website,
                    type: "bar",
                    stack: "Total",
                    data: websiteData.map((item) => item.impressions),
                    itemStyle: {
                        borderWidth: 0, // Přidání borderu mezi hodnoty ve sloupcích
                        // borderColor: isLightTheme.value ? "#ffffff" : "#000000",
                        borderColor: "#ffffff",
                    },
                    label: {
                        show: getDates.value.length < 60 ? true : false,
                        position: "inside",
                        formatter: formatImpressions,
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                };
            });
            return series.filter((serie) => serie.data.length > 0);
        });

        onMounted(() => {
            myChart = echarts.init(chart.value);
            const option = {
                title: {
                    text: "Impressions",
                    textStyle: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (params) {
                        let result = "<div>" + dateFormatAsText(params[0].name) + "</div>";
                        let totalValue = 0;
                        params.forEach(function (item) {
                            totalValue += item.value;
                        });
                        result += `<div style="padding-bottom: 0.25em;">Total: ${numberFormatWithSpace(
                            totalValue
                        )}</div>`;
                        params.forEach(function (item) {
                            result +=
                                item.marker + item.seriesName + ": " + numberFormatWithSpace(item.value) + "<br/>";
                        });
                        return result;
                    },
                },
                legend: {
                    data: getWebsites.value,
                    bottom: 0,
                    itemGap: 25, // Nastavení mezery mezi položkami v legendě
                    textStyle: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                        fontSize: 15,
                        fontFamily: "Open Sans",
                    },
                },
                grid: {
                    left: "1%",
                    right: "1%",
                    top: "10%",
                    bottom: "10%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    data: getDates.value,
                    axisLabel: {
                        formatter: dateFormatAsText,
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: isLightTheme.value ? "#000000" : "#ffffff",
                        },
                    },
                    axisLabel: {
                        formatter: numberFormatImpressions,
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                series: transformData.value,
            };
            myChart.setOption(option);
        });
        onBeforeUnmount(() => {
            if (myChart) {
                myChart.dispose();
            }
        });
        return {
            chart,
        };
    },
};
</script>
<style scoped></style>
