<template>
    <LaBaseCard v-if="website?.hash">
        <LaNumberList :items="listItems">
            <template #script>
                <div class="text-container">
                    <p class="text">
                        To start measuring the blockrate on your website, you have to install our Javascript tag in your
                        HTML.
                    </p>
                    <p class="text">
                        Copy the code and paste it <strong>at the end of the BODY section</strong> of each page of your
                        website. Make sure the script doesn't get altered in any way.
                    </p>
                    <p class="text">
                        The Javascript tag is unique for each website, after installing it, check status of your website
                        in Websites tab. If status is `detected`, it is properly installed. Once the script is present,
                        it will start collecting data, you can see the results in the Dashboard tab 2 hours after
                        installation.
                    </p>
                </div>
                <div class="copy-code-container" id="kununu-script">
                    <div class="copy-code-content">
                        {{ kununuScript }}
                    </div>
                    <LaBaseButton
                        data-test="copy-code"
                        icon-left="note-multiple-outline"
                        @click.stop.prevent="copyScript"
                        text="Copy measurement code"
                    />
                </div>
            </template>
            <template #adstxt>
                <div class="text-container">
                    <p class="text">
                        Make sure your site serves the ads.txt file properly:
                        <a :href="adsTxtLink" target="_blank" rel="noreferrer">{{ adsTxtLink }}</a
                        >.
                        <br />
                        <em>WARNING:</em> Some sites prevent the ads.txt file from opening in browsers. This does not
                        necessarily mean it's not served properly to the adsystem bots/scrapers.
                    </p>
                </div>
                <div class="text-container">
                    <p class="text">Insert the following code on a new line in your ads.txt.</p>
                </div>
                <div class="copy-code-container" id="ads-txt">
                    <div class="copy-code-content">
                        {{ adsTxtCode }}
                    </div>
                    <LaBaseButton
                        data-test="copy-code"
                        icon-left="note-multiple-outline"
                        @click.stop.prevent="copyAdsTxt"
                        text="Copy ads.txt code"
                    />
                </div>
            </template>

            <template #vendors>
                <div class="text-container">
                    <table id="vendors-table">
                        <thead>
                            <tr>
                                <td>ID</td>
                                <td>Company Name</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>755</td>
                                <td>Google Advertising Products</td>
                            </tr>
                            <tr>
                                <td>91</td>
                                <td>Criteo SA</td>
                            </tr>
                            <tr>
                                <td>76</td>
                                <td>PubMatic, Inc</td>
                            </tr>
                            <tr>
                                <td>50</td>
                                <td>Adform A/S</td>
                            </tr>
                            <tr>
                                <td>32</td>
                                <td>Xandr, Inc.</td>
                            </tr>
                            <tr>
                                <td>241</td>
                                <td>OneTag Limited</td>
                            </tr>
                            <tr>
                                <td>732</td>
                                <td>Performax.cz, s.r.o.</td>
                            </tr>
                            <tr>
                                <td>52</td>
                                <td>Magnite, Inc.</td>
                            </tr>
                            <tr>
                                <td>142</td>
                                <td>Media.net Advertising FZ-LLC</td>
                            </tr>
                            <tr>
                                <td>69</td>
                                <td>OpenX</td>
                            </tr>
                            <tr>
                                <td>45</td>
                                <td>Equativ</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </LaNumberList>
    </LaBaseCard>
</template>

<script>
import { computed, ref } from "vue";
import store from "@/store/store";
import LaBaseCard from "@/components/BaseCard";
import LaNumberList from "@/components/NumberList";
import LaBaseButton from "@/components/button/BaseButton";
import { actionTypes } from "@/store/Kununu/types";
import { mutationTypes as flashMessagesMutationTypes } from "@/store/FlashMessages/types";
import { MESSAGE_STATE } from "@/store/FlashMessages/state";
import axios from "@/plugins/axios";

function copyCode(code, codeName) {
    const textarea = document.createElement("textarea");
    textarea.style.cssText = "position:fixed;z-index:-9999;opacity:0;";
    textarea.value = code;

    document.body.appendChild(textarea);
    textarea.select();

    let message;
    try {
        document.execCommand("copy");
        message = {
            header: codeName + " was copied",
            messageState: MESSAGE_STATE.SUCCESS,
        };
    } catch (e) {
        message = {
            header: codeName + " cannot be copied'",
            messageState: MESSAGE_STATE.WARN,
        };
    }
    document.body.removeChild(textarea);
    store.commit(flashMessagesMutationTypes.addMessageToQueue, message);
}

export default {
    name: "LaInstallSteps",
    components: {
        LaBaseButton,
        LaBaseCard,
        LaNumberList,
    },
    props: {
        website: {
            required: true,
            type: Object,
        },
    },

    setup(props) {
        const adsTxtLink = computed(() => {
            let link = props.website.site_url;
            if (!link.endsWith("/")) {
                link += "/";
            }
            return link + "ads.txt";
        });
        store.dispatch(actionTypes.getData);

        const kununuScript = computed(() => {
            const innerScript = "<script src='https://cdn.ltba.io/acc/loader.js?aab=loader'></" + "script>";
            return `<iframe srcdoc="${innerScript}" style="display: none" data-web="${props.website.domain}" data-hash="${props.website.hash}"></iframe>`;
        });
        const copyScript = () => {
            copyCode(kununuScript.value, "Blockrate measurement code");
        };

        const adsTxtCode = ref("");
        axios
            .get(process.env.VUE_APP_API_URL + "/public/v2/ads-txt")
            .then((response) => {
                if (response && response.status && response.status == 200) {
                    adsTxtCode.value = response.data;
                } else {
                    console.error("Chyba: Neplatná odpověď od serveru");
                }
            })
            .catch((error) => {
                console.error(error);
            });

        const copyAdsTxt = () => {
            copyCode(adsTxtCode.value, "Ads.txt code");
        };

        const listItems = computed(() => [
            {
                heading: "Insert our Measurement Code into your page",
                name: "script",
            },
            {
                heading: "Insert our code into ads.txt",
                name: "adstxt",
            },
            {
                heading: "Make sure, you have all these vendors in your CMP",
                name: "vendors",
            },
        ]);

        return { kununuScript, copyScript, listItems, adsTxtLink, adsTxtCode, copyAdsTxt };
    },
};
</script>

<style lang="scss" scoped>
.text {
    margin-bottom: 0.5rem;
    &-container {
        margin-bottom: 2rem;
    }
}

.copy-code {
    &-content {
        margin-bottom: 2rem;
        font-family: Courier New;
        @include --big-mobile-viewport {
            font-size: 0.9rem;
        }
        @include --mobile-viewport {
            font-size: 0.8rem;
        }
    }
    &-container {
        word-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
        background-color: $blue;
        border-radius: $normal-radius;
        padding: 2rem;
        color: $dark-grey;
    }
}
#vendors-table {
    border-collapse: collapse;
}
#vendors-table th:first-child,
#vendors-table td:first-child {
    width: 50px;
}

#vendors-table th:nth-child(2),
#vendors-table td:nth-child(2) {
    width: 250px;
}
#vendors-table th,
#vendors-table td {
    border: 1px solid gray;
    padding: 10px;
}

body.dark {
    .copy-code {
        &-container {
            background-color: $light-black;
        }
    }

    strong {
        //background: $green-gradient;
        background: $green-2;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>
