export const actionTypes = {
    getData: "[payment method] Get data",
    editData: "[payment method] Edit data",
};

export const mutationTypes = {
    paymentMethodGetDataStart: "[payment method] Payment method get data start",
    paymentMethodGetDataSuccess: "[payment method] Payment method get data success",
    paymentMethodGetDataFailure: "[payment method] Payment method get data failure",
    paymentMethodEditDataStart: "[payment method] Payment method edit data start",
    paymentMethodEditDataSuccess: "[payment method] Payment method edit data success",
    paymentMethodEditDataFailure: "[payment method] Payment method edit data failure",
    paymentMethodFormErrors: "[payment method] Payment method form errors",
};

export const getterTypes = {
    getPaymentMethod: "[payment method] Get payment method",
    getPaymentMethodFormErrors: "[payment method] Get payment method form errors",
};
