<template>
    <div>
        <h3>Page was not found.</h3>
        <a href="/">Home</a>
    </div>
</template>

<script>
export default {
    name: "LaNotFoundView",
};
</script>

<style scoped></style>
