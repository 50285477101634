import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.blocRateStart](state) {
            state.isLoading = true;
            //state.data = null;
            //state.metadata = null;
        },
        [mutationTypes.blocRateSuccess](state, data) {
            state.isLoading = false;
            state.data = data.data;
            state.metadata = data.metadata;
        },
        [mutationTypes.blocRateFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.grandTotalBRStart](state) {
            state.isGtLoading = true;
            state.grandTotal = 0;
        },
        [mutationTypes.grandTotalBRSuccess](state, data) {
            state.isGtLoading = false;
            state.grandTotal = data;
        },
        [mutationTypes.grandTotalBRFailure](state) {
            state.isGtLoading = false;
        },
    },
    getters: {
        [getterTypes.getBlockRateData](state) {
            return state.data;
        },
        [getterTypes.getBlockRateMetadata](state) {
            return state.metadata;
        },
        [getterTypes.isLoading](state) {
            return state.isLoading || state.isGtLoading;
        },
        [getterTypes.getBlockRateGrandTotal](state) {
            return state.grandTotal;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
