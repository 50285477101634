<template>
    <div class="number-list">
        <div
            data-test="list-options"
            class="number-list-container"
            v-for="(item, index) in items"
            :key="item.name"
            :class="!item.active && activable ? 'inActive' : 'isActive'"
        >
            <div data-test="list-number" class="number-list-dot">
                <span>{{ index + 1 }}</span>
            </div>
            <div class="number-list-content">
                <LaPageHeader :heading="item.heading" class="mt-0" />
                <slot :name="item.name" />
            </div>
        </div>
    </div>
</template>

<script>
import LaPageHeader from "@/components/PageHeader";

export default {
    name: "LaNumberList",
    components: {
        LaPageHeader,
    },
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        activable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.number-list {
    &-container {
        display: flex;
        align-items: baseline;
        @include --big-mobile-viewport {
            display: block;
        }

        &.isActive {
            opacity: 1;
        }

        &.inActive {
            opacity: 0.4;
        }
    }

    &-content {
        margin-bottom: 2rem;
    }

    &-dot {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 90%;
        flex-direction: column;
        background-color: $shiny-blue;
        border: 0.13rem solid $light-shiny-blue;
        color: $white;
        width: 0;
        height: 0;
        padding: 1.2rem;
        font-size: 1.2rem;
        font-weight: 600;
        margin-right: 2rem;
        @include --tablet-viewport {
            font-size: 1rem;
            padding: 1rem;
            margin-right: 1.5rem;
        }
    }
}

body.dark {
    .number-list {
        &-dot {
            //background: $green-gradient;
            background: $green-2;
        }
    }
}
</style>
