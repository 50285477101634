export const actionTypes = {
    getLastFee: "[fee] Get last fee",
    getAll: "[fees] Get fees data",
    createFee: "[fee] Create fee data",
    editFee: "[fee] Edit fee data",
    deleteFee: "[fee] Delete fee data",
    selectPublisher: "[fee] Select publisher",
    selectWebsite: "[fee] Select website",
};

export const mutationTypes = {
    getFeesDataStart: "[fees] Fees get data start",
    getFeesDataSuccess: "[fees] Fees get data success",
    getFeesDataFailure: "[fees] Fees get data failure",

    createFeeDataStart: "[fee] Fee create data start",
    createFeeDataSuccess: "[fee] Fee create data success",
    createFeeDataFailure: "[fee] Fee create data failure",

    editFeeDataStart: "[fee] Fee edit data start",
    editFeeDataSuccess: "[fee] Fee edit data success",
    editFeeDataFailure: "[fee] Fee edit data failure",

    selectPublisherStart: "[fee] Select publisher start",
    selectPublisherSuccess: "[fee] Select publisher success",
    selectPublisherFailure: "[fee] Select publisher failure",

    selectWebsiteStart: "[fee] Select website start",
    selectWebsiteSuccess: "[fee] Select website success",
    selectWebsiteFailure: "[fee] Select website failure",
};

export const getterTypes = {
    getFees: "[fees] Get all",
    getPublisherSelected: "[fees] Get publisher selected",
    getWebsiteSelected: "[fees] Get website selected",
};
