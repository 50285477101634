<template>
    <BaseCard>
        <la-fee-table v-if="fees" :data="fees.feetimelines"></la-fee-table>
        <paginator :pages="fees.pages"></paginator>
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard";
import LaFeeTable from "@/components/fees/FeeTable";
import Paginator from "@/components/paginators/FeePaginator.vue";

export default {
    name: "FeeCard",
    components: {
        BaseCard,
        LaFeeTable,
        Paginator,
    },
    props: {
        fees: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped></style>
