import { getItem } from "@/store/persistantStorage";

const filter = {
    authorization: false,
    monetization: true,
    registration: true,
    verification: false,
    dailydata: false,
};

const loaded = getItem("eventFilter");
for (const v in getItem("eventFilter")) {
    if (filter[v] !== undefined) {
        filter[v] = loaded[v];
    }
}

export const DEFAULT_STATE = {
    data: null,
    isLoading: false,
    filter: filter,
};
