<template>
    <BaseCard>
        <div class="simple-card">
            <div class="simple-card-header">
                <p class="simple-card-heading simple-card-heading--sub">{{ title }}</p>
                <h1 class="simple-card-heading" :class="color" :data-value="value" data-cy="card-header-value">
                    {{ prefix }}{{ formatCell(value, isDecimal) }}{{ postfix }}
                </h1>
            </div>
            <div v-if="title_2" class="simple-card-header">
                <p class="simple-card-heading simple-card-heading--sub">{{ title_2 }}</p>
                <h1 class="simple-card-heading" :class="color" :data-value="value" data-cy="card-header-value">
                    {{ prefix }}{{ formatCell(value_2, isDecimal) }}{{ postfix }}
                </h1>
            </div>
            <div class="simple-card-content">
                <slot name="default" />
            </div>
            <div class="simple-card-footer" v-if="!!$slots.footer">
                <slot name="footer" />
            </div>
        </div>
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard";
import { formatCell } from "@/helpers/filters";

export default {
    name: "SimpleCard",
    components: {
        BaseCard,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number],
            required: true,
        },
        title_2: {
            type: String,
            required: false,
        },
        value_2: {
            type: [String, Number],
            required: false,
        },
        prefix: {
            type: String,
            required: false,
            default: "",
        },
        postfix: {
            type: String,
            required: false,
            default: "",
        },
        isDecimal: {
            type: Boolean,
            required: false,
            default: false,
        },
        color: {
            type: String,
            required: false,
            default: "",
            validator: (value) => {
                return !value ? true : ["success", "warning"].indexOf(value) !== -1;
            },
        },
    },
    setup() {
        return { formatCell };
    },
};
</script>

<style lang="scss" scoped>
.simple-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-content {
        margin-top: auto;
    }
    &-footer {
        border-top: 1px solid $dark-grey;
        padding-top: 1.5rem;
        margin-top: 1.5rem;
    }
}
.simple-card-heading {
    font-size: 3rem;
    text-align: center;
    color: $blue;
    margin: 0.8rem 0;
    padding: 0;
    font-weight: 400;
    text-transform: none;
    @include --big-mobile-viewport {
        font-size: 2rem;
    }
    &--sub {
        font-size: 1.2rem;
        font-weight: 400;
        @include --big-mobile-viewport {
            font-size: 1rem;
        }
    }
    &.success {
        color: $green;
    }
    &.warning {
        color: $red;
    }
}
body.dark {
    .simple-card-heading {
        color: $text;

        &.success {
            //background: $green-gradient;
            background: $green-2;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &.warning {
            background: $red-gradient;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
</style>
