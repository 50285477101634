<template>
    <div class="container-content container-indent container-flex">
        <LaBaseCard>
            <div class="container-legal">
                <h2 class="page-heading">Cookie Policy</h2>
                <p>
                    We are Let There Be Ads s.r.o., residing at Za Ženskými domovy 3379/1, Smíchov, 150 00 Prague 5,
                    Czech Republic, IČO (Company Identification Number): 05473438, registered in the Commercial Register
                    maintained by the Municipal Court in Prague, Section C, File 264241 and we operate the following
                    websites <a href="https://lettherebeads.io">lettherebeads.io</a> and
                    <a href="https://dashboard.lettherebeads.io">dashboard.lettherebeads.io</a> on which we use cookies.
                </p>
                <p>
                    You can contact us at e-mail: <a href="mailto:hi@lettherebeads.io">hi@lettherebeads.io</a> phone:
                    +44 20 4577 0383
                </p>
                <h2 class="page-heading">What are cookies</h2>
                <p>
                    Cookies are short, small text files that are stored in your browser on your device (mobile phone,
                    computer, etc.). When you return to our website at a later date, your browser will send the cookie
                    back to us and our website will remember you. You will not have to set your language, currency, etc.
                    again. It also allows us to customize the website for you and to show you the content you are
                    interested in or you have saved on the website. Cookies give us feedback on our website and let us
                    know what you are interested in, what you come back for and what needs to be improved. No need to
                    worry. Cookies are used by most websites these days and you can delete them from your browser at any
                    time.
                </p>

                <p>Depending on from whom the cookies come from, they are divided into:</p>
                <ul>
                    <li>First party cookies – these are limited to the domain of the website you are viewing.</li>
                    <li>
                        Third party cookies – they are placed on the website via a script from another domain and can
                        track you across multiple domains (not just ours).
                    </li>
                </ul>

                <p>According to the expiry date, we divide cookies into:</p>
                <ul>
                    <li>Short-term (session) – they are deleted from your browser as soon as you close it.</li>
                    <li>
                        Long-term (persistent) – they are not deleted after you close your browser and are retained.
                        They are only deleted after a certain amount of time has passed. The length of the time period
                        depends on the specific cookies and your browser settings. Of course, you can also delete such
                        cookies earlier, manually in your browser.
                    </li>
                </ul>

                <p>We then classify cookies according to their purpose into the following categories:</p>
                <ul>
                    <li>
                        Technical (necessary or functional) – these are cookies without which the website would not be
                        operational. Our website needs them to function properly. Without them, it would not be
                        possible, for example, to search, fill in a form or play a video.
                    </li>
                    <li>
                        Preferential – they help the website remember your language, articles you have read or videos
                        you have watched. We can then show you content that interests you or that you haven't seen yet.
                    </li>
                    <li>
                        Analytical and Marketing (statistical) – they help us understand what works and what doesn't on
                        the website. They allow us to find out what content and features are popular, on what types of
                        devices view the website, in other words, they allow us to improve not only our website but more
                        importantly our content.
                    </li>
                </ul>
                <h2 class="page-heading">Used cookies</h2>
                <p>Technical cookies</p>

                <table>
                    <tr>
                        <th>Name</th>
                        <th>Purpose</th>
                        <th>Expiration</th>
                        <th>Access to information</th>
                    </tr>
                    <tr>
                        <td>localStorage</td>
                        <td>Ensures log in functionality</td>
                        <td>none</td>
                        <td>lettherebeads.io and performax.cz</td>
                    </tr>
                </table>

                <p>Analytical cookies</p>
                <table>
                    <tr>
                        <th>Name</th>
                        <th>Purpose</th>
                        <th>Expiration</th>
                        <th>Access to information</th>
                    </tr>
                    <tr>
                        <td>_ga</td>
                        <td>
                            Distinguishes unique users by assigning a randomly generated number as a client identifier.
                        </td>
                        <td>2 years</td>
                        <td>google.com (google analytics)</td>
                    </tr>
                </table>

                <h2 class="page-heading">Other processors and their conditions</h2>
                <p>
                    Cookies are also processed by other processors according to their cookie and personal data policies:
                </p>
                <ul>
                    <li>
                        We use Google Analytics, operated by Google Ireland Ltd., with its registered office at Google
                        Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland
                        <ul>
                            <li>
                                <a target="_blank" href="https://policies.google.com/technologies/cookies"
                                    >https://policies.google.com/technologies/cookies</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        We use the measurement code Facebook pixel, operated by Meta Platforms Ireland Ltd., 4 Grand
                        Canal Square Grand Canal Harbour, Dublin 2, Ireland
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0"
                                    >https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        We use InMobi Pte Ltd as a CMP provider, with its registered office at 30 Cecil Street, #19-08,
                        Prudential Tower, Singapore 049712 – Singapore
                        <ul>
                            <li>
                                <a target="_blank" href="https://www.inmobi.com/cookie-policy"
                                    >https://www.inmobi.com/cookie-policy</a
                                >
                            </li>
                        </ul>
                    </li>
                </ul>

                <h2 class="page-heading">How to withdraw consent or change cookie settings"</h2>
                <p>
                    If you have consented to cookies, you can withdraw your consent at any time without harm. You can
                    also change your cookie settings or allow or refuse only certain categories of cookies on this
                    website.
                </p>
                <p>You can manage cookies on this website by clicking on the "Privacy" button.</p>

                <h2 class="page-heading">Blocking cookies in your browser</h2>
                <p>
                    Browsers allow you to block the storage of cookies directly in your browser. However, if you disable
                    the use of all cookies, some parts (not only) of our website may not function properly. The
                    customization in your browser need to be made separately for each of your devices (laptop, phone,
                    tablet).
                </p>

                <p>
                    You can always find information on how to customize your browser to store cookies on your browser's
                    website:‍
                </p>
                <div class="container-flex">
                    <div>
                        <p>
                            <a target="_blank" href="https://support.google.com/accounts/answer/61416">Chrome</a><br />
                            <a target="_blank" href="https://support.mozilla.org/cs/kb/blokovani-cookies">Firefox‍</a
                            ><br />
                            <a target="_blank" href="https://support.apple.com/cs-cz/guide/safari/sfri11471/mac"
                                >Safari</a
                            ><br />
                            <a
                                target="_blank"
                                href="https://napoveda.seznam.cz/cz/email/seznam.cz-prohlizec/smazani-docasnych-souboru-cookies-seznam/"
                                >Seznam</a
                            ><br />
                        </p>
                    </div>
                    <div>
                        <p>
                            <a
                                target="_blank"
                                href="https://support.microsoft.com/cs-cz/microsoft-edge/odstran%C4%9Bn%C3%AD-soubor%C5%AF-cookie-v-aplikaci-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                                >Microsoft Edge</a
                            ><br />
                            <a
                                target="_blank"
                                href="https://support.microsoft.com/cs-cz/help/17442/windows-internet-explorer-delete-manage-cookies"
                                >Internet Explorer‍</a
                            ><br />
                            <a target="_blank" href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a
                            ><br />
                            <a
                                target="_blank"
                                href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&hl=en"
                                >Android</a
                            ><br />
                        </p>
                    </div>
                </div>
                <p>Where you can find more information about cookies</p>

                <p>
                    Finally, please note that this Cookie Policy has been prepared as of February 26, 2024 and may be
                    subject to change in the future.
                </p>
            </div>
        </LaBaseCard>
    </div>
</template>

<script>
import LaBaseCard from "@/components/BaseCard";

export default {
    name: "CookiePolicy",
    components: {
        LaBaseCard,
    },
};
</script>

<style lang="scss" scoped>
.container-legal {
    ul {
        margin-left: 2.5rem;
    }

    table {
        display: block;
    }
}

.container-legal th,
.container-legal td {
    border: 1px solid black;
}

body.dark {
    .container-legal th,
    .container-legal td {
        border: 1px solid white;
    }
}
</style>
