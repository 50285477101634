<template>
    <template v-if="blockRateMetadata">
        <div class="columns" v-if="loading">
            <div class="column" v-for="index in 4" :key="index">
                <LaCardLoader class="small-card" />
            </div>
        </div>
        <div class="columns" v-else>
            <div class="column">
                <SimpleCard data-test="card" title="Total pageviews" :value="pageViews" />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="card"
                    title="Allowing ads"
                    :value="allowingAds"
                    postfix="%"
                    :is-decimal="true"
                    color="success"
                />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="card"
                    title="Blocking ads"
                    :value="blockingAds"
                    postfix="%"
                    :is-decimal="true"
                    color="warning"
                />
            </div>
            <div class="column">
                <SimpleCard
                    data-test="card"
                    title="Lost revenue"
                    :value="revenueLost"
                    :postfix="' ' + currencySymbol"
                    :is-decimal="true"
                    color="warning"
                />
            </div>
        </div>

        <div class="columns" v-if="blockRateData?.length">
            <div class="column">
                <LaBlockRateDataCard
                    :block-rate-data="blockRateData"
                    :block-rate-metadata="blockRateMetadata"
                    :block-rate-loading="loading"
                    :domains="domains"
                    @device-change="deviceChange"
                />
            </div>
        </div>
    </template>
    <LaNoData
        v-else
        description="Check if you added the measurement script correctly to your page or contact our support."
    />
</template>

<script>
import { ref, computed, watch } from "vue";
import store from "@/store/store";
import LaNoData from "@/components/NoData";
//import LaContentLoader from "@/components/loaders/ContentLoader";
import LaCardLoader from "@/components/loaders/CardLoader";
import LaBlockRateDataCard from "@/components/dashboard/BlockRateDataCard";
import SimpleCard from "@/components/SimpleCard";
import { getCurrencySymbol, getExchangeRateFromCzk, PeriodsEnum } from "@/helpers/data";
import { actionTypes, getterTypes } from "@/store/BlockRate/types";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

let device = "desktop";

export default {
    name: "LaBlockRateCards",
    components: {
        SimpleCard,
        LaBlockRateDataCard,
        //LaContentLoader,
        LaCardLoader,
        LaNoData,
    },
    props: {
        payload: {
            type: Object,
            required: true,
        },
        websites: {
            required: true,
            type: Array,
        },
    },
    setup(props) {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));

        const loading = computed(() => store.getters[getterTypes.isLoading]);
        const domains = computed(() => props.websites?.map(({ domain }) => domain) || null);
        const blockRateData = computed(() => store.getters[getterTypes.getBlockRateData]);
        const blockRateMetadata = computed(() => store.getters[getterTypes.getBlockRateMetadata]);
        const lostRevenueGrandTotal = computed(() => store.getters[getterTypes.getBlockRateGrandTotal] || 0);
        const pageViews = computed(() => blockRateMetadata.value?.["total_pageview"]);
        const allowingAds = computed(() => blockRateMetadata.value?.["percent"]["non_blocking"]);
        const blockingAds = computed(() => blockRateMetadata.value?.["percent"]["blocking"]);
        const revenueLost = computed(() => blockRateMetadata.value?.["lost_revenue"]);

        const revenueLostYesterday = ref(0);
        const revenueLostMonth = ref(0);

        const fetchData = (payload) => {
            payload.device = device;
            store.dispatch(actionTypes.getGrandTotal, payload);
            store.dispatch(actionTypes.getData, payload).then((data) => {
                if (payload.period === PeriodsEnum.Yesterday) {
                    revenueLostYesterday.value = data?.metadata?.["lost_revenue"] || 0;
                }
                if (payload.period === PeriodsEnum.ThisMonth) {
                    revenueLostMonth.value = data?.metadata?.["lost_revenue"] || 0;
                }
            });
            if (payload.period !== PeriodsEnum.Yesterday) {
                store
                    .dispatch(actionTypes.getData, {
                        ...payload,
                        period: PeriodsEnum.Yesterday,
                        noCommit: true,
                    })
                    .then((data) => {
                        revenueLostYesterday.value = data?.metadata?.["lost_revenue"] || 0;
                    });
            }
            if (payload.period !== PeriodsEnum.ThisMonth) {
                store
                    .dispatch(actionTypes.getData, {
                        ...payload,
                        period: PeriodsEnum.ThisMonth,
                        noCommit: true,
                    })
                    .then((data) => {
                        revenueLostMonth.value = data?.metadata?.["lost_revenue"] || 0;
                    });
            }
        };

        const fetchBlockrateData = (payload) => {
            payload.device = device;
            store.dispatch(actionTypes.getData, payload);
        };

        const deviceChange = (dev) => {
            device = dev;
            console.log("Device change", device);
            fetchBlockrateData(props.payload);
        };

        watch(
            () => props.payload,
            () => fetchData(props.payload),
            { immediate: true }
        );

        return {
            loading,
            pageViews,
            allowingAds,
            blockingAds,
            revenueLost,
            domains,
            blockRateData,
            blockRateMetadata,
            lostRevenueGrandTotal,
            revenueLostYesterday,
            revenueLostMonth,
            deviceChange,
            exchangeRate,
            currencySymbol,
        };
    },
};
</script>
