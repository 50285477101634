import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";
import { setItem } from "@/store/persistantStorage";

export default {
    actions,
    mutations: {
        [mutationTypes.eventsGetDataStart](state) {
            state.isLoading = true;
            state.data = null;
        },
        [mutationTypes.eventsGetDataSuccess](state, data) {
            state.isLoading = false;
            state.data = data.events;
        },
        [mutationTypes.eventsGetDataFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.setFilter](state, filter) {
            setItem("eventFilter", filter);
            state.filter = filter;
        },
    },
    getters: {
        [getterTypes.getEvents](state) {
            return state.data;
        },
        [getterTypes.getFilter](state) {
            return state.filter;
        },
        [getterTypes.isLoading](state) {
            return state.isLoading;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
