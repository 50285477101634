<template>
    <switch-theme-message />
    <div>
        <LaPageHeader heading="Websites">
            <template #controls>
                <LaAddWebsite />
            </template>
        </LaPageHeader>
        <WebsitesTable data-test="websites-table" :loading="!websites" :data="websites" />

        <LaDialog name="StartMonetizeDialog" :persistent="false" width="800">
            <LaStartMonetizeDialog />
        </LaDialog>

        <LaDialog name="websiteDelete">
            <LaDeleteConfirmDialog item-name="website" item-property="domain" />
        </LaDialog>

        <LaDialog name="MonetizationRequestedDialog" :persistent="false" width="800">
            <LaChangeMonetizeDialog
                :monetize-status="'in_progress'"
                :monetize-status-name="'Approved'"
                button-type="success"
                button-text="Yes, set as approved"
            />
        </LaDialog>

        <LaDialog name="RejectMonetizeDialog" :persistent="false" width="800">
            <LaRejectedMonetizeDialog
                :monetize-status="'rejected'"
                :monetize-status-name="'Rejected'"
                button-type="error"
                button-text="Yes, rejected"
            />
        </LaDialog>
    </div>
</template>

<script>
import LaPageHeader from "@/components/PageHeader";
import WebsitesTable from "@/components/websites/WebsitesTable";
import LaAddWebsite from "@/components/button/AddWebsite";
import LaDialog from "@/components/dialog/Dialog";
import LaStartMonetizeDialog from "@/components/dialog/StartMonetizeDialog";
import LaDeleteConfirmDialog from "@/components/dialog/DeleteConfirmDialog";
import LaChangeMonetizeDialog from "@/components/dialog/ChangeMonetizeDialog";
import LaRejectedMonetizeDialog from "@/components/dialog/ChangeMonetizeDialog";
import store from "@/store/store";
import { computed } from "vue";
import { actionTypes as websiteActionTypes, getterTypes as websiteGetterTypes } from "@/store/Websites/types";
import SwitchThemeMessage from "@/components/SwitchThemeMessage.vue";

export default {
    name: "LaWebsitesView",
    components: {
        LaAddWebsite,
        LaDeleteConfirmDialog,
        LaPageHeader,
        WebsitesTable,
        LaDialog,
        LaStartMonetizeDialog,
        LaChangeMonetizeDialog,
        LaRejectedMonetizeDialog,
        SwitchThemeMessage,
    },
    setup() {
        const websites = computed(() => store.getters[websiteGetterTypes.getWebsites]);
        if (!websites.value) {
            store.dispatch(websiteActionTypes.getData);
        }
        return { websites };
    },
};
</script>

<style scoped></style>
