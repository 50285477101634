<template>
    <LaBaseTag text="Request pending" type="neutral" />
</template>

<script>
import LaBaseTag from "@/components/BaseTag";

export default {
    name: "LaPendingMonetizeButton",
    components: {
        LaBaseTag,
    },
};
</script>

<style scoped></style>
