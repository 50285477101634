<template>
    <LaBaseTable data-test="earnings-table" :headers="headers" :items="data" stripped>
        <template #item-time="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ formatDateTime(new Date(item.time), false) }}</span>
                <div class="second" v-if="$mq.max.mobile">{{ formatCell(item.time, false) }}</div>
            </div>
        </template>

        <template #item-event="{ item }">
            <div class="is-flex is-flex-direction-column">
                <event-label :event="item" />
            </div>
        </template>
    </LaBaseTable>
</template>

<script>
import LaBaseTable from "@/components/BaseTable";
import EventLabel from "@/components/events/EventLabel.vue";
import { formatDateTime, formatCell } from "@/helpers/filters.js";

export default {
    name: "LaEventTable",
    components: {
        LaBaseTable,
        EventLabel,
    },
    props: {
        data: {
            required: true,
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: "time",
                    value: "time",
                    direction: -1,
                },
                {
                    text: "category",
                    value: "category",
                },
                {
                    text: "event",
                    value: "event",
                },
                {
                    text: "user",
                    value: "user",
                },
                {
                    text: "publisher",
                    value: "publisher",
                },
                {
                    text: "website",
                    value: "web",
                },
            ];
        },
    },
    setup() {
        return { formatDateTime, formatCell };
    },
};
</script>

<style lang="scss" scoped>
.second {
    font-size: 0.8rem;
    opacity: 0.8;
}
</style>
