<template>
    <LaBaseDropdown
        :disabled="disabled"
        class="select"
        data-test="base-select"
        :icon="false"
        ref="dropdown"
        :stretch="stretch"
    >
        <template #trigger>
            <slot name="trigger">
                <div class="select-trigger" :class="disabled ? 'disabled' : ''">
                    <slot name="selectedItem" :item="modelValue">
                        {{ displayValue }}
                    </slot>
                    <LaBaseIcon class="select-trigger-icon" name="chevron-down" />
                </div>
            </slot>
        </template>
        <template #default>
            <div class="select-content">
                <div v-if="searchable" class="select-content-search">
                    <input
                        id="search"
                        type="text"
                        v-model="search"
                        data-cy="base-select-search"
                        placeholder="Search"
                        class="base-input search-input"
                        data-test="base-input"
                        @input="searchItem()"
                    />
                    <LaBaseIcon class="search-icon" name="magnify" />
                </div>
                <div v-if="multiple" class="select-option underline" @click.exact="selectAll()">
                    <LaBaseIcon class="check-icon" name="check" />
                    <span>Select all</span>
                </div>
                <div class="select-result" data-cy="select-result">
                    <span
                        data-test="base-option"
                        v-for="item in sortedItems"
                        :key="item[itemValue]"
                        class="select-option"
                        :data-cy="`${item[itemValue]}_item`"
                        :class="renderOptionClass(modelValue, item)"
                        @click.shift.exact="selectItem(item, true)"
                        @click.ctrl.exact="selectItem(item, true)"
                        @click.exact="selectItem(item)"
                    >
                        <span> {{ item[itemText] || item }}</span>
                    </span>
                    <span v-if="!!$slots.appendItem">
                        <slot name="appendItem" />
                    </span>
                </div>
            </div>
        </template>
    </LaBaseDropdown>
</template>

<script>
import LaBaseDropdown from "@/components/BaseDropdown";
import LaBaseIcon from "@/components/BaseIcon";
import { computed, ref } from "vue";

export default {
    name: "LaBaseSelect",
    components: {
        LaBaseDropdown,
        LaBaseIcon,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        itemValue: {
            type: String,
            required: false,
        },
        itemText: {
            type: String,
            required: false,
        },
        modelValue: {
            type: [Array, Object, String, Number],
            required: false,
        },
        stretch: {
            type: Boolean,
            default: true,
            required: false,
        },
        searchable: {
            type: Boolean,
            default: false,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        itemLabel: {
            type: String,
            default: "item(s)",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        selectAllDefaults: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const search = ref("");
        const sortedItems = ref(props.items);
        const searchItem = () => {
            const text = search.value;
            if (text === "") {
                sortedItems.value = props.items;
            } else {
                sortedItems.value = props.items.filter((item) => {
                    const i = item[props.itemText] || item;
                    return i.toLowerCase().indexOf(text.toLowerCase()) > -1;
                });
            }
        };

        const isSameOption = (val, option) =>
            (props.itemValue && val[props.itemValue] === option[props.itemValue]) || val === option;

        const optionSelected = (val, option) => {
            if (!val || option.disabled) {
                return false;
            }

            return Array.isArray(val) ? val.some((v) => isSameOption(v, option)) : isSameOption(val, option);
        };

        const renderOptionClass = (val, option) => {
            if (option.disabled) {
                return "disabled";
            }

            return optionSelected(val, option) ? "selected" : "";
        };

        const displayValue = computed(() => {
            const arrVal = props.modelValue
                ? Array.isArray(props.modelValue)
                    ? props.modelValue
                    : [props.modelValue]
                : [];
            switch (arrVal.length) {
                case 1:
                    return arrVal[0][props.itemText] || arrVal[0];
                case 0:
                    return `Select ${props.itemLabel}`;
                case props.items.length:
                    return `All ${props.itemLabel} selected.`;
                default:
                    return `${arrVal.length} ${props.itemLabel} selected.`;
            }
        });

        const dropdown = ref(null);
        const selectItem = (item, multipleKey) => {
            if (item.disabled) {
                dropdown.value.closeMenu();
                emit("onSelectDisabled", item);
                return;
            }
            let val;
            const isOptionSelected = optionSelected(props.modelValue, item);
            //const selectedOptions = props.modelValue.length;
            if (props.multiple) {
                if (multipleKey) {
                    if (isOptionSelected) {
                        val = props.modelValue.filter((opt) => !isSameOption(opt, item));
                    } else {
                        val = [...props.modelValue, item];
                    }
                } else {
                    val = [item];
                }
            } else {
                val = isOptionSelected ? null : item;
            }
            emit("update:modelValue", val);
            emit("onSelected", val);
            (!props.multiple || !multipleKey) && dropdown.value.closeMenu();
        };
        const selectAll = () => {
            let val = props.items.filter((i) => !i.disabled);
            console.log("selectAll", val);
            emit("update:modelValue", val);
            emit("onSelected", val);
            dropdown.value.closeMenu();
        };

        if (props.selectAllDefaults) {
            let val = props.items.filter((i) => !i.disabled);
            emit("update:modelValue", val);
            emit("onSelected", val);
        }

        return { displayValue, search, searchItem, sortedItems, selectItem, selectAll, renderOptionClass, dropdown };
    },
};
</script>

<style lang="scss">
.select {
    position: relative;

    &-trigger {
        border-radius: $normal-radius;
        border: 1px solid $dark-grey;
        min-width: 20rem;
        padding: 0.7rem 1rem;
        background-color: $white;
        color: $blue;
        font-size: 1rem !important;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        user-select: none;

        &-icon {
            padding-left: 1rem;
        }

        &.disabled {
            cursor: not-allowed;
            background: $dark-grey;
        }
    }

    &-content {
        border: 1px solid $dark-grey;
        border-radius: $normal-radius;
        &-search {
            position: relative;
            .search-input {
                padding-left: 2rem;
            }
            .search-icon {
                position: absolute;
                top: 0;
                left: 0.5rem;
                font-size: 1.3rem;
                line-height: 2.8rem;
            }
        }
    }

    &-result {
        max-height: 600px;
        overflow-y: scroll;
    }

    &-option {
        display: block;
        position: relative;
        font-size: 1rem;
        color: $light-grey;
        cursor: pointer;
        padding: 0.3rem 1rem;
        margin: 0.4rem 0;

        &:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    &-option.selected {
        color: $blue;

        &:after {
            content: "";
            position: absolute;
            background-color: $green;
            bottom: 0;
            left: 0;
            top: 0;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            height: 100%;
            width: 3px;
        }
    }

    &-option.underline {
        background-color: #f6f7fa;
        border: 1px solid #c5cbdf;
        border-radius: 4px;
        margin: 0;
        padding: 0.7rem 1rem;
    }
}

body.dark {
    .select {
        &-trigger {
            background-color: inherit;
            color: $text;
            border: none;
        }
        &-content {
            border: none;
        }
        &-option {
            color: $text;

            &:hover {
                color: $dark-text;
            }
        }
        &-option.selected {
            color: $dark-text;

            &:after {
                //background: $green-gradient;
                background: $green-2;
            }
        }
        &-option.underline {
            color: $dark-text;
            background: $card-background-light;
            //border: 1px solid #c5cbdf;
            border-radius: 4px;
            margin: 0;
            padding: 0.7rem 1rem;

            &:hover {
                color: $white;
            }
        }
    }
    .navigation-right-item .select {
        &-trigger {
            //min-width: 10rem;
        }
    }
}
</style>
