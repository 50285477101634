import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { getterTypes, mutationTypes } from "@/store/FlashMessages/types";

let ID = 0;

export default {
    actions,
    mutations: {
        [mutationTypes.addMessageToQueue](state, { header, description, messageState }) {
            const id = ++ID;
            state.messages.push({
                id: id,
                header: header,
                description: description,
                messageState: messageState,
            });
        },
        [mutationTypes.removeMessageFromQueue](state, { id }) {
            for (var i = state.messages.length - 1; i >= 0; i--) {
                if (state.messages[i].id === id) {
                    state.messages.splice(i, 1);
                }
            }
        },
        [mutationTypes.removeAllMessages](state) {
            state.messages = [];
        },
    },
    getters: {
        [getterTypes.getMessages](state) {
            return state.messages;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
