import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.setData](state, data) {
            state.data = data;
            state.isLoading = false;
        },
        [mutationTypes.getDataStart](state) {
            state.isLoading = true;
        },
    },
    getters: {
        [getterTypes.getData](state) {
            return state.data;
        },
        [getterTypes.getPeriod](state) {
            return state.data?.period || null;
        },
        [getterTypes.getAabSiteIds](state) {
            return state.data?.aabSiteIds || [];
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
