import { locale_timezone } from "@/helpers/data";

export const timestampToDate = (timestamp) => {
    return new Date(timestamp * 1000);
};

export const formatDate = (date) => {
    const dateOptions = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-GB", dateOptions);
};

export const formatMonthDate = (date) => {
    const dateOptions = { year: "numeric", month: "short" };
    return date.toLocaleDateString("en-GB", dateOptions);
};

export const formatDateTime = (date) => {
    const dateOptions = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    return date.toLocaleDateString("en-GB", dateOptions);
};

export const formatDateTimeByLocaleTimezone = (date) => {
    const dateOptions = {
        timeZone: locale_timezone,
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    return new Date(date).toLocaleDateString("en-GB", dateOptions);
};

export const formatCell = (value, isDecimal, isDateTime) => {
    if (value instanceof Date) {
        if (isDateTime) {
            return formatDateTime(value);
        }
        return formatDate(value);
    }
    if (isDecimal) {
        value = parseFloat(value);
    } else {
        const valueX = parseInt(value);
        if (isNaN(valueX)) return value;
    }
    const num = value.toString().split(".");
    const int = num[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let dec = "";
    if (isDecimal) {
        dec = num[1] ? (num[1].length === 1 ? `.${num[1]}0` : `.${num[1].substring(0, 2)}`) : ".00";
    } else {
        dec = num[1] ? `.${num[1]}` : "";
    }
    return int + dec;
};

export const roundNumber = (num, index) => {
    let i = 10 ** index;
    return Math.round(num * i) / i;
};

export const formatSiteName = (site) => {
    if (site.site_name && site.site_name != site.domain && site.domain) {
        return site.site_name + " " + site.domain;
    }
    return site.domain || site.site_name;
};
