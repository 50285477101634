export const actionTypes = {
    getList: "[payoutsCsv] Get list",
    getItem: "[payoutsCsv] Get item",
};

export const mutationTypes = {
    payoutsCsvGetListStart: "[payoutsCsv] PayoutsCsv get list start",
    payoutsCsvGetListSuccess: "[payoutsCsv] PayoutsCsv get list success",
    payoutsCsvGetListFailure: "[payoutsCsv] PayoutsCsv get list failure",

    payoutsCsvGetItemStart: "[payoutsCsv] PayoutsCsv get item start",
    payoutsCsvGetItemSuccess: "[payoutsCsv] PayoutsCsv get item success",
    payoutsCsvGetItemFailure: "[payoutsCsv] PayoutsCsv get item failure",
};

export const getterTypes = {
    getList: "[websites] Get PayoutsCsv",
    isLoading: "[websites] Get isLoading",
};
