import { actionTypes, mutationTypes } from "./types";
import { getItem, setItem } from "@/store/persistantStorage";
import { getValidAabSiteIds, getDefaultPeriod, isWebsiteMeasured } from "@/helpers/data";

export const actions = {
    [actionTypes.getWebsiteDatesfromLS]({ commit, dispatch }, { websites, userId }) {
        let { userId: cachedUserId, period, aabSiteIds } = getItem("lawebsitedates") || {};
        const idsVerified = aabSiteIds?.every((aabSiteId) =>
            websites.some((web) => web.aab_site_id === aabSiteId && isWebsiteMeasured(web))
        );
        if (!idsVerified || !period || cachedUserId !== userId) {
            localStorage.removeItem("lawebsitedates");
            dispatch(actionTypes.setWebsiteDatesToLS, {
                aabSiteIds: getValidAabSiteIds(websites),
                period: getDefaultPeriod(),
                userId,
            });
            return;
        }

        commit(mutationTypes.setData, {
            aabSiteIds,
            period:
                period && typeof period !== "string"
                    ? {
                          dateFrom: new Date(period.timeFrom),
                          dateTo: new Date(period.timeTo),
                      }
                    : period,
        });
    },
    [actionTypes.setWebsiteDatesToLS]({ commit, state }, { period, aabSiteIds, userId }) {
        setItem("lawebsitedates", {
            userId,
            aabSiteIds: aabSiteIds || state.data?.aabSiteIds || [],
            period:
                period && typeof period !== "string"
                    ? {
                          timeFrom: period.dateFrom.getTime(),
                          timeTo: period.dateTo.getTime(),
                      }
                    : period,
        });
        commit(mutationTypes.setData, { aabSiteIds, period });
    },
};
