<template>
    <InputContainer :label="label" :name="name" :errors="errors" :showErrors="showErrors">
        <div class="base-checkbox base-input-container">
            <input
                v-bind="$attrs"
                type="checkbox"
                :id="name"
                :value="modelValue"
                class="base-checkbox-input"
                :class="{ hasError: showErrors }"
            />
            <span
                data-test="base-checkbox"
                class="base-checkbox-rectangle"
                :class="{ active: modelValue }"
                @click="setCheckbox(modelValue)"
            >
                <LaBaseIcon name="check-bold" />
            </span>
            <slot />
        </div>
    </InputContainer>
</template>

<script>
import LaBaseIcon from "@/components/BaseIcon";
import InputContainer from "@/components/inputs/InputContainer";
import inputs from "@/mixins/inputs.js";

export default {
    name: "LaBaseCheckbox",
    components: {
        LaBaseIcon,
        InputContainer,
    },
    mixins: [inputs],
    props: {
        modelValue: {
            required: true,
            type: Boolean,
        },
    },
    methods: {
        setCheckbox(val) {
            this.$emit("update:modelValue", !val);
        },
    },
};
</script>

<style lang="scss" scope>
.base-checkbox {
    display: flex;
    &-input {
        display: none;
    }
    &-rectangle {
        margin-top: 0.25rem;
        margin-right: 0.75rem;
        height: 1.4rem;
        width: 1.4rem;
        border: 2px solid $light-grey;
        border-radius: $small-radius;
        display: block;
        position: relative;
        cursor: pointer;
        color: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        &.active {
            border: 2px solid $green;
            background: $green;
        }
    }
    &.base-input-container {
        min-width: 0;
    }
}

body.dark {
    .base-checkbox {
        &-rectangle {
            border: 2px solid $dark-text;
            color: transparent;

            &.active {
                border: none;
                background: $green-2;
                //background: $green-gradient;
                color: $white;
            }
        }
    }
}
</style>
