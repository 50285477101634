export const actionTypes = {
    getData: "[block rate] Get data",
    getGrandTotal: "[block rate] Get grand total",
};

export const mutationTypes = {
    blocRateStart: "[block rate] Block rate get data start",
    blocRateSuccess: "[block rate] Block rate get data success",
    blocRateFailure: "[block rate] Block rate get data failure",
    grandTotalBRStart: "[block rate] Get grand total start",
    grandTotalBRSuccess: "[block rate] Get grand total success",
    grandTotalBRFailure: "[block rate] Get grand total failure",
};

export const getterTypes = {
    getBlockRateData: "[block rate] Get block rate data",
    getBlockRateGrandTotal: "[block rate] Get block rate grand total",
    getBlockRateMetadata: "[block rate] Get block rate metadata",
    isLoading: "[block rate] Get isLoading",
};
