import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.kununuGetDataStart](state) {
            state.isLoading = true;
            state.data = null;
        },
        [mutationTypes.kununuGetDataSuccess](state, data) {
            state.isLoading = false;
            state.data = data;
        },
        [mutationTypes.kununuGetDataFailure](state) {
            state.isLoading = false;
        },
    },
    getters: {
        [getterTypes.getKununu](state) {
            return state.data;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
