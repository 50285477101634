<template>
    <BaseCard>
        <template #header>
            <LaCsvDownload :csv-data="earningsCsvData" :filename="filename" />
        </template>

        <template v-if="!earningsLoading">
            <div class="columns">
                <div class="column">
                    <RevenueImpressionsChart :earnings-data="earningsData" v-if="domains.length == 1" />

                    <WebsRevenueStackedBarChart
                        v-if="domains.length > 1 && earningsCsvData.length"
                        :earnings-csv-data="earningsCsvData"
                    />
                    <WebsImpressionsStackedBarChart
                        v-if="domains.length > 1 && earningsCsvData.length"
                        :earnings-csv-data="earningsCsvData"
                    />
                </div>
            </div>

            <div style="margin-top: 2rem">
                <LaEarningsTable :data="earningsData" />
            </div>
        </template>
        <LaContentLoader v-else />
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard";
import LaContentLoader from "@/components/loaders/ContentLoader";
import LaEarningsTable from "@/components/earnings/EarningTable";
import LaCsvDownload from "@/components/CsvDownload";
import { getExchangeRateFromCzk } from "@/helpers/data";
import { computed } from "vue";
import store from "@/store/store";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import RevenueImpressionsChart from "@/components/charts/RevenueImpressionsChart.vue";
import WebsImpressionsStackedBarChart from "@/components/charts/WebsImpressionsStackedBarChart.vue";
import WebsRevenueStackedBarChart from "@/components/charts/WebsRevenueStackedBarChart.vue";

export default {
    name: "LaEarningDataCard",
    components: {
        BaseCard,
        LaContentLoader,
        LaCsvDownload,
        LaEarningsTable,
        RevenueImpressionsChart,
        WebsImpressionsStackedBarChart,
        WebsRevenueStackedBarChart,
    },
    props: {
        earningsLoading: {
            type: Boolean,
        },
        earningsMetadata: {
            type: Object,
            required: true,
        },
        earningsData: {
            type: Array,
            required: true,
        },
        earningsCsvData: {
            type: Array,
            required: true,
        },
        domains: {
            type: Array,
            required: true,
        },
    },
    computed: {
        filename() {
            return `${this.domains.join("_")}_earnings_%from>%to`;
        },
        revenue() {
            const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
            const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
            return this.earningsData.map(
                (item) => Math.round((item["publisher revenue"] / exchangeRate.value) * 100) / 100
            );
        },
        impression() {
            return this.earningsData.map((item) => item["impressions"]);
        },
    },
};
</script>
