export const actionTypes = {
    getData: "[websites] Get data",
    getDataByPublisher: "[websites] Get data by publisher",
    addData: "[websites] Add data",
    editData: "[websites] Edit data",
    deleteData: "[websites] Delete data",
    changeMonetize: "[websites] Change monetize",
};

export const mutationTypes = {
    websitesGetDataStart: "[websites] Websites get data start",
    websitesGetDataSuccess: "[websites] Websites get data success",
    websitesGetDataFailure: "[websites] Websites get data failure",

    websitesGetDataByPublisherStart: "[websites] Websites get data by publisher start",
    websitesGetDataByPublisherSuccess: "[websites] Websites get data by publisher success",
    websitesGetDataByPublisherFailure: "[websites] Websites get data by publisher failure",

    websiteAddStart: "[websites] Website add start",
    websiteAddSuccess: "[websites] Website add success",
    websiteAddFailure: "[websites] Website add failure",
    websiteEditStart: "[websites] Website edit start",
    websiteEditSuccess: "[websites] Website edit success",
    websiteEditFailure: "[websites] Website edit failure",
    websiteAddEditFormError: "[websites] Website add edit form error",
    websiteDeleteStart: "[websites] Website delete start",
    websiteDeleteSuccess: "[websites] Website delete success",
    websiteDeleteFailure: "[websites] Website delete failure",
};

export const getterTypes = {
    getWebsites: "[websites] Get websites",
    getWebsitesByPublisher: "[websites] Get websites by publisher",
    getWebsiteFromError: "[websites] Get website form error",
    isLoading: "[websites] Get isLoading",
};
