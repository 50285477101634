<template>
    <div v-if="showMessage" class="big-message">
        <div>
            <span>
                <h1>Antiadblock is now Let There Be Ads</h1>
                <p>
                    We have rebranded. For more information visit
                    <a href="https://lettherebeads.io/" target="_blank">lettherebeads.io</a>. <br />
                    Your login and password remain the same.
                </p>
                <p>
                    By continuing you agree to our
                    <router-link :to="{ name: 'termsAndConditions' }" target="_blank">Terms and Conditions</router-link>
                    and our <router-link :to="{ name: 'privacyPolicy' }" target="_blank">Privacy Policy</router-link>.
                </p>

                <p>This message will only appear once.</p>

                <div class="continue">
                    <a @click="hideMessage()">
                        <button>CONTINUE</button>
                    </a>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
import { useRoute } from "vue-router";
export default {
    name: "LtbaBigMessage",
    data() {
        const route = useRoute();
        const showMessageFromLocalStorage =
            localStorage.getItem("showLtbaMessage") === "true" || localStorage.getItem("showLtbaMessage") === null;
        const showMessageFromRoute = route && route.query && route.query.redirect;

        return {
            showMessage: showMessageFromLocalStorage && showMessageFromRoute,
        };
    },
    methods: {
        hideMessage() {
            this.showMessage = false;
            localStorage.setItem("showLtbaMessage", "false");
            window.location.href = "/";
        },
    },
};
</script>

<style lang="scss" scoped>
.big-message {
    z-index: 101;
    position: fixed;
    //top: 145px;

    max-width: 34.25rem;
    min-height: 28.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row reverse;
    margin: 5.5rem 0 1rem;
    color: white;
    border-radius: 1.5rem;
    padding: 2rem 3rem;
    font-size: 1.1rem;
    background-color: $green-3;
    font-family: "Open Sans";

    &__content {
        padding: 0 1rem;
    }
    &__header {
        font-weight: bold;
    }

    a {
        text-decoration: underline;
        color: $lime;

        &:hover {
            color: $lime-3;
        }
    }
    h1 {
        margin-top: 0;
        margin-right: 3rem;
    }
    p {
        margin-top: 1rem;
        line-height: normal;
        text-width: normal;
    }
}
.close-button {
    font-size: 2rem;
    border: none;
    display: inline-block;
    text-decoration: none;
    color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}
.close-button:hover {
    color: $black;
}

.continue {
    text-align: center;

    a {
        button {
            background: $green-2;
            color: $white;

            &:before {
                background: $green-4;
                color: black;
            }
        }
    }
}
</style>
