export const actionTypes = {
    getData: "[payouts] Get data",
    getAllData: "[payouts] Get all data",
    getDataForDetail: "[payouts-detail] Get data for detail",
    addPayout: "[payout] Add payout",
    editPayout: "[payout] Edit payout",
    deletePayout: "[payout] Delete payout",
    selectPublisher: "[payouts-management] Select publisher",
    selectStatus: "[payouts-management] Select status",
};

export const mutationTypes = {
    payoutsGetDataStart: "[payouts] payouts get data start",
    payoutsGetDataSuccess: "[payouts] payouts get data success",
    payoutsGetDataFailure: "[payouts] payouts get data failure",

    payoutsGetAllDataStart: "[payouts] payouts get all data start",
    payoutsGetAllDataSuccess: "[payouts] payouts get all data success",
    payoutsGetAllDataFailure: "[payouts] payouts get all data failure",

    payoutsGetDataForDetailStart: "[payouts-detail] payouts get data for detail start",
    payoutsGetDataForDetailSuccess: "[payouts-detail] payouts get data for detail success",
    payoutsGetDataForDetailFailure: "[payouts-detail] payouts get data for detail failure",

    payoutEditDataStart: "[payout] payout edit data start",
    payoutEditDataSuccess: "[payout] payout edit data success",
    payoutEditDataFailure: "[payout] payout edit data failure",

    selectPublisherStart: "[payouts-management] Select publisher start",
    selectPublisherSuccess: "[payouts-management] Select publisher success",
    selectPublisherFailure: "[payouts-management] Select publisher failure",

    selectStatusStart: "[payouts-management] Select status start",
    selectStatusSuccess: "[payouts-management] Select status success",
    selectStatusFailure: "[payouts-management] Select status failure",
};

export const getterTypes = {
    getPayouts: "[payouts] Get payouts",
    getAllPayouts: "[payouts] Get all payouts",
    getPayoutsForDetail: "[payouts-detail] Get payouts for detail",
    getPublisherSelected: "[payouts-management] Get publisher selected",
    getStatusSelected: "[payouts-management] Get status selected",
};
