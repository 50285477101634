<template>
    <LaBaseTable
        data-test="earnings-table"
        data-cy="earnings-table"
        :headers="headers"
        :items="data"
        stripped
        class="hp-earnings-table"
    >
        <template #header-date-second v-if="$mq.max.mobile"> Page views </template>

        <template #item-date="{ item }">
            <div class="is-flex is-flex-direction-column">
                <div>{{ formatDate(new Date(item.date)) }}</div>
                <div class="second" v-if="$mq.max.mobile">{{ formatDate(new Date(item.date)) }}</div>
            </div>
        </template>

        <template #item-impressions="{ item }">
            <div class="is-flex is-flex-direction-column">
                <div>{{ formatCell(item.impressions, false) }}</div>
                <div class="second" v-if="$mq.max.mobile">{{ formatCell(item.clicks, false) }}</div>
            </div>
        </template>

        <template #item-CPT="{ item }">
            <div class="is-flex is-flex-direction-column" v-if="item['publisher revenue']">
                <div>
                    {{ formatCell(item["publisher revenue"] / exchangeRate / (item.impressions / 1000), true) }}
                    {{ currencySymbol }}
                </div>
                <div class="second" v-if="$mq.max.mobile">
                    {{ formatCell(item["publisher revenue"] / exchangeRate / (item.impressions / 1000), true) }}
                    {{ currencySymbol }}
                </div>
            </div>

            <div class="is-flex is-flex-direction-column" v-else>{{ formatCell(0, true) }} {{ currencySymbol }}</div>
        </template>

        <template #header-CPT>
            <div class="is-flex is-flex-direction-column">
                <span class="tooltip">
                    <span class="title">Imp. RPM</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#24355a" viewBox="0 0 16 16">
                        <path
                            d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                        ></path>
                    </svg>
                    <div class="tooltip-container">
                        <span>
                            Impression RPM is the impression revenue per one thousand ad impressions. <br /><br />
                            <span class="code">Impression RPM = Estimated earnings / (Impressions / 1000)</span>
                        </span>
                    </div>
                </span>
            </div>
        </template>
    </LaBaseTable>
</template>

<script>
import LaBaseTable from "@/components/BaseTable";
import { formatDate, formatCell } from "@/helpers/filters.js";
import { getCurrencySymbol, getExchangeRateFromCzk } from "@/helpers/data";
import { computed } from "vue";
import store from "@/store/store";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export default {
    name: "LaEarningTable",
    components: {
        LaBaseTable,
    },
    props: {
        data: {
            required: true,
        },
    },
    computed: {
        headers() {
            const selectCurrency = store.getters[publisherGetterTypes.getSelectedPublisherCurrency];
            const exchangeRate = getExchangeRateFromCzk(selectCurrency.value);
            const currencySymbol = getCurrencySymbol(selectCurrency.value);

            return [
                {
                    text: "date",
                    value: "date",
                    direction: -1,
                },
                {
                    text: "impressions",
                    value: "impressions",
                    isNumber: true,
                },
                {
                    text: "revenue",
                    value: "publisher revenue",
                    isNumber: true,
                    isDecimal: true,
                    postfix: " " + currencySymbol,
                    divider: exchangeRate,
                },
                {
                    text: "RPM",
                    value: "CPT",
                    isNumber: true,
                    isDecimal: true,
                },
            ];
        },
    },
    setup() {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));

        return { formatDate, formatCell, exchangeRate, currencySymbol };
    },
};
</script>

<style lang="scss" scoped>
.second {
    font-size: 0.8rem;
    opacity: 0.8;
}
</style>
