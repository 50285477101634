<template>
    <div v-if="!verified">
        <p>
            To start monetizing your website, first insert the measurement code per
            <router-link
                :to="{
                    name: 'install',
                    params: {
                        publisher: website.publisher.aab_publisher_id,
                        website: website.aab_site_id,
                    },
                }"
                >step 1 in the install guide</router-link
            >
            into your site. This will verify you have access to the site and will start gathering block-rate data.
        </p>
        <br />
        <p>After successful verification, please click the monetize button again to request monetization.</p>
        <portal to="modalFooter">
            <LaBaseButton
                data-test="base-button"
                text="Get measurement code"
                @click="
                    $router.push({
                        name: 'install',
                        params: {
                            publisher: website.publisher.aab_publisher_id,
                            website: website.aab_site_id,
                        },
                    })
                "
                stretch
            />
        </portal>
    </div>
    <div v-else>
        <p>
            Your site is verified. Click the button below to send a request to our team to enable monetization for your
            site. This will take up to a few days and we will keep you updated on the status.
        </p>
        <p>
            In the mean time please make sure our
            <router-link to="install">measurement code and ads.txt entry</router-link>
            are present on your website.
        </p>
        <portal to="modalFooter">
            <LaBaseButton data-test="base-button" text="Request monetization" @click="send()" stretch />
        </portal>
    </div>
</template>

<script>
import LaBaseButton from "@/components/button/BaseButton";
import store from "@/store/store";
import { getterTypes as dialogGetterTypes, mutationTypes } from "@/store/Dialog/types";
import { actionTypes as websitesActionTypes } from "@/store/Websites/types";
import { computed } from "vue";

export default {
    name: "StartMonetizeDialog",
    components: {
        LaBaseButton,
    },
    setup() {
        const website = computed(() => store.getters[dialogGetterTypes.getDialog].item);
        const verified = website.value.verified;
        const aabSiteId = website.value.aab_site_id;
        const send = () => {
            store.dispatch(websitesActionTypes.changeMonetize, {
                aab_site_id: aabSiteId,
                monetize_status: "request_pending",
            });
            store.commit(mutationTypes.closeDialog);
        };

        return { send, verified, website };
    },
};
</script>

<style scoped></style>
