<template>
    <div class="dropdown">
        <div class="dropdown-close" v-if="open" @click="closeMenu" />
        <div class="dropdown-trigger" @click="openMenu" data-cy="trigger">
            <slot name="trigger" />
            <div class="dropdown-trigger-icon" v-if="icon">
                <LaBaseIcon :name="icon" />
            </div>
        </div>
        <div class="dropdown-content" :class="{ stretch: stretch }" v-if="open">
            <slot name="default" :close="closeMenu" />
        </div>
    </div>
</template>

<script>
import LaBaseIcon from "@/components/BaseIcon";

export default {
    name: "LaBaseDropdown",
    components: {
        LaBaseIcon,
    },
    props: {
        icon: {
            type: [Boolean, String],
            default: "chevron-down",
            required: false,
        },
        stretch: {
            type: Boolean,
            default: false,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        open: false,
    }),
    methods: {
        openMenu() {
            if (!this.disabled) {
                this.open = true;
            }
        },
        closeMenu() {
            this.open = false;
        },
    },
    watch: {
        $route() {
            this.open = false;
        },
    },
};
</script>

<style lang="scss">
.dropdown {
    ::-webkit-scrollbar-thumb {
        background: $background-light;
    }
    scrollbar-color: $background-light gray;

    &-trigger {
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        &-icon {
            margin-left: 0.2rem;
            font-size: 2.2rem;
            color: $white;
        }
    }
    &-close {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
    }
    &-content {
        border-radius: $normal-radius;
        position: absolute;
        top: 102%;
        right: 0;
        box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.2);
        background: $white;
        user-select: none;
        z-index: 999;
    }
    &-content.stretch {
        width: 100%;
    }
}

body.dark {
    .dropdown {
        &-trigger {
            background: $input-background;
            border: 1px solid transparent;

            &-icon:hover {
                color: $green;
            }
        }
        &-content {
            background: $card-background;
            border: 1px solid transparent;
        }
    }
}
</style>
