import { actionTypes } from "@/store/Main/types";

export const actions = {
    [actionTypes.resetAllStates]({ commit }) {
        const requireState = require.context("..", true, /state\.js/);
        requireState.keys().forEach((filename) => {
            const moduleName = filename.replace(/(\.\/|\/state\.js)/g, "");
            const st = requireState(filename).default || requireState(filename);
            commit(`[${moduleName}] Reset state`, { defaultState: st.DEFAULT_STATE });
        });
    },
};
