<template>
    <Suspense>
        <template #default>
            <LaPaymentMethod />
        </template>
        <template #fallback>
            <LaFormLoader />
        </template>
    </Suspense>

    <LaPaymentMethod />
</template>

<script>
import LaPaymentMethod from "@/components/account/PaymentMethod";
import LaFormLoader from "@/components/loaders/FormLoader";

export default {
    name: "LaPaymentMethodView",
    components: {
        LaPaymentMethod,
        LaFormLoader,
    },
};
</script>

<style scoped></style>
