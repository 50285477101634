<template>
    <LaBaseTable data-test="payouts-table" :headers="headers" :items="data" stripped data-cy="payout-table">
        <template #item-month="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ formatMonthDate(new Date(item.date), false) }}</span>
                <div class="second" v-if="$mq.max.mobile">{{ formatMonthDate(new Date(item.date), false) }}</div>
            </div>
        </template>

        <template #item-date="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ formatDate(new Date(item.date), false) }}</span>
                <div class="second" v-if="$mq.max.mobile">{{ formatDate(new Date(item.date), false) }}</div>
            </div>
        </template>

        <template #item-amount="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>
                    {{ reward_dict[item.id] && formatCell(reward_dict[item.id].sum / exchangeRate, true) }}
                    {{ currencySymbol }}
                </span>
            </div>
        </template>

        <template #item-status="{ item }">
            <div class="is-flex is-flex-direction-column">
                <div class="status">
                    {{ item.status }}
                    <span v-if="item.status_detail" :title="item.status_detail">
                        ({{ getShortStatusDetail(item.status_detail) }})
                    </span>
                </div>
            </div>
        </template>

        <template #item-retained="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span v-if="item.status == 'retained'">
                    {{ formatCell(Math.round(item.sum / exchangeRate)) }} {{ currencySymbol }}
                </span>
            </div>
        </template>

        <template #item-payout="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span v-if="item.status != 'retained'">
                    {{ formatCell(Math.round(item.sum / exchangeRate)) }} {{ currencySymbol }}
                </span>
            </div>
        </template>

        <template #item-info="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>
                    {{ item }}
                </span>
            </div>
        </template>

        <template #item-reference="{ item }">
            <div v-if="item.payment_method" class="is-flex is-flex-direction-column">
                <span v-if="item.payment_method.account_number">{{ item.payment_method.account_number }}</span>
                <span v-if="item.payment_method.iban">IBAN: {{ item.payment_method.iban }}</span>
                <span v-if="item.payment_method.swift">SWIFT: {{ item.payment_method.swift }}</span>
            </div>
        </template>

        <template #item-actions="{ item }">
            <LaActionIcons :item="item" item-name="payout" :show-delete-icon="false"></LaActionIcons>
        </template>
    </LaBaseTable>
</template>

<script>
import LaBaseTable from "@/components/BaseTable";
import LaActionIcons from "@/components/button/ActionIcons";
import { formatMonthDate, formatDate, formatCell } from "@/helpers/filters.js";
import { getCurrencySymbol, getExchangeRateFromCzk } from "@/helpers/data";
import { computed } from "vue";
import store from "@/store/store";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export default {
    name: "LaPayoutTable",
    components: {
        LaBaseTable,
        LaActionIcons,
    },
    props: {
        data: {
            required: true,
        },
        reward_data: {
            required: true,
        },
    },
    computed: {
        reward_dict() {
            let ret = {};
            this.reward_data.map((item) => {
                ret[item.id] = item;
            });
            return ret;
        },
        headers() {
            return [
                {
                    text: "month",
                    value: "month",
                    direction: -1,
                    flex: 1,
                },
                {
                    text: "date",
                    value: "date",
                    flex: 1,
                },
                {
                    text: "status",
                    value: "status",
                    flex: 2,
                },
                {
                    text: "amount",
                    value: "amount",
                    justifyContentEnd: true,
                    flex: 1,
                },

                {
                    text: "retained total",
                    value: "retained",
                    justifyContentEnd: true,
                    flex: 1,
                },
                {
                    text: "payout amount",
                    value: "payout",
                    justifyContentEnd: true,
                    flex: 1,
                },
                {
                    text: "payment date",
                    value: "date",
                    flex: 2,
                    justifyContentCenter: true,
                },
                {
                    text: "reference",
                    value: "reference",
                    flex: 2,
                },
                {
                    text: "actions",
                    value: "actions",
                },
            ];
        },
    },
    setup() {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));
        return { formatMonthDate, formatCell, formatDate, exchangeRate, currencySymbol };
    },
    methods: {
        getShortStatusDetail(statusDetail) {
            if (statusDetail === "Unpaid rewards too low.") {
                return "Too small";
            }
            if (statusDetail === "No payment method.") {
                return "Other";
            }
            return statusDetail;
        },
    },
};
</script>

<style lang="scss" scoped>
.second {
    font-size: 0.8rem;
    opacity: 0.8;
}

.status {
    text-transform: uppercase;

    span {
        cursor: help;
    }
}
</style>
