<template>
    <h3>You are not allowed to see this page.</h3>
</template>

<script>
export default {
    name: "LaNotAllowedView",
};
</script>

<style scoped></style>
