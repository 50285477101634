<template>
    <div v-if="showMessage" class="message">
        <div>
            <span>
                Are you not satisfied with the {{ themeSelected }} theme? Switch to the
                <span class="switch-theme" @click="toggleTheme()"
                    >{{ themeSelected == "dark" ? "light" : "dark" }} theme</span
                >. The theme can also be changed later on the Personal Info page.
            </span>
        </div>
        <span @click="hideMessage()" class="close-button">&times;</span>
    </div>
</template>

<script>
import { switchTheme } from "@/helpers/data";
import { getItem } from "@/store/persistantStorage";

export default {
    name: "SwitchThemeMessage",
    data() {
        return {
            showMessage:
                localStorage.getItem("showSwitchThemeMessage") === "true" ||
                localStorage.getItem("showSwitchThemeMessage") === null,
            themeSelected: getItem("theme") || "",
        };
    },
    methods: {
        toggleTheme() {
            switchTheme();
            this.themeSelected = getItem("theme");
        },
        hideMessage() {
            this.showMessage = false;
            localStorage.setItem("showSwitchThemeMessage", "false");
        },
    },
};
</script>

<style lang="scss" scoped>
.message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row reverse;
    margin: 0.5rem 0;
    color: white;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    background-color: $transparent-green;

    &__content {
        padding: 0 1rem;
    }
    &__header {
        font-weight: bold;
    }
}
.close-button {
    font-size: 2rem;
    border: none;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
}
.close-button:hover {
    color: $black;
}

.switch-theme {
    color: $lime-3;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        color: black;
    }
}
</style>
