<template>
    <LaBaseButton
        :text="buttonText"
        :buttonType="buttonType"
        size="medium"
        :show-inline="true"
        @click.stop.prevent="send()"
    />
</template>

<script>
import LaBaseButton from "@/components/button/BaseButton";
import store from "@/store/store";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";
import { actionTypes as websiteDatesActionTypes } from "@/store/WebsiteDates/types";
import { computed } from "vue";
import { getterTypes as userGetterTypes } from "@/store/LoggedUser/types";

export default {
    name: "ChangeMonetizeButton",
    components: {
        LaBaseButton,
    },
    props: {
        website: {
            required: true,
            type: Object,
        },
        dialogName: {
            required: true,
            type: String,
        },
        dialogHeading: {
            required: true,
            type: String,
        },
        buttonText: {
            required: true,
            type: String,
        },
        buttonType: {
            type: String,
            default: "success",
            required: false,
            validator: function (value) {
                return ["success", "warning", "error", "neutral"].indexOf(value) !== -1;
            },
        },
    },
    setup(props) {
        const userId = computed(() => store.getters[userGetterTypes.getUserId]);
        const send = () => {
            store.dispatch(websiteDatesActionTypes.setWebsiteDatesToLS, {
                aabSiteIds: [props.website.aab_site_id],
                userId: userId.value,
            });
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: props.dialogName,
                dialogHeading: `${props.dialogHeading} ${props.website.domain}`,
                item: props.website,
            });
        };
        return { send };
    },
};
</script>

<style scoped></style>
