<template>
    <div v-if="countries?.length && country">
        <InputContainer label="Country" name="countries">
            <div class="base-input-container">
                <LaBaseSelect
                    id="countries"
                    data-test="countries-select"
                    :items="countries"
                    :disabled="disabled"
                    itemValue="code"
                    itemText="name"
                    v-model="modelValue"
                    @update:modelValue="handleInput"
                    :searchable="true"
                >
                </LaBaseSelect>
            </div>
        </InputContainer>
    </div>
</template>

<script>
import { ref } from "vue";
import LaBaseSelect from "@/components/inputs/BaseSelect";
import InputContainer from "@/components/inputs/InputContainer";

export default {
    name: "LaCountrySelect",
    components: {
        LaBaseSelect,
        InputContainer,
    },
    props: {
        countries: {
            type: Array,
            required: true,
        },
        country: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const modelValue = ref(props.country);
        const handleInput = () => {
            emit("update:modelValue", modelValue.value);
        };

        return {
            modelValue,
            handleInput,
        };
    },
};
</script>

<style scoped></style>
