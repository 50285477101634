<template>
    <div class="columns">
        <div class="column">
            <SimpleCard
                data-test="card"
                title="Revenue Yesterday"
                :value="revenueYesterday > 0 ? numberFormatWithSpaceRound(revenueYesterday) : 'N/A'"
                :postfix="revenueYesterday > 0 ? ' ' + currencySymbol : ''"
                color="success"
            />
        </div>

        <div class="column">
            <SimpleCard
                data-test="card"
                title="Revenue for the selected period"
                :value="numberFormatWithSpaceRound(revenueSelectedPeriod)"
                :postfix="' ' + currencySymbol"
                color="success"
            />
        </div>

        <div class="column">
            <SimpleCard
                data-test="card"
                title="Revenue Grand Total"
                :value="numberFormatWithSpaceRound(revenueGrandTotal)"
                :postfix="' ' + currencySymbol"
                color="success"
            />
        </div>
    </div>

    <div class="columns">
        <div class="column">
            <SimpleCard
                data-test="card"
                title="Impressions for the selected period"
                :value="numberFormat(impressionsSelectedPeriod, 3)"
            />
        </div>

        <div class="column">
            <SimpleCard
                data-test="card"
                title="RPM for the selected period"
                :value="rpmSelectedPeriod"
                :postfix="' ' + currencySymbol"
                :is-decimal="true"
            />
        </div>
    </div>

    <div class="columns">
        <div class="column">
            <SimpleCard
                data-test="card"
                title="Impressions Yesterday"
                :value="impressionsYesterday > 0 ? numberFormat(impressionsYesterday, 3) : 'N/A'"
            />
        </div>

        <div class="column">
            <SimpleCard
                data-test="card"
                title="RPM Yesterday"
                :value="rpmYesterday > 0 ? rpmYesterday : 'N/A'"
                :postfix="rpmYesterday > 0 ? ' ' + currencySymbol : ''"
                :is-decimal="rpmYesterday > 0"
            />
        </div>

        <div class="column">
            <SimpleCard
                data-test="card"
                title="Impressions Grand Total"
                :value="numberFormat(impressionsGrandTotal, 3)"
            />
        </div>

        <div class="column">
            <SimpleCard
                data-test="card"
                title="RPM Grand Total"
                :value="rpmGrandTotal"
                :postfix="' ' + currencySymbol"
                :is-decimal="true"
            />
        </div>
    </div>

    <div class="columns" v-if="yieldData?.length">
        <div class="column">
            <LaYieldDataCard
                :yield-data="yieldData"
                :yield-metadata="yieldMetadata"
                :yield-csv-data="yieldCsvData"
                :yield-loading="yieldLoading"
                :domains="domains"
            />
        </div>
    </div>
    <LaContentLoader v-else-if="yieldLoading" />

    <LaNoData
        v-if="!yieldMetadata"
        description="Check if you added the measurement script correctly to your page or contact our support."
    />
</template>

<script>
import LaNoData from "@/components/NoData";
import LaContentLoader from "@/components/loaders/ContentLoader";
import LaYieldDataCard from "@/components/yield/YieldDataCard";
import { getExchangeRateFromCzk, getCurrencySymbol, numberFormat, numberFormatWithSpaceRound } from "@/helpers/data";
import store from "@/store/store";
import { PeriodsEnum } from "@/helpers/data";
import { ref, computed, watch } from "vue";
import { getterTypes as yieldGetterTypes, actionTypes as yieldActionTypes } from "@/store/Yield/types";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { getterTypes } from "@/store/LoggedUser/types";
import SimpleCard from "@/components/SimpleCard";

let device = "desktop";

export default {
    name: "LaYieldCards",
    methods: { numberFormat, numberFormatWithSpaceRound },
    components: {
        SimpleCard,
        LaContentLoader,
        LaNoData,
        LaYieldDataCard,
    },
    props: {
        payload: {
            type: Object,
            required: true,
        },
        websites: {
            required: true,
            type: Array,
        },
    },
    setup(props) {
        const userRights = computed(() => store.getters[getterTypes.getUserRights]);
        const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);

        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));
        const domains = computed(() => props.websites?.map(({ domain }) => domain) || null);
        const yieldLoading = computed(() => store.getters[yieldGetterTypes.isLoading]);
        const loading = computed(() => yieldLoading.value);

        const revenueSelectedPeriod = ref(0);
        const revenueYesterday = ref(0);
        const revenueMonth = ref(0);

        const impressionsSelectedPeriod = ref(0);
        const impressionsYesterday = ref(0);
        const impressionsMonth = ref(0);

        const rpmSelectedPeriod = ref(0);
        const rpmYesterday = ref(0);
        const rpmMonth = ref(0);

        const yieldData = computed(() => store.getters[yieldGetterTypes.getYieldData]);
        const yieldMetadata = computed(() => store.getters[yieldGetterTypes.getYieldMetadata]);
        const yieldCsvData = computed(() => store.getters[yieldGetterTypes.getYieldCsvData]);
        const revenueGrandTotal = computed(
            () => (store.getters[yieldGetterTypes.getGrandTotal].total_revenue || 0) / exchangeRate.value
        );
        const impressionsGrandTotal = computed(
            () => store.getters[yieldGetterTypes.getGrandTotal].total_impressions || 0
        );
        const rpmGrandTotal = computed(
            () => (store.getters[yieldGetterTypes.getGrandTotal].total_rpm || 0) / exchangeRate.value
        );

        const fetchData = (payload) => {
            payload.device = device;
            store.dispatch(yieldActionTypes.getGrandTotal, payload);

            store.dispatch(yieldActionTypes.getData, payload).then((data) => {
                revenueSelectedPeriod.value = (data?.metadata?.["total_revenue"] || 0) / exchangeRate.value;
                rpmSelectedPeriod.value = (data?.metadata?.["total_rpm"] || 0) / exchangeRate.value;
                impressionsSelectedPeriod.value = data?.metadata?.["total_impressions"] || 0;
            });
            if (payload.period !== PeriodsEnum.Yesterday) {
                store
                    .dispatch(yieldActionTypes.getData, {
                        ...payload,
                        period: PeriodsEnum.Yesterday,
                        noCommit: true,
                    })
                    .then((data) => {
                        revenueYesterday.value = (data?.metadata?.["total_revenue"] || 0) / exchangeRate.value;
                        rpmYesterday.value = (data?.metadata?.["total_rpm"] || 0) / exchangeRate.value;
                        impressionsYesterday.value = data?.metadata?.["total_impressions"] || 0;
                    });
            }
            if (payload.period !== PeriodsEnum.ThisMonth) {
                store
                    .dispatch(yieldActionTypes.getData, {
                        ...payload,
                        period: PeriodsEnum.ThisMonth,
                        noCommit: true,
                    })
                    .then((data) => {
                        revenueMonth.value = (data?.metadata?.["total_revenue"] || 0) / exchangeRate.value;
                        rpmMonth.value = (data?.metadata?.["total_rpm"] || 0) / exchangeRate.value;
                        impressionsMonth.value = data?.metadata?.["total_impressions"] || 0;
                    });
            }
        };

        watch(
            () => props.payload,
            () => fetchData(props.payload),
            { immediate: true }
        );
        return {
            loading,
            domains,
            isSuperAdmin,
            currencySymbol,
            yieldData,
            yieldMetadata,
            yieldCsvData,
            yieldLoading,
            revenueSelectedPeriod,
            revenueYesterday,
            revenueMonth,
            revenueGrandTotal,
            impressionsSelectedPeriod,
            impressionsYesterday,
            impressionsMonth,
            impressionsGrandTotal,
            rpmSelectedPeriod,
            rpmYesterday,
            rpmMonth,
            rpmGrandTotal,
        };
    },
};
</script>

<style lang="scss" scoped>
.small-label {
    font-size: 0.8rem;
}
</style>
