<template>
    <div class="menu">
        <LaBaseList :items="menuItems" @logout="logout" />
    </div>
</template>

<script>
import { ACCOUNT_MENU_ITEMS } from "@/helpers/data";
import { actionTypes } from "@/store/LoggedUser/types";
import store from "@/store/store";
import LaBaseList from "@/components/BaseList";

export default {
    name: "LaAccountMenu",
    components: {
        LaBaseList,
    },
    setup() {
        const menuItems = ACCOUNT_MENU_ITEMS;
        const logout = () => {
            store.dispatch(actionTypes.logout);
        };

        return { menuItems, logout };
    },
};
</script>

<style lang="scss">
.menu {
    display: flex;
    align-content: stretch;
    height: 100%;
    font-size: 0.9rem;

    .list-item.router-link-exact-active:after {
        left: -2rem;
    }
}
</style>
