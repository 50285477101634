import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        // get
        [mutationTypes.getFeesDataStart](state) {
            state.fees = null;
            state.isLoading = true;
        },
        [mutationTypes.getFeesDataSuccess](state, data) {
            state.fees = data;
            state.isLoading = false;
        },
        [mutationTypes.getFeesDataFailure](state) {
            state.isLoading = false;
        },
        // add
        [mutationTypes.createFeeDataStart](state) {
            state.fee = null;
            state.isLoading = true;
        },
        [mutationTypes.createFeeDataSuccess](state, data) {
            state.fee = data;
            state.isLoading = false;
        },
        [mutationTypes.createFeeDataFailure](state) {
            state.isLoading = false;
        },

        // edit
        [mutationTypes.editFeeDataStart](state) {
            state.fee = null;
            state.isLoading = true;
        },
        [mutationTypes.editFeeDataSuccess](state, data) {
            state.fee = data;
            state.isLoading = false;
        },
        [mutationTypes.editFeeDataFailure](state) {
            state.isLoading = false;
        },

        // select publisher
        [mutationTypes.selectPublisherStart](state) {
            state.publisherSelected = null;
            state.isLoading = true;
        },
        [mutationTypes.selectPublisherSuccess](state, data) {
            state.publisherSelected = data;
            state.isLoading = false;
        },
        [mutationTypes.selectPublisherFailure](state) {
            state.isLoading = false;
        },

        // select website
        [mutationTypes.selectWebsiteStart](state) {
            state.websiteSelected = null;
            state.isLoading = true;
        },
        [mutationTypes.selectWebsiteSuccess](state, data) {
            state.websiteSelected = data;
            state.isLoading = false;
        },
        [mutationTypes.selectWebsiteFailure](state) {
            state.isLoading = false;
        },
    },
    getters: {
        [getterTypes.getFees](state) {
            return state.fees;
        },
        [getterTypes.getPublisherSelected](state) {
            return state.publisherSelected;
        },
        [getterTypes.getWebsiteSelected](state) {
            return state.websiteSelected;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
