<template>
    <div>
        <LaBaseInput
            label="New password"
            v-model="newPassword"
            name="newPassword"
            :rules="rules.newPassword"
            type="password"
            @input="$emit('changePassword', newPassword)"
            :error="errors.new_password"
        />
        <br />
        <LaBaseInput
            label="Confirm new password"
            v-model="confirmPassword"
            name="confirmPassword"
            :rules="rules.confirmPassword"
            type="password"
            @input="$emit('changeConfirmPassword', confirmPassword)"
            :error="errors.confirm_password"
        />
    </div>
</template>

<script>
import { computed, ref } from "vue";
import LaBaseInput from "@/components/inputs/BaseInput";
import { isRequired, isPassword, isSamePassword } from "@/helpers/rules";
import store from "@/store/store";
import { getterTypes } from "@/store/LoggedUser/types";

export default {
    name: "LaNewPasswordInputs",
    components: {
        LaBaseInput,
    },
    setup() {
        const newPassword = ref("");
        const confirmPassword = ref("");
        const errors = computed(() => store.getters[getterTypes.getChangePasswordFormErrors] || {});

        const rules = {
            newPassword: [isRequired(), isPassword()],
            confirmPassword: [isRequired(), isSamePassword(newPassword)],
        };

        return {
            newPassword,
            confirmPassword,
            rules,
            errors,
        };
    },
};
</script>

<style scoped></style>
