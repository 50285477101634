export const DEFAULT_RIGHTS = {
    adscan: null,
    blockrate: null,
    budget: null,
    config: null,
    demo: null,
    edit_budget: null,
    edit_sas_zones: null,
    edit_users: null,
    lite: null,
    stats: null,
    super_admin: null,
    zones: null,
    payments: null,
};

export const DEFAULT_USER = {
    id: null,
    api_key: null,
    brs_only: null,
    brs_sites: null,
    brs_upgrade: null,
    email: null,
    avatar_path: null,
    name: null,
    frontend_rights: DEFAULT_RIGHTS,
    version: null,
};

export const DEFAULT_STATE = {
    isAuthenticated: false,
    isLoading: false,
    isLoadingVerification: false,
    isLoadingPersonalInfo: false,
    isLoadingAvatar: false,
    isSuccess: false,
    isRegistrationFinished: false,
    user: DEFAULT_USER,
    avatar: null,
    changePasswordFormErrors: null,
    loginFormErrors: null,
    registerFormErrors: null,
    forgottenPasswordFormErrors: null,
    personalInfoFormErrors: null,
};
