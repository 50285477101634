<template>
    <button
        class="base-button"
        :class="[size, buttonType, stretch ? 'stretch' : '', showInline ? 'inline' : '']"
        :type="type"
        v-bind="$attrs"
        :data-cy="`${$attrs.id || 'base'}-button`"
    >
        <span class="base-button-content">
            <LaBaseIcon v-if="icon" :name="icon" />
            {{ text }}
        </span>
    </button>
</template>

<script>
import LaBaseIcon from "@/components/BaseIcon";

export default {
    name: "LaBaseButton",
    components: {
        LaBaseIcon,
    },
    props: {
        type: {
            type: String,
            default: "submit",
            required: false,
            validator: function (value) {
                return ["submit", "reset", "button"].indexOf(value) !== -1;
            },
        },
        buttonType: {
            type: String,
            default: "success",
            required: false,
            validator: function (value) {
                return ["success", "warning", "error", "neutral"].indexOf(value) !== -1;
            },
        },
        size: {
            type: String,
            default: "large",
            required: false,
            validator: function (value) {
                return ["large", "medium", "small"].indexOf(value) !== -1;
            },
        },
        text: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            default: "",
            required: false,
        },
        stretch: {
            type: Boolean,
            required: false,
            default: false,
        },
        showInline: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
