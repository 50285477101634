<template>
    <div class="footer-bar container-indentation">build: {{ version }}-{{ commit_sha }}</div>
</template>

<script>
export default {
    name: "LaFooter",
    setup() {
        const version = process.env.VUE_APP_VERSION;
        const commit_sha = process.env.VUE_APP_COMMIT_SHA;
        return { version, commit_sha };
    },
};
</script>

<style lang="scss" scoped>
.footer {
    &-bar {
        opacity: 0.85;
        display: flex;
        justify-content: space-between;
        align-items: right;
        font-size: 0.8rem;
        font-weight: 400;
        letter-spacing: 1px;
        background-color: $blue;
        z-index: 99;
        min-height: 35px;
        padding-top: 10px;
        color: $grey;
    }
}

body.dark {
    .footer {
        &-bar {
            background-color: $light-black;
            opacity: 85;
        }
    }
}
</style>
