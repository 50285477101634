<template>
    <LaBaseTable :headers="headers" :items="data" v-bind="$attrs">
        <template #item-email="{ item }">
            {{ item.email }}
            <LaStatusLabel v-if="item.deleted" type="error" label="deleted" />
        </template>

        <template #item-actions="{ item }">
            <LaActionIcons :item="item" item-name="user" :show-delete-icon="item.id !== loggedUserId">
                <template #center-icons v-if="item.id !== loggedUserId">
                    <LaActionIcon type="invite" @click.stop="inviteUser(item)" />
                </template>
            </LaActionIcons>
        </template>
    </LaBaseTable>
</template>

<script>
import { computed } from "vue";
import LaActionIcons from "@/components/button/ActionIcons";
import LaActionIcon from "@/components/button/ActionIcon";
import LaBaseTable from "@/components/BaseTable";
import LaStatusLabel from "@/components/StatusLabel";
import { getterTypes } from "@/store/LoggedUser/types";
import store from "@/store/store";

export default {
    name: "LaUserListTable",
    components: {
        LaActionIcons,
        LaActionIcon,
        LaBaseTable,
        LaStatusLabel,
    },
    props: {
        data: {
            required: true,
        },
    },
    emits: ["sendInvitation"],
    setup(props, { emit }) {
        const headers = computed(() => [
            {
                text: "name",
                value: "name",
            },
            {
                text: "email",
                value: "email",
                direction: 1,
            },
            {
                text: "invitation sent",
                value: "last_invitation",
                sortable: false,
                isDateTime: true,
            },
            {
                text: "last login",
                value: "last_login",
                sortable: false,
                isDateTime: true,
            },
            {
                text: "actions",
                value: "actions",
                sortable: false,
            },
        ]);
        const inviteUser = (user) => {
            emit("sendInvitation", user);
        };

        const loggedUserId = computed(() => store.getters[getterTypes.getUserId]);

        return { headers, inviteUser, loggedUserId };
    },
};
</script>
