<template>
    <div class="input-container" :class="{ row: label }">
        <label class="input-label" :for="name" v-if="label">
            {{ label }}
            <span class="tooltip" v-if="!!$slots.tooltip">
                <svg fill="#24355a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="24px" height="24px">
                    <path
                        d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"
                    />
                </svg>
                <div class="tooltip-container">
                    <slot name="tooltip" />
                </div>
            </span>
        </label>
        <div class="input-content" :class="inline ? 'inline' : ''">
            <slot />
            <div class="input-container-error">
                <span v-if="showErrors">{{ errors[0] }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LaInputContainer",
    props: {
        label: {
            type: String,
            default: "",
        },
        name: {
            required: true,
            type: String,
        },
        errors: {
            type: [Array],
            required: false,
            default: () => [],
        },
        showErrors: {
            type: [Boolean],
            required: false,
        },
        inline: {
            type: Boolean,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.input {
    &-container {
        position: relative;
        margin-bottom: 0.75rem;
        text-align: left;
        &-error {
            padding-top: 0.2rem;
            padding-left: 4px;
            font-size: 0.7rem;
            color: $red;
            font-weight: 400;
            min-height: 1.4rem;
        }
        @include --big-mobile-viewport {
            margin-bottom: 0.5rem;
        }
        .inline {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: flex-end;
        }
    }
    &-container.row {
        display: flex;
        @include --big-mobile-viewport {
            display: block;
        }
    }
    &-label {
        font-family: "Open Sans - Semi Bold";
        padding-top: 0.75rem;
        color: $light-grey;
        min-width: 12rem;
        @include --big-mobile-viewport {
            display: block;
            padding: 0;
            margin-bottom: 0.5rem;
            font-size: 0.8rem;
        }
    }
    &-wide {
        min-width: 24rem;
    }
}

body.dark {
    .input {
        &-container.row {
            padding-top: 0.5rem;
        }
        &-label {
            padding-top: 0;
            height: 1.5rem;
            color: $light-white;
            min-width: 12rem;
            opacity: 1;
        }
    }
}
</style>
