export default {
    data: () => ({
        validate: false,
        inputError: null,
    }),
    props: {
        rules: {
            type: Array,
            default: () => [],
        },
        error: {
            type: String,
            required: false,
            default: null,
        },
    },
    inject: ["setStatus"],
    computed: {
        errors() {
            let rules = this.rules
                .map((rule) => {
                    const val = rule(this.modelValue);
                    if (val !== true) return val;
                })
                .filter((error) => error);
            if (this.inputError) {
                rules.push(this.inputError);
            }
            return rules;
        },
        hasErrors() {
            return !!this.errors.length;
        },
        showErrors() {
            return this.validate && !!this.errors.length;
        },
    },
    watch: {
        modelValue() {
            this.validate = true;
            this.inputError = null;
        },
        hasErrors: {
            handler(val) {
                this.setStatus(this.name, val);
            },
            immediate: true,
        },
        error: {
            handler(val) {
                this.inputError = val;
                if (this.inputError) {
                    this.validate = true;
                }
            },
            immediate: true,
        },
    },
};
