import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.usersGetDataStart](state) {
            state.isLoading = true;
            state.data = null;
        },
        [mutationTypes.usersGetDataSuccess](state, data) {
            state.isLoading = false;
            const users = [];
            data.forEach((u) => {
                let showPayment = false;
                let publisher = null;
                u.permissions.forEach((p) => {
                    if (p.name === "admin" || p.name === "view_payment") {
                        showPayment = true;
                        publisher = p.target.publisher;
                    }
                });
                if (u.last_login) {
                    u.last_login = new Date(u.last_login * 1000);
                }
                if (u.last_invitation) {
                    u.last_invitation = new Date(u.last_invitation * 1000);
                }
                users.push({ ...u, showPayments: showPayment, publisher: publisher });
            });
            state.data = users;
        },
        [mutationTypes.replaceUserInStore](state, { user, replaceData }) {
            const userIdx = state.data.findIndex((i) => i.id === user.id);
            if (userIdx >= 0) {
                state.data[userIdx] = { ...state.data[userIdx], ...replaceData };
            }
        },
        [mutationTypes.usersGetDataFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.userAddStart](state) {
            state.userFormErrors = null;
            state.isLoading = true;
        },
        [mutationTypes.userAddSuccess](state, data) {
            state.isLoading = false;
            state.data = [...state.data, data];
        },
        [mutationTypes.userAddFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.userEditStart](state) {
            state.userFormErrors = null;
            state.isLoading = true;
        },
        [mutationTypes.userEditSuccess](state, data) {
            state.isLoading = false;
            state.data = state.data.map((item) => (item.id === data.id ? data : item));
        },
        [mutationTypes.userEditFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.userAddEditErrors](state, errors) {
            state.userFormErrors = errors;
        },
        [mutationTypes.userDeleteStart](state) {
            state.isLoading = true;
        },
        [mutationTypes.userDeleteSuccess](state, data) {
            state.isLoading = false;
            state.data = state.data.filter((item) => item.id !== data.id);
        },
        [mutationTypes.userDeleteFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.userInviteStart]() {},
        [mutationTypes.userInviteSuccess]() {},
        [mutationTypes.userInviteFailure]() {},
    },
    getters: {
        [getterTypes.getUsers](state) {
            return state.data;
        },
        [getterTypes.getUserById](state) {
            return (userId) => {
                return state.data.find((i) => i.id === userId);
            };
        },
        [getterTypes.getUserFormErrors](state) {
            return state.userFormErrors;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
