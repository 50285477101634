<template>
    <div class="card loader" v-bind="$attrs">
        <div class="card-content card-content-header" />
        <div class="card-content" v-for="index in 3" :key="index" />
    </div>
</template>

<script>
export default {
    name: "LaCardLoader",
};
</script>

<style lang="scss" scoped>
.card {
    height: 25rem;
    background-color: $white;
    border-radius: $normal-radius;
    border: 1px solid $dark-grey;
    width: 100%;
    padding: 4rem 2rem;
    &-content {
        background: $grey;
        height: 1rem;
        width: 100%;
        margin-bottom: 1rem;
        border-radius: $normal-radius;
        &:last-child {
            margin-bottom: 0;
        }
        &-header {
            height: 2rem;
            width: 30%;
        }
    }
}
.small-card,
.regular-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
}
.small-card {
    height: 11.35rem;
}

body.dark {
    .card {
        background: $card-background;
        border: 1px solid transparent;
    }
}
</style>
