<template>
    <div v-if="isSuperAdmin">
        <LaPageHeader heading="Payout management"></LaPageHeader>

        <div class="columns">
            <div class="column is-flex">
                <LaBaseSelect
                    data-test="publishers-select"
                    v-if="publishersComputed"
                    :items="publishersComputed"
                    v-model="selectedPublisher"
                    itemValue="id"
                    itemText="name"
                    :stretch="true"
                    :searchable="false"
                    :multiple="false"
                    @update:modelValue="handleInputPublisher(selectedPublisher.id)"
                >
                </LaBaseSelect>

                <LaBaseSelect
                    data-test="status-select"
                    :items="statuses"
                    v-model="selectedStatus"
                    itemValue="id"
                    itemText="name"
                    :stretch="true"
                    :searchable="false"
                    :multiple="false"
                    @update:modelValue="handleInputStatus(selectedStatus.id)"
                >
                </LaBaseSelect>
            </div>

            <div class="column is-flex is-justify-content-right">
                <LaBaseButton
                    text="Add payout"
                    icon="plus"
                    data-test="add-payout"
                    id="add-payout"
                    @click="addPayout()"
                    :disabled="selectedPublisher === 'all'"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <PayoutManagementCard />
            </div>
        </div>

        <LaDialog name="payoutForm">
            <PayoutForm :date-is-timestamp="true" />
        </LaDialog>

        <LaDialog name="payoutDelete">
            <LaDeleteConfirmDialog item-name="payout" item-property="id" />
        </LaDialog>
    </div>
</template>

<script>
import LaPageHeader from "@/components/PageHeader";
import LaDialog from "@/components/dialog/Dialog";
import LaBaseSelect from "@/components/inputs/BaseSelect";
import { computed } from "vue";
import store from "@/store/store";
import { getterTypes as PayoutGetterTypes } from "@/store/Payouts/types";
import { getterTypes } from "@/store/LoggedUser/types";
import PayoutManagementCard from "@/components/payouts/PayoutManagementCard.vue";
import PayoutForm from "@/components/dialog/PayoutForm.vue";
import { actionTypes as publisherActionTypes, getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { actionTypes as payoutActionTypes, getterTypes as payoutGetterTypes } from "@/store/Payouts/types";
import LaBaseButton from "@/components/button/BaseButton.vue";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";
import LaDeleteConfirmDialog from "@/components/dialog/DeleteConfirmDialog";

export default {
    name: "PayoutsManagementView",
    components: {
        PayoutForm,
        PayoutManagementCard,
        LaPageHeader,
        LaDialog,
        LaBaseSelect,
        LaBaseButton,
        LaDeleteConfirmDialog,
    },
    data() {
        return {
            selectedPublisher: this.$route.params.publisher ? this.$route.params.publisher : "all",
            selectedStatus: this.$route.params.status ? this.$route.params.status : "all",
        };
    },
    mounted() {
        store.dispatch(payoutActionTypes.selectPublisher, this.selectedPublisher);
        store.dispatch(payoutActionTypes.selectStatus, this.selectedStatus);
        this.reload();
    },
    setup() {
        const userRights = computed(() => store.getters[getterTypes.getUserRights]);
        const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);
        const loading = computed(() => store.getters[PayoutGetterTypes.isLoading]);

        const publishersComputed = computed(
            () => store.getters[publisherGetterTypes.getPublishersForPayoutsManagementPage]
        );
        if (!publishersComputed.value) {
            store.dispatch(publisherActionTypes.getDataForPayoutsManagementPage);
        }

        const publisherSelectedComputed = computed(() => store.getters[payoutGetterTypes.getPublisherSelected]);
        if (!publisherSelectedComputed.value) {
            store.dispatch(payoutActionTypes.selectPublisher, "all");
        }

        const addPayout = () => {
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: "payoutForm",
                dialogHeading: "Add payout",
            });
        };

        const statuses = [
            { id: "all", name: "all" },
            { id: "created", name: "created" },
            { id: "retained", name: "retained" },
            { id: "pending", name: "pending" },
            { id: "paid", name: "paid" },
        ];

        return {
            addPayout,
            loading,
            isSuperAdmin,
            publishersComputed,
            publisherSelectedComputed,
            statuses,
        };
    },
    methods: {
        handleInputPublisher(publisherId) {
            this.$router.push({ params: { publisher: publisherId } });
            store.dispatch(payoutActionTypes.selectPublisher, publisherId);
            this.selectedPublisher = publisherId;
            this.reload();
        },
        handleInputStatus(status) {
            this.$router.push({ params: { status: status } });
            store.dispatch(payoutActionTypes.selectStatus, status);
            this.selectedStatus = status;
            this.reload();
        },
        reload() {
            store.dispatch(payoutActionTypes.getAllData, {
                publisherId: this.selectedPublisher,
                status: this.selectedStatus,
                refreshPaginator: true,
            });
        },
    },
};
</script>

<style scoped></style>
