<template>
    <div v-if="daily_data">
        <LaBaseForm
            :form-data="{
                name: name,
                publisher_revenue_custom: customRevenueCzk,
                is_verified: isVerified,
                id: daily_data.id,
            }"
            :vuex-action-mutation="formAction"
            :button="'Save'"
            id="daily-data-form"
            buttonPortalTo="modalFooter"
        >
            <LaBaseInput
                data-test="daily-data-custom-revenue"
                v-model="customRevenueEur"
                name="custom_revenue_eur"
                label="Custom revenue [EUR]"
                id="custom-revenue-eur"
                type="number"
            />
            <LaBaseInput
                data-test="daily-data-custom-revenue"
                v-model="customRevenueCzk"
                name="custom_revenue_czk"
                label="Custom revenue [CZK]"
                id="custom-revenue-czk"
                type="number"
            />

            <LaBaseCheckbox v-model="isVerified" name="Is verified" data-test="is-verified">
                Display in statistics</LaBaseCheckbox
            >
        </LaBaseForm>
    </div>
</template>

<script>
import LaBaseForm from "@/components/BaseForm";
import LaBaseInput from "@/components/inputs/BaseInput";
import { computed, ref, watch } from "vue";
import store from "@/store/store";
import { actionTypes } from "@/store/Yield/types";
import { getterTypes as dialogGetterTypes } from "@/store/Dialog/types";
import LaBaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import { getExchangeRateFromCzk } from "@/helpers/data";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export default {
    name: "DailyDataForm",
    components: {
        LaBaseCheckbox,
        LaBaseForm,
        LaBaseInput,
    },
    setup() {
        let customRevenueCzk = ref(0);
        let customRevenueEur = ref(0);
        let isVerified = ref("");
        let formAction = actionTypes.editRecord;
        const daily_data = computed(() => store.getters[dialogGetterTypes.getDialog].item);
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));

        if (daily_data.value) {
            customRevenueCzk.value = daily_data.value.publisher_revenue_custom;
            customRevenueEur.value = daily_data.value.publisher_revenue_custom / exchangeRate.value;
            isVerified.value = daily_data.value.is_verified;
        }

        // Watch for changes in CZK and update EUR
        watch(customRevenueCzk, (newCzkValue) => {
            if (newCzkValue !== 0) {
                customRevenueEur.value = newCzkValue / exchangeRate.value;
            }
        });

        // Watch for changes in EUR and update CZK
        watch(customRevenueEur, (newEurValue) => {
            if (newEurValue !== 0) {
                customRevenueCzk.value = newEurValue * exchangeRate.value;
            }
        });

        return {
            formAction,
            customRevenueCzk,
            customRevenueEur,
            isVerified,
            daily_data,
        };
    },
};
</script>

<style scoped></style>
