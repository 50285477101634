<template>
    <InputContainer :label="label" :name="name" :errors="errors" :showErrors="showErrors">
        <div class="base-input-container">
            <input
                v-bind="$attrs"
                :id="name"
                :value="modelValue"
                class="base-input"
                data-test="base-input"
                :data-cy="$attrs.id"
                @input="handleInput"
                :class="{ hasError: showErrors }"
            />
        </div>
    </InputContainer>
</template>

<script>
import InputContainer from "@/components/inputs/InputContainer";
import inputs from "@/mixins/inputs.js";

export default {
    name: "LaBaseInput",
    components: {
        InputContainer,
    },
    mixins: [inputs],
    props: {
        modelValue: {
            required: false,
            default: "",
            type: [String, Number, Array],
        },
    },
};
</script>

<style lang="scss" scope>
.base-input {
    width: 100%;
    background-color: $light-blue;
    border: 1px solid darken($light-blue, 15%);
    border-radius: $normal-radius;
    box-shadow: none;
    padding: 0.9rem 0.7rem;
    color: $blue;
    &-container {
        min-width: 20rem;
        @include --big-mobile-viewport {
            min-width: inherit;
            font-size: 0.9rem;
        }
    }
    &:disabled {
        cursor: not-allowed;
        background: $dark-grey;
    }
}
.base-input.hasError {
    border: 1px solid $red;
}

body.dark {
    .base-input {
        background: inherit;
        border: 1px solid transparent;
        color: $text;

        &:disabled {
            background: $dark-grey-3 !important;
        }
    }
}
</style>
