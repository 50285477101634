<template>
    <BaseCard>
        <la-publisher-table v-if="publishers && publishers.length > 1" :data="publishers"></la-publisher-table>
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard";
import LaPublisherTable from "@/components/publishers/PublisherTable";
import store from "@/store/store";
import { actionTypes as publisherActionTypes, getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { computed } from "vue";

export default {
    name: "PublisherCard",
    components: {
        BaseCard,
        LaPublisherTable,
    },
    setup() {
        const publishers = computed(() => store.getters[publisherGetterTypes.getPublishers]);
        if (!publishers.value) {
            store.dispatch(publisherActionTypes.getData);
        }
        return { publishers };
    },
};
</script>

<style scoped></style>
