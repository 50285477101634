<script>
import { defineComponent } from "vue";
import { Bar } from "vue3-chart-v2";
import { ExtendedTooltip } from "@/helpers/graphs";
import { formatCell } from "@/helpers/filters.js";
import graphs from "@/mixins/graphs";

export default defineComponent({
    name: "LaBarGraph",
    extends: Bar,
    mixins: [graphs],
    props: {
        chartOptions: {
            type: Object,
            required: false,
            default: () => ({
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                color: "#f3f6fa",
                                backdropPadding: 8,
                            },
                            gridLines: {
                                color: "#3e432d",
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                color: "#f3f6fa",
                                userCallback: function (value) {
                                    return formatCell(value, false);
                                },
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
                tooltips: {
                    mode: "index",
                    intersect: false,
                    enabled: false,
                    custom: ExtendedTooltip,
                },
                responsive: true,
                maintainAspectRatio: false,
            }),
        },
    },
    computed: {
        chartData() {
            return {
                labels: this.labels,
                datasets: this.datasets,
            };
        },
    },
});
</script>
