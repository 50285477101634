<template>
    <div class="navigation-bar container-indentation" data-test="navigation-bar">
        <div class="navigation-logo">
            <router-link to="/" class="navigation-logo">
                <img data-test="logo" src="@/assets/let-there-be-ads-logo.svg" alt="Let There Be Ads" />
            </router-link>
        </div>
        <div class="navigation-menu" v-if="$mq.min.desktop">
            <template v-for="item in mainMenuItems">
                <router-link
                    v-if="item.routeDef"
                    class="navigation-menu-item"
                    :class="{ 'super-admin': item.superAdmin }"
                    data-test="menu-item"
                    :name="item.routeName"
                    :key="item.routeName"
                    :to="item.routeDef"
                >
                    {{ item.label }}
                </router-link>
                <router-link
                    v-else-if="item.routeName"
                    class="navigation-menu-item"
                    :class="{ 'super-admin': item.superAdmin }"
                    data-test="menu-item"
                    :name="item.routeName"
                    :key="item.routeName"
                    :to="{ name: item.routeName }"
                >
                    {{ item.label }}
                </router-link>
            </template>
        </div>

        <div class="navigation-right">
            <LaPublisherPicker class="navigation-right-item" data-cy="select-publisher" />

            <div class="navigation-dropdown navigation-right-item">
                <NavigationDropdown
                    :items="!$mq.min.desktop ? [...mainMenuItems, ...accountMenuItems] : accountMenuItems"
                >
                    <template #trigger v-if="$mq.max.tablet">
                        <LaBaseIcon class="navigation-dropdown-icon" name="menu" />
                    </template>
                </NavigationDropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import NavigationDropdown from "@/components/layout/NavigationDropdown.vue";
import store from "@/store/store";
import { getterTypes } from "@/store/LoggedUser/types";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { ACCOUNT_MENU_ITEMS, MAIN_MENU_ITEMS } from "@/helpers/data";
import LaBaseIcon from "@/components/BaseIcon";
import LaPublisherPicker from "@/components/filters/PublisherPicker";
import { routeParamsApplicator } from "@/helpers/links";

export default {
    name: "LaNavigationBar",
    components: {
        LaBaseIcon,
        NavigationDropdown,
        LaPublisherPicker,
    },
    setup: () => {
        const userRights = computed(() => store.getters[getterTypes.getUserRights]);
        const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);
        const publisherId = computed(() => store.getters[publisherGetterTypes.getSelectedPublisher]);
        const accountMenuItems = computed(() => {
            if (!publisherId.value) {
                return [];
            }
            let ami = ACCOUNT_MENU_ITEMS.map(routeParamsApplicator({ publisher: publisherId.value }));
            return ami;
        });
        const mainMenuItems = computed(() => {
            if (!publisherId.value) {
                return [];
            }
            let mi = MAIN_MENU_ITEMS.filter((item) => !item.superAdmin || isSuperAdmin.value);
            mi = mi.map(routeParamsApplicator({ publisher: publisherId.value }));
            return mi;
        });

        return {
            userRights,
            isSuperAdmin,
            accountMenuItems,
            mainMenuItems,
        };
    },
};
</script>

<style lang="scss" scoped>
.navigation {
    &-bar {
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: 1px;
        background-color: $blue;
        z-index: 99;
        min-height: 65px;
        span {
            color: $white;
            margin-left: 2rem;
            top: -1rem;
        }
    }
    &-menu {
        display: flex;
        &-item {
            padding: 1.6rem 0;
            margin: 0 1rem;
            color: $white;
            position: relative;
            cursor: pointer;
            text-transform: uppercase;
            &:hover,
            &:active,
            &:focus {
                color: $white;
                &:before {
                    background-color: $white;
                }
            }
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
                height: 3px;
            }
        }
        &-item.router-link-active {
            -webkit-text-fill-color: transparent;
            //background: $green-gradient;
            background: $green-2;
            background-clip: text;
            &:before {
                //background: $green-gradient;
                background: $green-2;
            }
        }
        &-item.super-admin {
            &:after {
                content: "S";
                color: #fff;
                position: absolute;
                top: 20px;
                right: -4px;
                opacity: 0.4;
            }
        }
    }

    &-right {
        display: flex;

        &-item {
            margin: 0 1rem;
        }
    }
    &__user-icon {
        border-radius: 90%;
        width: 40px;
        height: 40px;
        border: 1px solid $white;
    }
    &-dropdown-icon {
        font-size: 2rem;
        color: $white;
    }
}
div.navigation-logo {
    padding: 1em 2em;

    img {
        height: 3em;
    }
}
.navbar-brand {
    & img {
        max-height: 1.5rem;
    }
}

body.dark {
    .navigation {
        &-bar {
            background-color: $light-black;
        }
    }
}
</style>
