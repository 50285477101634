<template>
    <LaBaseSelect
        v-if="websites.length"
        data-test="website-select"
        :items="websites"
        v-model="selectedWebsites"
        @onSelectDisabled="proxySelectDisabled"
        itemValue="aab_site_id"
        itemText="site_name"
        itemLabel="website(s)"
        :multiple="!!multiple"
        :select-all-defaults="!!selectAllDefaults"
    />
</template>

<script>
import LaBaseSelect from "@/components/inputs/BaseSelect";
import { useModelProxy } from "@/helpers/data";
export default {
    name: "LaWebsitePicker",
    components: {
        LaBaseSelect,
    },
    props: {
        modelValue: {
            type: [Array, Object],
            required: false,
        },
        websites: {
            type: Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        selectAllDefaults: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const proxySelectDisabled = (website) => emit("onSelectDisabled", website);
        return { proxySelectDisabled, selectedWebsites: useModelProxy(props, emit) };
    },
};
</script>

<style scoped></style>
