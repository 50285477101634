<template>
    <div>
        <router-view />

        <LaDialog name="websiteForm">
            <LaWebsiteForm />
        </LaDialog>
    </div>
</template>

<script>
import LaDialog from "@/components/dialog/Dialog";
import LaWebsiteForm from "@/components/dialog/WebsiteForm";
import store from "@/store/store";
import { actionTypes as publisherActionTypes, getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export default {
    name: "LoggedView",
    components: {
        LaDialog,
        LaWebsiteForm,
    },
    setup() {
        const publisher = store.getters[publisherGetterTypes.getPublishers];
        if (!publisher.length) {
            store.dispatch(publisherActionTypes.getData);
        }
    },
};
</script>
