<template>
    <div class="container-content container-indent container-flex">
        <LaBaseCard>
            <div class="container-legal">
                <h1 class="page-heading">General Terms and Conditions of Provision of Services</h1>
                <p>(Date of Last Revision: 26 February, 2024)</p>

                <h2 class="page-heading">1. Recitals</h2>
                <p>
                    This document sets forth the General Terms and Conditions (the &quot;<strong>Terms</strong>&quot;)
                    under which Let There Be Ads s.r.o., with its registered office at Za Ženskými domovy 3379/1,
                    Smíchov, 150 00 Prague 5, Czech Republic, identification number 05473438, registered in the
                    Commercial Register maintained by the Municipal Court in Prague under file No. C 264241 (hereinafter
                    &quot;<strong>LTBA</strong>&quot;), offers technology services designed to enable websites to
                    generate revenue by monetizing their advertising space displayed to users (the
                    &quot;<strong>Services</strong>&quot;). These Terms govern the relationship between LTBA and any
                    online publisher that registers on LTBA&#39;s platform, implements LTBA&#39;s technology on its
                    website(s), and uses the Services to any extent (hereinafter the
                    &quot;<strong>Publisher</strong>&quot; or &quot;<strong>Publishers</strong>&quot;).
                </p>
                <p>
                    The relationship between LTBA and the Publisher commences upon the Publisher&#39;s registration on
                    the LTBA platform, the implementation of LTBA&#39;s technology on the Publisher&#39;s website(s),
                    and the use of the Services. These Terms, alongside any Service Agreement signed by LTBA and the
                    Publisher, which may specify or alter the provisions of these Terms, define the entirety of the
                    legal relationship and obligations between the Parties. In the event of any conflict between the
                    terms of Service Agreement and the Terms, the provisions of the Service Agreement shall prevail. In
                    the absence of a separate Service Agreement, these Terms constitute the sole agreement between the
                    Parties with respect to the Services.
                </p>
                <p>
                    LTBA and the Publisher are collectively referred to as the &quot;Parties&quot; and individually as a
                    &quot;Party”.
                </p>

                <h2 class="page-heading">2. Definitions and Interpretations</h2>
                <p>The capitalised terms used in these Terms have the following meaning:</p>
                <p>
                    <strong>Ad Space</strong> means the Publisher’s available advertising space within the Site, which
                    has been provided by the Publisher to LTBA for the purpose of provision of the Services and on which
                    the advertising content is placed through the Technology;
                </p>
                <p>
                    <strong><em>Agreement</em></strong> <em>or</em> <strong><em>Service Agreement</em></strong> means
                    the service agreement entered into by LTBA and the Publisher, including any amendments;
                </p>
                <p><strong>Civil Code</strong> means Act No. 89/2012 Coll., Civil Code, as amended;</p>
                <p>
                    <strong>Confidential Information</strong> means information as defined in Article 6.2 a) (Protection
                    of Confidential Information) of these Terms;
                </p>
                <p><strong>Day or Days</strong> mean calendar day;</p>
                <p><strong>Fee or Fees</strong> mean the compensation for the Services provided by LTBA;</p>
                <p>
                    <strong>GDPR</strong> means Regulation (EU) 2016/679 of the European Parliament and of the Council
                    of 27 April 2016 on the protection of individuals with regard to the processing of personal data and
                    on the free movement of such data and repealing Directive 95/46 / EC (General Data Protection
                    Regulation);
                </p>
                <p>
                    <strong>Other Processors</strong> mean processors involved in the processing provided by LTBA in the
                    position of a processor for the Publisher;
                </p>
                <p><strong>Party or Parties</strong> have the meaning specified in Article of 1 of these Terms;</p>
                <p>
                    <strong>Site</strong> means the Publisher’s website(s), Internet-enabled applications, and other
                    digital properties on which the relevant Ad Space is placed and on which LTBA may display an
                    advertising content pursuant to the Agreement;
                </p>
                <p><strong>Publisher</strong> has the meaning specified in Article 1 of these Terms;</p>
                <p>
                    <strong>Relevant laws on data protection</strong> mean the GDPR, Act No. 110/2019 Coll., on the
                    processing of personal data, as amended, Act No. 127/2005 Coll., on electronic communications, as
                    amended, and any judicial or administrative interpretation of legislation concerning the processing
                    and protection of personal data, any guidelines, codes of conduct or approved mechanisms for issuing
                    certificates issued by the Office for Personal Data Protection;
                </p>
                <p>
                    <strong>Remuneration</strong> means the price earned by the Publisher from each advertising
                    displayed on the Ad Space after the deduction of the relevant Fees by LTBA;
                </p>
                <p>
                    <strong>Remuneration Statement</strong> has the meaning specified in Article 5.3 a) of these Terms;
                </p>
                <p>
                    <strong>Services</strong> mean the Technology services provided to the Publisher by LTBA consisting
                    of the placement of the suitable advertising content on the Ad Space through the Technology, as
                    described in these Terms;
                </p>
                <p><strong>Statistics</strong> have the meaning specified in Article 5.1 b) of these Terms;</p>
                <p>
                    <strong>Technology</strong> means the combination of LTBA’s proprietary software and any third-party
                    software that is either licensed to LTBA or used under terms that permit such combination, which
                    collectively enables LTBA to place advertising content on the Ad Space and display such advertising
                    to users, including those with ad blocking technology enabled in their web browsers. This includes
                    but is not limited to algorithms, processes, tools, and other technologies that facilitate the
                    optimization, delivery, and management of advertising content in compliance with the terms of the
                    Services provided to the Publisher;
                </p>
                <p><strong>Terms</strong> mean these general terms and conditions of provision of the Services;</p>
                <p><strong>User Account</strong> has the meaning specified in Article 3 of these Terms.</p>

                <h2 class="page-heading">3. User Account and Confidentiality</h2>
                <ol class="alpha">
                    <li>
                        Account Creation and Access: LTBA shall create a User Account on behalf of the Publisher and
                        provide access to the online console via an invitation sent to the Publisher&#39;s designated
                        email address. The online console is accessible at
                        <a href="https://dashboard.lettherebeads.io/">https://dashboard.lettherebeads.io/</a>. The
                        Publisher is required to complete the registration process by following the instructions
                        included in the invitation.
                    </li>
                    <li>
                        Account Security: The User Account is secured by an email address and a password. An initial
                        password, which the Publisher is encouraged to change promptly within the User Area following
                        the first login, will be communicated through an automatic email notification. It is the
                        Publisher&#39;s responsibility to maintain the confidentiality of their account credentials.
                    </li>
                    <li>
                        Data Accuracy and Updates: The Publisher agrees to provide accurate and current information
                        during the account creation process and to promptly update such information in the event of any
                        changes. Should there be any alterations to the Publisher&#39;s details, LTBA must be notified
                        without undue delay, or the Publisher must update the information directly in the User Account.
                    </li>
                    <li>
                        Confidentiality Obligations: The Publisher shall maintain strict confidentiality concerning the
                        access credentials to the User Account and any performance data or quantitative data related to
                        the monetization process. Such information is considered proprietary and confidential, and the
                        Publisher agrees that it will only be accessed by the Publisher or their expressly authorized
                        personnel.
                    </li>
                    <li>
                        Account Inactivity and Breach: Should the Publisher&#39;s User Account remain inactive for a
                        period exceeding three months, or should the Publisher breach any obligations under the
                        Agreement or these Terms, LTBA reserves the right to terminate the User Account
                    </li>
                    <li>
                        Service Availability: LTBA may temporarily restrict access to the Publisher’s User Account,
                        particularly for software maintenance or updates. LTBA will endeavour to notify the Publisher of
                        any planned maintenance that may affect account accessibility in advance.
                    </li>
                </ol>

                <h2 class="page-heading">4. Provision of Services</h2>

                <h3 class="no-underline">4.1. Provision of the Ad Space of the Publisher’s Site</h3>
                <ol class="alpha">
                    <li>
                        Designation of Ad Space: The Publisher is entitled to designate a specific portion of its Sites
                        as the Ad Space. Such designated Ad Space shall be made available for the insertion of
                        advertising content exclusively through LTBA&#39;s Technology as part of the Services provided.
                        Any changes of the list of the Sites as well as any changes of the space dedicated for provision
                        of Services will be made via Publisher`s email notification sent from the Publisher`s email to
                        LTBA`s email. These changes are accepted by LTBA upon delivery of email confirmation to the
                        Publisher. If the confirmation by LTBA is not delivered to the Publisher within 5 days, then the
                        proposed changes are automatically rejected.
                    </li>
                    <li>
                        No Charge for Ad Space: The Ad Space designated by the Publisher will be provided to LTBA
                        without any charge.
                    </li>
                    <li>
                        Rights to Ad Space: The Ad Space will remain under the Publisher&#39;s ownership for the
                        duration of the Agreement. LTBA shall have the exclusive right to utilize the Ad Space to place
                        advertising content therein. Upon termination of the Agreement, LTBA will cease any and all use
                        of the Ad Space.
                    </li>
                    <li>
                        Technical Specifications and Information: Prior to the designation of the Ad Space, the
                        Publisher must furnish LTBA with accurate details and technical specifications pertaining to the
                        Site. A comprehensive list of the required information will be made available to the Publisher
                        at
                        <a href="https://dashboard.lettherebeads.io/install"
                            >https://dashboard.lettherebeads.io/install</a
                        >.
                    </li>
                    <li>
                        LTBA&#39;s Discretion on Ad Space: LTBA reserves the right to reject any Ad Space offered by the
                        Publisher at its sole discretion and without the need to provide a reason.
                    </li>
                </ol>

                <h3 class="no-underline">4.2. Implementation and Launch of the Technology</h3>
                <ol class="alpha">
                    <li>
                        Implementation Acknowledgment: The Publisher acknowledges that the successful launch and
                        provision of the Services are contingent upon the Site&#39;s appropriate adaptation and the
                        correct implementation of the Technology by the Publisher.
                    </li>
                    <li>
                        Implementation Obligations: The Publisher is required to adapt the Site and implement the
                        Technology at its own cost, following detailed instructions provided by LTBA. Unless an
                        alternative arrangement is specified in the Service Agreement, the Publisher bears full
                        responsibility for this implementation.
                    </li>
                    <li>
                        Exclusion of Liability: LTBA disclaims any liability for the Services&#39; failure resulting
                        from the Publisher&#39;s improper adaptation of the Site or incorrect implementation of the
                        Technology.
                    </li>
                    <li>
                        Authorization of Content Placement: Upon implementing the Technology, the Publisher grants LTBA
                        the authority to place advertising content on the Ad Space at LTBA’s discretion. However, this
                        does not constitute an obligation on LTBA’s part to purchase advertising space from the
                        Publisher or to place any specific advertising content on the Ad Space.
                    </li>
                </ol>

                <h3 class="no-underline">4.3. Licenses</h3>
                <ol class="alpha">
                    <li>
                        Technology License to Publisher: LTBA grants the Publisher a non-transferable, non-exclusive,
                        royalty-free license to use the Technology, inclusive of its source code, to the extent
                        necessary for utilizing the Services provided by LTBA.
                    </li>
                    <li>
                        Publisher&#39;s License to LTBA: The Publisher grants LTBA a non-transferable, non-exclusive,
                        royalty-free license to use, reproduce, and display the Publisher&#39;s trademarks and logos in
                        conjunction with the promotion of the Services.
                    </li>
                    <li>
                        Scope and Term: The licenses provided by both the Publisher and LTBA are global and remain valid
                        for the duration of the Service Agreement or in case no Service Agreement is concluded for the
                        duration of their legal relationship. Notwithstanding the aforementioned, either Party may
                        suspend these licenses at any time, without cause or prior notice.
                    </li>
                </ol>

                <h2 class="page-heading">5. Payment Terms</h2>
                <h3 class="no-underline">5.1. Publisher’s Remuneration</h3>
                <ol class="alpha">
                    <li>
                        Entitlement to Remuneration: The Publisher shall receive Remuneration derived from LTBA&#39;s
                        earnings for each advertisement displayed on the Ad Space.
                    </li>
                    <li>
                        Measurement of Remuneration: Remuneration calculation will be based exclusively on Statistics
                        captured and recorded by LTBA&#39;s servers.
                    </li>
                    <li>
                        Accessibility of Statistics: These Statistics will be continuously accessible to the Publisher
                        via the User Account.
                    </li>
                </ol>
                <h3 class="no-underline">5.2. LTBA’s Fees</h3>
                <ol class="alpha">
                    <li>
                        Fee Structure: The Publisher agrees to pay LTBA a monthly Fee, which shall be thirty percent
                        (30%) of the LTBA`s earnings due for that month, unless a different fee arrangement is specified
                        in the Service Agreement.
                    </li>
                    <li>
                        Conditions for Fee Application: No Fee shall be applied in instances where the Publisher does
                        not earn any Remuneration during the relevant month.
                    </li>
                    <li>
                        Inclusive of Expenses: The Fee encompasses all costs associated with the provision of the
                        Services by LTBA.
                    </li>
                </ol>
                <h3 class="no-underline">5.3. Billing terms</h3>
                <ol class="alpha">
                    <li>
                        Invoicing by Publisher: The Publisher shall issue an invoice for its Remuneration to LTBA within
                        five (5) days following the conclusion of each calendar month, based on the finalized Statistics
                        from that month. The invoice shall have a sixty (60) day payment term from issuance. If the
                        Publisher fails to issue the invoice in this period, LTBA is authorized to invoice on behalf of
                        the Publisher under the same terms.
                    </li>
                    <li>Currency: All financial transactions under this Agreement will be conducted in EURO.</li>
                    <li>Tax Withholding: LTBA shall withhold taxes as required by applicable laws and regulations.</li>
                    <li>
                        Disputes on Remuneration Statement: Should the Publisher dispute the Remuneration Statement, it
                        must communicate such disputes to LTBA within three (3) days of the statement&#39;s issue date;
                        otherwise, the statement will be deemed accurate and accepted by the Publisher.
                    </li>
                    <li>
                        Monthly Settlement and Minimum Payout: The Fee and Remuneration are to be settled monthly.
                        Payment to the Publisher is subject to the net Remuneration exceeding the minimum payout
                        threshold of 500 EUR after the Fee deduction. Payment will be made 60 days post-invoice issuance
                        by the Publisher.
                    </li>
                    <li>
                        Payment Method: The net Remuneration will be transferred to the bank account specified in the
                        Publisher&#39;s User Account unless an alternative arrangement is documented in writing by both
                        Parties.
                    </li>
                    <li>
                        Tax Liability and Deductions: The Publisher is responsible for all applicable taxes and charges
                        imposed in relation to the Services. LTBA is entitled to deduct or withhold any taxes or amounts
                        required by law from payments due to the Publisher, with such net payment constituting full
                        settlement.
                    </li>
                </ol>

                <h2 class="page-heading">6. Protection of Confidential Information</h2>
                <h3 class="no-underline">6.1. Provision of Information</h3>
                <ol class="alpha">
                    <li>
                        In relation to the provision of the Services, the LTBA has made available and/or will make
                        available certain information that is confidential and that the Publishers is obliged to protect
                        it in accordance with the provisions of these Terms.
                    </li>
                    <li>
                        LTBA intends to provide the Publishers with Confidential Information exclusively for the purpose
                        of the cooperation based on the Agreement.
                    </li>
                </ol>

                <h3 class="no-underline">6.2. Protection of Confidential Information</h3>
                <ol class="alpha">
                    <li>
                        For the purposes of these Terms, the Confidential Information includes:
                        <ol>
                            <li>
                                all non-public information disclosed by LTBA in relation to the Agreement, any
                                communications related to the Technology, including LTBA’s software, source code,
                                specifications, materials, guidelines, and documentation relating to the Technology; and
                                any information that has been labelled as a trade secret;
                            </li>
                            <li>
                                any information of a legal, commercial, business, developmental, technical, financial,
                                operational, economic or similar nature relating to the LTBA
                            </li>
                            <li>
                                any other information, concerning LTBA or the Services provided including information
                                mentioned in art. 3. (d) of these Terms;
                            </li>
                            <li>
                                any and all negotiations held between the Parties in connection with the Services
                                provided; and/or
                            </li>
                            <li>
                                any documents drawn up for the Publisher, containing or otherwise expressing the
                                information described in paragraphs (i) to (iv) above or created on their basis.
                            </li>
                        </ol>
                    </li>
                    <li>
                        The above shall be considered Confidential Information notwithstanding:
                        <ol>
                            <li>the form and manner of disclosure, recording or retention of such information;</li>
                            <li>whether or not it is expressly designated as confidential; and</li>
                            <li>
                                whether or not it constitutes a trade secret within the meaning of Section 504 of the
                                Civil Code and whether or not it is protected by unfair competition, industrial property
                                or copyright laws.
                            </li>
                        </ol>
                    </li>
                    <li>
                        To the extent to which the Confidential Information is not publicly accessible as of the date of
                        execution of the Agreement or does not become publicly accessible after the date of execution of
                        the Agreement, otherwise than as a result of breach of the Publisher’s obligations under this
                        Article 6 (Protection of Confidential Information), the Publisher agrees:
                        <ol>
                            <li>
                                not to use the Confidential Information for any purpose other than the provision of the
                                Services;
                            </li>
                            <li>
                                not to disclose any Confidential Information to another person except for the case when
                                such disclosure is required by any legal regulation;
                            </li>
                            <li>
                                to protect the Confidential Information from disclosure to any third party, in
                                particular to take all reasonable measures to prevent unauthorised disclosure of the
                                Confidential Information to any third party.
                            </li>
                        </ol>
                    </li>
                    <li>
                        The Publisher undertakes not to use the confidential Information without the prior written
                        consent of LTBA to its purpose or the purpose of providing it for its own needs or for the
                        benefit of third parties, both during the duration of the agreement and after its termination.
                    </li>
                    <li>
                        Notwithstanding the above mentioned, the Parties agree that LTBA may disclose to online
                        advertisers who place their advertisement content to the Ad Space in connection to which the
                        Services are provided, any domain level statistics concerning the advertiser’s buying
                        activities. LTBA is not allowed to share any other information with the advertisers without
                        prior written consent of the Publisher.
                    </li>
                    <li>
                        In the event of a breach of obligations regarding the protection of confidential information,
                        the Publisher shall pay a contractual fine of 5 000 EUR (five thousand EUR) for each individual
                        breach.
                    </li>
                    <li>Payment of the contractual penalty does not affect LTBA&#39;s right to full compensation.</li>
                </ol>

                <h3 class="no-underline">6.3. Compensation for damage</h3>
                <p>
                    The Publisher is aware that any breach of the obligations stipulated in this Article 6 (Protection
                    of Confidential Information) could cause irreparable harm to LTBA. The Parties agree that the
                    Publisher will compensate LTBA the damage caused under this Article 6 (Protection of Confidential
                    Information) in money.
                </p>

                <h2 class="page-heading">7. Personal Data Protection</h2>

                <h3 class="no-underline">7.1. Processing of Personal Data by LTBA in the Position of Controller</h3>
                <ol class="alpha">
                    <li>
                        The Publisher acknowledges that LTBA, as data controller, processes personal data of the
                        Publisher and its employees to provide the Services under the Relevant laws on data protection.
                        Details regarding LTBA’s processing of personal data are set out at the following link:
                        <router-link :to="{ name: 'cookiePolicy' }">cookie policy</router-link>.
                    </li>
                    <li>The contact details of LTBA in the Position of Controller in provided under Article 9.</li>
                    <li>
                        <p>
                            Personal data is any information that relates to a person that LTBA can identify. In
                            connection with the purposes listed below, LTBA may process the following categories of
                            personal data in particular:
                        </p>
                        <ul>
                            <li>
                                address and identification data used for the unmistakable identification of the data
                                subject (e.g. name, surname, academic degree, or birth number, date of birth, address of
                                permanent residence, address of place of business, correspondence address, billing
                                address, ID number, VAT number, bank details, identification data of the customer&#39;s
                                representative or contact person designated by the customer, contract and signature),
                                data enabling contact with the data subject (contact data, e.g. contact address,
                                telephone number, fax number, e-mail address, etc.) and other data necessary for the
                                performance of the agreement.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            The Publisher agrees that LTBA may also, as data controller, collect, access, and use
                            technical data to improve the Technology and other LTBA products, programs, and services.
                            This technical data may include the personal data of the Site users, such as information
                            about their web browsers, device operating systems, and location. The Publisher will inform
                            the Site users of such processing of personal data and, where required by the Relevant laws
                            on data protection, obtain the consent of Site users to such processing of personal data by
                            LTBA.
                        </p>
                    </li>
                </ol>

                <h3 class="no-underline">7.2. Processing of Personal Data by LTBA in the Position of Processor</h3>
                <ol class="alpha">
                    <li>
                        When providing the Services, LTBA processes the personal data of the Site users, such as
                        information whether the users use an ad blocking software, information about their web browsers,
                        their device operating systems, and their location as a processor for the Publisher. Unless
                        individual personal data processing agreement stipulates otherwise, this Article 7.2 regulates
                        the terms and conditions of such data processing within the meaning of Section 28 (3) of the
                        GDPR.
                    </li>
                    <li>
                        Personal data is processed by LTBA. The processing is carried out at its headquarters or branch
                        (operational office) by individual authorized employees of LTBA, or processor. The processing
                        takes place through computer technology, or also manually for personal data in paper form,
                        subject to compliance with all security principles for reporting and processing personal data.
                        For this purpose, LTBA has adopted technical and organizational measures to ensure the
                        protection of personal data, in particular measures to prevent unauthorized or accidental access
                        to personal data, their change, destruction or loss, unauthorized transfers, their unauthorized
                        processing, as well as other misuse of personal data. All entities to which personal data may be
                        made available respect the data subjects&#39; right to privacy protection and are obliged to
                        proceed in accordance with applicable legal regulations regarding the protection of personal
                        data.
                    </li>
                    <li>
                        LTBA performs the processing described above solely to provide the Services to the Publisher,
                        for the period and to the extent specified in these Terms and the Agreement, unless LTBA is
                        entitled to process such personal data under another legal title.
                    </li>
                    <li>
                        The Publisher warrants that any data provided or made available to LTBA to provide the Services
                        will be obtained under the Relevant laws on data protection. Any failure by the Publisher to
                        comply with the Relevant laws on data protection is the Publisher&#39;s sole responsibility.
                    </li>
                    <li>
                        LTBA is entitled to involve other processors in the processing described above within the
                        meaning of Section 28 (2) of the GDPR (Other Processors). LTBA may involve Other Processors in
                        the processing if LTBA informs the Publisher no later than 14 days before they are involved in
                        the processing. If the Publisher does not object to the involvement of Other Processors, LTBA is
                        entitled to use the services of these Other Processors. LTBA is obliged to enter into a
                        contractual agreement with Other Processors under Section 28 of the GDPR.
                    </li>
                    <li>
                        LTBA undertakes to implement appropriate technical and organizational measures so that the
                        processing of personal data is under all the conditions set out in the Relevant laws on data
                        protection. At the same time, the LTBA is obliged to set up mechanisms to satisfy the rights of
                        data subjects whose personal data processes by means of appropriate technical and organizational
                        measures.
                    </li>
                    <li>
                        If the data subject is an identifiable natural person for LTBA and proves his identity, he has
                        the rights listed below. The aforementioned rights can only be exercised in relation to personal
                        data which undoubtedly belong to the applicant.
                        <ol>
                            <li>
                                According to Article 13 and Article 14 of the GDPR, the data subject has the right to
                                information, particularly about the identity of the administrator, the scope and purpose
                                of the processing, the legal basis of the processing, the method of processing personal
                                data and in accordance with Articles 13 and 14 of the GDPR. recipients of personal data.
                                LTBA fulfils the information obligation towards data subjects through this policy
                                published on the website lettehreberads.io.
                            </li>
                            <li>
                                According to Article 15 GDPR, the data subject has the right of access to personal data,
                                which includes the following rights:
                                <ul>
                                    <li>get confirmation as to whether it processes personal data,</li>
                                    <li>obtain information about the purposes of processing,</li>
                                    <li>categories of personal data concerned,</li>
                                    <li>recipients to whom personal data have been or will be made available,</li>
                                    <li>planned processing time,</li>
                                    <li>
                                        about the existence of the right to request from the controller the correction
                                        or deletion of personal data relating to the data subject or the limitation of
                                        their processing or to raise an objection to this processing,
                                    </li>
                                    <li>the right to file a complaint with the supervisory authority,</li>
                                    <li>
                                        about all available information about the source of personal data, if it is not
                                        obtained from the data subject,
                                    </li>
                                    <li>
                                        the fact that there is automated decision-making, including profiling, about
                                        appropriate safeguards when transferring data outside the EU,
                                    </li>
                                    <li>
                                        obtain a copy of personal data in the event that the rights and freedoms of
                                        other persons are not adversely affected.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                According to Article 16 of the GDPR, the data subject has the right to correct
                                inaccurate personal data and to supplement incomplete personal data that LTBA will
                                process about him. LTBA will perform the repair without unnecessary delay, but always
                                taking into account the given technical possibilities.
                            </li>
                            <li>
                                According to Article 17 of the GDPR, the data subject has the right to delete personal
                                data concerning him, if LTBA does not prove legitimate reasons for processing this
                                personal data.
                            </li>
                            <li>
                                According to Article 18 of the GDPR, the data subject has the right to limit processing
                                if he denies the accuracy of the personal data, the reasons for their processing, or if
                                he objects to their processing or objects to the illegality of the processing, or the
                                administrator no longer needs the personal data for the purposes of processing, but the
                                data subject requires them for determination, exercise or defense of legal claims.
                            </li>
                            <li>
                                According to Article 19 of the GDPR, the data subject has the right to be notified by
                                LTBA in case of correction, deletion or limitation of personal data processing.
                            </li>
                            <li>
                                According to Article 20 of the GDPR, the data subject has the right to request from LTBA
                                personal data concerning him and which LTBA provided in connection with the contract or
                                on the basis of consent and which are processed automatically, in a structured, commonly
                                used and machine-readable format, and further the right to request the transfer of this
                                data to another administrator, if a person acting on behalf of the relevant
                                administrator is properly designated and it is possible to authorize him.
                            </li>
                            <li>
                                According to Article 21 of the GDPR, the data subject has the right to object to the
                                processing of his personal data due to a legitimate interest.
                            </li>
                            <li>
                                Consent to the processing of personal data can be revoked at any time after its
                                effectiveness. It is necessary to make the appeal explicit, understandable and a certain
                                manifestation of the will.
                            </li>
                            <li>
                                According to Article 21 of the GDPR, the data subject has the right to contact the
                                Office for the Protection of Personal Data (www.uoou.cz) with a complaint if the data
                                subject believes that the processing of his personal data violates his rights or any
                                provisions of the GDPR.
                            </li>
                            <li>
                                LTBA is also required to implement additional technical and organizational measures to
                                protect the privacy of data subjects and ensure an appropriate level of security
                                appropriate to the risks of personal data processing under Section 32 of the GDPR. LTBA
                                undertakes also provide the Publisher with the necessary cooperation in fulfilling their
                                obligations under Sections 32 to 36 of the GDPR relating to the processing of personal
                                data under this Article 7 (Personal Data Protection).
                            </li>
                            <li>
                                LTBA undertakes to treat all data obtained in connection with the provision of the
                                Services, including personal data, as Confidential Information. LTBA will also inform
                                all persons performing activities for LTBA of the requirements and their obligations
                                under the Relevant laws on data protection. LTBA further ensures that all these persons
                                will be obliged in writing to maintain secrecy and confidentiality of all personal data
                                provided to them in connection with the provision of the Services.
                            </li>
                            <li>
                                LTBA is also obliged to immediately, no later than 24 hours, inform the Publisher of any
                                breach of personal data security if such breach of personal data security could result
                                in a risk to the rights and freedoms of the Site users.
                            </li>
                            <li>
                                If LTBA cannot provide the required information within 24 hours, it will inform the
                                Publisher about the reasons for the delay and expected time frame when LTBA will provide
                                the relevant information. LTBA will also keep the Publisher informed about its progress.
                            </li>
                            <li>
                                The processing of personal data and the technical and organizational measures taken in
                                connection with the performance of this Agreement may be subject to audit and inspection
                                by the Publisher, no more than once per calendar year. Audits and inspections must not
                                unreasonably interfere with the activities of LTBA. LTBA will provide the Publisher with
                                cooperation for the proper planning of inspections or audits. The costs incurred by the
                                Publisher in connection with the audit or inspection will be fully borne by the
                                Publisher.
                            </li>
                            <li>
                                If necessary to meet the requirements under the Relevant laws on data protection, the
                                Parties undertake to enter into contractual arrangements considering such requirements
                                without undue delay at the request of any of them.
                            </li>
                        </ol>
                    </li>
                </ol>

                <h3 class="no-underline">7.3. Compensation for damage</h3>
                <p>
                    The Publisher is aware it is fully responsible for the lawfulness of the processing of personal data
                    under this Article 7 (Personal Data Protection), including the lawfulness of the transmission of
                    personal data to LTBA. The Parties agree that if LTBA is ordered by a court or other decision to pay
                    any amount due to the Publisher&#39;s breach of its obligations under these Terms or the Relevant
                    laws on data protection, the Publisher will compensate LTBA for such amount in money.
                </p>

                <h2 class="page-heading">8. Liability</h2>
                <ol class="alpha">
                    <li>
                        It is a condition to the use of the Technology that it is implemented within the relevant Ad
                        Space and the Services are provided “as is”, without any warranty or conditions of title,
                        non-infringement, or efficiency by LTBA.
                    </li>
                    <li>
                        The Parties exclude all LTBA`s liability involving:
                        <ol>
                            <li>design or other defects of the Technology;</li>
                            <li>safety of the Technology</li>
                        </ol>
                        <p>
                            unless any of the defect of the Technology mentioned above were caused by LTBA intentionally
                            or due to gross negligence.
                        </p>
                    </li>
                    <li>
                        LTBA&#39;s total liability, both contractual and non-contractual, for any breach of obligations
                        or omissions related to the Agreement and these Terms including (but not limited to) the
                        liability to compensate the Publisher for damages or the obligation to pay contractual penalty
                        are limited in the aggregate for all incidents to a maximum of 10 000 EUR.
                    </li>
                    <li>
                        LTBA will not be liable for indirect or consequential damages, consequential financial damages,
                        loss of profit, loss of production, loss of production, loss of production interruptions and/or
                        downtime, damages and/or penalties paid to customers of the Publisher, loss of business
                        opportunity, costs for extension or re-securing of financing.
                    </li>
                </ol>

                <h2 class="page-heading">9. Communication and Delivery</h2>
                <p>Unless expressly agreed otherwise, the Publisher may use the following contact details LTBA:</p>
                <table class="left">
                    <thead>
                        <tr>
                            <th>Mailing address</th>
                            <th>Za Ženskými domovy 3379/1, 15000, Praha 5 – Smíchov, Czech Republic</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>E-mail address</td>
                            <td>
                                <a
                                    href="&#109;&#97;&#105;&#x6c;&#x74;&#x6f;&#58;&#x68;&#105;&#x40;&#x6c;&#x65;&#116;&#116;&#104;&#101;&#114;&#x65;&#x62;&#101;&#97;&#x64;&#x73;&#46;&#x69;&#x6f;"
                                    >&#x68;&#105;&#x40;&#x6c;&#x65;&#116;&#116;&#104;&#101;&#114;&#x65;&#x62;&#101;&#97;&#x64;&#x73;&#46;&#x69;&#x6f;</a
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    Exchange of e-mail or other electronic messages is deemed to be written form unless agreed otherwise
                    in these Terms or the Agreement.
                </p>

                <h2 class="page-heading">10. Termination of the Agreement</h2>
                <ol class="alpha">
                    <li>
                        The Agreement has been entered into for an indefinite term unless the agreement stipulates
                        otherwise.
                    </li>
                    <li>
                        Any of the Parties may terminate the Agreement at any time and for any reason by a written
                        notice of termination with 1 month notice period, which commences on the first day of the
                        calendar month following the calendar month in which the notice of termination was delivered.
                    </li>
                    <li>
                        Any termination will be without prejudice to (i) any claims or rights on any Party arising prior
                        to such termination, and (ii) Article 6 (Protection of Confidential Information).
                    </li>
                    <li>General and Final Provisions</li>
                </ol>

                <h2 class="page-heading">11. Amendments to the Terms</h2>
                <p>
                    LTBA reserves the right to update or modify these Terms at its sole discretion. The last revision
                    date of these Terms is stated in the header of this document. All amendments or modifications of the
                    Terms will be notified to the Publishers in advance (at least 14 Days) through the online console
                    provided by LTBA. Each Publisher is allowed to refuse the amendments to the Terms and has the right
                    to terminate the Agreement for this reason before the changes take effect by withdrawal. Refusal of
                    the amendments and termination of the Agreement must be notified to LTBA within 14 Days following
                    the publication date of the new version of these Terms. If the Publisher keeps using the Services
                    afterwards and /or no notification is received by LTBA, the new version of these Terms is considered
                    approved by the Publisher.
                </p>

                <h2 class="page-heading">12. Final provisions</h2>
                <ol class="alpha">
                    <li>
                        To avoid any doubts, the Parties expressly confirm that they are entrepreneurs and enter into
                        the Agreement within the pursuit of their business activities.
                    </li>
                    <li>
                        No rights and obligations of the Parties under the Agreement will be inferred from practice
                        established between the Parties or customs maintained in the sectors in which LTBA or the
                        Publisher operate.
                    </li>
                    <li>
                        The Parties assume the risk of a change in circumstances within the meaning of Section 1765 (2)
                        of Act No. 89/2012 Coll., Civil Code, as amended (the Civil Code).
                    </li>
                    <li>The Parties disapply Section 557 and 1766 of the Civil Code.</li>
                    <li>No assignment of rights arising under the Agreement is permitted.</li>
                    <li>Offsetting against the LTBA&#39;s claims is only allowed by common agreement.</li>
                    <li>
                        The Agreement contains the entire agreement between the Parties in connection with the
                        Technology and the Service and replaces any prior agreements, which may have been concluded
                        between the Parties. The Agreement can be only amended in writing.
                    </li>
                </ol>

                <h2 class="page-heading">13. Governing Law and Jurisdiction</h2>
                <ol class="alpha">
                    <li>
                        These Terms and the Agreement and any other statutory obligations arising from it or in relation
                        to them are governed by the laws of the Czech Republic.
                    </li>
                    <li>
                        All disputes arising out of or in connection with this Agreement will be finally settled by the
                        competent court of Czech Republic, which is District Court Prague 5.
                    </li>
                </ol>
            </div>
        </LaBaseCard>
    </div>
</template>

<script>
import LaBaseCard from "@/components/BaseCard";
export default {
    name: "TermsAndConditions",
    components: {
        LaBaseCard,
    },
};
</script>

<style lang="scss" scoped>
.container-legal {
    ul {
        margin-left: 2.5rem;
    }

    table {
        display: block;
    }
}
</style>
