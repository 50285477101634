<template>
    <div v-if="user">
        <button @click="toggleTheme" class="base-button large success">Switch Theme</button>
        <br />

        <LaBaseForm
            :form-data="{
                id: user.id,
                name: name,
                email: email,
                avatar: avatar,
                timezone: timezone,
            }"
            :vuex-action-mutation="editUserAction"
        >
            <LaBaseInput label="Name" type="text" v-model="name" name="name" :rules="rules.name" :error="errors.name" />
            <LaBaseInput
                label="Email"
                type="email"
                v-model="email"
                name="email"
                :rules="rules.email"
                :error="errors.email"
            />
            <div v-if="timezones?.length">
                <InputContainer label="Timezone" name="timezones">
                    <div class="base-input-container">
                        <LaBaseSelect
                            id="timezones"
                            data-test="timezone-select"
                            :items="timezones"
                            v-model="timezone"
                            :searchable="true"
                        >
                        </LaBaseSelect>
                    </div>
                </InputContainer>
            </div>
        </LaBaseForm>
    </div>
</template>

<script>
import LaBaseForm from "@/components/BaseForm";
import { computed, ref } from "vue";
import LaBaseInput from "@/components/inputs/BaseInput";
import LaBaseSelect from "@/components/inputs/BaseSelect";
import { isRequired, isValidEmail } from "@/helpers/rules.js";
import { actionTypes, getterTypes } from "@/store/LoggedUser/types";
import store from "@/store/store";
import { getTimeZones, guessDefaultTimeZone } from "@/helpers/data";
import InputContainer from "@/components/inputs/InputContainer";
import { switchTheme } from "@/helpers/data";

export default {
    name: "LaPersonalInfo",
    components: {
        LaBaseInput,
        LaBaseForm,
        LaBaseSelect,
        InputContainer,
    },
    async setup() {
        const errors = computed(() => store.getters[getterTypes.getPersonalInfoFormErrors] || {});
        const user = store.getters[getterTypes.getUser];
        const name = ref(user.name);
        const email = ref(user.email);
        const avatar = ref({});
        const avatarPath = computed(() => store.getters[getterTypes.getAvatar]);
        const editUserAction = actionTypes.editPersonalInfo;
        const timezones = getTimeZones();
        const timezone = ref(user.timezone || guessDefaultTimeZone());

        const previewUrl = computed(() => {
            if (avatar.value["name"]) {
                return URL.createObjectURL(avatar.value);
            } else if (avatarPath.value) {
                return avatarPath.value;
            }
            return null;
        });

        const rules = ref({
            name: [isRequired()],
            email: [isRequired(), isValidEmail()],
        });

        return {
            name,
            email,
            avatar,
            editUserAction,
            previewUrl,
            rules,
            user,
            timezones,
            timezone,
            errors,
        };
    },
    methods: {
        toggleTheme() {
            switchTheme(false);
        },
    },
};
</script>

<style lang="scss" scoped>
.upload-button {
    margin-left: 2rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $grey;
    color: $blue;
    border-radius: $normal-radius;
}
</style>
