export const actionTypes = {
    setPagesAction: "[paginator] Set pages action",
    setActualPage: "[paginator] Set actual page action",
};

export const mutationTypes = {
    setPages: "[paginator] Set pages",
    setActualPage: "[paginator] Set actual page",
};

export const getterTypes = {
    getPages: "[paginator] Get pages",
    getActualPage: "[paginator] Get actual page",
};
