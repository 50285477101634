import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.paymentMethodGetDataStart](state) {
            state.isLoading = true;
            state.data = null;
        },
        [mutationTypes.paymentMethodGetDataSuccess](state, data) {
            state.isLoading = false;
            state.data = data;
        },
        [mutationTypes.paymentMethodGetDataFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.paymentMethodEditDataStart](state) {
            state.isLoading = true;
            state.formErrors = null;
        },
        [mutationTypes.paymentMethodEditDataSuccess](state, data) {
            state.isLoading = false;
            state.data = data;
        },
        [mutationTypes.paymentMethodEditDataFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.paymentMethodFormErrors](state, errors) {
            state.formErrors = errors;
        },
    },
    getters: {
        [getterTypes.getPaymentMethod](state) {
            return state.data;
        },
        [getterTypes.getPaymentMethodFormErrors](state) {
            return state.formErrors;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
