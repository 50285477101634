<template>
    <div class="dropdown__container">
        <LaBaseDropdown :icon="!!$mq.max.tablet ? false : 'menu'" data-test="navigation-dropdown">
            <template #default>
                <LaBaseList :items="items" @logout="logout" />
            </template>
        </LaBaseDropdown>
    </div>
</template>

<script>
import LaBaseDropdown from "@/components/BaseDropdown";
import LaBaseList from "@/components/BaseList";
import store from "@/store/store";
import { actionTypes, getterTypes } from "@/store/LoggedUser/types";
import { computed } from "vue";

export default {
    name: "LaNavigationDropdown",
    components: {
        LaBaseDropdown,
        LaBaseList,
    },
    props: {
        items: {
            required: true,
        },
    },
    setup() {
        const userRights = computed(() => store.getters[getterTypes.getUserRights]);
        const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);
        const avatar = computed(() => store.getters[getterTypes.getAvatar]);
        const logout = () => {
            store.dispatch(actionTypes.logout);
        };
        return { logout, avatar, isSuperAdmin };
    },
};
</script>

<style lang="scss">
.dropdown {
    align-self: stretch;
    &__container {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
    }
    &__icon {
        margin-right: 0.2rem;
        border-radius: 90%;
        width: 40px;
        height: 40px;
    }
    &__item {
        display: flex;
        padding: 0.8rem 1.5rem !important;
        &.router-link-active:after {
            content: "";
            position: absolute;
            background-color: $green;
            bottom: 0;
            left: 0;
            top: 0;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            height: 100%;
            width: 3px;
        }
    }
    &__item-icon {
        margin-right: 0.7rem !important;
    }
}
</style>
