export const actionTypes = {
    getData: "[events] Get data",
};

export const mutationTypes = {
    eventsGetDataStart: "[events] Events get data start",
    eventsGetDataSuccess: "[events] Events get data success",
    eventsGetDataFailure: "[events] Events get data failure",
    setFilter: "[events] Events set filter",
};

export const getterTypes = {
    getEvents: "[events] Get events",
    getFilter: "[events] Get filter",
    isLoading: "[events] Is loading",
};
