<template>
    <div>
        <LaPageHeader :heading="$mq.min.desktop ? 'Account' : heading">
            <template #controls>
                <portal-target name="accountControls" />
            </template>
        </LaPageHeader>
        <BaseCard>
            <div class="account-container" v-if="$mq.min.desktop">
                <div class="pr-2 account-menu">
                    <LaAccountMenu />
                </div>
                <div class="p-0 account-content">
                    <LaPageHeader :heading="heading" v-if="$mq.min.desktop" />
                    <router-view />
                </div>
            </div>
            <div class="p-0 account-content-alone" v-else>
                <router-view />
            </div>
        </BaseCard>
    </div>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import BaseCard from "@/components/BaseCard";
import LaPageHeader from "@/components/PageHeader";
import LaAccountMenu from "@/components/account/AccountMenu";

export default {
    name: "LaAccountView",
    components: {
        BaseCard,
        LaAccountMenu,
        LaPageHeader,
    },
    setup() {
        const route = useRoute();
        const heading = computed(() => route.meta.label);
        return { heading };
    },
};
</script>

<style lang="scss">
.account {
    &-container {
        display: flex;
        min-height: calc(100vh - 20rem);
    }
    &-menu {
        padding-top: 2rem;
        margin: -2rem 2rem -2rem 0;
        width: 15rem;
        border-right: 1px solid $dark-grey;
    }
    &-content {
        margin-left: 2rem;
        width: 100%;
    }
}
</style>
