<template>
    <section>
        <div class="container">
            <div class="logo-container">
                <img src="@/assets/login/img/Logo.svg" alt="Logo" />
            </div>
            <div class="form-container">
                <form :class="hasErrors ? 'error' : ''">
                    <h2>Setup new password</h2>

                    <div class="input-container">
                        <label for="password">New Password</label>
                        <div class="password-container">
                            <input
                                v-model="passwordValue"
                                :type="inputPasswordType"
                                placeholder="Enter your new password"
                                @input="isValidPasswordValue()"
                            />
                            <div
                                id="togglePassword"
                                :class="showPassword ? 'show-password' : 'hide-password'"
                                @click="togglePassword"
                            />
                        </div>
                        <div class="input-container-error" v-if="passwordHasError">
                            <span>
                                Password must contain at least 8 characters. You must include at least one letter and
                                one digit.
                            </span>
                        </div>
                    </div>
                    <div class="input-container">
                        <label for="password">New Password Again</label>
                        <div class="password-container">
                            <input
                                v-model="confirmPasswordValue"
                                :type="inputPasswordType"
                                placeholder="Enter your password again"
                                @input="isValidConfirmPasswordValue()"
                            />
                            <div
                                id="togglePassword"
                                :class="showPassword ? 'show-password' : 'hide-password'"
                                @click="togglePassword"
                            />
                        </div>
                        <div class="input-container-error" v-if="confirmPasswordHasError">
                            <span>
                                Password must contain at least 8 characters. You must include at least one letter and
                                one digit.
                            </span>
                        </div>
                    </div>

                    <button type="button" @click="send">
                        {{ hasErrors ? "Passwords are not same" : "Save new password" }}
                    </button>
                </form>
                <img id="bg-circle" src="@/assets/login/img/Bg_circle.svg" loading="lazy" alt="Bg-circle" />

                <router-link :to="{ name: 'passwordRecovery' }">Reset my password</router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { actionTypes, actionTypes as loggedUserActionTypes, getterTypes } from "@/store/LoggedUser/types";
import { computed, ref, watch } from "vue";
import store from "@/store/store";

export default {
    name: "NewPasswordView",
    data() {
        return {
            passwordValue: "",
            passwordHasError: false,
            confirmPasswordValue: "",
            confirmPasswordHasError: false,
            inputPasswordType: "password",
            showPassword: false,
            showError: false,
            isPending: true,
        };
    },

    setup() {
        const action = loggedUserActionTypes.resetPassword;
        const errors = computed(() => store.getters[getterTypes.getChangePasswordFormErrors] || {});
        const hasErrors = ref(false);

        watch(errors, (newErrors) => {
            hasErrors.value = Object.keys(newErrors).length > 0;
        });

        store.dispatch(actionTypes.checkValidResetKey);

        return {
            action,
            errors,
            hasErrors,
        };
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
            if (this.inputPasswordType === "password") {
                this.inputPasswordType = "text";
            } else {
                this.inputPasswordType = "password";
            }
        },
        send() {
            const payload = {
                newPassword: this.passwordValue,
                confirmPassword: this.confirmPasswordValue,
            };

            store.dispatch(this.action, payload).finally(() => {
                this.isPending = false;
            });
        },
        verifyErrors() {
            if (
                !this.passwordHasError &&
                !this.confirmPasswordHasError &&
                this.passwordValue === this.confirmPasswordValue
            ) {
                this.hasErrors = false;
            } else this.hasErrors = true;
        },
        isValidPasswordValue() {
            this.passwordHasError = !this.validatePassword(this.passwordValue);
            this.verifyErrors();
        },
        isValidConfirmPasswordValue() {
            this.confirmPasswordHasError = !this.validatePassword(this.confirmPasswordValue);
            this.verifyErrors();
        },
        validatePassword(password) {
            const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
            return regex.test(password);
        },
    },
};
</script>

<style scoped lang="scss"></style>
