<template>
    <LaWebsitePicker
        v-model="websitesModel"
        :websites="websites"
        :multiple="multiple"
        @onSelectDisabled="onSelectDisabled"
    />
    <portal to="dashboardTop" v-if="$mq.max.tablet">
        <div class="mb-4 mt-2">
            <LaBaseDatePicker v-model="periodModel" :tz="tz" class="ml-4" range />
        </div>
    </portal>
    <LaBaseDatePicker v-model="periodModel" class="ml-4" range />
</template>

<script>
import LaWebsitePicker from "@/components/filters/WebsitePicker";
import LaBaseDatePicker from "@/components/datepicker/BaseDatePicker";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";
import store from "@/store/store";
import { usePropertyObjectModelProxy } from "@/helpers/data";
import { useRoute, useRouter } from "vue-router";
import { watch, computed } from "vue";
export default {
    name: "LaWebsiteDatePickers",
    components: {
        LaWebsitePicker,
        LaBaseDatePicker,
    },
    props: {
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        websites: {
            type: Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        selectAllDefaults: {
            type: Boolean,
            default: false,
        },
        tz: {
            type: String,
        },
    },
    setup(props, { emit }) {
        let initialized = false;
        const periodModel = usePropertyObjectModelProxy("period", props, emit);
        const websitesModel = usePropertyObjectModelProxy("websites", props, emit);

        const onSelectDisabled = (website) => {
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: "StartMonetizeDialog",
                dialogHeading: `Request monetization of ${website.name}`,
                item: website,
            });
        };
        const router = useRouter();
        const route = useRoute();
        const enabledWebs = computed(() => {
            return props.websites.filter((i) => !i.disabled);
        });

        watch(
            websitesModel,
            () => {
                let selectedIds = websitesModel.value.map((i) => {
                    return i.aab_site_id;
                });
                let selectedIdsString = selectedIds.join(",");
                //console.log("route.params.webs", route.params.webs);
                let paramIds = route.params.webs ? route.params.webs.split(",") : false;
                //console.log("paramIds", paramIds);
                if (selectedIdsString != route.params.webs || !paramIds) {
                    if (!initialized) {
                        websitesModel._setter(
                            props.websites.filter((i) => {
                                return !paramIds || paramIds.includes(i.aab_site_id);
                            })
                        );
                        initialized = true;
                    } else {
                        //console.log(selectedIds.length, enabledWebs.value.length);
                        if (selectedIds.length == enabledWebs.value.length) {
                            router.push({ params: { webs: "" } });
                        } else {
                            router.push({ params: { webs: selectedIds.join(",") } });
                        }
                    }
                }
            },
            { immediate: true }
        );
        return { periodModel, websitesModel, onSelectDisabled };
    },
};
</script>

<style lang="scss" scoped>
::v-deep .select-trigger {
    min-width: 225px;
}
</style>
