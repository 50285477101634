import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "./types";

export const actions = {
    async [actionTypes.getData]({ commit }, { min }) {
        commit(mutationTypes.paymentApprovalGetDataStart);
        return await axios({
            method: "get",
            url: "la/payment-approval?min=" + min,
        }).then(
            (data) => {
                commit(mutationTypes.paymentApprovalGetDataSuccess, data.data);
            },
            () => {
                commit(mutationTypes.paymentApprovalGetDataFailure);
            }
        );
    },

    async [actionTypes.addSelectValue]({ commit }, { id }) {
        commit(mutationTypes.paymentApprovalAddSelectValue, id);
    },

    async [actionTypes.approveChecked]({ commit }, { status, ids }) {
        commit(mutationTypes.paymentApprovalGetValuesSelectedStart);
        return await axios({
            method: "post",
            url: "la/payment-approval/set-status/" + status,
            data: {
                ids: ids,
            },
        }).then(
            (data) => {
                commit(mutationTypes.paymentApprovalGetValuesSelectedSuccess, data.data);
            },
            () => {
                commit(mutationTypes.paymentApprovalGetValuesSelectedFailure);
            }
        );
    },
};
