import { MESSAGE_STATE } from "@/store/FlashMessages/state";
import store from "@/store/store";
import { mutationTypes } from "@/store/FlashMessages/types";
import isEmpty from "lodash.isempty";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const showMessage = (response, verb, name) => {
    let state, text;
    if (response.error && !isEmpty(response.error)) {
        state = MESSAGE_STATE.WARN;
        text = `Error ${verb}ing ${name}.`;
    } else {
        name = capitalizeFirstLetter(name);
        state = MESSAGE_STATE.SUCCESS;
        text = `${name} ${verb}ed.`;
    }
    const message = {
        header: "",
        description: text,
        messageState: state,
    };
    store.commit(mutationTypes.addMessageToQueue, message);
};
