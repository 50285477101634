export const actionTypes = {
    getData: "[users] Get users data",
    addData: "[users] Add user",
    editData: "[users] Edit user",
    deleteData: "[users] Delete user",
    inviteUser: "[users] Invite user",
};

export const mutationTypes = {
    usersGetDataStart: "[users] Users get data start",
    usersGetDataSuccess: "[users] Users get data success",
    usersGetDataFailure: "[users] Users get data failure",
    userAddStart: "[users] User add start",
    userAddSuccess: "[users] User add success",
    userAddFailure: "[users] User add failure",
    userEditStart: "[users] User edit start",
    userEditSuccess: "[users] User edit success",
    userEditFailure: "[users] User edit failure",
    userAddEditErrors: "[users] User add/edit errors",
    userDeleteStart: "[users] User delete start",
    userDeleteSuccess: "[users] User delete success",
    userDeleteFailure: "[users] User delete failure",
    userInviteStart: "[users] User invite start",
    userInviteSuccess: "[users] User invite success",
    userInviteFailure: "[users] User invite failure",
    replaceUserInStore: "[users] Replace user in store",
};

export const getterTypes = {
    getUsers: "[users] Get users",
    getUserById: "[users] Get one user by ID",
    getUserFormErrors: "[users] Get user form errors",
};
