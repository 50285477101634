export const actionTypes = {
    getData: "[earnings] Get data",
    getGrandTotal: "[earnings] Get grand total",
};

export const mutationTypes = {
    earningsStart: "[earnings] Earnings get data start",
    earningsSuccess: "[earnings] Earnings get data success",
    earningsFailure: "[earnings] Earnings get data failure",
    grandTotalStart: "[earnings] Grand total start",
    grandTotalSuccess: "[earnings] Grand total success",
    grandTotalFailure: "[earnings] Grand total failure",
};

export const getterTypes = {
    getEarningsData: "[earnings] Get earnings data",
    getEarningsMetadata: "[earnings] Get earnings metadata",
    getEarningsCsvData: "[earnings] Get earnings csvdata",
    getGrandTotal: "[earnings] Get grand total",
    isLoading: "[earnings] Get isLoading",
};
