<template>
    <BaseCard>
        <template #header></template>
        <PaymentApprovalTable :data="paymentApprovals" />
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard";

import { formatMonthDate, formatCell } from "@/helpers/filters.js";
import PaymentApprovalTable from "@/components/payment-approval/PaymentApprovalTable.vue";

export default {
    name: "PaymentApprovalDataCard",
    props: {
        paymentApprovals: {
            type: Array,
            required: true,
        },
    },
    components: {
        BaseCard,
        PaymentApprovalTable,
    },
    setup() {
        return {
            formatMonthDate,
            formatCell,
        };
    },
};
</script>

<style scoped></style>
