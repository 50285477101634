<template>
    <InputContainer
        :label="label"
        :name="name"
        :errors="errors"
        :showErrors="showErrors"
        v-if="buttonOptions?.length"
        :inline="true"
    >
        <div v-for="op in buttonOptions" :key="op.name" class="base-radio-container">
            <input
                v-bind="$attrs"
                type="radio"
                :id="op.name"
                :name="name"
                :value="op.name"
                class="base-radio-input"
                :class="{ hasError: showErrors }"
            />
            <span
                data-test="base-radio"
                class="base-radio"
                :class="{ active: selectedValue.name === op.name }"
                @click="setCheckbox(op.name)"
            >
                <LaBaseIcon name="circle-medium" v-if="selectedValue.name === op.name" />
            </span>
            <label class="base-radio-label" :for="op.name">{{ op.label }}</label>
        </div>
    </InputContainer>
</template>

<script>
import { ref } from "vue";
import LaBaseIcon from "@/components/BaseIcon";
import InputContainer from "@/components/inputs/InputContainer";
import inputs from "@/mixins/inputs.js";

export default {
    name: "LaBaseRadioButton",
    components: {
        LaBaseIcon,
        InputContainer,
    },
    mixins: [inputs],
    props: {
        modelValue: {
            required: true,
            type: Object,
        },
        buttonOptions: {
            required: true,
            type: Array,
        },
    },
    setup(props, { emit }) {
        const selectedValue = ref(props.modelValue);

        const setCheckbox = (val) => {
            selectedValue.value = props.buttonOptions.find((i) => i.name === val);
            emit("update:modelValue", selectedValue.value);
        };

        return { setCheckbox, selectedValue };
    },
};
</script>

<style lang="scss" scope>
.base-radio {
    margin-top: 0.5rem;
    height: 1.4rem;
    width: 1.4rem;
    border: 2px solid $light-grey;
    border-radius: $round-radius;
    position: relative;
    cursor: pointer;
    color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
        border: 2px solid $green;
        background: $green;
        color: $white;
    }
    &-input {
        display: none;
    }
    &-label {
        padding-left: 0.3rem;
    }
    &-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: flex-end;
        padding-right: 1.8rem;
    }
}
</style>

<style lang="scss" scope>
body.dark {
    .base-radio {
        border: 2px solid $dark-text;
        color: $dark-text;

        &.active {
            border: none;
            background: $green-2;
            //background: $green-gradient;
            color: $white;
        }
    }
}
</style>
