import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "./types";

export const actions = {
    async [actionTypes.getData]({ commit }) {
        commit(mutationTypes.kununuGetDataStart);
        return axios.get("/kununu").then(
            (data) => {
                commit(mutationTypes.kununuGetDataSuccess, data.data["script"]);
            },
            () => {
                commit(mutationTypes.kununuGetDataFailure);
            }
        );
    },
};
