<template>
    <portal to="accountControls">
        <LaBaseButton text="Add new user" data-test="add-user" @click="addUser()" id="add-user" icon="plus" />
    </portal>
    <LaUserList />

    <LaDialog name="userForm">
        <LaUserForm />
    </LaDialog>

    <LaDialog name="userDelete">
        <LaDeleteConfirmDialog item-name="user" item-property="name" />
    </LaDialog>
</template>

<script>
import LaBaseButton from "@/components/button/BaseButton";
import LaDeleteConfirmDialog from "@/components/dialog/DeleteConfirmDialog";
import LaUserForm from "@/components/dialog/UserForm";
import LaDialog from "@/components/dialog/Dialog";
import LaUserList from "@/components/account/UserList";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";
import store from "@/store/store";

export default {
    name: "LaUserListView",
    components: {
        LaBaseButton,
        LaDeleteConfirmDialog,
        LaDialog,
        LaUserForm,
        LaUserList,
    },
    setup() {
        const addUser = () => {
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: "userForm",
                dialogHeading: "Add new user",
            });
        };

        return { addUser };
    },
};
</script>

<style scoped></style>
