import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        // get
        [mutationTypes.websitesGetDataStart](state) {
            state.isLoading = true;
            state.data = [];
        },
        [mutationTypes.websitesGetDataSuccess](state, data) {
            state.isLoading = false;
            state.data = data.websites;
        },
        [mutationTypes.websitesGetDataFailure](state) {
            state.isLoading = false;
        },
        // by publisher
        [mutationTypes.websitesGetDataByPublisherStart](state) {
            state.isLoading = true;
            state.dataByPublisher = [];
        },
        [mutationTypes.websitesGetDataByPublisherSuccess](state, data) {
            state.isLoading = false;

            let items = data.websites;
            items.unshift({ id: "*", site_name: "*" });
            items.unshift({ id: "all", site_name: "all" });
            state.dataByPublisher = items;
        },
        [mutationTypes.websitesGetDataByPublisherFailure](state) {
            state.isLoading = false;
        },
        // add
        [mutationTypes.websiteAddStart](state) {
            state.formError = null;
            state.isLoading = true;
        },
        [mutationTypes.websiteAddSuccess](state, data) {
            const web = data.website;
            state.isLoading = false;
            state.data = [...state.data, web];
        },
        [mutationTypes.websiteAddFailure](state) {
            state.isLoading = false;
        },
        // edit
        [mutationTypes.websiteEditStart](state) {
            state.formError = null;
            state.isLoading = true;
        },
        [mutationTypes.websiteEditSuccess](state, data) {
            const web = data.website;
            state.data = state.data.map((item) => {
                if (item.aab_site_id === web.aab_site_id) {
                    let ret = {
                        ...item,
                        cpm: web.cpm,
                        monetize: web.monetize,
                        name: web.name,
                        site_status: web.site_status,
                        verified: web.verified,
                        number_of_hours_of_verification: web.number_of_hours_of_verification,
                    };
                    return ret;
                }
                return item;
            });
            state.isLoading = false;
        },
        [mutationTypes.websiteEditFailure](state) {
            state.isLoading = false;
        },
        // delete
        [mutationTypes.websiteDeleteStart](state) {
            state.isLoading = true;
        },
        [mutationTypes.websiteDeleteSuccess](state, data) {
            state.isLoading = false;
            state.data = state.data.filter(({ aab_site_id }) => aab_site_id !== data.aab_site_id);
        },
        [mutationTypes.websiteDeleteFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.websiteAddEditFormError](state, error) {
            state.formError = error;
        },
    },
    getters: {
        [getterTypes.getWebsites](state) {
            return state.data || null;
        },
        [getterTypes.getWebsitesByPublisher](state) {
            return state.dataByPublisher;
        },
        [getterTypes.isLoading](state) {
            return state.isLoading;
        },
        [getterTypes.getWebsiteFromError](state) {
            return state.formError;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
