<template>
    <div>
        {{ message }}
        <LaStatusLabel v-if="tooltip" :noDot="true" type="none">
            <template #tooltip>
                <span>{{ tooltip }}</span>
            </template>
        </LaStatusLabel>
    </div>
</template>

<script>
import { ref } from "vue";
import LaStatusLabel from "@/components/StatusLabel";
export default {
    name: "EventLabel",
    components: {
        LaStatusLabel,
    },
    props: {
        event: {
            required: true,
        },
    },
    setup(props) {
        const eventMessage = ref(props.event.event);
        const eventMessageParts = eventMessage.value.split("#");
        const message = eventMessageParts[0];
        const tooltip = eventMessageParts[1];

        if (message.at(-1) == "\\" || message.at(-1) == "&") {
            return { message: message.slice(0, -1) + "#" + tooltip, tooltip: "" };
        }
        return { message, tooltip };
    },
};
</script>

<style scoped></style>
