<template>
    <switch-theme-message />

    <LaPageHeader heading="Install">
        <template #controls v-if="websites?.length">
            <LaWebsitePicker v-model="selectedWebsite" :websites="websites" />
        </template>
    </LaPageHeader>

    <LaCardLoader v-if="loading" />
    <LaNoData
        v-else-if="websites && !websites.length"
        header="Add website"
        description="Please add website to show install steps for it."
    >
        <LaAddWebsite />
    </LaNoData>
    <LaInstallSteps v-else-if="selectedWebsite" :website="selectedWebsite" />
</template>

<script>
import LaInstallSteps from "@/components/install/InstallSteps";
import LaPageHeader from "@/components/PageHeader";
import LaAddWebsite from "@/components/button/AddWebsite";
import LaWebsitePicker from "@/components/filters/WebsitePicker";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "@/store/store";
import { actionTypes as websiteActionTypes, getterTypes as websiteGetterTypes } from "@/store/Websites/types";
import LaNoData from "@/components/NoData";
import LaCardLoader from "@/components/loaders/CardLoader";
import SwitchThemeMessage from "@/components/SwitchThemeMessage.vue";

export default {
    name: "LaInstallView",
    components: {
        LaWebsitePicker,
        LaInstallSteps,
        LaPageHeader,
        LaAddWebsite,
        LaNoData,
        LaCardLoader,
        SwitchThemeMessage,
    },
    data() {
        return {
            paramWebsite: this.$route.params.website ? this.$route.params.website : null,
        };
    },
    setup() {
        const websites = computed(() => store.getters[websiteGetterTypes.getWebsites]);
        if (!websites.value) {
            store.dispatch(websiteActionTypes.getData).then(() => (selectedWebsite.value = defaultSelected.value));
        }

        const route = useRoute();
        const paramWebsite = route.params.website;
        const defaultSelected = computed(() => {
            if (paramWebsite) {
                return websites.value.find((i) => i.aab_site_id == paramWebsite) || null;
            } else {
                return websites.value.find(({ verified }) => !verified) || null;
            }
        });

        const selectedWebsite = ref(defaultSelected.value);
        const loading = computed(() => store.getters[websiteGetterTypes.isLoading]);
        const router = useRouter();

        watch(
            [selectedWebsite],
            () => {
                if (selectedWebsite.value) {
                    document.title = "AAB Install | " + selectedWebsite.value.site_name;
                }
                if ((!router.currentRoute.params || !router.currentRoute.params.website) && selectedWebsite.value) {
                    router.push({ params: { website: selectedWebsite.value.aab_site_id } });
                }
            },
            { immediate: true }
        );

        return { loading, websites, selectedWebsite };
    },
};
</script>
