<template>
    <div>
        <LaFlashMessages />
        <router-view />
    </div>
</template>

<script>
import LaFlashMessages from "@/components/FlashMessages";
import { getItem, setItem } from "@/store/persistantStorage";
const { version: appVersion } = require("/package.json");

export default {
    name: "App",
    components: { LaFlashMessages },
    setup() {
        localStorage.setItem("adminversion", appVersion);

        const theme = getItem("theme") || "dark";
        setItem("theme", theme);
        document.body.classList.add(theme);

        return {};
    },
};
</script>

<style lang="scss">
@import "~@/styles/main.scss";
</style>
