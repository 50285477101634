<template>
    <LaBaseTable data-test="payment-approval-table" :headers="headers" :items="data" stripped>
        <template #item-checkbox="{ item }">
            <div class="is-flex is-flex-direction-column" @change="toggleOption(item.id)">
                <span>
                    <input type="checkbox" class="show-checkbox" />
                    {{ item.name }}
                </span>
            </div>
        </template>

        <template #item-date="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ formatMonthDate(new Date(item.date), false) }}</span>
                <div class="second" v-if="$mq.max.mobile">{{ formatMonthDate(new Date(item.date), false) }}</div>
            </div>
        </template>

        <template #item-fee="{ item }">
            <div class="is-flex is-flex-direction-column align-center" v-if="item.fee_in_percent">
                <div class="tooltip">
                    {{ item.fee_in_percent }}%
                    <div class="tooltip-container">
                        <span v-if="item.fee_valid_from">
                            From {{ formatMonthDate(new Date(item.fee_valid_from), false) }}
                        </span>
                        <span v-else>Base fee</span>
                    </div>
                </div>
            </div>
        </template>

        <template #item-rewards_months="{ item }">
            <div class="is-flex is-flex-direction-column">
                <table class="approving-table" v-if="item.rewards_final.length > 0 && item.id == showItemDetail">
                    <!--                    <thead>-->
                    <!--                        <th>Months</th>-->
                    <!--                        <th>Amount</th>-->
                    <!--                    </thead>-->
                    <tbody>
                        <tr v-for="reward in item.rewards_final" :key="reward.id">
                            <td>{{ formatMonthDate(new Date(reward.date), false) }}</td>
                            <td>{{ formatCell(convertCurrency(reward.sum)) }} €</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <template #item-actions="{ item }">
            <div class="is-flex is-flex-direction-column">
                <button @click="showDetail(item.id)">Show detail</button>
            </div>
        </template>
    </LaBaseTable>
</template>

<script>
import LaBaseTable from "@/components/BaseTable";
import { formatMonthDate, formatDate, formatCell, formatDateTime } from "@/helpers/filters.js";
import { exchange_rate, convertCurrency } from "@/helpers/data";
import store from "@/store/store";
import {
    actionTypes as PaymentApprovalActionTypes,
    getterTypes as PaymentApprovalGetterTypes,
} from "@/store/PaymentApproval/types";

export default {
    name: "PaymentApprovalTable",
    components: {
        LaBaseTable,
    },
    props: {
        data: {
            required: true,
        },
    },
    data() {
        return {
            valuesSelected: [],
            showItemDetail: null,
        };
    },
    computed: {
        headers() {
            return [
                {
                    text: "Name",
                    value: "checkbox",
                    flex: 2,
                },
                {
                    text: "Fee",
                    value: "fee",
                    justifyContentStart: true,
                },
                {
                    text: "Payment",
                    value: "fee_sum_all",
                    isNumber: true,
                    isDecimal: true,
                    postfix: " €",
                    divider: exchange_rate,
                    justifyContentCenter: true,
                },
                {
                    text: "Rewards",
                    value: "rewards_months",
                    flex: 2,
                    justifyContentCenter: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    justifyContentCenter: true,
                },
            ];
        },
    },
    setup() {
        const publisherIds = store.getters[PaymentApprovalGetterTypes.getPaymentApprovalValuesSelected];

        return {
            publisherIds,
            formatMonthDate,
            formatCell,
            formatDate,
            formatDateTime,
            convertCurrency,
            exchange_rate,
        };
    },

    methods: {
        toggleOption(id) {
            store.dispatch(PaymentApprovalActionTypes.addSelectValue, { id: id });
        },
        showDetail(id) {
            this.showItemDetail = this.showItemDetail == id ? null : id;
        },
    },
};
</script>

<style lang="scss" scoped>
.approving-table {
    border-collapse: collapse;

    tr {
        border-top: 1px solid #cdcdcd;
    }
    tr:first-child {
        border-top: none;
    }
    td {
        border-left: 1px solid #cdcdcd;
        border-right: 1px solid #cdcdcd;
        padding: 0.5rem 2rem;
        text-align: left;
    }
}

.second {
    font-size: 0.8rem;
    opacity: 0.8;
}

.show-checkbox {
    -webkit-appearance: revert;
    appearance: revert;
    margin: 10px;
}

button:hover {
    background-color: #bebebe;
}

.tooltip {
    padding: 0 0 0 0;
}
</style>
