<template>
    <div>
        <LaPageHeader heading="Events"> </LaPageHeader>

        <LaBaseForm v-if="filter" :formData="filter" :vuex-action-mutation="applyFilters" :hideButton="true">
            <div class="columns">
                <div v-for="(value, key) in filter" :key="key" class="column filter">
                    <LaBaseCheckbox v-model="filter[key]" name="key" data-test="crons-{{key}}"
                        >{{ key }}
                    </LaBaseCheckbox>
                </div>
                <div :key="'submit_button'" class="column filter">
                    <LaBaseButton data-test="apply-filter-button" :disabled="loading" text="Apply" />
                </div>
            </div>
        </LaBaseForm>

        <LaContentLoader v-if="loading" />

        <div v-else>
            <LaNoData v-if="!hasData" header="No Events" description="Couldn't load any Events data."> </LaNoData>
            <div v-else>
                <div class="columns">
                    <div class="column">
                        <LaEventTable :data="events" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LaBaseForm from "@/components/BaseForm";
import LaPageHeader from "@/components/PageHeader";
import store from "@/store/store";
import { computed, ref } from "vue";
import { actionTypes as eventActionTypes, getterTypes as eventGetterTypes } from "@/store/Events/types";
import LaContentLoader from "@/components/loaders/ContentLoader";
import LaBaseCheckbox from "@/components/inputs/BaseCheckbox";
import LaBaseButton from "@/components/button/BaseButton";
import LaNoData from "@/components/NoData";
import LaEventTable from "@/components/events/EventTable.vue";
//import { actionTypes as loggedUserActionTypes } from "@/store/LoggedUser/types";
import cloneDeep from "lodash.clonedeep";

export default {
    name: "LaEventsView",
    components: {
        LaEventTable,
        LaPageHeader,
        LaContentLoader,
        LaNoData,
        LaBaseCheckbox,
        LaBaseForm,
        LaBaseButton,
    },

    setup() {
        const loading = computed(() => store.getters[eventGetterTypes.isLoading]);
        const filter = ref(cloneDeep(store.getters[eventGetterTypes.getFilter]));
        const events = computed(() => store.getters[eventGetterTypes.getEvents]);
        const hasData = computed(() => !!events.value);

        store.dispatch(eventActionTypes.getData);

        const applyFilters = eventActionTypes.getData;

        return {
            loading,
            hasData,
            events,
            applyFilters,
            filter,
        };
    },
};
</script>

<style scoped>
.filter {
    max-width: 10rem;
}
</style>
