import rules from "@/mixins/rules.js";

export default {
    mixins: [rules],
    props: {
        label: {
            type: String,
            default: "",
        },
        name: {
            required: true,
            type: String,
        },
    },
    methods: {
        handleInput(event) {
            this.$emit("update:modelValue", event.target.value);
        },
    },
};
