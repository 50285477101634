<template>
    <div>
        <div v-if="messages?.length" class="flasmessage__container" data-cy="flash-container">
            <LaFlashMessage v-for="message in messages" :key="message.id" :message="message" />
        </div>
    </div>
</template>

<script>
import LaFlashMessage from "@/components/FlashMessage";
import { getterTypes } from "@/store/FlashMessages/types";
import { computed } from "vue";
import store from "@/store/store";
export default {
    name: "LaFlashMessages",
    components: { LaFlashMessage },
    setup() {
        const messages = computed(() => store.getters[getterTypes.getMessages]);
        return { messages };
    },
};
</script>

<style lang="scss" scoped>
.flasmessage__container {
    z-index: 9999;
    position: fixed;
    right: $outer-padding;
    top: 2rem;
    max-width: 35rem;
    min-width: 20rem;
}
</style>
