<template>
    <div class="dialog" v-if="visibility">
        <div class="dialog-background" @click="closeModal(persistent)" />
        <div class="dialog-card" :style="{ width: width + 'px' }">
            <div class="dialog-card-head">
                <h1 class="dialog-card-title">{{ headingName }}</h1>
                <div @click="closeModal()" class="dialog-card-close">
                    <span>&times;</span>
                </div>
            </div>
            <div class="dialog-card-body">
                <slot />
            </div>
            <div class="dialog-card-foot is-justify-content-center">
                <portal-target class="dialog-card-foot-Portal" name="modalFooter" />
            </div>
        </div>
    </div>
</template>

<script>
import { getterTypes as dialogGetterTypes, mutationTypes } from "@/store/Dialog/types";
import store from "@/store/store";
import { computed } from "vue";

export default {
    name: "LaDialog",
    props: {
        name: {
            type: String,
            required: true,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [Number, String],
            default: "inherit",
        },
    },
    setup(props) {
        const activeDialogName = computed(() => store.getters[dialogGetterTypes.getDialog].activeDialogName);
        const headingName = computed(() => store.getters[dialogGetterTypes.getDialog].heading);
        const closeModal = (persistent) => {
            if (persistent) return;
            store.commit(mutationTypes.closeDialog);
        };
        const visibility = computed(() => activeDialogName.value === props.name);

        return {
            activeDialogName,
            headingName,
            closeModal,
            visibility,
        };
    },
};
</script>

<style lang="scss" scoped>
.dialog,
.dialog-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.dialog-card-head,
.dialog-card-foot,
.dialog-card-body {
    padding: 1.5rem 2rem;
}
.dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    &-card {
        background: $white;
        position: relative;
        border-radius: $normal-radius;
        min-width: 450px;
        color: $blue;
        z-index: 9999;
        //max-width: 860px;
        &-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $dark-grey;
        }
        &-foot {
            border-top: 1px solid $dark-grey;
        }
        &-body {
            padding-bottom: 0;
        }
        &-title {
            font-size: 1.2rem;
            margin: 0;
        }
        &-close {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5em;
            line-height: 2.5rem;
            color: $light-grey;
            font-size: 2.5rem;
            cursor: pointer;
            font-weight: 400;
        }
    }
    &-background {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.7);
        z-index: 999;
    }
    &-title {
        color: $blue;
        font-size: 1.1rem;
        font-family: "Open Sans - Semi Bold";
    }
    &-foot-Portal {
        width: 100%;
    }
}

body.dark {
    .dialog {
        &-card {
            color: $white;
            background: $light-black;

            &-head {
                border-bottom: 1px solid $dark-grey-3;
                border-top: 2px solid $green-4;
            }
            &-foot {
                border-top: none;
            }
            &-title {
                font-size: 1.6rem;
                margin: 0;
                background: $green-2;
                //background: $green-gradient;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &-close {
                &:hover {
                    color: $green-4;
                }
            }
        }
    }
}
</style>
