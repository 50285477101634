import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "./types";

export const actions = {
    async [actionTypes.getData]({ commit }) {
        commit(mutationTypes.rewardsGetDataStart);
        return axios({
            method: "get",
            url: "la/rewards",
        }).then(
            (data) => {
                commit(mutationTypes.rewardsGetDataSuccess, data.data);
            },
            () => {
                commit(mutationTypes.rewardsGetDataFailure);
            }
        );
    },

    // eslint-disable-next-line
    async [actionTypes.getDataById]({ commit }, { id }) {
        // commit(mutationTypes.rewardsGetDataStart);

        id = "mafra_2023-01-31T00:00:00";

        return axios({
            method: "get",
            url: "/rest/reward/" + id,
        }).then(
            (data) => {
                return data.data.reward;
                // commit(mutationTypes.rewardsGetDataSuccess, data.data);
            },
            () => {
                // commit(mutationTypes.rewardsGetDataFailure);
            }
        );
    },

    async [actionTypes.getDataWithPayouts]({ commit }, { publisherId }) {
        commit(mutationTypes.rewardsWithPayoutsGetDataStart);
        return axios({
            method: "get",
            url: "/la/payment-approval/detail-optimize/" + publisherId,
        }).then(
            (data) => {
                commit(mutationTypes.rewardsWithPayoutsGetDataSuccess, data.data);
            },
            () => {
                commit(mutationTypes.rewardsWithPayoutsGetDataFailure);
            }
        );
    },

    async [actionTypes.getDataWithPayoutsSA]({ commit }, { publisherId }) {
        commit(mutationTypes.rewardsWithPayoutsGetDataSaStart);
        return axios({
            method: "get",
            url: "/la/payment-approval/detail/" + publisherId,
        }).then(
            (data) => {
                commit(mutationTypes.rewardsWithPayoutsGetDataSaSuccess, data.data);
            },
            () => {
                commit(mutationTypes.rewardsWithPayoutsGetDataSaFailure);
            }
        );
    },
};
