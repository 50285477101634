<template>
    <div class="page-header">
        <h1 class="page-heading" v-bind="$attrs" data-test="heading">{{ heading }}</h1>
        <div class="page-pickers">
            <slot name="controls">
                <div class="page-pickers"></div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "LaPageHeader",
    props: {
        heading: {
            required: true,
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    &-header {
        padding: 1.5rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include --big-mobile-viewport {
            padding: 0.5rem 0;
        }
    }
    &-heading {
        font-weight: 600;
        font-size: 1.5rem;
        padding-left: 0.5rem;
        color: $blue;
    }
    &-pickers {
        display: flex;
    }
}

body.dark {
    .page {
        &-heading {
            padding-left: 0.5rem;
            //background: $green-gradient;
            background: $green-2;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
</style>
