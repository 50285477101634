<template>
    <div>
        <LaPageHeader heading="PayoutsCsv"> </LaPageHeader>
        <LaContentLoader v-if="loading" />
        <div v-else-if="payoutsCsv">
            <div class="columns">
                <div class="column">
                    <LaBaseTable
                        data-test="csv-table"
                        :headers="headers"
                        :items="payoutsCsv"
                        stripped
                        data-cy="payouts-csv-table"
                    >
                        <template #item-CSV="{ item }">
                            <div class="is-flex is-flex-direction-column">
                                <span class="cursor-pointer" @click="downloadCsv(item)">{{ item.key }}</span>
                            </div>
                        </template>
                    </LaBaseTable>
                </div>
            </div>
        </div>

        <LaDialog name="csvDownload">
            <LaCsvDownload />
        </LaDialog>
    </div>
</template>

<script>
import LaPageHeader from "@/components/PageHeader";
import store from "@/store/store";
import { computed } from "vue";
import { actionTypes as payoutsCsvActionTypes, getterTypes as payoutsCsvGetterTypes } from "@/store/PayoutsCsv/types";
import LaContentLoader from "@/components/loaders/ContentLoader";
import LaDialog from "@/components/dialog/Dialog";
import LaCsvDownload from "@/components/CsvDownload";
import LaBaseTable from "@/components/BaseTable";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";

export default {
    name: "LaPayoutsCsvView",
    components: {
        LaBaseTable,
        LaPageHeader,
        LaContentLoader,
        LaDialog,
        LaCsvDownload,
    },

    setup() {
        const loading = computed(() => store.getters[payoutsCsvGetterTypes.isLoading]);
        const payoutsCsv = computed(() => store.getters[payoutsCsvGetterTypes.getList]);
        if (!payoutsCsv.value) {
            store.dispatch(payoutsCsvActionTypes.getList);
        }

        const headers = [{ text: "CSV (click to download)", value: "CSV" }];

        const downloadItem = (item) => {
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: "csvDownload",
                dialogHeading: "Download CSV",
                item: item,
            });
        };

        const downloadCsv = (listItem) => {
            //console.log("csv download item", listItem.id);
            store.dispatch(payoutsCsvActionTypes.getItem, { id: listItem.id }).then((item) => {
                //console.log("loaded", item);
                const csv = "\ufeff" + item.payout_csv.value;
                const link = document.createElement("a");
                link.setAttribute("href", "data:text/csv;charset=UTF-8," + csv);
                link.setAttribute("download", listItem.id + ".csv");
                link.click();
            });
        };

        return {
            headers,
            loading,
            payoutsCsv,
            downloadItem,
            downloadCsv,
        };
    },
};
</script>
