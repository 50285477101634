export const routeParamsApplicator = function (paramValues) {
    const applyRouteParams = function (i) {
        if (i.routeParams) {
            var routeDef = { name: i.routeName, params: {} };
            for (var p in i.routeParams) {
                if (Object.prototype.hasOwnProperty.call(i.routeParams, p)) {
                    if (i.routeParams[p] && !paramValues[p]) {
                        console.error(
                            "Required paramValues[" + p + "] not set:",
                            paramValues,
                            "for link definition:",
                            i
                        );
                    }
                    routeDef.params[p] = paramValues[p];
                }
            }
        }
        if (routeDef) {
            i.routeDef = routeDef;
        }
        return i;
    };
    return applyRouteParams;
};
