import { actionTypes, mutationTypes } from "./types";

const isoCountries = require("i18n-iso-countries");
isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const actions = {
    async [actionTypes.getData]({ commit }) {
        commit(mutationTypes.countryGetDataStart);
        try {
            const countriesList = isoCountries.getNames("en", { select: "official" });
            const countries = [];
            Object.keys(countriesList).map((code) => {
                countries.push({ code: code, name: countriesList[code] });
            });
            commit(mutationTypes.countryGetDataSuccess, countries);
        } catch {
            commit(mutationTypes.countryGetDataFailure);
        }
    },
    async [actionTypes.getCountry]({ commit }, { isPublisher, code }) {
        const name = isoCountries.getName(code, "en", { select: "official" });
        const country = { name: name, code: code };
        if (isPublisher) {
            commit(mutationTypes.setPublisherCountry, country);
        } else {
            commit(mutationTypes.setBankCountry, country);
        }
    },
};
