import axios from "@/plugins/axios";
import store from "@/store/store";
import { actionTypes, mutationTypes } from "./types";
import { actionTypes as paginatorActionTypes } from "@/store/Paginators/types";
import { getterTypes as payoutGetterTypes } from "@/store/Payouts/types";
import { actionTypes as PayoutActionTypes } from "@/store/Payouts/types";
import { actionTypes as RewardActionTypes } from "@/store/Rewards/types";
import { getExchangeRateFromCzk } from "@/helpers/data";
import { showMessage } from "@/helpers/messages";
import { computed } from "vue";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export const actions = {
    async [actionTypes.getData]({ commit }) {
        commit(mutationTypes.payoutsGetDataStart);
        return axios({
            method: "get",
            url: "la/payouts",
        }).then(
            (data) => {
                commit(mutationTypes.payoutsGetDataSuccess, data.data);
            },
            () => {
                commit(mutationTypes.payoutsGetDataFailure);
            }
        );
    },

    async [actionTypes.getAllData](
        { commit, dispatch },
        { publisherId, status, searchAfter1, searchAfter2, refreshPaginator = false }
    ) {
        commit(mutationTypes.payoutsGetAllDataStart);

        let params = new URLSearchParams("");
        params.append("_sort", "date:desc");

        if (publisherId && publisherId !== "all") {
            params.append("publisher", publisherId);
        }
        if (status && status !== "all") {
            params.append("status", status);
        }

        if (searchAfter1 && searchAfter2) {
            params.append("_search_after", searchAfter1);
            params.append("_search_after", searchAfter2);
        }

        const url = "rest/payout/?" + params.toString();

        return axios({
            method: "get",
            url: url,
        }).then(
            (data) => {
                commit(mutationTypes.payoutsGetAllDataSuccess, data.data);
                if (refreshPaginator) {
                    dispatch(paginatorActionTypes.setPagesAction, { pages: data.data.pages });
                    dispatch(paginatorActionTypes.setActualPage, { num: 1 });
                }
            },
            () => {
                commit(mutationTypes.payoutsGetAllDataFailure);
            }
        );
    },

    async [actionTypes.getDataForDetail]({ commit }, { publisherId }) {
        commit(mutationTypes.payoutsGetDataForDetailStart);
        return axios({
            method: "get",
            url: "la/rewards-by-id/" + publisherId,
        }).then(
            (data) => {
                commit(mutationTypes.payoutsGetDataForDetailSuccess, data.data);
            },
            () => {
                commit(mutationTypes.payoutsGetDataForDetailFailure);
            }
        );
    },

    async [actionTypes.addPayout]({ commit, dispatch }, payload) {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        payload.sum *= getExchangeRateFromCzk(selectCurrency.value);
        payload.date.setUTCHours(0, 0, 0, 0);
        payload.date = payload.date.toISOString().split("T")[0] + "T00:00:00";

        commit(mutationTypes.payoutEditDataStart);
        return axios({
            method: "post",
            url: "/rest/payout",
            data: payload,
        }).then(
            (data) => {
                commit(mutationTypes.payoutEditDataSuccess, data.data);

                const publisherId = store.getters[payoutGetterTypes.getPublisherSelected];
                const status = store.getters[payoutGetterTypes.getStatusSelected];

                dispatch(actionTypes.getAllData, {
                    publisherId: publisherId,
                    status: status,
                    refreshPaginator: true,
                });
            },
            () => {
                commit(mutationTypes.payoutEditDataFailure);
            }
        );
    },

    async [actionTypes.editPayout]({ commit, dispatch }, payload) {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        payload.sum *= getExchangeRateFromCzk(selectCurrency.value);

        commit(mutationTypes.payoutEditDataStart);
        return axios({
            method: "put",
            url: "/rest/payout/" + payload.id,
            data: payload,
        }).then(
            (data) => {
                commit(mutationTypes.payoutEditDataSuccess, data.data);

                const publisherId = store.getters[payoutGetterTypes.getPublisherSelected];
                const status = store.getters[payoutGetterTypes.getStatusSelected];

                dispatch(actionTypes.getAllData, {
                    publisherId: publisherId,
                    status: status,
                    refreshPaginator: true,
                });
                store.dispatch(PayoutActionTypes.getData);
                store.dispatch(RewardActionTypes.getData);
            },
            () => {
                commit(mutationTypes.payoutEditDataFailure);
            }
        );
    },

    // eslint-disable-next-line
    [actionTypes.deletePayout]({commit, dispatch}, payload) {
        return axios({
            method: "delete",
            url: "/rest/payout/" + payload.id + "?r=1",
        }).then(
            (data) => {
                const publisherId = store.getters[payoutGetterTypes.getPublisherSelected];
                const status = store.getters[payoutGetterTypes.getStatusSelected];

                dispatch(actionTypes.getAllData, {
                    publisherId: publisherId,
                    status: status,
                    refreshPaginator: true,
                });
                showMessage(data.data, "delet", "fee");
            },
            () => {}
        );
    },

    [actionTypes.selectPublisher]({ commit }, publisherId) {
        commit(mutationTypes.selectPublisherSuccess, publisherId);
    },
    [actionTypes.selectStatus]({ commit }, status) {
        commit(mutationTypes.selectStatusSuccess, status);
    },
};
