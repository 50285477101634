import axios from "@/plugins/axios";
import { actionTypes as feeActionTypes, actionTypes, getterTypes as feeGetterTypes } from "./types";
import { mutationTypes } from "@/store/Fees/types";
import { actionTypes as feePaginatorActionTypes } from "@/store/Paginators/types";
import store from "@/store/store";
import { showMessage } from "@/helpers/messages";

export const actions = {
    // eslint-disable-next-line
    [actionTypes.getLastFee]({commit}, {aabPublisherId, aabSiteId}) {
        return axios({
            method: "get",
            url: "fee/" + aabPublisherId + "/" + aabSiteId,
        }).then(({ data }) => {
            return data;
        });
    },

    [actionTypes.getAll](
        { commit, dispatch },
        { publisherId, websiteId, timestamp, after_search, refreshPaginator = false }
    ) {
        let params = new URLSearchParams("");
        if (publisherId && publisherId !== "all") {
            params.append("publisher", publisherId);
        }
        if (websiteId && websiteId !== "all") {
            params.append("website", websiteId);
        }
        if (timestamp && after_search) {
            params.append("_search_after", timestamp);
            params.append("_search_after", after_search);
        }
        const url = "fees/?" + params.toString();

        commit(mutationTypes.getFeesDataStart);
        return axios({
            method: "get",
            url: url,
        }).then(
            (data) => {
                commit(mutationTypes.getFeesDataSuccess, data.data);
                if (refreshPaginator) {
                    dispatch(feePaginatorActionTypes.setPagesAction, { pages: data.data.pages });
                    dispatch(feePaginatorActionTypes.setActualPage, { num: 1 });
                }
            },
            () => {
                commit(mutationTypes.getFeesDataFailure);
            }
        );
    },

    [actionTypes.createFee]({ commit, dispatch }, payload) {
        commit(mutationTypes.createFeeDataStart);
        if (typeof payload.valid_from === "string") {
            payload.valid_from = new Date(payload.valid_from);
        }
        payload.valid_from.setUTCHours(0, 0, 0, 0);
        payload.valid_from = payload.valid_from.toISOString().split("T")[0] + "T00:00:00";
        console.log(payload.valid_from);

        return axios({
            method: "post",
            url: "fees",
            data: {
                publisher: payload.publisher,
                valid_from: payload.valid_from,
                value: parseInt(payload.value) * 100,
                website: payload.website === "all" ? "*" : payload.website,
            },
        }).then(
            (data) => {
                commit(mutationTypes.createFeeDataSuccess, data.data);
                const websiteId = store.getters[feeGetterTypes.getWebsiteSelected];
                const publisherId = store.getters[feeGetterTypes.getPublisherSelected];
                dispatch(feeActionTypes.getAll, {
                    publisherId: publisherId,
                    websiteId: websiteId,
                    refreshPaginator: true,
                });
                showMessage(data.data, "creat", "fee");
            },
            () => {
                commit(mutationTypes.createFeeDataFailure);
            }
        );
    },

    [actionTypes.editFee]({ commit, dispatch }, payload) {
        commit(mutationTypes.editFeeDataStart);

        return axios({
            method: "put",
            url: "fees/" + payload.fee_id,
            data: {
                value: parseInt(payload.value) * 100,
                time_line: payload.time_line,
            },
        }).then(
            (data) => {
                commit(mutationTypes.editFeeDataSuccess, data.data);
                dispatch(feeActionTypes.getAll, { publisherId: payload.publisher, websiteId: payload.website });
                showMessage(data.data, "edit", "fee");
            },
            () => {
                commit(mutationTypes.editFeeDataFailure);
            }
        );
    },

    // eslint-disable-next-line
    [actionTypes.deleteFee]({commit, dispatch}, payload) {
        return axios({
            method: "delete",
            url: "fees/" + payload.id,
        }).then(
            (data) => {
                const websiteId = store.getters[feeGetterTypes.getWebsiteSelected];
                const publisherId = store.getters[feeGetterTypes.getPublisherSelected];
                dispatch(feeActionTypes.getAll, {
                    publisherId: publisherId,
                    websiteId: websiteId,
                    refreshPaginator: true,
                });
                showMessage(data.data, "delet", "fee");
            },
            () => {}
        );
    },

    [actionTypes.selectPublisher]({ commit }, publisherId) {
        commit(mutationTypes.selectPublisherSuccess, publisherId);
    },
    [actionTypes.selectWebsite]({ commit }, websiteId) {
        commit(mutationTypes.selectWebsiteSuccess, websiteId);
    },
};
