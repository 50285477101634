<template>
    <Suspense>
        <template #default>
            <LaPersonalInfo />
        </template>
        <template #fallback>
            <LaFormLoader />
        </template>
    </Suspense>
</template>

<script>
import LaPersonalInfo from "@/components/account/PersonalInfo";
import LaFormLoader from "@/components/loaders/FormLoader";

export default {
    name: "LaPersonalInfoView",
    components: {
        LaPersonalInfo,
        LaFormLoader,
    },
};
</script>

<style scoped></style>
