<template>
    <LaBaseTable
        data-test="payouts-management-table"
        :headers="headers"
        :items="data"
        stripped
        data-cy="payouts-management-table"
    >
        <template #item-date="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ formatDate(new Date(item.date * 1000), false) }}</span>
                <div class="second" v-if="$mq.max.mobile">{{ formatDate(new Date(item.date * 1000), false) }}</div>
            </div>
        </template>

        <template #item-sum="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ formatCell(Math.round(item.sum / exchangeRate)) }} {{ currencySymbol }}</span>
            </div>
        </template>

        <template #item-reference="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ item.reference }}</span>
            </div>
        </template>

        <template #item-status="{ item }">
            <div class="is-flex is-flex-direction-column">
                <div class="status">
                    {{ item.status }}
                    <span v-if="item.status_detail" :title="item.status_detail">
                        ({{ getShortStatusDetail(item.status_detail) }})
                    </span>
                </div>
            </div>
        </template>

        <template #item-actions="{ item }">
            <LaActionIcons :item="item" item-name="payout" :show-delete-icon="true"></LaActionIcons>
        </template>
    </LaBaseTable>
</template>

<script>
import LaBaseTable from "@/components/BaseTable";
import LaActionIcons from "@/components/button/ActionIcons";
import { formatCell, formatDate, formatMonthDate, roundCurrency, roundNumber } from "@/helpers/filters";
import { getCurrencySymbol, getExchangeRateFromCzk } from "@/helpers/data";
import { computed } from "vue";
import store from "@/store/store";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export default {
    name: "PayoutManagementTable",
    components: {
        LaBaseTable,
        LaActionIcons,
    },
    props: {
        data: {
            required: true,
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: "date",
                    value: "date",
                    direction: -1,
                    flex: 1,
                },
                {
                    text: "publisher",
                    value: "publisher",
                    flex: 1,
                },
                {
                    text: "status",
                    value: "status",
                    flex: 1,
                },
                {
                    text: "sum",
                    value: "sum",
                    isNumber: true,

                    flex: 1,
                },
                {
                    text: "reference",
                    value: "reference",
                    flex: 1,
                    justifyContentCenter: true,
                },

                {
                    text: "actions",
                    value: "actions",
                    justifyContentCenter: true,
                },
            ];
        },
    },
    setup() {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));
        return { formatMonthDate, formatCell, formatDate, roundNumber, roundCurrency, exchangeRate, currencySymbol };
    },
    methods: {
        getShortStatusDetail(statusDetail) {
            if (statusDetail === "Unpaid rewards too low.") {
                return "Too small";
            }
            if (statusDetail === "No payment method.") {
                return "Other";
            }
            return statusDetail;
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    text-transform: uppercase;

    span {
        cursor: help;
    }
}
</style>
