import { createStore } from "vuex";

const modules = require("@/store/autoloader.js");

export default createStore({
    strict: process.env.NODE_ENV !== "production",
    modules: {
        ...modules.default,
    },
});
