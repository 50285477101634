<template>
    <router-view />
</template>

<script>
// import LaFooter from "@/components/layout/Footer";
export default {
    name: "LaUnloggedView",
    components: {
        // LaFooter,
    },
};
</script>

<style lang="scss" scoped>
.navigation-logo img {
    padding: 1em 2em;
}
.brand {
    &-bar {
        position: fixed;
        display: flex;
        align-items: center;
        background-color: $blue;
        z-index: 99;
        min-height: 65px;
        width: 100%;
        span {
            color: $white;
            margin-left: 0.8rem;
            top: -1rem;
        }
    }
}

.unlogged {
    &-content {
        max-width: 30rem;
        padding: 1rem;
        margin-top: 4rem;
        min-height: 100vh;
        color: $text;
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $background;
    }
}
</style>
