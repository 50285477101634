export const actionTypes = {
    getData: "[kununu] Get data",
};

export const mutationTypes = {
    kununuGetDataStart: "[kununu] Kununu get data start",
    kununuGetDataSuccess: "[kununu] Kununu get data success",
    kununuGetDataFailure: "[kununu] Kununu get data failure",
};

export const getterTypes = {
    getKununu: "[kununu] Get kununu script",
};
