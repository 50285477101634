import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { getterTypes, mutationTypes } from "@/store/Publisher/types";
import { setItem, removeItem } from "@/store/persistantStorage";

function updatePublisherState(
    state,
    {
        id,
        is_eu,
        vat_number,
        company_name,
        street1,
        street2,
        city,
        zip,
        ico,
        country,
        contact_email,
        identification_number,
    }
) {
    const selectedPublisher = getSelectedPublisher(state);
    state.vatNumber = vat_number;
    state.data = state.data.map((p) => {
        return p === selectedPublisher
            ? {
                  ...p,
                  pub_id: id,
                  is_eu,
                  vat_number,
                  company_name,
                  street1,
                  street2,
                  city,
                  zip,
                  ico,
                  country,
                  contact_email,
                  identification_number,
              }
            : p;
    });
    state.isLoading = false;
}

function getSelectedPublisher(state) {
    if (!state.data || !state.data.length) {
        return null;
    }

    if (state.selectedPublisher) {
        return state.data.find((publisher) => publisher?.id === state.selectedPublisher) || state.data[0];
    }

    return state.data[0];
}

export default {
    actions,
    mutations: {
        [mutationTypes.publishersGetDataStart](state) {
            state.data = null;
            state.isLoading = true;
        },
        [mutationTypes.publishersGetDataSuccess](state, data) {
            state.vatNumber = data[0]["vat_number"];
            state.data = data;
            state.isLoading = false;
        },
        [mutationTypes.publishersGetDataFailure](state) {
            state.isLoading = false;
        },
        // data for fee page
        [mutationTypes.publishersGetDataForFeePageStart](state) {
            state.dataForFeePage = null;
            state.isLoading = true;
        },
        [mutationTypes.publishersGetDataForFeePageSuccess](state, data) {
            let items = data;
            items.unshift({ id: "*", name: "*" });
            items.unshift({ id: "all", name: "all" });
            state.dataForFeePage = items;
            state.isLoading = false;
        },
        [mutationTypes.publishersGetDataForFeePageFailure](state) {
            state.isLoading = false;
        },

        // data for payouts management page
        [mutationTypes.publishersGetDataForPayoutsManagementPageStart](state) {
            state.dataForPayoutsManagementPage = null;
            state.isLoading = true;
        },
        [mutationTypes.publishersGetDataForPayoutsManagementPageSuccess](state, data) {
            let items = data;
            items.unshift({ id: "all", name: "all" });
            state.dataForPayoutsManagementPage = items;
            state.isLoading = false;
        },
        [mutationTypes.publishersGetDataForPayoutsManagementPageFailure](state) {
            state.isLoading = false;
        },

        // company create
        [mutationTypes.createPublisherStart](state) {
            state.createPublisherErrors = null;
            state.isLoading = true;
        },
        [mutationTypes.createPublisherSuccess](state) {
            state.isLoading = false;
        },
        [mutationTypes.createPublisherErrors](state, data) {
            state.createPublisherErrors = data;
            state.isLoading = false;
        },
        [mutationTypes.createPublisherFailure](state) {
            state.isLoading = false;
        },
        // company edit
        [mutationTypes.companyEditDataStart](state) {
            state.companyAccountErrors = null;
        },
        [mutationTypes.companyEditDataSuccess](state, data) {
            updatePublisherState(state, data);
        },
        [mutationTypes.companyEditDataErrors](state, data) {
            state.companyAccountErrors = data;
        },
        [mutationTypes.companyEditDataFailure](state) {
            state.isLoading = false;
        },
        // check vat
        [mutationTypes.checkVAtNumberStart](state) {
            state.companyAccountErrors = null;
        },
        [mutationTypes.checkVAtNumberSuccess](state, data) {
            updatePublisherState(state, data);
        },
        [mutationTypes.checkVAtNumberFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.setSelectedPublisher](state, data) {
            state.selectedPublisher = data;
            setItem("selectedPublisher", data);
        },
        [mutationTypes.removeSelectedPublisher](state) {
            state.selectedPublisher = null;
            removeItem("selectedPublisher");
        },
        // currency
        [mutationTypes.setCurrencyBySelectedPublisher](state, data) {
            console.log("currency" + data);
            state.selectedPublisherCurrency = data;
            setItem("selectedPublisherCurrency", data);
        },
        // publisher delete
        [mutationTypes.deletePublisherStart](state) {
            state.deletePublisherErrors = null;
            state.isLoading = true;
        },
        [mutationTypes.deletePublisherSuccess](state) {
            state.isLoading = false;
        },
        [mutationTypes.deletePublisherErrors](state, data) {
            state.deletePublisherErrors = data;
            state.isLoading = false;
        },
        [mutationTypes.deletePublisherFailure](state) {
            state.isLoading = false;
        },
    },
    getters: {
        [getterTypes.getPublishers](state) {
            return state.data;
        },
        [getterTypes.getPublishersForFeePage](state) {
            return state.dataForFeePage;
        },
        [getterTypes.getPublishersForPayoutsManagementPage](state) {
            return state.dataForPayoutsManagementPage;
        },
        [getterTypes.getPublisher](state) {
            return getSelectedPublisher(state);
        },
        [getterTypes.getVatNumber](state) {
            return state.vatNumber;
        },
        [getterTypes.getCompanyAccountErrors](state) {
            return state.companyAccountErrors;
        },
        [getterTypes.getSelectedPublisher](state) {
            return state.selectedPublisher;
        },
        [getterTypes.getIsLoading](state) {
            return state.isLoading;
        },
        [getterTypes.getCreatePublisherErrors](state) {
            return state.createPublisherErrors;
        },
        [getterTypes.getDeletePublisherErrors](state) {
            return state.deletePublisherErrors;
        },
        [getterTypes.getSelectedPublisherCurrency](state) {
            return state.selectedPublisherCurrency;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
