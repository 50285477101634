<template>
    <div v-if="selectedPublisherComputed || !isEdit">
        <LaBaseForm
            :form-data="
                isEdit
                    ? {
                          publisher: selectedPublisherComputed,
                          website: selectedWebsiteComputed,
                          value: percentValue,
                          time_line: timeLine,
                          fee_id: feeId,
                      }
                    : {
                          publisher: selectedPublisherComputed,
                          website: selectedWebsiteComputed,
                          valid_from: validFrom,
                          value: percentValue,
                      }
            "
            :vuex-action-mutation="formAction"
            :button="isEdit ? 'Save' : 'Add fee'"
            id="fee"
            buttonPortalTo="modalFooter"
        >
            <LaBaseInput
                v-if="isEdit"
                data-test="valid-from-value"
                v-model="validFrom"
                name="validFrom"
                label="Valid from"
                id="validFrom"
                :disabled="true"
            />

            <LaBaseInput
                data-test="selected-website-value"
                v-model="selectedWebsiteComputed"
                name="website"
                label="Website"
                id="website"
                :disabled="true"
            />

            <LaBaseInput
                data-test="fee-value"
                v-model="percentValue"
                name="percentValue"
                label="Value in percent"
                id="percentValue"
                :rules="rules.percentValue"
                :error="errors.percentValue"
            />

            <LaSimpleDatePicker
                v-if="!isEdit"
                data-test="fee-valid-from"
                v-model="validFrom"
                name="pickit"
                label="Valid from"
                placeholder="Date from when this fee applies"
                :rules="rules.validFrom"
                :error="errors.validFrom"
            />
        </LaBaseForm>
    </div>
</template>

<script>
import LaBaseForm from "@/components/BaseForm";
import LaBaseInput from "@/components/inputs/BaseInput";
import LaSimpleDatePicker from "@/components/datepicker/SimpleDatePicker";
import { computed, ref } from "vue";
import { isRequired } from "@/helpers/rules";
import store from "@/store/store";
import { getterTypes as feeGetterTypes, actionTypes as feeActionTypes } from "@/store/Fees/types";
import { getterTypes as dialogGetterTypes } from "@/store/Dialog/types";
import { formatDateTimeByLocaleTimezone } from "@/helpers/filters.js";

export default {
    name: "FeeForm",
    components: {
        LaBaseForm,
        LaBaseInput,
        LaSimpleDatePicker,
    },
    setup() {
        let isEdit = ref(false);
        let validFrom = ref("");
        let percentValue = ref("");
        let timeLine = ref("");
        let feeId = ref("");

        let formAction = feeActionTypes.createFee;
        const selectedPublisherComputed = computed(() => store.getters[feeGetterTypes.getPublisherSelected]);
        const selectedWebsiteComputed = computed(() => store.getters[feeGetterTypes.getWebsiteSelected]);
        const feeComputed = computed(() => store.getters[dialogGetterTypes.getDialog].item);

        if (feeComputed.value) {
            isEdit = true;
            validFrom.value = formatDateTimeByLocaleTimezone(feeComputed.value.valid_from_isoformat);
            percentValue.value = feeComputed.value.value / 100;
            formAction = feeActionTypes.editFee;
            timeLine = feeComputed.value.time_line;
            feeId = feeComputed.value.id;

            store.dispatch(
                feeActionTypes.selectWebsite,
                selectedWebsiteComputed.value === "all" ? "*" : selectedWebsiteComputed.value
            );
        }
        const rules = {
            percentValue: [isRequired()],
            validFrom: [isRequired()],
        };

        const errors = {
            percentValue: null,
            validFrom: null,
        };

        return {
            selectedPublisherComputed,
            selectedWebsiteComputed,
            isEdit,
            formAction,
            rules,
            errors,
            percentValue,
            validFrom,
            timeLine,
            feeId,
        };
    },
};
</script>

<style scoped></style>
