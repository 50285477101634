<template>
    <div>
        <LaPageHeader heading="Payouts"> </LaPageHeader>
        <LaContentLoader v-if="loading" />

        <div v-else>
            <LaNoData
                v-if="!hasData"
                header="Add website"
                description="Please add website to show earnings data for it."
            >
                <LaAddWebsite />
            </LaNoData>
            <div v-else>
                <div class="columns">
                    <div class="column">
                        <LaPayoutDataCard :payouts="payouts" :revenue="revenue" />
                    </div>
                </div>
            </div>
        </div>

        <LaDialog name="payoutForm">
            <PayoutForm />
        </LaDialog>
    </div>
</template>

<script>
import LaPageHeader from "@/components/PageHeader";
import LaAddWebsite from "@/components/button/AddWebsite";
import LaDialog from "@/components/dialog/Dialog";
import store from "@/store/store";
import PayoutForm from "@/components/dialog/PayoutForm.vue";
import { computed } from "vue";
import { actionTypes as PayoutActionTypes, getterTypes as PayoutGetterTypes } from "@/store/Payouts/types";
import { actionTypes as RewardActionTypes, getterTypes as RewardGetterTypes } from "@/store/Rewards/types";
import LaContentLoader from "@/components/loaders/ContentLoader";
import LaNoData from "@/components/NoData";
import LaPayoutDataCard from "@/components/payouts/PayoutDataCard.vue";

export default {
    name: "LaPayoutsView",
    components: {
        LaPayoutDataCard,
        LaPageHeader,
        LaAddWebsite,
        LaContentLoader,
        LaNoData,
        LaDialog,
        PayoutForm,
    },

    setup() {
        const loading = computed(() => store.getters[PayoutGetterTypes.isLoading]);
        const payouts = computed(() => store.getters[PayoutGetterTypes.getPayouts]);
        const revenue = computed(() => store.getters[RewardGetterTypes.getRewards]);

        const hasData = computed(() => !!payouts.value && !!revenue.value);

        if (!payouts.value) {
            store.dispatch(PayoutActionTypes.getData);
        }
        if (!revenue.value) {
            store.dispatch(RewardActionTypes.getData);
        }

        return {
            loading,
            hasData,
            payouts,
            revenue,
        };
    },
};
</script>

<style scoped></style>
