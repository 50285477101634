export const actionTypes = {
    getData: "[yield] Get data",
    getGrandTotal: "[yield] Get grand total",
    editRecord: "[yield] Edit record for form",
};

export const mutationTypes = {
    yieldStart: "[yield] Yield get data start",
    yieldSuccess: "[yield] Yield get data success",
    yieldFailure: "[yield] Yield get data failure",
    grandTotalStart: "[yield] Grand total start",
    grandTotalSuccess: "[yield] Grand total success",
    grandTotalFailure: "[yield] Grand total failure",
    recordEditDataStart: "[yield] Record edit data start",
    recordEditDataSuccess: "[yield] Record edit data success",
    recordEditDataFailure: "[yield] Record edit data failure",
    recordEditDataErrors: "[yield] Record edit data errors",
};

export const getterTypes = {
    getYieldData: "[yield] Get yield data",
    getYieldMetadata: "[yield] Get yield metadata",
    getYieldCsvData: "[yield] Get yield csvdata",
    getGrandTotal: "[yield] Get grand total",
    isLoading: "[yield] Get isLoading",
};
