import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        // getList
        [mutationTypes.payoutsCsvGetListStart](state) {
            state.isLoading = true;
            state.list = [];
        },
        [mutationTypes.payoutsCsvGetListSuccess](state, data) {
            state.isLoading = false;
            state.list = data.payout_csvs;
        },
        [mutationTypes.payoutsCsvGetListFailure](state) {
            state.isLoading = false;
        },
        // getItem
        [mutationTypes.payoutsCsvGetItemStart](state) {
            state.isLoading = true;
        },
        [mutationTypes.payoutsCsvGetItemSuccess](state) {
            state.isLoading = false;
        },
        [mutationTypes.payoutsCsvGetItemFailure](state) {
            state.isLoading = false;
        },
    },
    getters: {
        [getterTypes.getList](state) {
            return state.list || null;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
