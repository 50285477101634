<template>
    <InputContainer :label="label" :name="name" :errors="errors" :showErrors="showErrors">
        <div class="base-input-container">
            <DatePicker v-bind="$attrs" :modelValue="modelValue" @input="handleInput" :masks="masks">
                <template #default="{ inputValue, inputEvents }">
                    <input
                        :value="inputValue"
                        v-on="inputEvents"
                        class="base-input"
                        :placeholder="placeholder ? placeholder : ''"
                    />
                </template>
            </DatePicker>
        </div>
    </InputContainer>
</template>
<script>
import { DatePicker } from "v-calendar";
import inputs from "@/mixins/inputs.js";
import InputContainer from "@/components/inputs/InputContainer";

export default {
    name: "LaSimpleDatePicker",
    components: {
        InputContainer,
        DatePicker,
    },
    mixins: [inputs],
    props: {
        modelValue: {
            type: [String, Object],
            required: true,
        },
        placeholder: {
            type: [String, Object],
            required: false,
        },
    },
    setup() {
        const masks = {
            input: ["YYYY-MM-DD", "iso"],
            output: ["iso"],
        };
        return {
            masks,
        };
    },
};
</script>
