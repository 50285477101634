import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.earningsStart](state) {
            state.isLoading = true;
            //state.data = null;
            //state.metadata = null;
        },
        [mutationTypes.earningsSuccess](state, data) {
            state.isLoading = false;
            state.data = data.data;
            state.metadata = data.metadata;
            state.csvdata = data.csv;
        },
        [mutationTypes.earningsFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.grandTotalStart](state) {
            state.isGtLoading = true;
            state.grandTotal = 0;
        },
        [mutationTypes.grandTotalSuccess](state, data) {
            state.isGtLoading = false;
            state.grandTotal = data;
        },
        [mutationTypes.grandTotalFailure](state) {
            state.isGtLoading = false;
        },
    },
    getters: {
        [getterTypes.getEarningsData](state) {
            return state.data;
        },
        [getterTypes.getEarningsMetadata](state) {
            return state.metadata;
        },
        [getterTypes.getEarningsCsvData](state) {
            return state.csvdata;
        },
        [getterTypes.isLoading](state) {
            return state.isLoading || state.isGtLoading;
        },
        [getterTypes.getGrandTotal](state) {
            return state.grandTotal;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
