<template>
    <div data-test="no-data" class="no-data">
        <div class="no-data-img-container">
            <img src="@/assets/not_found.svg" />
        </div>
        <h1 class="no-data-header">{{ header }}</h1>
        <p class="no-data-text">{{ description }}</p>
        <slot />
    </div>
</template>

<script>
export default {
    name: "LaNoData",
    props: {
        header: {
            type: String,
            default: "No data found",
        },
        description: {
            type: String,
            default: "No data were found.",
        },
    },
};
</script>

<style lang="scss" scoped>
.no-data {
    text-align: center;
    margin: 4rem auto auto auto;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8rem;
        height: 8rem;
        border-radius: 90%;
        background-color: $white;
    }
    &-text {
        font-size: 0.9rem;
        line-height: 1.8;
        margin-bottom: 2.5rem;
    }
}
</style>
