<template>
    <div v-if="storePages" class="table-footer">
        Page
        <input
            class="table-page"
            v-model="inputActualPage"
            @change="setPage(inputActualPage)"
            type="number"
            min="1"
            :max="maxPage"
        />
        of
        {{ maxPage }}

        <button class="table-arrow" @click="setPage(storeActualPage - 1)" :disabled="storeActualPage <= 1">
            <LaBaseIcon name="arrow-left" />
        </button>

        <button class="table-arrow" @click="setPage(storeActualPage + 1)" :disabled="storeActualPage >= maxPage">
            <LaBaseIcon name="arrow-right" />
        </button>
    </div>
</template>

<script>
import LaBaseIcon from "@/components/BaseIcon.vue";
import store from "@/store/store";
import { actionTypes as payoutActionTypes, getterTypes as payoutGetterTypes } from "@/store/Payouts/types";
import { getterTypes as paginatorGetterTypes, actionTypes as paginatorActionTypes } from "@/store/Paginators/types";
import { computed } from "vue";

export default {
    name: "PayoutManagementPaginator",
    props: {
        pages: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    setup(props) {
        const storeActualPage = computed(() => store.getters[paginatorGetterTypes.getActualPage]);
        let inputActualPage = storeActualPage.value;

        const storePages = computed(() => store.getters[paginatorGetterTypes.getPages]);
        if (!storePages.value) {
            store.dispatch(paginatorActionTypes.setPagesAction, { pages: props.pages });
        }
        const maxPage = storePages.value.length;

        const storePublisherSelected = computed(() => store.getters[payoutGetterTypes.getPublisherSelected]);
        const storeStatusSelected = computed(() => store.getters[payoutGetterTypes.getStatusSelected]);

        return {
            inputActualPage,
            storeActualPage,
            storePages,
            maxPage,
            storePublisherSelected,
            storeStatusSelected,
        };
    },
    methods: {
        setPage(index) {
            if (index > 0 && index <= this.maxPage) {
                store.dispatch(paginatorActionTypes.setActualPage, { num: index });
            }
            this.reload(index);
        },
        reload(index) {
            let page = this.storePages[index - 1];
            let searchAfter1 = page ? page[0] : null;
            let searchAfter2 = page ? page[1] : null;

            store.dispatch(payoutActionTypes.getAllData, {
                publisherId: this.storePublisherSelected,
                status: this.storeStatusSelected,
                searchAfter1: searchAfter1,
                searchAfter2: searchAfter2,
            });
        },
    },

    components: {
        LaBaseIcon,
    },
};
</script>
