<template>
    <LaBaseDropdown :icon="false" class="datepicker" data-test="dates-select" ref="dropdown">
        <template #trigger>
            <slot name="trigger">
                <div class="datepicker-trigger" v-if="isPeriodsModel">
                    <div class="datepicker-date">{{ modelValue }}</div>
                </div>
                <div class="datepicker-trigger" v-else-if="range" @click="close">
                    <div class="datepicker-date">{{ formatDate(dates["start"]) }}</div>
                    <LaBaseIcon class="datepicker-arrow" name="arrow-right" />
                    <div class="datepicker-date">{{ formatDate(dates["end"]) }}</div>
                </div>
                <div class="datepicker-trigger" v-else>
                    <div class="datepicker-date">{{ formatDate(dates["start"]) }}</div>
                </div>
            </slot>
        </template>
        <template #default>
            <div class="datepicker-content">
                <div class="is-flex">
                    <div>
                        <DatePicker v-model="periodModel" :is-range="range" :max-date="maxDate" />
                    </div>
                    <div class="custom-button-container">
                        <button
                            class="custom-button"
                            v-for="(period, index) in PeriodsEnum"
                            :key="index"
                            @click="selectPeriod(period)"
                            data-test="period-option"
                            :text="period"
                        >
                            {{ period }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </LaBaseDropdown>
</template>

<script>
import { computed, ref } from "vue";
import { DatePicker } from "v-calendar";
import LaBaseIcon from "@/components/BaseIcon";
import LaBaseDropdown from "@/components/BaseDropdown";
import { formatDate } from "@/helpers/filters.js";
import { getDatesByPeriod, PeriodsEnum } from "@/helpers/data.js";

export default {
    name: "LaBaseDatePicker",
    components: {
        LaBaseDropdown,
        LaBaseIcon,
        DatePicker,
    },
    props: {
        modelValue: {
            type: [String, Object],
            required: true,
        },
        range: {
            type: Boolean,
            required: false,
            default: true,
        },
        tz: {
            type: String,
            default: "Europe/Prague",
        },
    },
    setup(props, { emit }) {
        const maxDate = ref(new Date());
        const dropdown = ref(null);
        const closeDropdown = () => dropdown.value?.closeMenu();
        const periodModel = computed({
            get: () => props.modelValue,
            set: (value) => {
                if (value && typeof value !== "string") {
                    emit("update:modelValue", { dateFrom: value["start"], dateTo: value["end"] });
                } else {
                    emit("update:modelValue", value);
                }
                return closeDropdown();
            },
        });
        const isPeriodsModel = computed(() => typeof props.modelValue === "string");

        const dates = computed(() =>
            props.modelValue && typeof props.modelValue !== "string"
                ? { start: props.modelValue.dateFrom, end: props.modelValue.dateTo }
                : getDatesByPeriod(props.modelValue, props.tz)
        );

        const selectPeriod = (val) => {
            periodModel.value = val;
        };

        return {
            isPeriodsModel,
            periodModel,
            formatDate,
            PeriodsEnum,
            dates,
            dropdown,
            maxDate,
            selectPeriod,
        };
    },
};
</script>

<style lang="scss" scoped>
.datepicker {
    position: relative;
    &-trigger {
        width: 100%;
        border-radius: $normal-radius;
        border: 1px solid $dark-grey;
        min-width: 20rem;
        padding: 0.7rem 1rem;
        background-color: $white;
        color: $blue;
        font-size: 1rem !important;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        user-select: none;
    }
    &-content {
        background: $white;
        border-radius: $normal-radius;
        user-select: none;

        .vc-container {
            background: transparent;
            color: $dark-text;
        }
    }
}
.custom-button {
    border-radius: $normal-radius;
    background: $white;
    border: 1px solid $blue;
    color: $blue;
    cursor: pointer;
    margin: 0 0 1rem 0;
    font-size: 0.8rem;
    width: 100%;
    white-space: nowrap;
    padding: 0.3rem;
    display: block;
    &-container {
        padding: 1rem 1rem 1rem 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

body.dark {
    .datepicker {
        &-trigger {
            border: 1px solid transparent;
            background: $input-background;
            color: $text;
        }

        &-content {
            background: $card-background;
        }
    }
    .custom-button {
        background: transparent;
        border: 1px solid $text;
        color: $text;
    }
}
</style>
