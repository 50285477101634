<template>
    <div v-if="payout || !isEdit">
        <LaBaseForm
            :form-data="
                isEdit
                    ? {
                          id: payout.id,
                          status_detail: statusDetail,
                          status: payoutStatus,
                          reference: invoiceNumber,
                          sum: sum,
                          // date: date,
                      }
                    : {
                          status_detail: statusDetail,
                          status: payoutStatus,
                          reference: invoiceNumber,
                          publisher: publisherId,
                          date: date,
                          sum: sum,
                      }
            "
            :vuex-action-mutation="formAction"
            :button="isEdit ? 'Save' : 'Add payout'"
            id="payout"
            buttonPortalTo="modalFooter"
        >
            <InputContainer label="Status" name="status">
                <div class="base-input-container">
                    <LaBaseSelect
                        data-test="payout-status"
                        :items="statusArr"
                        v-model="payoutStatus"
                        :stretch="true"
                        :searchable="false"
                    >
                    </LaBaseSelect>
                </div>
            </InputContainer>

            <LaSimpleDatePicker
                data-test="payout-date"
                v-model="date"
                name="pickit"
                label="Date"
                placeholder="Date"
                :rules="rules.date"
                v-if="!isEdit"
            />

            <LaBaseInput
                data-test="payout-date"
                v-model="date"
                name="status-date"
                label="Status date"
                id="status-date"
                type="text"
                maxlength="80"
                :rules="rules.date"
                v-if="isEdit"
                :disabled="true"
            />

            <LaBaseInput
                data-test="payout-sum"
                v-model="sum"
                name="sum"
                :label="'Sum ' + currencySymbol"
                currencySymbol
                id="sum"
                type="number"
                :rules="rules.sum"
            />

            <LaBaseInput
                data-test="payout-status_detail"
                v-model="invoiceNumber"
                name="invoice-number"
                label="Invoice number"
                id="invoice-number"
                type="text"
                maxlength="12"
                :rules="rules.invoiceNumber"
            />

            <LaBaseInput
                data-test="payout-status-detail"
                v-model="statusDetail"
                name="status-detail"
                label="Status detail"
                id="status-detail"
                type="text"
                maxlength="80"
                :rules="rules.statusDetail"
            />
        </LaBaseForm>
    </div>
</template>

<script>
import LaBaseForm from "@/components/BaseForm";
import LaBaseInput from "@/components/inputs/BaseInput";
import LaBaseSelect from "@/components/inputs/BaseSelect";
import InputContainer from "@/components/inputs/InputContainer";
import { computed, ref } from "vue";
import { isRequired } from "@/helpers/rules";
import store from "@/store/store";
import { actionTypes, getterTypes as payoutGetterTypes } from "@/store/Payouts/types";
import { getterTypes as dialogGetterTypes } from "@/store/Dialog/types";
import { getCurrencySymbol, getExchangeRateFromCzk } from "@/helpers/data";
import LaSimpleDatePicker from "@/components/datepicker/SimpleDatePicker.vue";
import { formatDateTimeByLocaleTimezone } from "@/helpers/filters.js";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export default {
    name: "PayoutForm",
    props: {
        dateIsTimestamp: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        LaBaseForm,
        InputContainer,
        LaBaseInput,
        LaBaseSelect,
        LaSimpleDatePicker,
    },
    setup(props) {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));

        let isEdit = ref(false);
        let payoutStatus = ref("created");
        let statusDetail = ref("");
        let invoiceNumber = ref("");
        let sum = ref("");
        let date = ref("");
        let formAction = actionTypes.addPayout;
        const payout = computed(() => store.getters[dialogGetterTypes.getDialog].item);
        const publisherId = store.getters[payoutGetterTypes.getPublisherSelected];

        if (payout.value) {
            isEdit = true;
            statusDetail.value = payout.value.status_detail;
            invoiceNumber.value = payout.value.reference;
            payoutStatus.value = payout.value.status;
            date.value = props.dateIsTimestamp
                ? formatDateTimeByLocaleTimezone(payout.value.date * 1000)
                : payout.value.date;
            sum.value = Math.round(payout.value.sum / exchangeRate.value);
            formAction = actionTypes.editPayout;
        }
        const rules = {
            sum: [isRequired()],
            date: [isRequired()],
        };

        const statusArr = ["created", "retained", "pending", "paid"];

        return {
            isEdit,
            formAction,
            rules,
            statusDetail,
            sum,
            date,
            payoutStatus,
            invoiceNumber,
            statusArr,
            payout,
            publisherId,
            currencySymbol,
        };
    },
};
</script>

<style scoped></style>
