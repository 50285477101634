<template>
    <LaBaseTable data-test="fee-table" :headers="headers" :items="data" stripped data-cy="fee-table">
        <template #item-valid_from="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ formatDateTimeByLocaleTimezone(item.valid_from_isoformat) }}</span>
                <div class="second" v-if="$mq.max.mobile">
                    {{ formatDateTimeByLocaleTimezone(item.valid_from_isoformat) }}
                </div>
            </div>
        </template>

        <template #item-value="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ item.value / 100 }} %</span>
            </div>
        </template>

        <template #item-actions="{ item }">
            <LaActionIcons :item="item" item-name="fee" :show-delete-icon="publisherSelected !== 'all'">
            </LaActionIcons>
        </template>
    </LaBaseTable>
</template>

<script>
import LaBaseTable from "@/components/BaseTable";
import {
    formatMonthDate,
    formatDate,
    formatCell,
    timestampToDate,
    formatDateTimeByLocaleTimezone,
} from "@/helpers/filters.js";
import LaActionIcons from "@/components/button/ActionIcons";
import { computed } from "vue";
import store from "@/store/store";
import { getterTypes as feeGetterTypes } from "@/store/Fees/types";
export default {
    name: "LaFeeTable",
    components: {
        LaBaseTable,
        LaActionIcons,
    },
    props: {
        data: {
            required: true,
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: "valid_from",
                    value: "valid_from",
                    direction: -1,
                },
                {
                    text: "publisher",
                    value: "publisher",
                },

                {
                    text: "website",
                    value: "website",
                },
                {
                    text: "value",
                    value: "value",
                },
                {
                    text: "actions",
                    value: "actions",
                },
            ];
        },
    },
    setup() {
        const publisherSelected = computed(() => store.getters[feeGetterTypes.getPublisherSelected]);

        return {
            publisherSelected,
            formatMonthDate,
            formatCell,
            formatDate,
            timestampToDate,
            formatDateTimeByLocaleTimezone,
        };
    },
};
</script>

<style lang="scss" scoped>
.second {
    font-size: 0.8rem;
    opacity: 0.8;
}
</style>
