import { mutationTypes, getterTypes, actionTypes } from "./types";

export default {
    actions: {
        [actionTypes.setPagesAction]({ commit }, { pages }) {
            commit(mutationTypes.setPages, pages);
        },
        [actionTypes.setActualPage]({ commit }, { num }) {
            commit(mutationTypes.setActualPage, num);
        },
    },
    mutations: {
        [mutationTypes.setPages](state, data) {
            state.pages = data;
        },
        [mutationTypes.setActualPage](state, num) {
            state.actualPage = num;
        },
    },
    getters: {
        [getterTypes.getPages](state) {
            return state.pages;
        },
        [getterTypes.getActualPage](state) {
            return state.actualPage;
        },
    },
    state: {
        pages: null,
        actualPage: 1,
    },
};
