<template>
    <div v-if="selectedPublisherModel">
        <LaBaseSelect
            data-test="publishers-select"
            :items="publishers"
            v-model="selectedPublisherModel"
            itemValue="id"
            itemText="name"
            :stretch="false"
            @onSelected="publisherSelected"
            :searchable="true"
            v-if="publishers.length > 1"
        >
        </LaBaseSelect>
    </div>
</template>

<script>
import LaBaseSelect from "@/components/inputs/BaseSelect";
import { computed, watch } from "vue";
import store from "@/store/store";
import { mutationTypes } from "@/store/Publisher/types";
import { actionTypes, getterTypes } from "@/store/Publisher/types";
import { useRouter } from "vue-router";
import { getterTypes as getterTypesUser } from "@/store/LoggedUser/types";
import { default_currency } from "@/helpers/data";

export default {
    name: "LaPublisherPicker",
    components: {
        LaBaseSelect,
    },
    data() {
        return {
            paramPublisher: this.$route.params.publisher ? this.$route.params.publisher : null,
        };
    },
    mounted() {
        if (this.paramPublisher) {
            this.publisherSelected(this.paramPublisher);
        }
    },
    setup() {
        const publishers = computed(() => store.getters[getterTypes.getPublishers]);
        if (!publishers.value) {
            store.dispatch(actionTypes.getData);
        }

        const selectedPublisher = computed(() => store.getters[getterTypes.getSelectedPublisher]);
        if (!selectedPublisher.value) {
            store.dispatch(actionTypes.getSelectedPublisherFromLS);
        }

        const selectedPublisherModel = computed(() => {
            if (publishers.value) {
                const found =
                    publishers.value.find((publisher) => publisher.id === selectedPublisher.value) ||
                    publishers.value[0];
                store.commit(mutationTypes.setSelectedPublisher, found.id);
                store.dispatch(actionTypes.setSelectedPublisherToLS, found.id);

                const userRights = computed(() => store.getters[getterTypesUser.getUserRights]);
                const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);

                store.commit(
                    mutationTypes.setCurrencyBySelectedPublisher,
                    isSuperAdmin.value && found.currency ? found.currency : default_currency
                );
                return found;
            }
            return null;
        });

        const router = useRouter();
        const publisherSelected = (publisher) => {
            // publisher selected by selectbox
            if (publisher.id) {
                store.commit(mutationTypes.setSelectedPublisher, publisher.id);
                router.push({ params: { publisher: publisher.id } });
                // needs timeout to let propagate change in route
                setTimeout(() => {
                    // need to reload whole app on Publisher change
                    router.go();
                }, 1);
                // publisher selected by ID from route param
            } else {
                store.commit(mutationTypes.setSelectedPublisher, publisher);
                router.push({ params: { publisher: publisher } });
            }
        };

        watch(
            [selectedPublisher, router.currentRoute],
            () => {
                if (!router.currentRoute.params || !router.currentRoute.params.publisher) {
                    router.push({ params: { publisher: selectedPublisher.value } });
                }
            },
            { immediate: true }
        );
        return { publishers, selectedPublisherModel, publisherSelected };
    },
};
</script>
