<template>
    <div class="payment-method-container">
        <LaBaseForm
            v-if="companyDataIsCompleted"
            :form-data="{
                bank_name: bankName,
                swift,
                iban,
                account_number: accountNumber,
                isCz: isCz,
            }"
            :vuex-action-mutation="editPaymentMethodAction"
        >
            <LaBaseInput
                v-if="isCz"
                label="Account number"
                v-model="accountNumber"
                name="accountNumber"
                :rules="rules.accountNumber"
                :error="errors.account_number"
            />
            <LaBaseInput
                v-else
                label="Bank name"
                v-model="bankName"
                name="bankName"
                :rules="rules.bankName"
                :error="errors.bank_name"
            />
            <LaBaseInput label="IBAN" v-model="iban" name="iban" :rules="rules.iban" :error="errors.iban" />
            <LaBaseInput label="SWIFT" v-model="swift" name="swift" :rules="rules.swift" :error="errors.swift" />
        </LaBaseForm>
        <div class="company-card">
            <div v-if="companyDataIsCompleted">
                <p>{{ publisher.company_name }}</p>
                <p>{{ publisher.street1 }}</p>
                <p v-if="publisher.street2">{{ publisher.street2 }}</p>
                <p>{{ publisher.city }}</p>
                <p>{{ publisher.zip }}</p>
                <p>{{ publisher.country }}</p>
            </div>
            <router-link v-else :to="{ name: 'companyAccount' }">
                <div class="warn-message">No company data. Please fill company account form.</div>
            </router-link>
        </div>
    </div>
</template>
<script>
import { actionTypes, getterTypes } from "@/store/PaymentMethod/types";
import { computed, ref } from "vue";
import LaBaseForm from "@/components/BaseForm";
import LaBaseInput from "@/components/inputs/BaseInput";
import store from "@/store/store";
import { isRequired, validateBIC, validateIBAN } from "@/helpers/rules.js";
import { actionTypes as publisherActionTypes, getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export default {
    name: "LaPaymentMethod",
    components: {
        LaBaseForm,
        LaBaseInput,
    },
    async setup() {
        const errors = computed(() => store.getters[getterTypes.getPaymentMethodFormErrors] || {});
        const payment = computed(() => store.getters[getterTypes.getPaymentMethod]);
        const publisher = computed(() => store.getters[publisherGetterTypes.getPublisher]);
        if (!publisher.value) {
            await store.dispatch(publisherActionTypes.getData);
        }
        if (!payment.value) {
            await store.dispatch(actionTypes.getData);
        }
        const isCz = computed(() => publisher.value.country === "CZ");
        const editPaymentMethodAction = actionTypes.editData;
        const bankName = ref(payment.value.bank_name);
        const accountNumber = ref(payment.value.account_number);
        const swift = ref(payment.value.swift);
        const iban = ref(payment.value.iban);
        const companyDataIsCompleted = computed(
            () =>
                publisher.value.company_name &&
                publisher.value.street1 &&
                publisher.value.city &&
                publisher.value.zip &&
                publisher.value.country
        );

        const rules = computed(() => ({
            bankName: !isCz.value ? [isRequired()] : [],
            accountNumber: isCz.value ? [isRequired()] : [],
            iban: [validateIBAN(true)],
            swift: [validateBIC(true)],
        }));

        return {
            bankName,
            accountNumber,
            publisher,
            swift,
            iban,
            isCz,
            editPaymentMethodAction,
            payment,
            rules,
            companyDataIsCompleted,
            errors,
        };
    },
};
</script>

<style lang="scss" scoped>
.payment-method-container {
    display: flex;
}
.company-card {
    margin-left: 10rem;
}
.warn-message {
    color: $red;
    border-radius: $normal-radius;
    border: solid 1px $red;
    padding: 0.8rem;
}
</style>
