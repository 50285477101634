<template>
    <LaUserListTable
        data-test="users-table"
        data-cy="users-table"
        v-if="userList && userList.length"
        :data="userList"
        :loading="!userList"
        @sendInvitation="sendInvitation"
    />
</template>

<script>
import LaUserListTable from "@/components/account/UserListTable";
import store from "@/store/store";
import { actionTypes, getterTypes } from "@/store/Users/types";
import { computed } from "vue";
import { mutationTypes } from "../../store/Users/types";

export default {
    name: "LaUserList",
    components: {
        LaUserListTable,
    },
    setup() {
        const userList = computed(() => store.getters[getterTypes.getUsers]);
        store.dispatch(actionTypes.getData);

        const sendInvitation = (user) => {
            store.dispatch(actionTypes.inviteUser, { id: user.id, email: user.email }).then(() => {
                store.commit(mutationTypes.replaceUserInStore, {
                    user: user,
                    replaceData: { last_invitation: new Date() },
                });
            });
        };

        return { userList, sendInvitation };
    },
};
</script>

<style scoped></style>
