<template>
    <div v-if="isSuperAdmin">
        <LaPageHeader heading="Publishers">
            <template #controls>
                <LaBaseButton
                    v-if="!showAll"
                    buttonType="neutral"
                    text="Show all"
                    data-test="show-all"
                    id="show-all"
                    @click="showAllToggle()"
                />

                <LaBaseButton
                    v-if="showAll"
                    buttonType="neutral"
                    text="Show active"
                    data-test="show-all"
                    id="show-all"
                    @click="showAllToggle()"
                />

                <LaBaseButton
                    text="Add publisher"
                    icon="plus"
                    data-test="add-publisher"
                    id="add-publisher"
                    @click="addPublisher()"
                />
            </template>
        </LaPageHeader>

        <div class="columns">
            <div class="column">
                <PublisherCard />
            </div>
        </div>

        <LaDialog name="publisherForm">
            <PublisherForm></PublisherForm>
        </LaDialog>

        <LaDialog name="publisherDelete">
            <LaDeleteConfirmDialog item-name="publisher" item-property="name" />
        </LaDialog>
    </div>
</template>

<script>
import LaPageHeader from "@/components/PageHeader";
import LaDialog from "@/components/dialog/Dialog";
import LaBaseButton from "@/components/button/BaseButton";
import LaDeleteConfirmDialog from "@/components/dialog/DeleteConfirmDialog";
import PublisherForm from "@/components/dialog/PublisherForm";
import PublisherCard from "@/components/publishers/PublisherCard";
import store from "@/store/store";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";
import { computed } from "vue";
import { getterTypes } from "@/store/LoggedUser/types";
import { actionTypes as publisherActionTypes } from "@/store/Publisher/types";

export default {
    name: "PublishersView",
    components: {
        LaPageHeader,
        PublisherCard,
        PublisherForm,
        LaDialog,
        LaBaseButton,
        LaDeleteConfirmDialog,
    },
    data() {
        return {
            showAll: false,
        };
    },
    setup() {
        const userRights = computed(() => store.getters[getterTypes.getUserRights]);
        const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);

        const addPublisher = () => {
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: "publisherForm",
                dialogHeading: "Add publisher",
            });
        };

        return { addPublisher, isSuperAdmin };
    },
    methods: {
        showAllToggle() {
            this.showAll = !this.showAll;
            store.dispatch(publisherActionTypes.getData, this.showAll);
        },
    },
};
</script>

<style scoped></style>
