export const actionTypes = {
    getData: "[rewards] Get data",
    getDataById: "[rewards] Get data by id",
    getDataWithPayouts: "[rewards-payouts] Get data rewards payouts",
    getDataWithPayoutsSA: "[rewards-payouts-sa] Get data rewards payouts",
};

export const mutationTypes = {
    rewardsGetDataStart: "[rewards] rewards get data start",
    rewardsGetDataSuccess: "[rewards] rewards get data success",
    rewardsGetDataFailure: "[rewards] rewards get data failure",
    rewardsWithPayoutsGetDataStart: "[rewards-payouts] rewards payouts get data start",
    rewardsWithPayoutsGetDataSuccess: "[rewards-payouts] rewards payouts get data success",
    rewardsWithPayoutsGetDataFailure: "[rewards-payouts] rewards payouts get data failure",

    rewardsWithPayoutsGetDataSaStart: "[rewards-payouts-sa] rewards payouts get data start",
    rewardsWithPayoutsGetDataSaSuccess: "[rewards-payouts-sa] rewards payouts get data success",
    rewardsWithPayoutsGetDataSaFailure: "[rewards-payouts-sa] rewards payouts get data failure",
};

export const getterTypes = {
    getRewards: "[rewards] Get rewards",
    getRewardsWithPayouts: "[rewards-payouts] Get rewards payouts",
    getRewardsWithPayoutsSa: "[rewards-payouts-sa] Get rewards payouts",
};
