<template>
    <div v-if="isSuperAdmin">
        <LaPageHeader heading="Fees"></LaPageHeader>

        <div class="columns">
            <div class="column is-flex">
                <div v-if="publishersComputed">
                    <LaBaseSelect
                        data-test="publishers-select"
                        :items="publishersComputed"
                        v-model="selectedPublisher"
                        itemValue="id"
                        itemText="name"
                        :stretch="true"
                        :searchable="false"
                        :multiple="false"
                        @update:modelValue="handleInputPublisher(selectedPublisher.id)"
                    >
                    </LaBaseSelect>
                </div>

                <div v-if="websitesComputed.length > 0 && publisherSelectedComputed !== 'all'">
                    <LaBaseSelect
                        data-test="websites-select"
                        :items="websitesComputed"
                        v-model="selectedWebsite"
                        itemValue="id"
                        itemText="site_name"
                        :stretch="true"
                        :searchable="false"
                        :multiple="false"
                        @update:modelValue="handleInputWebsite(publisherSelectedComputed, selectedWebsite.id)"
                    >
                    </LaBaseSelect>
                </div>
            </div>

            <div class="column is-flex is-justify-content-right">
                <LaBaseButton
                    text="Add fee"
                    icon="plus"
                    data-test="add-fee"
                    id="add-fee"
                    @click="addFee()"
                    :disabled="websiteSelectedComputed === 'all'"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column" v-if="feesComputed">
                <FeeCard :fees="feesComputed" />
            </div>
        </div>

        <LaDialog name="feeForm">
            <FeeForm />
        </LaDialog>

        <LaDialog name="feeDelete">
            <LaDeleteConfirmDialog item-name="fee" item-property="website" item-property2="valid_from_isoformat" />
        </LaDialog>
    </div>
</template>

<script>
import LaPageHeader from "@/components/PageHeader";
import LaDialog from "@/components/dialog/Dialog";
import LaBaseButton from "@/components/button/BaseButton";
import LaBaseSelect from "@/components/inputs/BaseSelect";
import LaDeleteConfirmDialog from "@/components/dialog/DeleteConfirmDialog";
import FeeForm from "@/components/dialog/FeeForm";
import FeeCard from "@/components/fees/FeeCard";
import store from "@/store/store";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";
import { computed } from "vue";
import { getterTypes } from "@/store/LoggedUser/types";
import { actionTypes as publisherActionTypes, getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { actionTypes as websiteActionTypes, getterTypes as websiteGetterTypes } from "@/store/Websites/types";
import { actionTypes as feeActionTypes, getterTypes as feeGetterTypes } from "@/store/Fees/types";
import publisher from "../store/Publisher";

export default {
    name: "FeeView",
    computed: {
        publisher() {
            return publisher;
        },
    },
    components: {
        LaPageHeader,
        FeeCard,
        FeeForm,
        LaBaseSelect,
        LaDialog,
        LaBaseButton,
        LaDeleteConfirmDialog,
    },
    data() {
        return {
            selectedWebsite: "all",
            selectedPublisher: "all",
        };
    },
    setup() {
        const userRights = computed(() => store.getters[getterTypes.getUserRights]);
        const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);

        const publishersComputed = computed(() => store.getters[publisherGetterTypes.getPublishersForFeePage]);
        if (!publishersComputed.value) {
            store.dispatch(publisherActionTypes.getDataForFeePage);
        }

        const publisherSelectedComputed = computed(() => store.getters[feeGetterTypes.getPublisherSelected]);
        if (!publisherSelectedComputed.value) {
            store.dispatch(feeActionTypes.selectPublisher, "all");
        }

        const websiteSelectedComputed = computed(() => store.getters[feeGetterTypes.getWebsiteSelected]);
        if (!websiteSelectedComputed.value) {
            store.dispatch(feeActionTypes.selectWebsite, "all");
        }

        store.dispatch(websiteActionTypes.getDataByPublisher, { publisherId: publisherSelectedComputed.value });
        const websitesComputed = computed(() => store.getters[websiteGetterTypes.getWebsitesByPublisher]);

        const addFee = () => {
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: "feeForm",
                dialogHeading: "Add fee",
            });
        };

        const feesComputed = computed(() => store.getters[feeGetterTypes.getFees]);
        if (!feesComputed.value) {
            store.dispatch(feeActionTypes.getAll, {
                publisherId: "all",
                websiteId: "all",
            });
        }

        return {
            isSuperAdmin,
            feesComputed,
            publishersComputed,
            websitesComputed,
            publisherSelectedComputed,
            websiteSelectedComputed,
            addFee,
        };
    },
    methods: {
        handleInputPublisher(publisherId) {
            const websiteId = "all";
            this.selectedWebsite = websiteId;
            store.dispatch(feeActionTypes.selectWebsite, websiteId);
            store.dispatch(feeActionTypes.selectPublisher, publisherId);
            store.dispatch(websiteActionTypes.getDataByPublisher, { publisherId: publisherId });

            store.dispatch(feeActionTypes.getAll, {
                publisherId: publisherId,
                websiteId: websiteId,
                refreshPaginator: true,
            });
        },
        handleInputWebsite(publisherId, websiteId) {
            store.dispatch(feeActionTypes.getAll, {
                publisherId: publisherId,
                websiteId: websiteId,
                refreshPaginator: true,
            });
            store.dispatch(feeActionTypes.selectWebsite, websiteId);
        },
    },
};
</script>

<style scoped></style>
