import { MESSAGE_STATE } from "@/store/FlashMessages/state";
import router from "@/router";
import { mutationTypes as flashMessagesMutationTypes } from "@/store/FlashMessages/types";
import { actionTypes as mainActionTypes } from "@/store/Main/types";
import { actionTypes, mutationTypes } from "./types";
import axios from "@/plugins/axios";
import { getItem, setItem } from "@/store/persistantStorage";
import { showMessage } from "@/helpers/messages";

function getForgottenEmailMessage(email) {
    return {
        header: "A reset link was sent to your email " + email,
        description: "Please check your email (inbox or spam) and reset your password.",
        messageState: MESSAGE_STATE.SUCCESS,
    };
}

function getVerifiedInvalidMessage() {
    return {
        header: "Email verification",
        description:
            "Link for email verification is invalid or already used. Please reset your password at sign in page.",
        messageState: MESSAGE_STATE.WARN,
    };
}

export const actions = {
    [actionTypes.verify]({ commit }, { token }) {
        commit(mutationTypes.userEmailVerificationStart);
        return axios.get(`la/user/verify/${token}`).then(
            (data) => {
                if (data.data.ok) {
                    commit(mutationTypes.userEmailVerificationSuccess);
                    router.push({ name: "login" }).finally(() => {
                        commit(flashMessagesMutationTypes.addMessageToQueue, {
                            header: "Email verification",
                            description: "Email was successfully verified, please sign in.",
                            messageState: MESSAGE_STATE.INFO,
                        });
                    });
                } else {
                    commit(mutationTypes.userEmailVerificationFailure);
                    router.push({ name: "login" }).finally(() => {
                        commit(flashMessagesMutationTypes.addMessageToQueue, getVerifiedInvalidMessage());
                    });
                }
            },
            () => {
                commit(mutationTypes.userEmailVerificationFailure);
                router.push({ name: "login" }).finally(() => {
                    commit(flashMessagesMutationTypes.addMessageToQueue, getVerifiedInvalidMessage());
                });
            }
        );
    },
    [actionTypes.editPersonalInfo]({ commit, dispatch }, { id, name, email, avatar, timezone, vatNumber }) {
        commit(mutationTypes.editPersonalInfoStart);
        let formData = new FormData();
        if (avatar instanceof File) {
            formData.append("avatar", avatar);
        }
        formData.append("id", id);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("timezone", timezone);
        formData.append("VAT_number", vatNumber);
        return axios
            .put("la/my-personal-info", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(
                (data) => {
                    showMessage(data.data, "sav", "personal info");
                    if (data.data.ok) {
                        dispatch(actionTypes.setPersonalInfoToLS, data.data);
                        commit(mutationTypes.editPersonalInfoSuccess, data.data);
                    } else {
                        commit(mutationTypes.setPersonalInfoFormErrors, data.data.error);
                    }
                },
                () => {
                    commit(mutationTypes.editPersonalInfoFailure);
                }
            );
    },
    [actionTypes.login]({ commit, dispatch }, { email, password }) {
        commit(mutationTypes.loginUserStart);
        console.log("%cLogging in...", "color: green;");
        return axios({
            method: "post",
            url: "la/login",
            data: {
                email: email,
                password: password,
            },
        })
            .then(({ data }) => {
                if (data.ok) {
                    dispatch(actionTypes.setUserToLS, data.user);
                    const path = router.currentRoute.value.query.redirect || "/";
                    router.push({ path: path }).catch(() => {});
                    commit(mutationTypes.loginUserSuccess, data.user);
                } else {
                    commit(mutationTypes.setLoginFormErrors, data.error);
                }
            })
            .catch((error) => {
                if (error?.response?.data?.error) {
                    commit(mutationTypes.setLoginFormErrors, error.response.data.error);
                }
                commit(mutationTypes.loginUserFailure);
                if (error.response.status === 401) {
                    commit(flashMessagesMutationTypes.addMessageToQueue, {
                        header: error.response.data.title,
                        description: error.response.data.description,
                        messageState: MESSAGE_STATE.WARN,
                    });
                }
            });
    },
    [actionTypes.register]({ commit }, { email, password, wantsNewsletter, timezone }) {
        commit(mutationTypes.registerUserStart);
        console.log("%cSigning up...", "color: green;");
        return axios({
            method: "post",
            url: "la/register",
            data: {
                email: email,
                password: password,
                wantsNewsletter: wantsNewsletter,
                timezone: timezone,
            },
        }).then(
            (data) => {
                if (data.data.ok) {
                    commit(mutationTypes.registerSuccess);
                } else {
                    commit(mutationTypes.setRegisterFormErrors, data.data.error);
                }
            },
            (error) => {
                commit(mutationTypes.registerFailure);
                if (error.response.status === 401) {
                    commit(flashMessagesMutationTypes.addMessageToQueue, {
                        header: error.response.data.title,
                        description: error.response.data.description,
                        messageState: MESSAGE_STATE.WARN,
                    });
                }
            }
        );
    },
    [actionTypes.sendEmailForgottenPassword]({ commit }, { email }) {
        commit(mutationTypes.forgottenPasswordStart);
        return axios({
            method: "post",
            url: "/la/password_recovery/send_email",
            data: {
                email: email,
            },
        }).then(
            (res) => {
                if (res.data.ok) {
                    router.push({ name: "login" }).finally(() => {
                        commit(mutationTypes.forgottenPasswordSuccess);
                        commit(flashMessagesMutationTypes.addMessageToQueue, getForgottenEmailMessage(email));
                    });
                } else {
                    commit(mutationTypes.setForgottenPasswordFormErrors, res.data.error);
                }
            },
            () => {
                commit(mutationTypes.forgottenPasswordFailure);
                commit(mutationTypes.setForgottenPasswordFormErrors, {
                    email: "Something went wrong with send email. Please try again later.",
                });
            }
        );
    },
    [actionTypes.checkValidResetKey]({ commit }) {
        const resetKey = router.currentRoute.value.params.token;
        return axios({
            method: "get",
            url: `/password_recovery/reset/${resetKey}`,
        }).then((res) => {
            if (!res.data.ok) {
                setTimeout(() => {
                    router.push({ name: "login" });
                }, 3000);
                commit(flashMessagesMutationTypes.addMessageToQueue, {
                    header: "An invalid token",
                    description:
                        "It seems that token for reset password is out of date. Please generate new one by send forgotten password link to your email on login page",
                    messageState: MESSAGE_STATE.WARN,
                });
            }
        });
    },
    [actionTypes.resetPassword]({ commit }, { newPassword, confirmPassword }) {
        commit(mutationTypes.changePasswordStart);
        if (newPassword === confirmPassword) {
            const password = newPassword.trim();
            return axios({
                method: "post",
                url: "/password_recovery/reset",
                data: {
                    password: password,
                    reset_key: router.currentRoute.value.params.token,
                },
            }).then(
                (res) => {
                    if (res.data.ok) {
                        commit(mutationTypes.changePasswordSuccess);
                        router.push({ name: "login" }).finally(() => {
                            commit(flashMessagesMutationTypes.addMessageToQueue, {
                                header: "A password has been changed",
                                description: "Please login with your new password.",
                                messageState: MESSAGE_STATE.SUCCESS,
                            });
                        });
                    } else {
                        commit(mutationTypes.setChangePasswordFormErrors, res.data.error);
                    }
                },
                () => {
                    commit(mutationTypes.changePasswordFailure);
                    commit(flashMessagesMutationTypes.addMessageToQueue, {
                        header: "A password has not been changed",
                        description: "Something went wrong.",
                        messageState: MESSAGE_STATE.WARN,
                    });
                }
            );
        } else {
            commit(mutationTypes.setChangePasswordFormErrors, { confirm_password: "Passwords are not same." });
        }
    },
    [actionTypes.changePassword]({ commit }, { oldPassword, newPassword, confirmPassword }) {
        commit(mutationTypes.changePasswordStart);
        return axios({
            method: "post",
            url: "/user/change_password",
            data: {
                current_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
            },
        }).then((res) => {
            if (res.data.ok) {
                commit(mutationTypes.changePasswordSuccess);
                commit(flashMessagesMutationTypes.addMessageToQueue, {
                    header: "Password changed",
                    description: "A password has been successfully changed",
                    messageState: MESSAGE_STATE.SUCCESS,
                });
            } else {
                commit(mutationTypes.setChangePasswordFormErrors, res.data.error);
            }
        });
    },
    [actionTypes.logout]({ commit, dispatch }) {
        console.log("%cLogging out...", "color: red;");
        commit(mutationTypes.removeUser);
        dispatch(actionTypes.removeUserFromLS).finally(() => {
            router.push({ name: "login" });
            setTimeout(() => {
                dispatch(mainActionTypes.resetAllStates);
            }, 1);
        });
    },
    // eslint-disable-next-line no-empty-pattern
    [actionTypes.setUserToLS]({}, data) {
        const userInfo = getItem("loggedinuser");
        setItem("loggedinuser", {
            id: data.id,
            api_key: data.api_key,
            name: data.name,
            email: data.email,
            frontend_rights: data.frontend_rights,
            brs_only: data.brs_only,
            brs_sites: data.brs_sites,
            brs_upgrade: data.brs_upgrade,
            avatar_path: data.avatar_path,
            timezone: data.timezone,
            version: userInfo.version,
        });
    },
    [actionTypes.setUserVersionToLS]({ commit }, version) {
        const userInfo = getItem("loggedinuser");
        const user = {
            ...userInfo,
            version: version,
        };
        setItem("loggedinuser", user);
        commit(mutationTypes.setUser, user);
    },
    // eslint-disable-next-line no-empty-pattern
    [actionTypes.setPersonalInfoToLS]({}, data) {
        const userInfo = getItem("loggedinuser");
        setItem("loggedinuser", {
            ...userInfo,
            name: data.name,
            email: data.email,
            avatar_path: data.avatar_path,
            timezone: data.timezone,
        });
    },
    [actionTypes.getUserFromLS]({ commit }) {
        const userInfo = getItem("loggedinuser");
        if (userInfo) {
            commit(mutationTypes.setUser, userInfo);
        } else {
            commit(mutationTypes.removeUser);
        }
    },
    [actionTypes.removeUserFromLS]({ commit }) {
        localStorage.removeItem("loggedinuser");
        commit(mutationTypes.removeUser);
    },
};
