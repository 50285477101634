export const MESSAGE_STATE = Object.freeze({
    INFO: { value: 0, name: "Info", class: "info-message" },
    WARN: { value: 1, name: "Warn", class: "warn-message" },
    SUCCESS: { value: 2, name: "Success", class: "success-message" },
});

// export const DEFAULT_MESSAGE = {
//   id: 0,
//   header: null,
//   description: null,
//   messageState: MESSAGE_STATE.INFO
// };

export const DEFAULT_STATE = {
    messages: [],
};
