<template>
    <BaseCard>
        <template v-if="!yieldLoading">
            <div class="columns">
                <div class="column">
                    <RevenueImpressionsChart :earnings-data="yieldData" v-if="domains.length == 1" />

                    <WebsRevenueStackedBarChart
                        v-if="domains.length > 1 && yieldCsvData.length"
                        :earnings-csv-data="yieldCsvData"
                    />
                    <WebsImpressionsStackedBarChart
                        v-if="domains.length > 1 && yieldCsvData.length"
                        :earnings-csv-data="yieldCsvData"
                    />
                </div>
            </div>

            <div style="margin-top: 2rem">
                <LaYieldTable :data="yieldData" />
            </div>

            <LaDialog name="dailyDataForm">
                <LaDailyDataForm />
            </LaDialog>
        </template>
        <LaContentLoader v-else />
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard";
import LaContentLoader from "@/components/loaders/ContentLoader";
import LaYieldTable from "@/components/yield/YieldTable";
// import { getExchangeRateFromCzk } from "@/helpers/data";
// import { computed } from "vue";
// import store from "@/store/store";
// import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import RevenueImpressionsChart from "@/components/charts/RevenueImpressionsChart.vue";
import WebsRevenueStackedBarChart from "@/components/charts/WebsRevenueStackedBarChart.vue";
import WebsImpressionsStackedBarChart from "@/components/charts/WebsImpressionsStackedBarChart.vue";
import LaDailyDataForm from "@/components/dialog/DailyDataForm.vue";
import LaDialog from "@/components/dialog/Dialog.vue";

export default {
    name: "LaYieldDataCard",
    components: {
        BaseCard,
        LaContentLoader,
        LaYieldTable,
        RevenueImpressionsChart,
        WebsRevenueStackedBarChart,
        WebsImpressionsStackedBarChart,
        LaDialog,
        LaDailyDataForm,
    },
    props: {
        yieldLoading: {
            type: Boolean,
        },
        yieldMetadata: {
            type: Object,
            required: true,
        },
        yieldData: {
            type: Array,
            required: true,
        },
        yieldCsvData: {
            type: Array,
            required: true,
        },
        domains: {
            type: Array,
            required: true,
        },
    },
    computed: {
        // filename() {
        //     return `${this.domains.join("_")}_yield_%from>%to`;
        // },
        // revenue() {
        //     const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        //     const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        //     return this.yieldData.map(
        //         (item) => Math.round((item["publisher revenue"] / exchangeRate.value) * 100) / 100
        //     );
        // },
        // impression() {
        //     return this.yieldData.map((item) => item["impressions"]);
        // },
    },
};
</script>
