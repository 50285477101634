import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.paymentApprovalGetDataStart](state) {
            state.isLoading = true;
            state.data = null;
        },
        [mutationTypes.paymentApprovalGetDataSuccess](state, data) {
            state.isLoading = false;
            state.data = data;
            state.valuesSelected = [];
        },
        [mutationTypes.paymentApprovalGetDataFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.paymentApprovalAddSelectValue](state, id) {
            if (state.valuesSelected.includes(id)) {
                const index = state.valuesSelected.indexOf(id);
                state.valuesSelected.splice(index, 1);
            } else {
                state.valuesSelected.push(id);
            }
        },

        [mutationTypes.paymentApprovalGetValuesSelectedStart](state) {
            state.isLoading = true;
            state.dataChecked = null;
        },
        [mutationTypes.paymentApprovalGetValuesSelectedSuccess](state, data) {
            state.isLoading = false;
            state.dataChecked = data;
            state.valuesSelected = [];
        },
        [mutationTypes.paymentApprovalGetValuesSelectedFailure](state) {
            state.isLoading = false;
        },
    },
    getters: {
        [getterTypes.getPaymentApproval](state) {
            return state.data;
        },
        [getterTypes.getPaymentApprovalValuesSelected](state) {
            return state.valuesSelected;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
