// /* eslint-disable */

/**
 * Automatically imports all the modules and exports as a single module object
 *
 * Each module must be in its own directory with index.js as main file
 */
const requireModule = require.context(".", true, /index\.js/);
const modules = {};
requireModule.keys().forEach((filename) => {
    const moduleName = filename.replace(/(\.\/|\/index\.js)/g, "");
    const module = requireModule(filename).default || requireModule(filename);
    module.mutations[`[${moduleName}] Reset state`] = (state, defaultState) => {
        for (const [key, value] of Object.entries(defaultState.defaultState)) {
            state[key] = value;
        }
    };
    modules[moduleName] = {
        namespaced: false,
        ...module,
    };
});

export default modules;
