import { MESSAGE_STATE } from "@/store/FlashMessages/state";
import { mutationTypes as flashMessagesMutationTypes } from "@/store/FlashMessages/types";
import { actionTypes, mutationTypes } from "@/store/Publisher/types";
import axios from "@/plugins/axios";
import { actionTypes as countryActionTypes } from "@/store/Country/types";
import { actionTypes as publisherActionTypes } from "@/store/Publisher/types";
import { showMessage } from "@/helpers/messages";
import { getItem, setItem } from "@/store/persistantStorage";

function compare_publishers(a, b) {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
}

export const actions = {
    [actionTypes.getData]({ commit }, showAll = false) {
        commit(mutationTypes.publishersGetDataStart);
        return axios({
            method: "get",
            url: "la/publishers" + (showAll ? "?all=1" : ""),
        }).then(
            (data) => {
                const publishers = data.data;
                if (!publishers.length) {
                    commit(flashMessagesMutationTypes.addMessageToQueue, {
                        header: "Cannot get proper rights for your user.",
                        description: "Please contact our sales team for further information.",
                        messageState: MESSAGE_STATE.WARN,
                    });
                } else {
                    publishers.sort(compare_publishers);
                    commit(mutationTypes.publishersGetDataSuccess, publishers);
                }
            },
            () => {
                commit(mutationTypes.publishersGetDataFailure);
            }
        );
    },
    [actionTypes.editCompanyData]({ commit, dispatch }, payload) {
        commit(mutationTypes.companyEditDataStart);
        return axios({
            method: "put",
            url: "la/publisher",
            data: payload,
        }).then(
            (data) => {
                showMessage(data.data, "sav", "company account");
                if (data.data.ok) {
                    commit(mutationTypes.companyEditDataSuccess, data.data.publisher);
                    dispatch(countryActionTypes.getCountry, {
                        isPublisher: true,
                        code: data.data.publisher.country,
                    });
                } else {
                    commit(mutationTypes.companyEditDataErrors, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.companyEditDataFailure);
            }
        );
    },
    [actionTypes.getDataForFeePage]({ commit }, showAll = false) {
        commit(mutationTypes.publishersGetDataForFeePageStart);
        return axios({
            method: "get",
            url: "la/publishers" + (showAll ? "?all=1" : ""),
        }).then(
            (data) => {
                const publishers = data.data;
                if (!publishers.length) {
                    commit(flashMessagesMutationTypes.addMessageToQueue, {
                        header: "Cannot get proper rights for your user.",
                        description: "Please contact our sales team for further information.",
                        messageState: MESSAGE_STATE.WARN,
                    });
                } else {
                    publishers.sort(compare_publishers);
                    commit(mutationTypes.publishersGetDataForFeePageSuccess, publishers);
                }
            },
            () => {
                commit(mutationTypes.publishersGetDataForFeePageFailure);
            }
        );
    },
    [actionTypes.getDataForPayoutsManagementPage]({ commit }, showAll = false) {
        commit(mutationTypes.publishersGetDataForPayoutsManagementPageStart);
        return axios({
            method: "get",
            url: "la/publishers" + (showAll ? "?all=1" : ""),
        }).then(
            (data) => {
                const publishers = data.data;
                if (!publishers.length) {
                    commit(flashMessagesMutationTypes.addMessageToQueue, {
                        header: "Cannot get proper rights for your user.",
                        description: "Please contact our sales team for further information.",
                        messageState: MESSAGE_STATE.WARN,
                    });
                } else {
                    publishers.sort(compare_publishers);
                    commit(mutationTypes.publishersGetDataForPayoutsManagementPageSuccess, publishers);
                }
            },
            () => {
                commit(mutationTypes.publishersGetDataForPayoutsManagementPageFailure);
            }
        );
    },
    [actionTypes.createPublisher]({ commit, dispatch }, payload) {
        commit(mutationTypes.createPublisherStart);
        payload["performax_id"] = parseInt(payload["performax_id"]);

        return axios({
            method: "post",
            url: "rest/publisher",
            data: payload,
        }).then(
            (data) => {
                if (data.data.success) {
                    commit(mutationTypes.createPublisherSuccess, data.data);
                    commit(flashMessagesMutationTypes.addMessageToQueue, {
                        header: "Publisher: '" + data.data.publisher.name + "' was successfully created.",
                        messageState: MESSAGE_STATE.SUCCESS,
                    });
                    dispatch(publisherActionTypes.getData);
                } else {
                    commit(mutationTypes.createPublisherErrors, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.createPublisherFailure);
            }
        );
    },
    [actionTypes.deletePublisher]({ commit, dispatch }, payload) {
        commit(mutationTypes.deletePublisherStart);
        const id = payload.id;
        delete payload.id;

        return axios({
            method: "put",
            url: "rest/publisher/" + id,
            data: payload,
        }).then(
            (data) => {
                if (data.data.success) {
                    commit(mutationTypes.deletePublisherSuccess, data.data);
                    showMessage(data.data, "delet", "publisher");
                    dispatch(publisherActionTypes.getData);
                } else {
                    commit(mutationTypes.deletePublisherErrors, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.deletePublisherFailure);
            }
        );
    },
    [actionTypes.editPublisher]({ commit, dispatch }, payload) {
        commit(mutationTypes.companyEditDataStart);
        payload["performax_id"] = parseInt(payload["performax_id"]);

        return axios({
            method: "put",
            url: "rest/publisher/" + payload.id,
            data: payload,
        }).then(
            (data) => {
                showMessage(data.data, "sav", "publisher");
                if (data.data.success) {
                    commit(mutationTypes.companyEditDataSuccess, data.data.publisher);
                    dispatch(publisherActionTypes.getData);
                } else {
                    commit(mutationTypes.companyEditDataErrors, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.companyEditDataFailure);
            }
        );
    },
    [actionTypes.checkVatNumber]({ commit, dispatch }, payload) {
        commit(mutationTypes.checkVAtNumberStart);
        return axios({
            method: "put",
            url: "la/publisher/vat",
            data: payload,
        }).then(
            (data) => {
                showMessage(data.data, "sav", "VAT number");
                if (data.data.ok) {
                    commit(mutationTypes.checkVAtNumberSuccess, data.data.publisher);
                    dispatch(countryActionTypes.getCountry, {
                        isPublisher: true,
                        code: data.data.publisher.country,
                    });
                } else {
                    commit(mutationTypes.companyEditDataErrors, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.checkVAtNumberFailure);
            }
        );
    },
    [actionTypes.getSelectedPublisherFromLS]({ commit }) {
        const selectedPublisher = getItem("selectedPublisher");
        commit(mutationTypes.setSelectedPublisher, selectedPublisher);
    },
    [actionTypes.setSelectedPublisherToLS]({ commit }, publisher_id) {
        setItem("selectedPublisher", publisher_id);
        commit(mutationTypes.setSelectedPublisher, publisher_id);
    },
    [actionTypes.getCurrencyFromLS]({ commit }) {
        const currencyCode = getItem("selectedPublisherCurrency");
        commit(mutationTypes.setCurrencyBySelectedPublisher, currencyCode);
    },
    [actionTypes.setCurrencyToLS]({ commit }, currency_code) {
        setItem("selectedPublisherCurrency", currency_code);
        commit(mutationTypes.setCurrencyBySelectedPublisher, currency_code);
    },
};
