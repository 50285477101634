<template>
    <div class="table" :class="stripped ? 'stripped' : ''">
        <div class="table-header">
            <div
                class="table-cell"
                data-test="table-header"
                v-for="header in filteredHeaders"
                :key="header.value"
                :class="{
                    sortable: isSortable(header),
                    hasWidth: header.width,
                    isNumber: header.isNumber,
                    justifyContentStart: header.justifyContentStart,
                    justifyContentEnd: header.justifyContentEnd,
                    justifyContentCenter: header.justifyContentCenter,
                }"
                :style="{ width: header.width, flex: header.flex }"
                :data-cy="`filter-table-${header.value}`"
                @click="sortTableByHeader(header)"
            >
                <div class="is-flex is-flex-direction-column">
                    <slot :name="`header-${header.value}`" :item="header">
                        <span>
                            {{ header.text }}
                            <LaBaseIcon
                                v-if="isSortable(header)"
                                :name="
                                    header.direction === 1
                                        ? 'chevron-down'
                                        : header.direction === -1
                                        ? 'chevron-up'
                                        : ''
                                "
                            />
                        </span>
                    </slot>
                    <span class="table-header-second">
                        <slot :name="`header-${header.value}-second`" :item="header" />
                    </span>
                </div>
            </div>
        </div>
        <div class="table-loading" v-if="loading">
            <LaTableLoader />
        </div>

        <div class="table-no-data" data-test="table-no-data" v-else-if="!items.length">
            <slot name="no-data"> No data avaliable in table</slot>
        </div>

        <div class="table-body" v-for="item in actualItems" :key="item.id" v-else data-cy="base-table-row">
            <div
                class="table-cell"
                data-test="table-cell"
                v-for="header in filteredHeaders"
                :key="header.value"
                :data-cy="`table-cell-${header.value}`"
                :class="{
                    hasWidth: header.width,
                    isNumber: header.isNumber,
                    justifyContentStart: header.justifyContentStart,
                    justifyContentEnd: header.justifyContentEnd,
                    justifyContentCenter: header.justifyContentCenter,
                }"
                :style="{ width: header.width, flex: header.flex }"
            >
                <slot :name="`item-${header.value}`" :item="item">
                    <slot v-if="header.divider">
                        {{ formatCell(item[header.value] / header.divider, header.isDecimal, header.isDatetime)
                        }}{{ header.postfix }}
                    </slot>
                    <slot v-else>
                        {{ formatCell(item[header.value], header.isDecimal, header.isDateTime) }}{{ header.postfix }}
                    </slot>
                </slot>
            </div>
        </div>
        <div class="table-footer" v-if="showFooter">
            Page
            <input class="table-page" type="number" min="1" :max="pagesLength" v-model.lazy.number="actualPage" />
            of {{ pagesLength }}
            <button class="table-arrow" :disabled="actualPage <= 1" @click="changeActualPage(actualPage - 1)">
                <LaBaseIcon name="arrow-left" />
            </button>
            <button
                class="table-arrow"
                :disabled="pagesLength === actualPage"
                @click="changeActualPage(actualPage + 1)"
            >
                <LaBaseIcon name="arrow-right" />
            </button>
        </div>
    </div>
</template>

<script>
import LaBaseIcon from "@/components/BaseIcon";
import LaTableLoader from "@/components/loaders/TableLoader";
import { ref, computed } from "vue";
import { formatCell } from "@/helpers/filters";

export default {
    name: "BaseTable",
    components: {
        LaBaseIcon,
        LaTableLoader,
    },
    props: {
        headers: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        itemsPerPage: {
            type: Number,
            required: false,
            default: 25,
        },
        stripped: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    setup(props) {
        const filteredHeaders = computed(() => {
            return props.headers.filter((item) => !item.hide);
        });
        const actualPage = ref(1); // default page
        const pagesLength = computed(() => {
            return Math.ceil(props.items.length / props.itemsPerPage);
        });

        const showFooter = computed(() => {
            if (!props.items) return false;
            return props.itemsPerPage < props.items.length;
        });

        const sortByHeader = ref(props.headers[0]);
        const sortDirection = ref(props.headers[0].direction ? props.headers[0].direction : 1);

        const actualItems = computed(() => {
            return sortTable(props.items, sortDirection.value);
        });

        const changeActualPage = (val) => {
            actualPage.value = parseInt(val);
        };

        const isSortable = (header) => {
            return header.sortable === undefined || header.sortable;
        };

        const sortTableByHeader = (header) => {
            if (!isSortable(header)) {
                return;
            }
            header.direction = header.direction === 1 ? -1 : 1;
            sortDirection.value = header.direction;
            props.headers.forEach((h) => {
                if (header.value !== h.value) {
                    h.direction = null;
                }
            });
            sortByHeader.value = header;
        };

        const sortTable = (items, direction) => {
            const startIndex = (actualPage.value - 1) * props.itemsPerPage;
            const endIndex = startIndex + props.itemsPerPage;

            const filterItems = items.map((item) => item);

            const header = sortByHeader.value;
            if (header.sortable) {
                return filterItems;
            }
            if (header.isNumber) {
                return filterItems
                    .sort((a, b) => (a[header.value] - b[header.value]) * direction)
                    .filter((item, index) => index >= startIndex && index < endIndex);
            } else {
                return filterItems
                    .sort((a, b) => {
                        if (a[header.value] < b[header.value]) {
                            return -1 * direction;
                        } else if (a[header.value] > b[header.value]) {
                            return 1 * direction;
                        }
                        return 0;
                    })
                    .filter((item, index) => index >= startIndex && index < endIndex);
            }
        };

        return {
            formatCell,
            filteredHeaders,
            isSortable,
            actualPage,
            pagesLength,
            actualItems,
            showFooter,
            changeActualPage,
            sortTableByHeader,
        };
    },
};
</script>

<style lang="scss" scoped>
.table {
    color: $blue;

    &-header,
    &-body {
        padding: 0.1rem 0;
        display: flex;
        justify-content: space-between;
        border-radius: $normal-radius;
    }

    &-header {
        font-size: 0.8rem;
        background-color: $grey;
        text-transform: uppercase;
        align-items: baseline;
        letter-spacing: 0.92px;
        padding: 1rem 0;

        &-second {
            margin-top: 0.2rem;
            font-size: 0.7rem;
            opacity: 0.7;
        }
    }

    &-cell {
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        flex: 1;

        &.hasWidth {
            flex: none;
        }

        &.isNumber {
            justify-content: flex-end;
        }

        &.justifyContentStart {
            justify-content: flex-start;
        }

        &.justifyContentEnd {
            justify-content: flex-end;
        }

        &.justifyContentCenter {
            justify-content: center;
        }

        &.sortable {
            cursor: pointer;
        }

        @include --tablet-viewport {
            padding: 0 0.5rem;
            font-size: 0.9rem;
        }
        @include --mobile-viewport {
            font-size: 0.75rem;
        }
    }

    &-body:hover {
        background-color: $dark-white;

        .table-button {
            background-color: $white;
        }
    }

    &-footer {
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-grey;
    }

    &-arrow,
    &-page {
        width: 2.5rem;
        height: 2.5rem;
        color: $blue;
        border-radius: $normal-radius;
    }

    &-arrow {
        margin-left: 0.5rem;
    }

    &-page {
        margin: 0 0.3rem;
        border: 1px solid $grey;
        padding: 0.3rem;
        font-weight: 600;
    }

    &-no-data {
        padding: 2rem;
        display: flex;
        width: 100%;
        justify-content: center;
        border-radius: $normal-radius;
    }
}

.table-body {
    border-bottom: 1px solid darken($light-blue, 10%);
}

.table.stripped {
    .table-body:nth-child(odd) {
        background-color: $light-blue;
    }
}

body.dark {
    .table {
        font-family: "Open Sans";
        font-size: 1rem;
        color: $text;

        &-header,
        &-body {
            background: $card-background;
            border-bottom: 1px solid transparent;
        }

        &-header {
            margin-bottom: 0.5rem;
        }

        &-body:hover {
            background: $card-background-dark;

            .table-button {
                //background-color: $white;
                background-color: inherit;
            }
        }

        &-footer {
            color: $dark-text;
        }

        &-arrow,
        &-page {
            border: 1px solid transparent;
            background: $input-background;
            color: $dark-text;
        }

        &-arrow {
            &:hover {
                color: $lime;
            }
        }
    }

    .table-body {
        border-bottom: none;
    }

    .table.stripped {
        .table-body:nth-child(odd) {
            background: $card-background-light;

            &:hover {
                background: $card-background-dark;
            }
        }
    }
}
</style>
