<template>
    <div class="container-content container-indent container-flex">
        <LaBaseCard>
            <div class="container-legal">
                <h1 class="page-heading">Privacy Policy</h1>
                <p>
                    This Privacy Policy is issued by Let There Be Ads s.r.o. (“LTBA”) in accordance with Regulation (EU)
                    2016/679 on the protection of natural persons with regard to the processing of personal data
                    ("Regulation" or "GDPR") in order to ensure compliance with the information obligation of LTBA as
                    the controller under Art. 13 GDPR towards data subjects whose personal data are processed by LTBA in
                    accordance with this Policy and the GDPR Regulation.
                </p>

                <h2 class="page-heading">1. Data Controller</h2>
                <p>
                    Your personal data are processed by LTBA, with its registered office at Za Ženskými domovy 3379/1,
                    Smíchov, 150 00 Prague 5, Czech Republic, IČO (Company Identification Number): 05473438, registered
                    in the Commercial Register maintained by the Municipal Court in Prague, Section C, File 264241, as
                    the controller, for the purposes, in the manner, and on the basis of the legal reason and for the
                    time period set forth further in this Policy.
                </p>

                <h2 class="page-heading">2. Controller's Contact Details</h2>
                <p>
                    When exercising your rights stated further in this Policy, you can contact the controller in writing
                    at LTBA, Za Ženskými domovy 3379/1, Smíchov, 150 00 Prague 5, Czech Republic, or via e-mail at
                    hi@lettherebeads.io.
                </p>

                <h2 class="page-heading">3. Categories of Data Subjects</h2>
                <p>Data subjects, whose personal data are processed by LTBA, include but are not limited to:</p>

                <ul>
                    <li>Customers of LTBA and their employees</li>
                    <li>Employees and members of LTBA</li>
                    <li>Carriers</li>
                    <li>Suppliers</li>
                    <li>Sales representatives of LTBA</li>
                    <li>Other persons who are in a contractual relationship with LTBA</li>
                    <li>Persons, with whom LTBA is negotiating a contract or another legal relationship</li>
                    <li>Job candidates</li>
                </ul>

                <h2 class="page-heading">4. Categories of Personal Data</h2>
                <p>
                    Personal data means any information concerning a natural person identifiable by LTBA. In connection
                    with the below stated purposes, LTBA may process in particular the following categories of personal
                    data:
                </p>

                <ul>
                    <li>
                        Address and identification details that can be used to unambiguously and unmistakably identify
                        the data subject (such as first name, last name, title, personal birth number, date of birth,
                        permanent address, place of business, mailing address, billing address, IČ - Company
                        Identification Number, DIČ - Tax Identification Number, bank details, identification details of
                        the customer’s representative or a contact person nominated by the customer, contract and
                        signature), information allowing contact with the data subject (contact details such as contact
                        address, phone number, fax number, e-mail address and the like), and other data needed to
                        perform a contact
                    </li>
                    <li>
                        Data provided beyond the relevant laws processed with a consent granted by the data subject
                        (processing of photos, use of personal data for human resource development purposes, signature
                        specimens, copies of identity cards for the purposes of identifying a party to a trade,
                        guarantor declarations containing personal data)
                    </li>
                </ul>

                <h2 class="page-heading">5. Purposes, Legal Grounds and Duration of Processing of Personal Data</h2>
                <p>
                    The extent of the processed data depends on the purpose of processing. For some purposes, data may
                    be processed directly on the basis of a contract, a legitimate interest of LTBA or law (without a
                    consent), and for any other purposes only with consent.
                </p>

                <h4>
                    Processing on grounds of performing a contract, compliance with legal obligations and due to
                    legitimate interests of LTBA
                </h4>
                <p>
                    The processing of personal data needed to perform a contract, to comply with legal obligations of
                    LTBA and to protect the legitimate interests of LTBA does not require consent of the relevant data
                    subjects; however, the processing of personal data for the purposes of the legitimate interests of
                    LTBA can be objected to. Processing on grounds of performing a contract and compliance with legal
                    obligations cannot be refused.
                </p>
                <p>This includes particularly the following specific purposes:</p>

                <ul>
                    <li>
                        Compliance with labour and wage administration requirements (compliance with legal obligations)
                    </li>
                    <li>Bookkeeping (compliance with legal obligations)</li>
                    <li>Compliance with legal tax obligations (compliance with legal obligations)</li>
                    <li>Negotiating a contract (legitimate interest of LTBA)</li>
                    <li>
                        Provision of services, sale of goods, provision of warranty services, improving of services
                        (performing a contract, legitimate interest of LTBA)
                    </li>
                    <li>Compliance proceedings (compliance with legal obligations)</li>
                    <li>Invoicing (performing a contract)</li>
                    <li>
                        Collection of debts owed by a customer and other disputes with customers (legitimate interest of
                        LTBA)
                    </li>
                </ul>

                <p>
                    Personal data used for such specific purposes are processed to the extent necessary to fulfil such
                    purposes and for the time period necessary to achieve them, or for a period set out in legislation,
                    a contract or the shredding regulations of LTBA. After that, the personal data are deleted or
                    anonymized. The basic time periods for the processing of personal data are provided below (all the
                    time periods may be extended by the statutory limitation period, particularly where there is a risk
                    of litigation).
                </p>

                <p>
                    In terms of customers, LTBA is entitled to, in the event that the customers have met all their
                    obligations towards the company, process particularly in its client database their basic personal,
                    identification and contact data, details of the services and details of their communication with
                    LTBA for a period of 4 years from the date of termination of the last contract with LTBA.
                </p>

                <p>
                    In the event that a product is purchased from LTBA, or a service is provided by LTBA, LTBA is
                    entitled to process personal data, identification and contact data, details of the services and
                    details of their communication between the customer and LTBA for a period of 4 years from the date
                    of expiry of the product or service warranty or termination of the respective contract (depending
                    which occurs later).
                </p>

                <p>
                    In the event that LTBA and its potential customer are holding contract negotiations that do not
                    result in the conclusion of a contract, LTBA is entitled to process the personal data provided for a
                    period of 12 months from the negotiations.
                </p>

                <p>
                    Invoices issued by LTBA are archived for a period of 10 years from the date of issue in accordance
                    with Section 35 of Act No. 235/2004 Sb., on Value Added Tax. In order to be able to substantiate a
                    legal ground for issuing invoices, customer contracts are also archived for a period of 10 years
                    from the date of termination.
                </p>

                <p>
                    Personal data from employment relationships or bookkeeping are processed for the time period
                    prescribed by the law.
                </p>

                <h4>LTBA customer data processing for marketing purposes with consent</h4>
                <p>
                    In terms of customers, LTBA processes personal data for its own marketing purposes only with the
                    customer's consent.
                </p>

                <p>
                    With consent to the processing for marketing purposes, LTBA processes customer personal data
                    primarily to prepare an offer of LTBA products and services and in connection with contacting the
                    customer by phone, in writing or electronically using the customer's contact details.
                </p>

                <p>
                    Granting consent for marketing purposes is voluntary and the customer may revoke it at any time. The
                    consent remains valid during the use of the LTBA products and services and for 4 years thereafter or
                    until the customer revokes it. If the customer revokes its consent for marketing purposes, it will
                    not affect the processing of the customer's personal data by LTBA for other purposes and on the
                    basis of other legal titles in accordance with this Policy.
                </p>

                <h4>
                    Processing of data of data subjects who have granted their consent to receiving marketing
                    communication electronically
                </h4>
                <p>
                    For subjects, who have granted consent to receiving marketing connection electronically, LTBA
                    processes - with their consent for the time period specified in the consent - contact details
                    provided by the subject for the purposes of marketing communication regarding offers of LTBA
                    products and services.
                </p>
                <p>
                    It is always apparent from business communication sent by LTBA that such communication is business
                    communication as defined in applicable laws and that LTBA is the sender. LTBA sends business
                    communication using the customers' contact details either on the basis of a legitimate interest of
                    LTBA only until the customer objects to the processing of personal data or on the basis of an
                    express consent to the processing of personal data for business purposes or on the basis of a
                    consent to receiving marketing communication electronically. All business communication sent must
                    also provide contact details for unsubscribing from receiving such communication.
                </p>

                <h4>Processing of cookies on websites operated by LTBA</h4>
                <p>
                    In the event that the data subject has cookies enabled in their browser and visits
                    www.lettherebeads.io, LTBA registers the visitor's IP address, how much time the visitor spent on
                    the website and from which website the visitor came. LTBA considers the use of cookies to measure
                    website traffic and adjust the display of the website to be its legitimate interest of a controller
                    because it allows LTBA to offer better services to its customers and visitors. The website of LTBA
                    can also be browsed in a mode that does not allow any collection of personal data. The processing of
                    data from cookies can be disabled in the browser settings. The subject may disable the use of
                    cookies on his/her device or modify the settings for www.lettherebeads.io
                </p>
                <p>
                    The cookie policy is available here:
                    <router-link :to="{ name: 'cookiePolicy' }" target="_blank">Cookie Policy</router-link>
                </p>

                <h2 class="page-heading">
                    6. Categories of Recipients to Whom the Personal Data Have Been or Will Be Disclosed Including
                    Recipients in Third Countries or International Organisations
                </h2>
                <p>
                    To comply with its legal obligations, LTBA transfers personal data to administrative authorities and
                    offices specified in applicable legislation. In addition, in complying with its obligations and
                    duties arising from contracts, LTBA uses expert and specialised services of other entities. In the
                    event that these suppliers process personal data transferred by LTBA, they have the status of data
                    processors and process the personal data only as instructed by LTBA and are not allowed to use them
                    in any other manner. This includes but is not limited to the collection of outstanding debt,
                    activities of experts, accountants (company, independent), translators, attorneys, auditors, IT
                    system administration, online advertising and agency. LTBA selects every supplier carefully and
                    enters into a personal data processing agreement with every supplier, imposing strict obligations on
                    the processor with regard to the protection and security of personal data.
                </p>

                <p>
                    The processors are companies seated in the Czech Republic, in an EU Member State or in so-called
                    secured countries. Any transfer and processing of personal data in countries outside the European
                    Union always complies with applicable legislation, in particular on the basis of so-called standard
                    contractual clauses pursuant to Commission Implementing Decision (EU) No 2021/914 of 9 June 2021 on
                    standard contractual clauses for the transfer of personal data to third countries under Regulation
                    (EU) 2016/679 of the European Parliament and of the Council.
                </p>

                <p>The categories of recipients of the personal data are:</p>
                <ul>
                    <li>Financial institutions</li>
                    <li>Public institutions</li>
                    <li>Processors</li>
                    <li>Contractual partners conducting selection procedures for job vacancies</li>
                    <li>
                        State and other authorities when complying with legal obligations prescribed by applicable legal
                        regulations
                    </li>
                    <li>
                        Other recipients – mainly depending on the consent granted by the data subject (such as transfer
                        of personal data abroad)
                    </li>
                </ul>

                <table class="two-columns">
                    <tr>
                        <th>Data processors</th>
                        <th>Activity concerned</th>
                    </tr>
                    <tr>
                        <td>Performax.cz, s.r.o.</td>
                        <td>
                            Storing and accessing information on device to ensure basic technical functionality of the
                            website and logging in.
                        </td>
                    </tr>
                    <tr>
                        <td>Google Analytics</td>
                        <td>Used to generate anonymous statistics about the use of the website.</td>
                    </tr>
                    <tr>
                        <td>InMobi Pte Ltd</td>
                        <td>https://www.inmobi.com/privacy-policy</td>
                    </tr>
                </table>

                <h2 class="page-heading">7. Personal Data Updates</h2>
                <p>
                    LTBA updates the personal data processed using information from data subjects, third parties, and,
                    where appropriate, using public resources (internet, public registers).
                </p>

                <h2 class="page-heading">8. Method of Personal Data Processing</h2>
                <p>
                    The personal data are processed by LTBA. The processing takes place in its registered office or
                    branch (establishment) by individual authorised employees of LTBA, or, a processor, as the case may
                    be. The processing is carried out using computers or manually when it comes to personal data in
                    documentary form in order to comply with all personal data administration and processing security
                    principles. For this reason, LTBA took technical and organizational measures to ensure security of
                    the personal data, including but not limited to measures to prevent unauthorised or accidental
                    access to, changes, destruction or loss, unauthorised transmission, any unauthorised processing or
                    another misuse of personal data. All entities, to whom the personal data may be disclosed, respect
                    the right of data subjects to privacy and are required to proceed in accordance with applicable laws
                    regarding the protection of personal data.
                </p>

                <h2 class="page-heading">
                    9. Information about the Rights of Data Subjects in Connection with the Processing of Personal Data
                </h2>
                <p>
                    In the event that a data subject is an identifiable natural person for LTBA and proves his/her
                    identity, he/she has the following rights. The rights stated below may be exercised only in relation
                    to personal data where there is no doubt that they belong to the requesting data subject.
                </p>

                <h3>1. Right to information (Art. 13 and 14 GDPR)</h3>
                <p>
                    Under Art. 13 and 14 GDPR, the data subject has the right to information, including but not limited
                    to identification of the data controller, legal grounds for data processing, the scope and purpose
                    of processing, the method of personal data processing, and information regarding to whom the
                    personal data may be disclosed. LTBA meets its information obligation towards data subjects through
                    these principles posted at
                    <router-link :to="{ name: 'privacyPolicy' }" target="_blank">Privacy Policy</router-link>
                    , as well as in contracts with customers and contractual partners, and in responses to requests of
                    data subjects.
                </p>

                <h3>2. Right of access to personal data (Art. 15 GDPR)</h3>
                <p>
                    Under Art. 15 GDPR, the data subject has the right of access to personal data, which includes the
                    following rights:
                </p>
                <ul>
                    <li>To obtain a confirmation whether personal data are processed;</li>
                    <li>To obtain information about the purposes of processing;</li>
                    <li>Categories of personal data concerned;</li>
                    <li>Recipients to whom the personal data have been or will be disclosed;</li>
                    <li>Planned duration of processing;</li>
                    <li>
                        Existence of the right to request from the controller rectification or erasure of personal data
                        or restriction of processing of personal data concerning the data subject or to object to such
                        processing;
                        <ul>
                            <li>To lodge a complaint with a supervisory authority;</li>
                            <li>
                                To obtain any available information as to the source of personal data if they are not
                                collected from the data subject;
                            </li>
                            <li>
                                To obtain information about the fact that automated decision making is used, including
                                profiling, about suitable guarantees when transferring data outside the EU;
                            </li>
                            <li>
                                To obtain a copy of personal data provided that the rights and freedoms of other persons
                                are not adversely affected.
                            </li>
                        </ul>
                    </li>
                </ul>

                <p>
                    In case of repetitive requests, LTBA will be entitled to charge an adequate fee for a copy of
                    personal data.
                </p>

                <h3>3. Right of rectification of inaccurate data (Art. 16 GDPR)</h3>
                <p>
                    Under Art. 16 GDPR, the data subject has the right of rectification of inaccurate personal data and
                    completion of incomplete personal data processed by LTBA about the data subject. LTBA will rectify
                    the data without undue delay, however, always depending on the relevant technical possibilities.
                </p>

                <h3>4. Right of erasure (Art. 17 GDPR)</h3>
                <p>
                    Under Art. 17 GDPR, the data subject has the right of erasure of personal data regarding the data
                    subject if LTBA fails to prove its legitimate reasons for the processing of such personal data. LTBA
                    hasimplemented mechanisms for ensuring automatic anonymization or erasure of personal data in the
                    event that the data are no longer needed for the purposes for which they were processed. If the data
                    subject believes nonetheless that his/her personal data were not erased, he/she may request erasure
                    provided that the data subject substantiates the legitimacy of such a request.
                </p>

                <p>In particular, personal data will be deleted if:</p>
                <ul>
                    <li>
                        the data controller no longer needs the personal data for the purpose for which the data were
                        collected or for which the data were processed in another way;
                    </li>
                    <li>
                        the processing is based on consent given by the data subject, but the data subject has withdrawn
                        his or her consent and there is no other legal basis for the processing;
                    </li>
                    <li>
                        the processing is based on a legitimate interest of the data controller or of a third party, but
                        the data subject has objected to the processing and – except for the objection to the processing
                        for the purpose of direct marketing – there is no overriding legitimate reason for the
                        processing;
                    </li>
                    <li>the data controller has processed the personal data unlawfully;</li>
                    <li>the erasure of the personal data is necessary to comply with a legal obligation; or</li>
                    <li>
                        the personal data were collected in connection with the offer of information society services.
                    </li>
                </ul>

                <h3>5. Right of limitation on processing (Art. 18 GDPR)</h3>
                <p>
                    Under Art. 18 GDPR, until his/her case is handled, the data subject has the right to request a
                    limitation on processing if the data subject claims inaccuracy of the personal data, the reasons for
                    their processing or lodges an objection to their processing or objects to the unlawfulness of the
                    processing or the controller no longer needs the personal data for the purposes of the processing
                    but the data subject requires them for the establishment, exercise or defence of legal claims.
                </p>

                <h3>6. Right to be notified of rectifications, erasure or limitation on processing (Art. 19 GDPR)</h3>
                <p>
                    Under Art. 19 GDPR, the data subject has the right to be notified by LTBA of a rectification,
                    erasure or limitation on processing of personal data.
                </p>

                <h3>7. Right to portability of personal data (Art. 20 GDPR)</h3>
                <p>
                    Under Art. 20 GDPR, the data subject has the right to request from LTBA personal data regarding the
                    data subject and which were provided by the data subject to LTBA in connection with a contract or on
                    the basis of the consent and that are processed by an automated system in a structured, commonly
                    used and machine readable format, and the right to request that such data be transferred to another
                    controller if the person acting on behalf of the relevant controller is properly identified and can
                    be verified.
                </p>
                <p>
                    In the event that this right cannot be exercised due to an adverse effect on the rights and freedoms
                    of third parties, the request cannot be granted.
                </p>

                <h3>8. Right to lodge an objection to the processing of personal data (Art. 21 GDPR)</h3>
                <p>
                    Under Art. 21 GDPR, the data subject has the right to object to the processing of his/her personal
                    data due to a legitimate interest of LTBA
                </p>

                <p>
                    In the event that LTBA fails to prove that there is a considerable legitimate reason for such
                    processing that outweighs the interests or rights and freedoms of the data subject, LTBA will stop
                    such processing according to the objection without undue delay.
                </p>

                <p>
                    Where personal data are processed for direct marketing purposes, the data subject shall have the
                    right to object at any time to processing of personal data concerning him or her for such marketing,
                    which includes profiling insofar as it relates to such direct marketing. If the data subject objects
                    to theprocessing for direct marketing purposes, the personal data will no longer be processed for
                    these purposes.
                </p>

                <h3>9. Right to revoke consent to the processing of personal data</h3>
                <p>
                    Consent to the processing of personal data can be revoked at any time after it comes into effect.
                    Such revocation requires an express, comprehensible and specific manifestation of will.
                </p>

                <p>
                    Consent to marketing communication granted for a specific electronic contact may be revoked at any
                    time in the manner specified in such marketing communication.
                </p>

                <p>
                    The revocation of the consent shall not affect the lawfulness of processing based on consent given
                    before the revocation.
                </p>

                <h3>10. Automated individual decision-making, including profiling (Art. 22 GDPR)</h3>
                <p>
                    Under Art. 22 GDPR, the data subject has the right not to be subject to a decision based solely on
                    automated processing, including profiling, which produces legal effects concerning him or her or
                    similarly significantly affects him or her. LTBA declares that it does not utilise automated
                    decision- making.
                </p>

                <h3>11. Right to contact the Office for Personal Data Protection (Art. 77 GDPR)</h3>
                <p>
                    Under Art. 77 GDPR, the data subject has the right to file a complaint to the Office for Personal
                    Data Protection (www.uoou.cz) if the data subject believes that the processing of his/her personal
                    data violates his/her rights or any provision of the GDPR.
                </p>
            </div>
        </LaBaseCard>
    </div>
</template>

<script>
import LaBaseCard from "@/components/BaseCard";

export default {
    name: "PrivacyPolicy",
    components: {
        LaBaseCard,
    },
};
</script>

<style scoped>
.container-legal {
    ul {
        margin-left: 2.5rem;
    }

    table {
        display: block;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-wrap: initial;
    }
}
</style>
