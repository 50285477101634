export const mutationTypes = {
    setUser: "[logged user] Set user ",
    removeUser: "[logged user] Remove user",
    loginUserStart: "[logged user] Login user start",
    loginUserSuccess: "[logged user] Login user success",
    loginUserFailure: "[logged user] Login user failure",
    editPersonalInfoStart: "[logged user] Edit personal info start",
    editPersonalInfoSuccess: "[logged user] Edit personal info success",
    editPersonalInfoFailure: "[logged user] Edit personal info failure",
    registerUserStart: "[logged user] Register user start",
    registerSuccess: "[logged user] Register user success",
    registerFailure: "[logged user] Register user failure",
    userEmailVerificationStart: "[logged user] User email verification start",
    userEmailVerificationSuccess: "[logged user] User email verification success",
    userEmailVerificationFailure: "[logged user] User email verification failure",
    changePasswordStart: "[logged user] Change password start",
    changePasswordSuccess: "[logged user] Change password success",
    changePasswordFailure: "[logged user] Change password failure",
    forgottenPasswordStart: "[logged user] Forgotten password start",
    forgottenPasswordSuccess: "[logged user] Forgotten password success",
    forgottenPasswordFailure: "[logged user] Forgotten password failure",
    setChangePasswordFormErrors: "[logged user] Set change password form errors",
    setLoginFormErrors: "[logged user] Set login form errors",
    setRegisterFormErrors: "[logged user] Set register form errors",
    setForgottenPasswordFormErrors: "[logged user] Set forgotten password form errors",
    setPersonalInfoFormErrors: "[logged user] Set personal info form errors",
};

export const getterTypes = {
    isAuthenticated: "[logged user] Is authenticated",
    isAllowed: "[logged user] Is allowed",
    getUserRights: "[logged user] Get user rights",
    isMainUser: "[logged user] Is main user",
    getPersonalInfo: "[logged user] Get personal info",
    getUserId: "[logged user] Get user id",
    getAvatar: "[logged user] Get avatar",
    getUser: "[logged user] Get user",
    getTimezone: "[logged user] Get timezone",
    getChangePasswordFormErrors: "[logged user] Get change password form errors",
    getLoginFormErrors: "[logged user] Get login form errors",
    getRegisterFormErrors: "[logged user] Get register form errors",
    getForgottenPasswordErrors: "[logged user] Get forgotten password errors",
    getPersonalInfoFormErrors: "[logged user] Get personal info errors",
    isRegistrationComplete: "[logged user] Is registration completed",
};

export const actionTypes = {
    login: "[logged user] Login",
    register: "[logged user] Register",
    getForgottenEmailMessage: "[logged user] Get forgotten email message",
    sendEmailForgottenPassword: "[logged user] Send email forgotten password",
    checkValidResetKey: "[logged user] Check valid reset key",
    resetPassword: "[logged user] Reset password",
    changePassword: "[logged user] Change password",
    logout: "[logged user] Logout",
    getUserFromLS: "[logged user] Get user from local storage",
    setUserToLS: "[logged user] Set user to local storage",
    setUserVersionToLS: "[logged user] Set user version to local storage",
    setPersonalInfoToLS: "[logged user] Set personal info to local storage",
    removeUserFromLS: "[logged user] Remove user from local storage",
    editPersonalInfo: "[logged user] Edit personal info",
    verify: "[logged user] Verify email after registration",
};
