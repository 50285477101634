<template>
    <LaBaseButton text="Add website" icon="plus" data-test="add-website" id="add-website" @click="addWebsite()" />
</template>

<script>
import LaBaseButton from "@/components/button/BaseButton";
import store from "@/store/store";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";

export default {
    name: "LaAddWebsite",
    components: {
        LaBaseButton,
    },
    setup() {
        const addWebsite = () => {
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: "websiteForm",
                dialogHeading: "Add website",
            });
        };
        return { addWebsite };
    },
};
</script>

<style scoped></style>
