import { formatCell } from "@/helpers/filters.js";
import { numberFormatImpressions, numberFormatRevenue } from "@/helpers/data";

export default class ExtendedLabel {
    constructor(items) {
        Object.entries(items).map((item) => (this[item[0]] = item[1]));
    }
}

ExtendedLabel.prototype.toString = function () {
    return this.label;
};

const formatRow = (data, color) => {
    const [title, value] = data.split(":");
    const dot = `<span style="background-color:${color};width: 10px; height: 10px;display:inline-block;border-radius:90%;margin-right:10px"></span>`;
    const htmlTitle = `<span>${title}</span>`;
    const htmlValue = `${formatCell(value, false)} `;
    return `<div class="graph-tooltip"><div class="graph-tooltip__point">${dot}</div><div class="graph-tooltip__title">${htmlTitle}</div> <div class="graph-tooltip__count">${htmlValue}</div> </div>`;
};

export const ExtendedTooltip = function (tooltipModel) {
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = "chartjs-tooltip";
        tooltipEl.innerHTML = "<table></table>";
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add("no-transform");
    }

    // Set Text
    if (tooltipModel.body) {
        const colors = tooltipModel.labelColors.map((item) => item.borderColor);
        const dataPoints = tooltipModel.dataPoints;

        let innerHtml = `<thead><tr><th style="font-family:"Open Sans - Semi Bold";font-size: 13px;"></th><tr></thead><tbody>`;

        innerHtml += "<tr><td>";
        innerHtml += `<span class="graph-tooltip__header" ">${tooltipModel.dataPoints[0].label}</span>`;
        innerHtml += "</td></tr>";

        dataPoints.forEach((item, index) => {
            const rawRow = tooltipModel.body[index].lines[0];
            innerHtml += "<tr><td>";
            innerHtml += formatRow(rawRow, colors[index]);
            innerHtml += "</td></tr>";
        });

        innerHtml += "</tbody>";

        const tableRoot = tooltipEl.querySelector("table");
        tableRoot.innerHTML = innerHtml;
    }

    // `this` will be the overall tooltip
    const position = this._chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.color = "white";
    tooltipEl.style.cursor = "pointer";
    tooltipEl.style.padding = "20px";
    tooltipEl.style.opacity = 0.9;
    //tooltipEl.style.backgroundColor = "#24355aE6"; #121409, #1f2117
    tooltipEl.style.background = "linear-gradient(159deg, #3f461f 3.01%, #454933 103.3%)";
    tooltipEl.style.borderRadius = "4px";
    tooltipEl.style.boxShadow = "5px 5px 16px 2px rgba(0,0,0,0.13)";
    //tooltipEl.style.borderRadius = "4px";
    tooltipEl.style.zIndex = 999;
    tooltipEl.style.position = "absolute";
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 20 + "px";
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
    tooltipEl.style.pointerEvents = "none";
};

/* Echart */

export const formatImpressions = (params) => {
    return numberFormatImpressions(params.value);
};

export const formatRevenue = (params, currencySymbol) => {
    return numberFormatRevenue(params.value, currencySymbol);
};
