import axios from "@/plugins/axios";
import { actionTypes as websiteActionTypes, actionTypes, mutationTypes } from "./types";
import { showMessage } from "@/helpers/messages.js";

export const actions = {
    async [actionTypes.getData]({ commit }) {
        commit(mutationTypes.websitesGetDataStart);
        return axios({
            method: "get",
            url: "la/list-website",
        }).then(
            (data) => {
                commit(mutationTypes.websitesGetDataSuccess, data.data);
                return data.data;
            },
            () => {
                commit(mutationTypes.websitesGetDataFailure);
            }
        );
    },
    async [actionTypes.getDataByPublisher]({ commit }, { publisherId }) {
        commit(mutationTypes.websitesGetDataByPublisherStart);

        let params = new URLSearchParams("");
        params.append("is_lite", "1");
        if (publisherId && publisherId !== "all") {
            params.append("publisher", publisherId);
        }
        const url = "/la/website?" + params.toString();

        return axios({
            method: "get",
            url: url,
        }).then(
            (data) => {
                commit(mutationTypes.websitesGetDataByPublisherSuccess, data.data);
                return data.data;
            },
            () => {
                commit(mutationTypes.websitesGetDataByPublisherFailure);
            }
        );
    },
    [actionTypes.addData]({ commit }, payload) {
        commit(mutationTypes.websiteAddStart);
        payload["performax_id"] = parseInt(payload["performax_id"]);

        return axios({
            method: "post",
            url: "la/website",
            data: payload,
        }).then(
            (data) => {
                showMessage(data.data, "creat", "website");
                if (data.data.success) {
                    commit(mutationTypes.websiteAddSuccess, data.data);
                } else {
                    commit(mutationTypes.websiteAddEditFormError, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.websiteAddFailure);
            }
        );
    },
    [actionTypes.editData]({ commit, dispatch }, payload) {
        commit(mutationTypes.websiteEditStart);
        const body = {
            aab_site_id: payload.aabSiteId,
            publisher: payload.publisher,
            cpm: payload.cpm,
            state: payload.status,
            site_name: payload.site_name,
            site_status: payload.site_status,
            verified: payload.verified ? payload.verified : null,
            zoho_id: payload.zoho_id ? payload.zoho_id : null,
            performax_id: payload.performax_id ? parseInt(payload.performax_id) : null,
        };
        return axios({
            method: "put",
            url: "la/website",
            data: body,
        }).then(
            (data) => {
                showMessage(data.data, "sav", "website");
                if (data.data.success) {
                    commit(mutationTypes.websiteEditSuccess, data.data);
                    dispatch(websiteActionTypes.getData);
                } else {
                    commit(mutationTypes.websiteAddEditFormError, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.websiteEditFailure);
            }
        );
    },
    [actionTypes.deleteData]({ commit }, { aab_site_id }) {
        commit(mutationTypes.websiteDeleteStart);
        return axios({
            method: "delete",
            url: `la/website/${aab_site_id}`,
        }).then(
            (data) => {
                showMessage(data.data, "delet", "website");
                commit(mutationTypes.websiteDeleteSuccess, data.data);
            },
            () => {
                commit(mutationTypes.websiteDeleteFailure);
            }
        );
    },
    [actionTypes.changeMonetize]({ commit }, { aab_site_id, monetize_status }) {
        commit(mutationTypes.websiteEditStart);

        return axios({
            method: "put",
            url: `la/website/monetize/${aab_site_id}`,
            data: { monetize: monetize_status },
        }).then(
            (data) => {
                commit(mutationTypes.websiteEditSuccess, data.data);
            },
            () => {
                commit(mutationTypes.websiteEditFailure);
            }
        );
    },
};
