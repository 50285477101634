<template>
    <div v-if="storePages" class="table-footer">
        Page
        <input
            class="table-page"
            v-model="inputActualPage"
            @change="setPage(inputActualPage)"
            type="number"
            min="1"
            :max="maxPage"
        />
        of
        {{ maxPage }}

        <button class="table-arrow" @click="setPage(storeActualPage - 1)" :disabled="storeActualPage <= 1">
            <LaBaseIcon name="arrow-left" />
        </button>

        <button class="table-arrow" @click="setPage(storeActualPage + 1)" :disabled="storeActualPage >= maxPage">
            <LaBaseIcon name="arrow-right" />
        </button>
    </div>
</template>

<script>
import LaBaseIcon from "@/components/BaseIcon.vue";
import store from "@/store/store";
import { actionTypes as feeActionTypes, getterTypes as feeGetterTypes } from "@/store/Fees/types";
import {
    getterTypes as feePaginatorGetterTypes,
    actionTypes as feePaginatorActionTypes,
} from "@/store/Paginators/types";
import { computed } from "vue";

export default {
    name: "FeePaginator",
    props: {
        pages: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    setup(props) {
        const storeActualPage = computed(() => store.getters[feePaginatorGetterTypes.getActualPage]);
        let inputActualPage = storeActualPage.value;

        const storePages = computed(() => store.getters[feePaginatorGetterTypes.getPages]);
        if (!storePages.value) {
            store.dispatch(feePaginatorActionTypes.setPagesAction, { pages: props.pages });
        }
        const maxPage = storePages.value.length;

        const storePublisherSelected = computed(() => store.getters[feeGetterTypes.getPublisherSelected]);
        const storeWebsiteSelected = computed(() => store.getters[feeGetterTypes.getWebsiteSelected]);

        return {
            inputActualPage,
            storeActualPage,
            storePages,
            maxPage,
            storePublisherSelected,
            storeWebsiteSelected,
        };
    },
    methods: {
        setPage(index) {
            if (index > 0 && index <= this.maxPage) {
                store.dispatch(feePaginatorActionTypes.setActualPage, { num: index });
            }
            this.reload(index);
        },
        reload(index) {
            let page = this.storePages[index - 1];
            let timestamp = page ? page[0] : null;
            let after_search = page ? page[1] : null;

            store.dispatch(feeActionTypes.getAll, {
                publisherId: this.storePublisherSelected,
                websiteId: this.storeWebsiteSelected,
                timestamp: timestamp,
                after_search: after_search,
            });
        },
    },

    components: {
        LaBaseIcon,
    },
};
</script>

<style lang="scss" scoped>
.table {
    color: $blue;

    &-footer {
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-grey;
    }

    &-arrow,
    &-page {
        width: 2.5rem;
        height: 2.5rem;
        color: $blue;
        border-radius: $normal-radius;
    }

    &-arrow {
        margin-left: 0.5rem;
    }

    &-page {
        margin: 0 0.3rem;
        border: 1px solid $grey;
        padding: 0.3rem;
        font-weight: 600;
    }
}

body.dark {
    .table {
        font-family: "Open Sans";
        font-size: 1rem;
        color: $text;

        &-header,
        &-body {
            background: $card-background;
            border-bottom: 1px solid transparent;
        }

        &-header {
            margin-bottom: 0.5rem;
        }

        &-body:hover {
            background: $card-background-dark;

            .table-button {
                //background-color: $white;
                background-color: inherit;
            }
        }

        &-footer {
            color: $dark-text;
        }

        &-arrow,
        &-page {
            border: 1px solid transparent;
            background: $input-background;
            color: $dark-text;
        }

        &-arrow {
            &:hover {
                color: $lime;
            }
        }
    }

    .table-body {
        border-bottom: none;
    }

    .table.stripped {
        .table-body:nth-child(odd) {
            background: $card-background-light;

            &:hover {
                background: $card-background-dark;
            }
        }
    }
}
</style>
