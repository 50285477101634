import cloneDeep from "lodash.clonedeep";
import { DEFAULT_STATE } from "./state";
import { actions } from "./actions";
import { getterTypes, mutationTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.setUser](state, user) {
            if (user) {
                state.user.id = user.id;
                state.user.api_key = user.api_key;
                state.user.email = user.email;
                state.user.name = user.name || "";
                state.user.frontend_rights = user.frontend_rights;
                state.user.brs_only = user.brs_only;
                state.user.brs_sites = user.brs_sites;
                state.user.brs_upgrade = user.brs_upgrade;
                state.user.avatar_path = user.avatar_path;
                state.user.timezone = user.timezone;
                state.isAuthenticated = true;
                if (user.version) {
                    state.user.version = user.version;
                }
            }
        },
        [mutationTypes.removeUser](state) {
            state.user = DEFAULT_STATE.user;
            state.isAuthenticated = false;
        },
        [mutationTypes.loginUserStart](state) {
            state.loginFormErrors = null;
            state.isLoading = true;
        },
        [mutationTypes.loginUserSuccess](state, data) {
            state.user.id = data.id;
            state.user.api_key = data.api_key;
            state.user.email = data.email;
            state.user.name = data.name || "";
            state.user.frontend_rights = data.frontend_rights || null;
            state.user.brs_only = data.brs_only;
            state.user.brs_sites = data.brs_sites;
            state.user.brs_upgrade = data.brs_upgrade;
            state.user.avatar_path = data.avatar_path;
            state.user.timezone = data.timezone;
            state.isLoading = false;
            state.isSuccess = true;
            state.isAuthenticated = true;
        },
        [mutationTypes.loginUserFailure](state) {
            state.isLoading = false;
            state.isAuthenticated = false;
        },
        [mutationTypes.editPersonalInfoStart](state) {
            state.personalInfoFormErrors = null;
            state.isLoadingPersonalInfo = true;
        },
        [mutationTypes.editPersonalInfoSuccess](state, data) {
            state.isLoadingPersonalInfo = false;
            state.user.avatar_path = data.avatar_path;
            state.user.name = data.name;
            state.user.email = data.email;
            state.user.timezone = data.timezone;
        },
        [mutationTypes.editPersonalInfoFailure](state) {
            state.isLoadingPersonalInfo = false;
        },
        [mutationTypes.registerUserStart](state) {
            state.registerFormErrors = null;
            state.isLoading = true;
        },
        [mutationTypes.registerSuccess](state) {
            state.isLoading = false;
            state.isRegistrationFinished = true;
        },
        [mutationTypes.registerFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.userEmailVerificationStart](state) {
            state.isLoadingVerification = true;
        },
        [mutationTypes.userEmailVerificationSuccess](state) {
            state.isLoadingVerification = false;
        },
        [mutationTypes.userEmailVerificationFailure](state) {
            state.isLoadingVerification = false;
        },
        [mutationTypes.changePasswordStart](state) {
            state.changePasswordFormErrors = null;
        },
        [mutationTypes.changePasswordSuccess]() {},
        [mutationTypes.changePasswordFailure]() {},
        [mutationTypes.setChangePasswordFormErrors](state, errors) {
            state.changePasswordFormErrors = errors;
        },
        [mutationTypes.setLoginFormErrors](state, errors) {
            state.loginFormErrors = errors;
        },
        [mutationTypes.setRegisterFormErrors](state, errors) {
            state.registerFormErrors = errors;
        },
        [mutationTypes.forgottenPasswordStart](state) {
            state.forgottenPasswordFormErrors = null;
        },
        [mutationTypes.forgottenPasswordSuccess]() {},
        [mutationTypes.forgottenPasswordFailure]() {},
        [mutationTypes.setForgottenPasswordFormErrors](state, errors) {
            state.forgottenPasswordFormErrors = errors;
        },
        [mutationTypes.setPersonalInfoFormErrors](state, errors) {
            state.personalInfoFormErrors = errors;
        },
    },
    getters: {
        [getterTypes.isAuthenticated](state) {
            return state.isAuthenticated;
        },
        [getterTypes.isRegistrationComplete]: (state) => {
            return state.isRegistrationFinished;
        },
        [getterTypes.isAllowed]: (state) => (currentPath) => {
            if (
                currentPath.includes("payout") ||
                currentPath.includes("user-list") ||
                currentPath.includes("company-account")
            ) {
                return state.user.frontend_rights.edit_users;
            }
            return true;
        },
        [getterTypes.getUserRights](state) {
            return state.user.frontend_rights;
        },
        [getterTypes.getPersonalInfo](state) {
            return {
                name: state.user.name,
                id: state.user.id,
                email: state.user.email,
                timezone: state.user.timezone,
            };
        },
        [getterTypes.getUserId](state) {
            return state.user.id;
        },
        [getterTypes.getUser](state) {
            return state.user;
        },
        [getterTypes.isMainUser](state) {
            return state.user.frontend_rights.edit_users; // tohle muze jen prvni zaregistrovany
        },
        [getterTypes.getAvatar](state) {
            if (state.user.avatar_path) {
                return `${process.env.VUE_APP_API_URL}/avatars/${state.user.id}/${state.user.avatar_path}`;
            }
            return `${process.env.VUE_APP_URL}/img/unicorn.38433cfa.png`;
        },
        [getterTypes.getTimezone](state) {
            return state.user.timezone;
        },
        [getterTypes.getChangePasswordFormErrors](state) {
            return state.changePasswordFormErrors;
        },
        [getterTypes.getLoginFormErrors](state) {
            return state.loginFormErrors;
        },
        [getterTypes.getRegisterFormErrors](state) {
            return state.registerFormErrors;
        },
        [getterTypes.getForgottenPasswordErrors](state) {
            return state.forgottenPasswordFormErrors;
        },
        [getterTypes.getPersonalInfoFormErrors](state) {
            return state.personalInfoFormErrors;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
