<template>
    <section>
        <div class="container">
            <div class="logo-container">
                <img src="@/assets/login/img/Logo.svg" alt="Logo" />
            </div>
            <div class="form-container">
                <form :class="hasErrors ? 'error' : ''">
                    <h2>Reset my password</h2>
                    <div class="input-container">
                        <label for="email">Your email</label>
                        <input
                            v-model="emailValue"
                            type="text"
                            placeholder="Enter your email"
                            id="email"
                            @input="dataModify"
                        />
                    </div>

                    <button type="button" @click="send">
                        {{ hasErrors ? "Wrong email" : "Send reset instructions" }}
                    </button>
                </form>

                <img id="bg-circle" src="@/assets/login/img/Bg_circle.svg" loading="lazy" alt="Bg-circle" />
                <router-link :to="{ name: 'login' }">Back to login</router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { actionTypes, getterTypes } from "@/store/LoggedUser/types";
import { computed, ref, watch } from "vue";
import store from "@/store/store";

export default {
    name: "PasswordRecoveryView",
    data() {
        return {
            emailValue: "",
            passwordValue: "",
            inputPasswordType: "password",
            showPassword: false,
            showError: false,
            isPending: true,
        };
    },

    setup() {
        const sendEmailForgottenPasswordAction = actionTypes.sendEmailForgottenPassword;
        const errors = computed(() => store.getters[getterTypes.getForgottenPasswordErrors] || {});
        const hasErrors = ref(false);

        watch(errors, (newErrors) => {
            hasErrors.value = Object.keys(newErrors).length > 0;
        });

        return {
            sendEmailForgottenPasswordAction,
            errors,
            hasErrors,
        };
    },
    methods: {
        send() {
            const payload = {
                email: this.emailValue,
            };
            store.dispatch(this.sendEmailForgottenPasswordAction, payload).finally(() => {
                this.isPending = false;
            });
        },
        dataModify() {
            this.hasErrors = false;
        },
    },
};
</script>

<style scoped lang="scss"></style>
