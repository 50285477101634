<template>
    <BaseCard>
        <la-payout-management-table v-if="payouts" :data="payouts.payouts"></la-payout-management-table>
        <paginator v-if="payouts" :pages="payouts.pages"></paginator>
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard";
import LaPayoutManagementTable from "@/components/payouts/PayoutManagementTable";
import Paginator from "@/components/paginators/PayoutManagementPaginator";
import store from "@/store/store";
import { getterTypes as payoutGetterTypes } from "@/store/Payouts/types";
import { computed } from "vue";

export default {
    name: "PayoutManagementCard",
    components: {
        BaseCard,
        LaPayoutManagementTable,
        Paginator,
    },
    setup() {
        const payouts = computed(() => store.getters[payoutGetterTypes.getAllPayouts]);
        return { payouts };
    },
};
</script>

<style scoped></style>
