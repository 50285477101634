<template>
    <div class="base-tag" :class="[type, stretch ? 'stretch' : '']" v-bind="$attrs">
        <span class="base-tag-content">
            <LaBaseIcon v-if="icon" :name="icon" />
            {{ text }}
        </span>
    </div>
</template>

<script>
import LaBaseIcon from "@/components/BaseIcon";

export default {
    name: "LaBaseTag",
    components: {
        LaBaseIcon,
    },
    props: {
        type: {
            type: String,
            default: "success",
            required: false,
            validator: function (value) {
                return (
                    [
                        "success",
                        "warning",
                        "error",
                        "neutral",
                        "inverted green",
                        "inverted blue",
                        "inverted black",
                    ].indexOf(value) !== -1
                );
            },
        },
        text: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            default: "",
            required: false,
        },
        stretch: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.base-tag {
    display: block;
    padding: 0.4rem 0.8rem;
    border-radius: $small-radius;
    color: $blue;
    @include --tablet-viewport {
        padding: 0.6rem 1.4rem;
    }
    &-icon {
        margin-right: 0.4rem;
        margin-left: -0.4rem;
    }
    &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }
    &.error {
        background-color: $red;
    }
    &.neutral {
    }
    &.stretch {
        width: 100%;
    }
    &:disabled {
        cursor: not-allowed;
        background: $dark-grey;
    }
    &.inverted {
        cursor: text;
        background-color: $white;

        &.green {
            color: $green;
            border: $green solid 1px;
        }

        &.blue {
            color: $blue;
            border: $blue solid 1px;
        }
        &.black {
            color: $black;
            border: $black solid 1px;
        }
    }
}

body.dark {
    .base-tag {
        background-color: inherit;
        color: $white;

        &.success {
            color: $green;
        }
    }
}
</style>
