<template>
    <LaActionIcon type="edit" @click="editItem(item)" />
    <slot name="center-icons" />
    <LaActionIcon type="delete" v-if="showDeleteIcon" @click="deleteItem(item)" />
</template>

<script>
import LaActionIcon from "@/components/button/ActionIcon";
import store from "@/store/store";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";

export default {
    name: "LaActionIcons",
    components: {
        LaActionIcon,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        itemName: {
            type: String,
            required: true,
        },
        showDeleteIcon: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const editItem = (item) => {
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: `${props.itemName}Form`,
                dialogHeading: `Edit ${props.itemName}`,
                item: item,
            });
        };

        const deleteItem = (item) => {
            store.commit(dialogMutationTypes.openDialog, {
                dialogName: `${props.itemName}Delete`,
                dialogHeading: `Delete ${props.itemName}`,
                item: item,
            });
        };

        return { editItem, deleteItem };
    },
};
</script>
