<template>
    <LaBaseTable data-test="publisher-table" :headers="headers" :items="data" stripped data-cy="publisher-table">
        <template #item-currency="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ item.currency ? item.currency : default_currency_symbol() }}</span>
            </div>
        </template>

        <template #item-is_lite="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ item.is_lite ? "yes" : "no" }}</span>
            </div>
        </template>
        <template #item-zoho_id="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ item.zoho_id }}</span>
            </div>
        </template>

        <template #item-performax_id="{ item }">
            <div class="is-flex is-flex-direction-column">
                <span>{{ item.performax_id }}</span>
            </div>
        </template>

        <template #item-actions="{ item }">
            <LaActionIcons :item="item" item-name="publisher"> </LaActionIcons>
        </template>
    </LaBaseTable>
</template>

<script>
import LaBaseTable from "@/components/BaseTable";
import { formatMonthDate, formatDate, formatCell } from "@/helpers/filters.js";
import LaActionIcons from "@/components/button/ActionIcons";
import { default_currency } from "../../helpers/data";

export default {
    name: "LaPublisherTable",
    methods: {
        default_currency_symbol() {
            return default_currency;
        },
    },
    components: {
        LaBaseTable,
        LaActionIcons,
    },
    props: {
        data: {
            required: true,
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: "name",
                    value: "name",
                    direction: 1,
                    // flex: 2,
                },
                {
                    text: "status",
                    value: "status",
                },
                {
                    text: "currency",
                    value: "currency",
                },
                {
                    text: "Performax ID",
                    value: "performax_id",
                    isNumber: true,
                    justifyContentCenter: true,
                },
                {
                    text: "Zoho ID",
                    value: "zoho_id",
                    isNumber: false,
                    justifyContentCenter: true,
                },
                {
                    text: "actions",
                    value: "actions",
                    justifyContentCenter: true,
                },
            ];
        },
    },
    setup() {
        return { formatMonthDate, formatCell, formatDate };
    },
};
</script>

<style lang="scss" scoped>
.second {
    font-size: 0.8rem;
    opacity: 0.8;
}
</style>
