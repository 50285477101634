import axios from "axios";
import * as _ from "lodash";
import { MESSAGE_STATE } from "@/store/FlashMessages/state";
import store from "@/store/store";
import { getItem } from "@/store/persistantStorage";
import { mutationTypes as flashMessagesMutationTypes } from "@/store/FlashMessages/types";
import { actionTypes, actionTypes as loggedInUserActionTypes } from "@/store/LoggedUser/types";
import { mutationTypes as dialogMutationTypes } from "@/store/Dialog/types";

function isUrlWithApiKey(url) {
    return !(
        url === "https://cdn.dopc.cz/js/stat-loader.min.js" ||
        url.includes("login") ||
        url.includes("password_recovery") ||
        url.includes("verify") ||
        url.includes("register")
    );
}

function isStatLoader(url) {
    return url === "https://cdn.dopc.cz/js/stat-loader.min.js";
}

function isLogin(url) {
    return url.includes("login");
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL + "/api/v1";
// console.log("baseURL", axios.defaults.baseURL);
axios.defaults.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

axios.interceptors.request.use(function (config) {
    // SET Authorization header
    if (config.url && isUrlWithApiKey(config.url)) {
        const authConfig = config;
        const user = getItem("loggedinuser");
        const publisher = getItem("selectedPublisher");
        authConfig.headers["Authorization"] = _.get(user, "api_key");
        authConfig.headers["X-Publisher"] = publisher;
        return authConfig;
    } else {
        return config;
    }
});

axios.interceptors.response.use(
    function (response) {
        if (response.data.success || response.data.ok) {
            store.commit(dialogMutationTypes.closeDialog);
        }
        const bothVersions = response.headers["x-api-version"];
        if (bothVersions) {
            const versions = bothVersions.split(",");
            const apiVersion = versions[0];
            const adminVersion = versions[1];
            const requestUrl = response.request.responseURL;
            if (!isStatLoader(requestUrl)) {
                if (0 && localStorage.getItem("adminversion") !== adminVersion && apiVersion !== "debug") {
                    store.dispatch(actionTypes.logout);
                    store.commit(flashMessagesMutationTypes.addMessageToQueue, {
                        header: "Please login again.",
                        description: "Version of application has been updated.",
                        messageState: MESSAGE_STATE.INFO,
                    });
                } else if (isLogin(requestUrl)) {
                    store.dispatch(actionTypes.setUserVersionToLS, bothVersions);
                } else if (isUrlWithApiKey(requestUrl)) {
                    const user = getItem("loggedinuser");
                    if (user.version !== bothVersions) {
                        store.dispatch(actionTypes.logout);
                        store.commit(flashMessagesMutationTypes.addMessageToQueue, {
                            header: "Please login again.",
                            description: "Version of application has been updated.",
                            messageState: MESSAGE_STATE.INFO,
                        });
                    }
                }
            }
        }

        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            // Unauthorized
            store.commit(flashMessagesMutationTypes.addMessageToQueue, {
                header: "Please login again.'",
                description: "Your session has expired.",
                messageState: MESSAGE_STATE.INFO,
            });
            store.dispatch(loggedInUserActionTypes.logout);
        } else if (!error?.response?.data?.error) {
            // error.response.data.error slouzi k vykresleni erroru ve formu, ne do flash message
            const message = {
                header: error?.response?.data?.title,
                description: error?.response?.data?.description,
                messageState: MESSAGE_STATE.WARN,
            };
            store.commit(flashMessagesMutationTypes.addMessageToQueue, message);
        }
        return Promise.reject(error);
    }
);

export default axios;
