<template>
    <Suspense>
        <template #default>
            <LaCompanyAccount />
        </template>
        <template #fallback>
            <LaFormLoader />
        </template>
    </Suspense>
</template>

<script>
import LaFormLoader from "@/components/loaders/FormLoader";
import LaCompanyAccount from "@/components/account/CompanyAccount";

export default {
    name: "LaCompanyAccountView",
    components: {
        LaCompanyAccount,
        LaFormLoader,
    },
};
</script>

<style scoped></style>
