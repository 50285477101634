<template>
    <span class="table-button-container" :class="'table-button-container-' + type">
        <button
            v-bind="$attrs"
            :data-test="button.dataTest"
            :class="button.class"
            :data-cy="`table-row-action-${type}`"
        >
            <LaBaseIcon :name="button.icon" />
        </button>
    </span>
</template>

<script>
import LaBaseIcon from "@/components/BaseIcon";
import { computed } from "vue";

export default {
    name: "LaActionIcon",
    components: {
        LaBaseIcon,
    },
    props: {
        type: {
            type: String,
            default: "edit",
        },
    },
    setup(props) {
        const buttons = computed(() => {
            return [
                {
                    type: "delete",
                    icon: "trash-can-outline",
                    dataTest: "delete-icon",
                    class: "table-button",
                },
                {
                    type: "edit",
                    icon: "pencil-outline",
                    dataTest: "edit-icon",
                    class: "table-button",
                },
                {
                    type: "monetize",
                    icon: "currency-usd",
                    dataTest: "monetize-icon",
                    class: "table-button",
                },
                {
                    type: "invite",
                    icon: "send-outline",
                    dataTest: "invite-icon",
                    class: "table-button",
                },
            ];
        });
        const button = computed(() => buttons.value.find((i) => i.type === props.type));
        return { button };
    },
};
</script>

<style lang="scss" scoped>
.table {
    &-body:hover {
        .table-button {
            background-color: $white;
        }
    }
    &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $light-grey;
        box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 1px rgb(0 0 0 / 8%);
        background: $dark-light linear-gradient(180deg, lighten($dark-light, 1%), darken($dark-light, 2%)) repeat-x;
        border: 1px solid darken($dark-light, 2%);
        border-radius: $normal-radius;
        margin: 0.3rem;
        font-size: 1.5rem;
        &-container {
            display: inline-block;
        }
        &:hover {
            background: $dark-light linear-gradient(180deg, lighten($dark-light, 3%), darken($dark-light, 2%)) repeat-x;
        }
    }
}

body.dark {
    .table {
        &-body:hover {
            .table-button {
                background-color: inherit;
            }
        }
        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark-grey-2;
            box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 1px rgb(0 0 0 / 8%);
            background: $black;
            border: 1px solid darken($dark-grey-2, 2%);
            border-radius: $normal-radius;
            margin: 0.3rem;
            font-size: 1.5rem;
            padding: 1.2rem;
            width: 0;
            height: 0;
            @include --mobile-viewport {
                padding: 1rem;
                font-size: 1.3rem;
            }
            &-container {
                display: inline-block;
            }
        }

        &-button i {
            &:hover {
                color: $green;
            }
        }

        &-button:before {
            background-color: initial;
        }
    }
}
</style>
