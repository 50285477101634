<template>
    <div>
        <p>Do you want to set monetize status as: "{{ monetizeStatusName }}" ?</p>

        <portal to="modalFooter">
            <LaBaseButton :button-type="buttonType" :text="buttonText" @click="send" stretch />
        </portal>
    </div>
</template>

<script>
import LaBaseButton from "@/components/button/BaseButton";
import store from "@/store/store";
import { getterTypes as dialogGetterTypes, mutationTypes } from "@/store/Dialog/types";
import { actionTypes as websitesActionTypes } from "@/store/Websites/types";
import { computed } from "vue";

export default {
    name: "ChangeMonetizeDialog",
    components: {
        LaBaseButton,
    },
    props: {
        monetizeStatus: {
            required: true,
            type: String,
        },
        monetizeStatusName: {
            required: true,
            type: String,
        },
        buttonText: {
            required: true,
            type: String,
        },
        buttonType: {
            type: String,
            default: "success",
            required: false,
            validator: function (value) {
                return ["success", "warning", "error", "neutral"].indexOf(value) !== -1;
            },
        },
    },
    setup(props) {
        const website = computed(() => store.getters[dialogGetterTypes.getDialog].item);
        const aabSiteId = website.value.aab_site_id;
        const send = () => {
            store.dispatch(websitesActionTypes.changeMonetize, {
                aab_site_id: aabSiteId,
                monetize_status: props.monetizeStatus,
            });
            store.commit(mutationTypes.closeDialog);
        };
        return { send };
    },
};
</script>

<style scoped></style>
