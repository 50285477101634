<template>
    <template v-if="website">
        <template v-if="website.monetize === null">
            <LaStartMonetizingButton
                :website="website"
                dialog-name="StartMonetizeDialog"
                dialog-heading="Request monetization of "
                button-text="Request monetization"
            />
        </template>

        <template v-else-if="website.monetize === 'Pending'"> <LaPendingMonetizeButton /> </template>
        <template v-else-if="website.monetize === 'In progress'">
            <LaBaseTag text="Approved" type="neutral" />
        </template>
        <template v-else-if="website.monetize === 'Request pending'">
            <LaBaseTag text="Request pending" type="neutral" />
        </template>

        <template v-if="website.monetize === 'Monetized'">
            <LaBaseTag :text="website.monetize" type="success" />
        </template>

        <template v-if="website.monetize === 'Rejected'">
            <LaBaseTag :text="website.monetize" type="neutral" />
        </template>

        <template v-if="isSuperAdmin">
            <template v-if="website.monetize === 'Pending'">
                <LaApproveMonetizeButton
                    :website="website"
                    dialog-name="MonetizationRequestedDialog"
                    button-text="Approve"
                />
            </template>

            <template
                v-if="
                    website.monetize != 'Rejected' &&
                    website.monetize != 'Monetized' &&
                    website.monetize != 'In progress'
                "
            >
                <LaRejectMonetizeButton
                    :website="website"
                    dialog-name="RejectMonetizeDialog"
                    dialog-heading="Reject website of "
                    button-text="Reject"
                    button-type="error"
                />
            </template>
        </template>
    </template>
</template>

<script>
import LaPendingMonetizeButton from "@/components/button/PendingMonetizeButton";
import LaStartMonetizingButton from "@/components/button/ChangeMonetizeButton";
import LaRejectMonetizeButton from "@/components/button/ChangeMonetizeButton";
import LaApproveMonetizeButton from "@/components/button/ChangeMonetizeButton";
import LaBaseTag from "@/components/BaseTag";
import { computed } from "vue";
import store from "@/store/store";
import { getterTypes } from "@/store/LoggedUser/types";

export default {
    name: "LaMonetizeField",
    components: {
        LaRejectMonetizeButton,
        LaPendingMonetizeButton,
        LaStartMonetizingButton,
        LaApproveMonetizeButton,
        LaBaseTag,
    },
    props: {
        website: {
            required: true,
            type: Object,
        },
    },
    setup() {
        const userRights = computed(() => store.getters[getterTypes.getUserRights]);
        const isSuperAdmin = computed(() => userRights.value && userRights.value["super_admin"]);
        return { isSuperAdmin };
    },
};
</script>

<style scoped></style>
