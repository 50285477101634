import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "@/store/Earnings/types";
import { prepareBodyForInterval } from "@/helpers/data";

export const actions = {
    [actionTypes.getData]({ commit }, { period, aabSiteIds, timezone, noCommit }) {
        !noCommit && commit(mutationTypes.earningsStart);
        return axios({
            method: "post",
            url: "la/ultra-lite",
            data: prepareBodyForInterval(period, aabSiteIds, timezone),
        }).then(
            ({ data }) => {
                !noCommit && commit(mutationTypes.earningsSuccess, data);
                return data;
            },
            () => {
                !noCommit && commit(mutationTypes.earningsFailure);
            }
        );
    },
    [actionTypes.getGrandTotal]({ commit }, { aabSiteIds, timezone }) {
        commit(mutationTypes.grandTotalStart);
        const jan2020 = new Date(2020, 1);
        const today = new Date();
        const period = { dateFrom: jan2020, dateTo: today };
        return axios({
            method: "post",
            url: "la/ultra-lite",
            data: prepareBodyForInterval(period, aabSiteIds, timezone),
        }).then(
            ({ data }) => {
                if (data?.metadata?.["total_revenue"]) {
                    commit(mutationTypes.grandTotalSuccess, data.metadata["total_revenue"]);
                } else {
                    commit(mutationTypes.grandTotalFailure);
                }
            },
            () => {
                commit(mutationTypes.grandTotalFailure);
            }
        );
    },
};
