import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "./types";
import { actionTypes as countryActionTypes } from "@/store/Country/types";
import { showMessage } from "@/helpers/messages";

export const actions = {
    async [actionTypes.getData]({ commit }) {
        commit(mutationTypes.paymentMethodGetDataStart);
        return axios({
            method: "get",
            url: "la/payment-method",
        }).then(
            (data) => {
                commit(mutationTypes.paymentMethodGetDataSuccess, data.data);
            },
            () => {
                commit(mutationTypes.paymentMethodGetDataFailure);
            }
        );
    },
    [actionTypes.editData]({ commit, dispatch }, payload) {
        commit(mutationTypes.paymentMethodEditDataStart);
        return axios({
            method: "put",
            url: "la/payment-method",
            data: payload,
        }).then(
            (data) => {
                showMessage(data.data, "sav", "payment method");
                if (data.data.ok) {
                    commit(mutationTypes.paymentMethodEditDataSuccess, data.data.payment_method);
                    dispatch(countryActionTypes.getCountry, {
                        isPublisher: false,
                        code: data.data.payment_method.bank_country,
                    });
                } else {
                    commit(mutationTypes.companyEditDataErrors, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.paymentMethodEditDataFailure);
            }
        );
    },
};
