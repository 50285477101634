import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.yieldStart](state) {
            state.isLoading = true;
            //state.data = null;
            //state.metadata = null;
        },
        [mutationTypes.yieldSuccess](state, data) {
            state.isLoading = false;
            state.data = data.data;
            state.metadata = data.metadata;
            state.csvdata = data.csv;
        },
        [mutationTypes.yieldFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.grandTotalStart](state) {
            state.isGtLoading = true;
            state.grandTotal = 0;
        },
        [mutationTypes.grandTotalSuccess](state, data) {
            state.isGtLoading = false;
            state.grandTotal = data;
        },
        [mutationTypes.grandTotalFailure](state) {
            state.isGtLoading = false;
        },
        // edit
        [mutationTypes.recordEditDataStart](state) {
            state.recordEdit = null;
            state.isLoading = true;
        },
        [mutationTypes.recordEditDataSuccess](state, data) {
            const record = data.data;
            state.data = state.data.map((item) => {
                if (item.id == record.id) {
                    let ret = {
                        ...item,
                        publisher_revenue_custom: record.publisher_revenue_custom,
                        is_verified: record.is_verified,
                        rpm: record.rpm,
                    };
                    return ret;
                }
                return item;
            });
            state.recordEdit = record;
            state.isLoading = false;
        },
        [mutationTypes.recordEditDataFailure](state) {
            state.isLoading = false;
        },
    },
    getters: {
        [getterTypes.getYieldData](state) {
            return state.data;
        },
        [getterTypes.getYieldMetadata](state) {
            return state.metadata;
        },
        [getterTypes.getYieldCsvData](state) {
            return state.csvdata;
        },
        [getterTypes.isLoading](state) {
            return state.isLoading || state.isGtLoading;
        },
        [getterTypes.getGrandTotal](state) {
            return state.grandTotal;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
