export const DEFAULT_USER = {
    id: null,
    name: "",
    email: null,
    permissions: [],
    showPayments: false,
    deleted: false,
};

export const DEFAULT_STATE = {
    data: [],
    isLoading: false,
    userFormErrors: null,
};
