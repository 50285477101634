import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "./types";

export const actions = {
    [actionTypes.getData]({ state, commit }, filter) {
        commit(mutationTypes.eventsGetDataStart);
        if (filter) {
            commit(mutationTypes.setFilter, filter);
        }
        const request = {
            method: "get",
            url: "events",
        };

        const categories = [];
        for (const k in state.filter) {
            if (state.filter[k] === false) {
                categories.push(k);
            }
        }
        if (categories.length) {
            request.params = { exclude_category: categories.join(",") };
        }
        return axios(request).then(
            (data) => {
                commit(mutationTypes.eventsGetDataSuccess, data.data);
            },
            () => {
                commit(mutationTypes.eventsGetDataFailure);
            }
        );
    },
};
