import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { getterTypes, mutationTypes } from "@/store/Dialog/types";

export default {
    actions,
    mutations: {
        [mutationTypes.openDialog](state, { dialogName, dialogHeading, item }) {
            state.activeDialogName = dialogName;
            state.heading = dialogHeading;
            state.item = item ? item : null;
        },
        [mutationTypes.closeDialog](state) {
            state.activeDialogName = "";
            state.heading = "";
            state.item = null;
        },
    },
    getters: {
        [getterTypes.getDialog](state) {
            return {
                activeDialogName: state.activeDialogName,
                heading: state.heading,
                item: state.item,
            };
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
