export const isRequired = () => (val) => {
    return !!val || "Required";
};

export const hasMaxLength = (maxLength) => (val) => {
    return !val || val.length <= maxLength || `Max length is ${maxLength} characters`;
};

export const isNumber = () => (val) => {
    return !isNaN(val) || "Only numbers are allowed";
};

export const hasNoWhitespace = () => (val) => {
    return /^\S*$/.test(val) || "Don't use whitespaces";
};

export const hasMinLength = (minLength) => (val) => {
    return !val || val.length >= minLength || `Minimum length is ${minLength} characters`;
};

export const hasLength = (len) => (val) => {
    return val.length === len || `Length must be equal ${len}`;
};

export const greaterThan = (min) => (val) => {
    return parseInt(val) > min || `Must be greater than ${min}`;
};

export const greaterThanEqual = (min) => (val) => {
    return parseInt(val) >= min || `Must be greater or equal than ${min}`;
};

export const isPassword = () => (val) => {
    return (
        /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/.test(val) ||
        "Password must contain at least 8 characters. You must include at least one letter and one digit."
    );
};
export const isSamePassword = (compareValue) => (val) => {
    return compareValue.value === val || "Passwords does not match";
};

export const isValidUrl = () => (val) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    );
    return !!pattern.test(val) || "Use valid url";
};

export const isValidEmail = () => (val) => {
    var pattern = new RegExp("^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$", "i");
    return pattern.test(val) || "Use valid email";
};

export const isJSONValid = () => (val) => {
    try {
        JSON.parse(val);
        return true;
    } catch (e) {
        return e.toString();
    }
};

const dicRegex = /^[a-zA-Z]{2}[0-9a-zA-Z]+$/;

// prevzate otestovane validatory VAT z buyingu
// returns [string|null] (null => no value)
export const validateDIC = (required) => (value) => {
    if (!value) {
        return required ? "Required value." : true;
    }

    if (!dicRegex.test(value)) {
        return "Invalid VAT format.";
    }

    return true;
};

export const validateIdentificationNumber = (required, allowDIC) => (value) => {
    if (!value) {
        return required ? "Required value." : true;
    }

    if (allowDIC && dicRegex.test(value)) {
        return validateDIC(required)(value);
    }

    if (!/^\d{0,8}$/.test(value)) {
        return "Invalid IČO format.";
    }

    const numArr = new Array(8 - value.length).fill(0).concat(value.split("").map(Number));
    const ctrlSum = numArr.reduce((sum, n, i) => (i < 7 ? sum + n * (8 - i) : sum), 0);
    const ctrlRest = ctrlSum % 11;
    const ctrlNum = (ctrlRest ? (ctrlRest === 1 ? 0 : 11 - ctrlRest) : 1) | 0;
    if (numArr[7] !== ctrlNum) {
        return "Invalid IČO.";
    }

    return true;
};

export const validateIBAN = (required) => (value) => {
    if (!value) {
        return required ? "Required value." : true;
    }

    const iban = String(value)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, ""); // keep only alphanumeric characters
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
    // check syntax and length
    if (!code || iban.length < 16) {
        return "Invalid IBAN format.";
    }
    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => letter.charCodeAt(0) - 55);
    // final check
    let checksum = digits.slice(0, 2);
    for (let offset = 2, fragment = ""; offset < digits.length; offset += 7) {
        fragment = String(checksum) + digits.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum === 1 ? true : "Invalid IBAN.";
};

export const validateBIC = (required) => (value) => {
    if (!value) {
        return required ? "Required value." : true;
    }

    if (!/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value)) {
        return "Invalid SWIFT code format.";
    }

    return true;
};
