export default {
    props: {
        labels: {
            type: Array,
            required: true,
        },
        datasets: {
            type: Object,
            required: true,
        },
    },
    watch: {
        chartData() {
            this.$data._chart.update();
        },
    },
    mounted() {
        this.renderChart(this.chartData, this.chartOptions);
    },
};
