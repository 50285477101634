<template>
    <div class="field loader" v-for="index in 3" :key="index">
        <div class="field-label" />
        <div class="field-input" />
    </div>
    <div class="field loader">
        <div class="field-label" />
        <div class="field-input field-checkbox" />
    </div>
</template>

<script>
export default {
    name: "LaFormLoader",
};
</script>

<style lang="scss" scoped>
.field {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    &-label,
    &-input {
        padding: 1rem 0;
        background-color: $light-blue;
        border-radius: $normal-radius;
    }
    &-label {
        width: 7rem;
        margin-right: 2rem;
    }
    &-input {
        width: 25rem;
    }
    &-checkbox {
        width: 2rem;
    }
}
</style>
