<template>
    <LaBaseCard>
        <template #header-left>
            <div class="button-set">
                <div class="base-button neutral large" :class="{ pressed: showDesktop }" @click="selectDesktop()">
                    <LaBaseIcon :name="'monitor'" /> desktop
                </div>
                <div class="base-button neutral large" :class="{ pressed: showMobile }" @click="selectMobile()">
                    <LaBaseIcon :name="'cellphone'" /> mobile
                </div>
                <div
                    class="base-button neutral large"
                    :class="{ pressed: showMobile == showDesktop }"
                    @click="selectAll()"
                >
                    all
                </div>
            </div>
        </template>
        <template #header>
            <div class="header">
                <LaCsvDownload class="header-button" :csv-data="blockRateData" :filename="filename" />
            </div>
        </template>

        <div v-if="!blockRateLoading" class="columns">
            <div class="column is-one-thirds is-flex is-justify-content-center" v-if="$mq.min.bigTablet">
                <BlockRateDonutChart
                    :allowed-percent="allowedPercent"
                    :blocking-percent="blockingPercent"
                    :allowed-pageviews-total="Number(allowedPageviewsTotal)"
                    :blocked-pageviews-total="Number(blockedPageviewsTotal)"
                />
            </div>
            <div class="column">
                <BlockRateStackedChart
                    :dates="lineLabels"
                    :allowed-pageviews="allowedData"
                    :blocked-pageviews="blockingData"
                />
            </div>
        </div>
        <LaContentLoader v-else />
    </LaBaseCard>
</template>

<script>
import { ref } from "vue";
import LaBaseCard from "@/components/BaseCard";
import LaContentLoader from "@/components/loaders/ContentLoader";
import LaCsvDownload from "@/components/CsvDownload";
import { formatDate, formatCell } from "@/helpers/filters.js";
import LaBaseIcon from "@/components/BaseIcon";
import { getItem } from "@/store/persistantStorage";
import BlockRateDonutChart from "@/components/charts/BlockRateDonutChart.vue";
import BlockRateStackedChart from "@/components/charts/BlockRateStackedChart.vue";

export default {
    name: "LaBlockRateDataCard",
    components: {
        LaBaseCard,
        LaBaseIcon,
        LaContentLoader,
        LaCsvDownload,
        BlockRateDonutChart,
        BlockRateStackedChart,
    },
    props: {
        blockRateLoading: {
            type: Boolean,
        },
        blockRateData: {
            type: Array,
            required: true,
        },
        blockRateMetadata: {
            type: Object,
            required: true,
        },
        domains: {
            type: Array,
            required: true,
        },
    },
    computed: {
        isLightTheme() {
            return getItem("theme") == "light";
        },
        filename() {
            return `${this.domains.join("_")}_blockRate_%from>%to`;
        },
        blockingPercent() {
            return this.blockRateMetadata.percent.blocking;
        },
        allowedPercent() {
            return this.blockRateMetadata.percent.non_blocking;
        },
        pieLabels() {
            return [
                "Allowed pageviews" + " (" + formatCell(this.allowedPageviewsTotal) + ")",
                "Blocked pageviews" + " (" + formatCell(this.blockedPageviewsTotal) + ")",
            ];
        },
        pieDatasetsLight() {
            return {
                label: "Pageviews",
                data: [this.allowedPercent, this.blockingPercent],
                backgroundColor: ["#1dce80", "#de350be6"],

                borderColor: ["#ffffff", "#ffffff"],
                borderWidth: 1,
            };
        },
        pieDatasetsDark() {
            return {
                label: "Pageviews",
                data: [this.allowedPercent, this.blockingPercent],
                backgroundColor: ["#0f2214", "#20140F"],
                borderColor: ["#a8ef4f", "#c10000"],
                borderWidth: 1,
                // cutout dowsn't work with vue3-chart-v2 cutout: "90%"
            };
        },
        blockingData() {
            return this.blockRateData.map((item) => item.blocking);
        },
        allowedData() {
            return this.blockRateData.map((item) => item["non_blocking"]);
        },
        blockedPageviewsTotal() {
            let total = 0;
            this.blockRateData.map((item) => {
                total += item.blocking;
            });
            return total;
        },
        allowedPageviewsTotal() {
            let total = 0;
            this.blockRateData.map((item) => {
                total += item.non_blocking;
            });
            return total;
        },
        lineLabels() {
            return this.blockRateData.map((item) => formatDate(new Date(item.date)));
        },
    },
    emits: ["deviceChange"],
    setup(props, { emit }) {
        let showDesktop = ref(true);
        let showMobile = ref(false);

        const getDevice = () => {
            if (showMobile.value == showDesktop.value) {
                return "";
            }
            if (showMobile.value) {
                return "mobile";
            }
            return "desktop";
        };

        const selectMobile = () => {
            console.log("selectMobile");
            if (!showMobile.value) {
                showMobile.value = true;
                showDesktop.value = false;
            } else {
                showMobile.value = false;
            }
            emit("deviceChange", getDevice());
        };
        const selectDesktop = () => {
            console.log("selectDesktop");
            if (!showDesktop.value) {
                showDesktop.value = true;
                showMobile.value = false;
            } else {
                showDesktop.value = false;
            }
            emit("deviceChange", getDevice());
        };
        const selectAll = () => {
            console.log("selectAll");
            showDesktop.value = false;
            showMobile.value = false;

            emit("deviceChange", getDevice());
        };
        return {
            selectMobile,
            selectDesktop,
            selectAll,
            showMobile,
            showDesktop,
        };
    },
};
</script>

<style lang="scss" scoped>
.graph-title {
    color: $blue;
    font-size: 2rem;
    margin-bottom: 0.5rem;
    &-sub {
        font-size: 0.9rem;
    }
}
.dd {
    width: 100%;
}

body.dark {
    .graph-title {
        color: $text;
    }
}
</style>
