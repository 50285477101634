<template>
    <div>
        <h1>Email Verification</h1>
        <p v-if="isLoading">loading...</p>
    </div>
</template>

<script>
import store from "@/store/store";
import { actionTypes, getterTypes } from "@/store/LoggedUser/types";
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
    name: "LaVerifyEmailView",
    setup() {
        const route = useRoute();
        const token = computed(() => route.params.token);
        store.dispatch(actionTypes.verify, { token: token.value });
        const isLoading = computed(() => store.getters[getterTypes.isLoadingVerification]);

        return { isLoading };
    },
};
</script>

<style scoped></style>
