export const actionTypes = {
    getData: "[country] Get data",
    getCountry: "[country] Get county",
};

export const mutationTypes = {
    countryGetDataStart: "[country] Country get data start",
    countryGetDataSuccess: "[country] Country get data success",
    countryGetDataFailure: "[country] Country get data failure",
    setBankCountry: "[country] Set bank country",
    setPublisherCountry: "[country] Set publisher country",
};

export const getterTypes = {
    getBankCountry: "[country] Get bank country",
    getPublisherCountry: "[country] Get publisher country",
    getCountries: "[country] Get countries",
};
