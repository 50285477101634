<template>
    <div>
        <p>Deleting the '{{ itemText }} {{ itemText2 }}' {{ itemName }} cannot be undone. Are you sure?</p>
        <portal to="modalFooter">
            <LaBaseButton
                data-test="delete-button"
                @click="deleteItem()"
                id="confirm-delete"
                buttonType="error"
                text="Delete"
                stretch
            />
        </portal>
    </div>
</template>

<script>
import LaBaseButton from "@/components/button/BaseButton";
import { computed } from "vue";
import { formatDateTimeByLocaleTimezone } from "@/helpers/filters.js";
import store from "@/store/store";
import { getterTypes as dialogGetterTypes, mutationTypes } from "@/store/Dialog/types";
import { actionTypes as usersActionTypes } from "@/store/Users/types";
import { actionTypes as websitesActionTypes } from "@/store/Websites/types";
import { actionTypes as publishersActionTypes } from "@/store/Publisher/types";
import { actionTypes as payoutsActionTypes } from "@/store/Payouts/types";
import { actionTypes as feesActionTypes } from "@/store/Fees/types";

export default {
    name: "LaDeleteConfirmDialog",
    components: {
        LaBaseButton,
    },
    props: {
        itemName: {
            type: String,
            required: true,
        },
        itemProperty: {
            type: String,
            required: true,
        },
        itemProperty2: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const item = computed(() => store.getters[dialogGetterTypes.getDialog].item);
        let itemText = computed(() => item.value[props.itemProperty]);
        let itemText2 = props.itemProperty2
            ? computed(() => {
                  if (props.itemProperty2 === "valid_from_isoformat") {
                      return " - " + formatDateTimeByLocaleTimezone(item.value[props.itemProperty2]);
                  }
                  return " - " + item.value[props.itemProperty2];
              })
            : "";

        const deleteItem = () => {
            if (props.itemName === "user") {
                store.dispatch(usersActionTypes.deleteData, { id: item.value.id });
            } else if (props.itemName === "website") {
                store.dispatch(websitesActionTypes.deleteData, { aab_site_id: item.value.aab_site_id });
            } else if (props.itemName === "publisher") {
                store.dispatch(publishersActionTypes.deletePublisher, { id: item.value.id, status: "Inactive" });
            } else if (props.itemName === "payout") {
                store.dispatch(payoutsActionTypes.deletePayout, { id: item.value.id });
            } else if (props.itemName === "fee") {
                store.dispatch(feesActionTypes.deleteFee, {
                    id: item.value.id,
                    publisher: item.value.publisher,
                });
            }
        };

        const closeModal = () => {
            store.commit(mutationTypes.closeDialog);
        };

        return { itemText, itemText2, deleteItem, closeModal };
    },
};
</script>

<style scoped></style>
