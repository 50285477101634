<template>
    <div class="card">
        <div class="card-header" v-if="!!$slots.header">
            <span class="card-header-left">
                <slot name="header-left" />
            </span>
            <span class="card-header-button">
                <slot name="header" />
            </span>
        </div>
        <div class="card-content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseCard",
};
</script>

<style lang="scss" scoped>
.card {
    background-color: $white;
    border: 1px solid $dark-grey;
    padding: 2rem;
    border: 1px solid transparent;
    margin: 0;
    height: 100%;

    @include --tablet-viewport {
        padding: 1rem;
    }

    &-header {
        border-bottom: 1px solid $dark-grey;
        margin: 0 -2rem 1rem -2rem;
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;

        &-button {
            margin-bottom: 2rem;
            @include --tablet-viewport {
                margin-bottom: 1rem;
            }
        }

        @include --tablet-viewport {
            margin: 0 -1rem 1rem -1rem;
            padding: 0 1rem;
        }
    }

    &-content {
        height: 100%;
    }
}

body.dark {
    .card {
        background: $card-background;
        border-radius: $normal-radius;

        &-header {
            border-bottom: 1px solid $dark-grey-3;
        }
    }
}
</style>
