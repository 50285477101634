import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.rewardsGetDataStart](state) {
            state.isLoading = true;
            state.data = null;
        },
        [mutationTypes.rewardsGetDataSuccess](state, data) {
            state.isLoading = false;
            state.data = data.rewards;
        },
        [mutationTypes.rewardsGetDataFailure](state) {
            state.isLoading = false;
        },
        [mutationTypes.rewardsWithPayoutsGetDataStart](state) {
            state.isLoading = true;
            state.dataDetail = null;
        },
        [mutationTypes.rewardsWithPayoutsGetDataSuccess](state, data) {
            state.isLoading = false;
            state.dataDetail = data;
        },
        [mutationTypes.rewardsWithPayoutsGetDataFailure](state) {
            state.isLoading = false;
        },

        [mutationTypes.rewardsWithPayoutsGetDataSaStart](state) {
            state.isLoading = true;
            state.dataDetailSa = null;
        },
        [mutationTypes.rewardsWithPayoutsGetDataSaSuccess](state, data) {
            state.isLoading = false;
            state.dataDetailSa = data;
        },
        [mutationTypes.rewardsWithPayoutsGetDataSaFailure](state) {
            state.isLoading = false;
        },
    },
    getters: {
        [getterTypes.getRewards](state) {
            return state.data;
        },
        [getterTypes.getRewardsWithPayouts](state) {
            return state.dataDetail;
        },
        [getterTypes.getRewardsWithPayoutsSa](state) {
            return state.dataDetailSa;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
