import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import LoggedView from "@/views/LoggedView";
import MenuView from "@/views/MenuView.vue";
import store from "@/store/store";
import NotAllowedView from "@/views/NotAllowedView";
import NotFoundView from "@/views/NotFoundView";
import { getterTypes, actionTypes } from "@/store/LoggedUser/types";
import { mutationTypes as messagesMutationTypes } from "@/store/FlashMessages/types";
import AccountView from "@/views/account/AccountView";
import ChangePasswordView from "@/views/account/ChangePasswordView";
import PersonalInfoView from "@/views/account/PersonalInfoView";
import PaymentMethodView from "@/views/account/PaymentMethodView";
import UserListView from "@/views/account/UserListView";
import PayoutsView from "@/views/PayoutsView";
import PayoutsCsvView from "@/views/PayoutsCsvView";
import ApprovingView from "@/views/ApprovingView";
import PublishersView from "@/views/PublishersView.vue";
import FeeView from "@/views/FeeView.vue";
import EventsView from "@/views/EventsView";
import InstallView from "@/views/InstallView";
import WebsitesView from "@/views/WebsitesView";
import UnloggedView from "@/views/unlogged/UnloggedView";
import LoginView from "@/views/unlogged/LoginView.vue";
import AddWebsiteView from "@/views/unlogged/AddWebsiteView";
import VerifyEmailView from "@/views/unlogged/VerifyEmailView";
import CompanyAccountView from "@/views/account/CompanyAccountView";
import { mutationTypes } from "@/store/Dialog/types";
import PayoutsManagementView from "@/views/PayoutsManagementView.vue";
import PasswordRecoveryView from "@/views/unlogged/PasswordRecoveryView.vue";
import ResetPasswordView from "@/views/unlogged/ResetPasswordView.vue";
import TermsAndConditions from "@/views/unlogged/TermsAndConditions.vue";
import PrivacyPolicy from "@/views/unlogged/PrivacyPolicy.vue";
import CookiePolicy from "@/views/unlogged/CookiePolicy.vue";
// import ZohoView from "@/views/unlogged/ZohoView.vue";

// let routeExists = true;

function isAuthenticated() {
    return store.getters[getterTypes.isAuthenticated];
}

function isAllowed(path) {
    return store.getters[getterTypes.isAllowed](path);
}

const accountRoutes = [
    {
        path: "/account/change-password",
        name: "changePassword",
        component: ChangePasswordView,
        meta: {
            label: "Change password",
        },
    },
    {
        path: "/account/personal-info",
        name: "personalInfo",
        component: PersonalInfoView,
        meta: {
            label: "Personal info",
        },
    },
    {
        path: "/account/:publisher/company-account",
        name: "companyAccount",
        component: CompanyAccountView,
        meta: {
            label: "Company account",
        },
    },
    {
        path: "/account/:publisher/payment-method",
        name: "paymentMethod",
        component: PaymentMethodView,
        meta: {
            label: "Payment method",
        },
    },
    {
        path: "/account/:publisher/user-list",
        name: "userList",
        component: UserListView,
        meta: {
            label: "User list",
        },
    },
];

const loggedRoutes = [
    {
        path: "/",
        component: MenuView,
        children: [
            {
                path: "/dashboard/:publisher?/:webs?",
                name: "dashboard",
                component: Dashboard,
            },
            {
                path: "/account",
                component: AccountView,
                children: accountRoutes,
            },
            {
                path: "/payouts/:publisher?",
                name: "payouts",
                component: PayoutsView,
            },
            {
                path: "/payouts-management/:publisher?/:status?",
                name: "payoutsManagement",
                component: PayoutsManagementView,
            },
            {
                path: "/payouts-csv",
                name: "payoutsCsv",
                component: PayoutsCsvView,
            },
            {
                path: "/approving",
                name: "approving",
                component: ApprovingView,
            },
            {
                path: "/publishers",
                name: "publishers",
                component: PublishersView,
            },
            {
                path: "/fees",
                name: "fees",
                component: FeeView,
            },
            {
                path: "/install/:publisher/:website?",
                name: "install",
                component: InstallView,
                meta: {
                    customName: true,
                },
            },
            {
                path: "/websites/:publisher",
                name: "websites",
                component: WebsitesView,
            },
            {
                path: "/events",
                name: "events",
                component: EventsView,
            },
            // {
            //     path: "/monetize/:aabSiteId",
            //     name: "monetize",
            //     component: MonetizeView,
            // },
            {
                path: "/not-found",
                name: "notFound",
                component: NotFoundView,
            },
            {
                path: "/not-allowed",
                name: "notAllowed",
                component: NotAllowedView,
            },
        ],
    },
];

const unloggedRoutes = [
    {
        path: "/",
        name: "unlogged",
        children: [
            {
                path: "sign-in",
                name: "login",
                component: LoginView,
            },
        ],
    },
    {
        path: "sign-in",
        name: "login",
        component: LoginView,
    },
    {
        path: "password-recovery",
        name: "passwordRecovery",
        component: PasswordRecoveryView,
    },
    {
        path: "/add-website",
        name: "addWebsite",
        component: AddWebsiteView,
    },
    {
        path: "/reset-password/:token",
        name: "resetPassword",
        component: ResetPasswordView,
    },
    {
        path: "/change-password/:token",
        name: "changePasswordUnlogged",
        component: ResetPasswordView,
    },
    {
        path: "/verify-user/:token",
        name: "verifyEmail",
        component: VerifyEmailView,
    },
    {
        path: "/terms-and-conditions",
        name: "termsAndConditions",
        component: TermsAndConditions,
    },
    {
        path: "/privacy-policy",
        name: "privacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/cookie-policy",
        name: "cookiePolicy",
        component: CookiePolicy,
    },
    // {
    //     path: "/zoho",
    //     name: "zoho",
    //     component: ZohoView,
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),

    routes: [
        {
            path: "/",
            component: UnloggedView,
            meta: {
                public: true,
                requiresAuth: false,
            },
            children: unloggedRoutes,
        },
        {
            path: "/",
            component: LoggedView,
            meta: {
                public: false,
                requiresAuth: true,
            },
            children: [
                ...loggedRoutes,
                {
                    path: "*",
                    name: "logged-404",
                    redirect: "/not-found",
                    beforeEnter: (to, from, next) => {
                        // routeExists = false;
                        next();
                    },
                },
            ],
        },
        {
            path: "/:pathMatch(.*)*",
            name: "default",
            meta: {
                public: true,
                requiresAuth: false,
            },
            redirect: { name: "login" },
        },
    ],
});

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

router.beforeEach((to, from, next) => {
    if (to.meta?.title) {
        document.title = "Let There Be Ads - " + to.meta.title;
    } else if (!to.meta.customName) {
        document.title = "Let There Be Ads - " + capitalize(to.name);
    }
    next();
});

router.beforeEach((to, from, next) => {
    store.dispatch(actionTypes.getUserFromLS).finally(() => {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (isAuthenticated()) {
                if (!isAllowed(to.path)) {
                    next({ name: "notAllowed" });
                } else {
                    next();
                    return;
                }
            } else {
                next({
                    name: "login",
                    query: {
                        redirect: to.fullPath,
                    },
                });
                return;
            }
        } else if (to.name === "unlogged" && isAuthenticated() && isAllowed(to.path)) {
            next({ name: "dashboard" });
        } else if (to.name === "unlogged" && !isAuthenticated()) {
            next({ name: "login" });
        } else {
            next();
        }
    });
});

router.afterEach(() => {
    store.commit(messagesMutationTypes.removeAllMessages);
    store.commit(mutationTypes.closeDialog);
});

export default router;
