import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "@/store/BlockRate/types";
import { prepareBodyForInterval } from "@/helpers/data";

export const actions = {
    [actionTypes.getData]({ commit }, { period, aabSiteIds, timezone, noCommit, device }) {
        // console.log(
        //     "period",
        //     period,
        //     "aabSiteids",
        //     aabSiteIds,
        //     "timezon",
        //     timezone,
        //     "noCommit",
        //     noCommit,
        //     "device",
        //     device
        // );
        !noCommit && commit(mutationTypes.blocRateStart);
        let url = "la/block_rate/";
        if (device) {
            url = url + device;
        }
        return axios({
            method: "post",
            url: url,
            data: prepareBodyForInterval(period, aabSiteIds, timezone),
        }).then(
            ({ data }) => {
                !noCommit && commit(mutationTypes.blocRateSuccess, data);
                return data;
            },
            () => {
                !noCommit && commit(mutationTypes.blocRateFailure);
            }
        );
    },
    // TODO: now it's counting the grand total from hourly data - this will get out of hand quite soon
    // rework this to count the grand total based on past Payments - ie. from data aggregated per month
    [actionTypes.getGrandTotal]({ commit }, { aabSiteIds, timezone }) {
        commit(mutationTypes.grandTotalBRStart);
        const jan2020 = new Date(2020, 1);
        const today = new Date();
        const period = { dateFrom: jan2020, dateTo: today };
        return axios({
            method: "post",
            url: "la/block_rate",
            data: prepareBodyForInterval(period, aabSiteIds, timezone),
        }).then(
            ({ data }) => {
                if (data?.metadata?.["lost_revenue"]) {
                    commit(mutationTypes.grandTotalBRSuccess, data.metadata["lost_revenue"]);
                } else {
                    commit(mutationTypes.grandTotalBRFailure);
                }
            },
            () => {
                commit(mutationTypes.grandTotalBRFailure);
            }
        );
    },
};
