<template>
    <div class="row loader" v-for="index in 6" :key="index" />
</template>

<script>
export default {
    name: "LaTableLoader",
};
</script>

<style lang="scss" scoped>
.row {
    width: 100%;
    padding: 1.5rem 0;
    border-radius: $normal-radius;
    &:nth-child(even) {
        background-color: $light-blue;
    }
}
</style>
