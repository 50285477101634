<template v-if="chart">
    <div ref="chart" style="width: 100%; height: 350px"></div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import * as echarts from "echarts";
import { formatDate } from "@/helpers/filters";
import store from "@/store/store";
import { getItem } from "@/store/persistantStorage";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import {
    getCurrencySymbol,
    getExchangeRateFromCzk,
    numberFormatImpressions,
    numberFormatWithSpace,
} from "@/helpers/data";
import { formatImpressions, formatRevenue } from "@/helpers/graphs";

export default {
    name: "RevenueImpressionsChart",
    props: {
        earningsData: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const chart = ref(null);
        let myChart = null;

        const isLightTheme = computed(() => {
            return getItem("theme") == "light";
        });

        const impression = computed(() => {
            return props.earningsData.map((item) => item["impressions"]);
        });

        const dates = computed(() => {
            return props.earningsData.map((item) => formatDate(new Date(item.date)));
        });

        const revenue = computed(() => {
            const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
            const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
            return props.earningsData.map(
                (item) => Math.round((item["publisher revenue"] / exchangeRate.value) * 100) / 100
            );
        });

        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));

        onMounted(() => {
            myChart = echarts.init(chart.value);
            const option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: function (params) {
                        let result = params[0].name + "<br/>";
                        params.forEach(function (item) {
                            if (item.seriesName === "Revenue") {
                                result +=
                                    item.marker +
                                    item.seriesName +
                                    ": " +
                                    item.value +
                                    " " +
                                    currencySymbol.value +
                                    "<br/>";
                            } else {
                                result +=
                                    item.marker +
                                    item.seriesName +
                                    ": " +
                                    numberFormatWithSpace(item.value.toString()) +
                                    "<br/>";
                            }
                        });
                        return result;
                    },
                },
                legend: {
                    data: ["Revenue", "Impressions"],
                    bottom: 0,
                    itemGap: 25, // Nastavení mezery mezi položkami v legendě
                    textStyle: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                        fontSize: 15,
                        fontFamily: "Open Sans",
                    },
                },
                xAxis: {
                    type: "category",
                    data: dates.value,
                    axisLabel: {
                        color: isLightTheme.value ? "#000000" : "#ffffff", // Nastavení barvy popisků na bílou
                    },
                },
                yAxis: [
                    {
                        type: "value",
                        name: "Revenue",
                        position: "left",
                        axisLine: {
                            lineStyle: {
                                color: isLightTheme.value ? "#1fad82" : "#a8ef4f",
                            },
                        },
                        axisLabel: {
                            formatter: "{value} " + currencySymbol.value,
                        },
                    },
                    {
                        type: "value",
                        name: "Impressions",
                        position: "right",
                        axisLine: {
                            lineStyle: {
                                color: isLightTheme.value ? "#2596be" : "#4fa8ef",
                            },
                        },
                        axisLabel: {
                            formatter: numberFormatImpressions,
                        },
                    },
                ],
                series: [
                    {
                        name: "Revenue",
                        type: "bar",
                        label: {
                            show: dates.value.length < 20 ? true : false,
                            formatter: (params) => formatRevenue(params, currencySymbol.value),
                        },
                        data: revenue.value,
                        itemStyle: {
                            color: isLightTheme.value ? "#1fad82" : "#a8ef4f", // Nastavení barvy pro tuto sérii
                        },
                        yAxisIndex: 0, // Použití první y-osy
                    },
                    {
                        name: "Impressions",
                        type: "bar",
                        label: {
                            show: dates.value.length < 20 ? true : false,
                            formatter: formatImpressions,
                        },
                        data: impression.value,
                        itemStyle: {
                            color: isLightTheme.value ? "#2596be" : "#4fa8ef", // Nastavení barvy pro tuto sérii
                        },
                        yAxisIndex: 1, // Použití druhé y-osy
                    },
                ],
                grid: {
                    left: "1%",
                    right: "1%",
                    top: "10%",
                    bottom: "10%",
                    containLabel: true,
                },
            };
            myChart.setOption(option);
        });

        onBeforeUnmount(() => {
            if (myChart) {
                myChart.dispose();
            }
        });

        return {
            chart,
        };
    },
};
</script>

<style scoped>
/* Stylování komponenty */
</style>
