import cloneDeep from "lodash.clonedeep";
import { actions } from "./actions";
import { DEFAULT_STATE } from "./state";
import { mutationTypes, getterTypes } from "./types";

export default {
    actions,
    mutations: {
        [mutationTypes.payoutsGetDataStart](state) {
            state.isLoading = true;
            state.data = null;
        },
        [mutationTypes.payoutsGetDataSuccess](state, data) {
            state.isLoading = false;
            state.data = data.payouts;
        },
        [mutationTypes.payoutsGetDataFailure](state) {
            state.isLoading = false;
        },
        // all
        [mutationTypes.payoutsGetAllDataStart](state) {
            state.isLoading = true;
            state.dataAll = null;
        },
        [mutationTypes.payoutsGetAllDataSuccess](state, data) {
            state.isLoading = false;
            state.dataAll = data;
        },
        [mutationTypes.payoutsGetAllDataFailure](state) {
            state.isLoading = false;
        },
        // detail
        [mutationTypes.payoutsGetDataForDetailStart](state) {
            state.isLoading = true;
            state.dataDetail = null;
        },
        [mutationTypes.payoutsGetDataForDetailSuccess](state, data) {
            state.isLoading = false;
            state.dataDetail = data;
        },
        [mutationTypes.payoutsGetDataForDetailFailure](state) {
            state.isLoading = false;
        },
        // edit
        [mutationTypes.payoutEditDataStart](state) {
            state.isLoading = true;
            state.payoutEdited = null;
        },
        [mutationTypes.payoutEditDataSuccess](state, data) {
            state.isLoading = false;
            state.payoutEdited = data;
        },
        [mutationTypes.payoutEditDataFailure](state) {
            state.isLoading = false;
        },
        // select publisher
        [mutationTypes.selectPublisherStart](state) {
            state.publisherSelected = null;
            state.isLoading = true;
        },
        [mutationTypes.selectPublisherSuccess](state, data) {
            state.publisherSelected = data;
            state.isLoading = false;
        },
        [mutationTypes.selectPublisherFailure](state) {
            state.isLoading = false;
        },
        //
        // select status
        [mutationTypes.selectStatusStart](state) {
            state.statusSelected = null;
            state.isLoading = true;
        },
        [mutationTypes.selectStatusSuccess](state, data) {
            state.statusSelected = data;
            state.isLoading = false;
        },
        [mutationTypes.selectStatusFailure](state) {
            state.isLoading = false;
        },
    },
    getters: {
        [getterTypes.getPayouts](state) {
            return state.data;
        },
        [getterTypes.getAllPayouts](state) {
            return state.dataAll;
        },
        [getterTypes.getPayoutsForDetail](state) {
            return state.dataDetail;
        },
        [getterTypes.getPublisherSelected](state) {
            return state.publisherSelected;
        },
        [getterTypes.getStatusSelected](state) {
            return state.statusSelected;
        },
    },
    state: cloneDeep(DEFAULT_STATE),
};
