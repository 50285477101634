import { default_currency } from "@/helpers/data";

export const DEFAULT_STATE = {
    data: [],
    dataForFeePage: null,
    dataForPayoutsManagementPage: null,
    publisher: null,
    vatNumber: null,
    isLoading: false,
    companyAccountErrors: null,
    createPublisherErrors: null,
    deletePublisherErrors: null,
    selectedPublisher: null,
    selectedPublisherCurrency: default_currency,
};
