<template>
    <div ref="chart" style="width: 100%; height: 400px"></div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount, watch, computed } from "vue";
import * as echarts from "echarts";
import { formatCell } from "@/helpers/filters.js";
import { getItem } from "@/store/persistantStorage";

export default {
    name: "BlockRateDonutChart",
    props: {
        allowedPercent: {
            type: Number,
            required: true,
        },
        blockingPercent: {
            type: Number,
            required: true,
        },
        allowedPageviewsTotal: {
            type: Number,
            required: true,
        },
        blockedPageviewsTotal: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const isLightTheme = computed(() => {
            return getItem("theme") == "light";
        });

        const chart = ref(null);
        let myChart = null;

        const renderChart = () => {
            if (myChart) {
                const option = {
                    tooltip: {
                        trigger: "item",
                        formatter: (params) => {
                            const total =
                                params.name === "Allowed" ? props.allowedPageviewsTotal : props.blockedPageviewsTotal;
                            return `${params.seriesName} ${params.name.toLowerCase()}: ${formatCell(total)} (${
                                params.percent
                            }%)`;
                        },
                    },
                    legend: {
                        orient: "horizontal",
                        bottom: "0%",
                        data: ["Allowed", "Blocked"],
                        selectedMode: false,
                        textStyle: {
                            color: isLightTheme.value ? "#5a5959" : "#ffffff",
                        },
                        formatter: function (name) {
                            const total =
                                name === "Allowed" ? props.allowedPageviewsTotal : props.blockedPageviewsTotal;
                            return `${name} pageviews (${formatCell(total)})`;
                        },
                    },
                    title: [
                        {
                            left: "center",
                            text: "Total pageviews",
                            textStyle: {
                                color: isLightTheme.value ? "#000" : "#ffffff",
                            },
                        },
                    ],
                    series: [
                        {
                            name: "Pageviews",
                            type: "pie",
                            padAngle: 5,
                            radius: ["40%", "70%"],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                color: (params) => {
                                    if (params.name === "Allowed") {
                                        return isLightTheme.value ? "#1dce80" : "#a8ef4f";
                                    } else if (params.name === "Blocked") {
                                        return isLightTheme.value ? "#de350b" : "#c10000";
                                    }
                                },
                            },
                            label: {
                                show: true,
                                position: "outside",
                                formatter: (params) => {
                                    return `${params.name} ${params.percent}%`;
                                },
                                fontSize: 14,
                                fontWeight: "bold",
                                textStyle: {
                                    color: isLightTheme.value ? "#5a5959" : "#ffffff",
                                },
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 20,
                                    fontWeight: "bold",
                                },
                            },
                            labelLine: {
                                show: true,
                            },
                            data: [
                                { value: props.allowedPercent, name: "Allowed" },
                                { value: props.blockingPercent, name: "Blocked" },
                            ],
                        },
                    ],
                };
                myChart.setOption(option);
            }
        };

        onMounted(() => {
            myChart = echarts.init(chart.value);
            renderChart();
        });

        watch(
            () => [
                props.allowedPercent,
                props.blockingPercent,
                props.allowedPageviewsTotal,
                props.blockedPageviewsTotal,
                isLightTheme.value,
            ],
            () => {
                renderChart();
            }
        );

        onBeforeUnmount(() => {
            if (myChart) {
                myChart.dispose();
            }
        });

        return {
            chart,
        };
    },
};
</script>

<style scoped>
/* Můžete přidat vlastní styly zde */
</style>
