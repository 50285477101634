<template>
    <div>
        <LaNavigationBar />
        <div class="container-content container-indentation">
            <router-view />
        </div>
        <LaFooter />
    </div>
</template>

<script>
import LaNavigationBar from "@/components/layout/NavigationBar";
import LaFooter from "@/components/layout/Footer";

export default {
    name: "LaMenuView",
    components: {
        LaNavigationBar,
        LaFooter,
    },
};
</script>

<style lang="scss" scoped></style>
