export const actionTypes = {
    getData: "[publishers] Get publishers data",
    getDataForFeePage: "[publishers] Get publishers data for fee page",
    getDataForPayoutsManagementPage: "[payouts-management] Get publishers data for payouts management page",
    createPublisher: "[publishers] Create publisher",
    editPublisher: "[publishers] Edit publisher",
    deletePublisher: "[publishers] Delete publisher",
    editCompanyData: "[publishers] Edit company data",
    checkVatNumber: "[publishers] Check VAT number",
    getSelectedPublisherFromLS: "[publishers] Get selectedPublisher from localStorage",
    setSelectedPublisherToLS: "[publishers] Set selectedPublisher to localStorage",
    getCurrencyFromLS: "[currency-by-publisher] get currency by publisher from localStorage",
    setCurrencyToLS: "[currency-by-publisher] set currency by publisher to localStorage",
};

export const mutationTypes = {
    publishersGetDataStart: "[publishers] Publishers get data start",
    publishersGetDataSuccess: "[publishers] Publishers get data success",
    publishersGetDataFailure: "[publishers] Publishers get data failure",

    publishersGetDataForFeePageStart: "[publishers-fee] Publishers get data for fee page start",
    publishersGetDataForFeePageSuccess: "[publishers-fee] Publishers get data for fee page success",
    publishersGetDataForFeePageFailure: "[publishers-fee] Publishers get data for fee page failure",

    publishersGetDataForPayoutsManagementPageStart:
        "[publishers-payouts-management] Publishers get data for payouts management page start",
    publishersGetDataForPayoutsManagementPageSuccess:
        "[publishers-payouts-management] Publishers get data for payouts management page success",
    publishersGetDataForPayoutsManagementPageFailure:
        "[publishers-payouts-management] Publishers get data for payouts management page failure",

    createPublisherStart: "[publishers] Create publisher start",
    createPublisherSuccess: "[publishers] Create publisher success",
    createPublisherFailure: "[publishers] Create publisher failure",
    createPublisherErrors: "[publishers] Create publisher errors",

    deletePublisherStart: "[publishers] Delete publisher start",
    deletePublisherSuccess: "[publishers] Delete publisher success",
    deletePublisherFailure: "[publishers] Delete publisher failure",
    deletePublisherErrors: "[publishers] Delete publisher errors",

    companyEditDataStart: "[publishers] Company edit data start",
    companyEditDataSuccess: "[publishers] Company edit data success",
    companyEditDataFailure: "[publishers] Company edit data failure",
    companyEditDataErrors: "[publishers] Company edit data errors",
    checkVAtNumberStart: "[publishers] Check VAT number start",
    checkVAtNumberSuccess: "[publishers] Check VAT number success",
    checkVAtNumberFailure: "[publishers] Check VAT number failure",
    setSelectedPublisher: "[publishers] Set selectedPublisher",
    setCurrencyBySelectedPublisher: "[publishers] Set currency by selected publisher",
    removeSelectedPublisher: "[publishers] Remove selectedPublisher",
};

export const getterTypes = {
    getIsLoading: "[publishers] Get loader",
    getPublishers: "[publishers] Get publishers",
    getPublishersForFeePage: "[publishers-fee] Get publishers for fee page",
    getPublishersForPayoutsManagementPage: "[publishers-payouts-management] Get publishers for payouts management page",
    getPublisher: "[publisher] Get main publisher",
    getVatNumber: "[publisher] Get VAT number",
    getCompanyAccountErrors: "[publisher] Get errors for company account form",
    getSelectedPublisher: "[publisher] Get selectedPublisher",
    getSelectedPublisherCurrency: "[publisher] Get selectedPublisherCurrency",
    getCreatePublisherErrors: "[publisher-create] Get errors",
    getDeletePublisherErrors: "[publisher-delete] Get errors",
};
