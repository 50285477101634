<template>
    <div v-if="publisher">
        <LaBaseForm
            :form-data="{
                id: id,
                domain: domain,
                cpm: cpm / exchangeRate,
                publisher: publisher,
                aabSiteId: aabSiteId,
                site_status: siteStatus,
                site_name: site_name,
                verified: verified,
                zoho_id: zohoId,
                performax_id: performaxId,
            }"
            :vuex-action-mutation="formAction"
            :button="isEdit ? 'Save' : 'Add website'"
            id="website"
            buttonPortalTo="modalFooter"
        >
            <div class="columns">
                <div class="column">
                    <LaBaseInput
                        v-model="domain"
                        :disabled="isEdit"
                        name="domain"
                        label="Domain"
                        id="domain"
                        :rules="rules.domain"
                        :error="errors.domain"
                    />

                    <LaBaseInput
                        v-model="site_name"
                        v-if="isEdit"
                        name="site_name"
                        label="Name"
                        :rules="rules.site_name"
                        :error="errors.site_name"
                    />

                    <LaInputWithTooltip
                        v-model="cpm"
                        :disabled="!userRights['super_admin']"
                        name="cpm"
                        :label="'RPM [' + currencySymbol + ']'"
                        type="number"
                        min="0"
                        max="0.5"
                        step=".01"
                        :rules="rules.cpm"
                        :error="errors.cpm"
                    >
                        <template #tooltip>
                            <h3 class="tooltip-title">Page RPM</h3>
                            <p>Page RPM is the average earnings per thousand page views.</p>
                            <code>Page RPM = (Estimated earnings / Number of page views) * 1000</code>
                        </template>
                    </LaInputWithTooltip>

                    <LaSimpleDatePicker
                        v-model="verified"
                        name="pickit"
                        label="Verified to"
                        placeholder="Date until when the site is verified"
                        v-if="userRights['super_admin'] && isEdit"
                    />

                    <InputContainer label="Status" name="site_status" v-if="isEdit && userRights['super_admin']">
                        <div class="base-input-container">
                            <LaBaseSelect
                                id="site_status"
                                data-test="site-status-select"
                                :items="statuses"
                                :disabled="false"
                                v-model="siteStatus"
                                :searchable="false"
                                :multiple="false"
                            >
                            </LaBaseSelect>
                        </div>
                    </InputContainer>
                </div>
                <div class="column" v-if="userRights['super_admin']">
                    <LaInputWithTooltip
                        v-if="userRights['super_admin']"
                        v-model="urlMask"
                        name="url_mask"
                        label="Url mask"
                    >
                        <template #tooltip>
                            <h3 class="tooltip-title">Url mask</h3>
                            <p>Wildcard pattern selecting which urls belong to this website.</p>
                        </template>
                    </LaInputWithTooltip>

                    <LaInputWithTooltip
                        v-if="userRights['super_admin']"
                        v-model="notUrlMask"
                        :disabled="true"
                        name="not_url_mask"
                        label="Not url mask"
                    >
                        <template #tooltip>
                            <h3 class="tooltip-title">Url mask</h3>
                            <p>List of wildcard patterns selecting which urls DO NOT belong to this website.</p>
                            <p>
                                Cannot be set manually - it is computed from all websites of the given publisher that
                                share a common url base.
                            </p>
                        </template>
                    </LaInputWithTooltip>

                    <LaBaseInput
                        data-test="publisher-performax-id"
                        v-model="performaxId"
                        name="performaxId"
                        label="Performax ID"
                        id="performax-id"
                        type="number"
                    />
                    <LaBaseInput
                        data-test="publisher-zoho-id"
                        v-model="zohoId"
                        name="zohoId"
                        label="Zoho ID"
                        id="zoho-id"
                        type="text"
                    />
                </div>
            </div>
        </LaBaseForm>
    </div>
</template>

<script>
import { computed, ref } from "vue";
import LaBaseForm from "@/components/BaseForm";
import LaBaseInput from "@/components/inputs/BaseInput";
import LaInputWithTooltip from "@/components/inputs/InputWithTooltip";
import LaBaseSelect from "@/components/inputs/BaseSelect";
import LaSimpleDatePicker from "@/components/datepicker/SimpleDatePicker";
import InputContainer from "@/components/inputs/InputContainer";
import { actionTypes, getterTypes } from "@/store/Websites/types";
import { getterTypes as dialogGetterTypes } from "@/store/Dialog/types";
import { isRequired, hasNoWhitespace, isValidUrl, hasMinLength } from "@/helpers/rules.js";

import store from "@/store/store";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { getterTypes as userGetterTypes } from "@/store/LoggedUser/types";
import { getCurrencySymbol, getExchangeRateFromEur } from "@/helpers/data";

export default {
    name: "LaWebsiteForm",
    components: {
        LaInputWithTooltip,
        LaBaseForm,
        LaBaseInput,
        LaBaseSelect,
        LaSimpleDatePicker,
        InputContainer,
    },
    setup() {
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromEur(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));

        const website = computed(() => store.getters[dialogGetterTypes.getDialog].item);
        const errors = computed(() => store.getters[getterTypes.getWebsiteFromError] || {});
        const publisher = computed(() => store.getters[publisherGetterTypes.getSelectedPublisher]);
        let formAction = actionTypes.addData;
        let domain = ref("");
        let cpm = ref(0.25);
        let isEdit = ref(false);
        let id = ref(null);
        let aabSiteId = ref(null);
        let siteStatus = ref(null);
        let site_name = ref(null);
        let verified = ref(null);
        let urlMask = ref(null);
        let notUrlMask = ref(null);
        let performaxId = ref(null);
        let zohoId = ref(null);

        const rules = {
            domain: [isRequired(), hasNoWhitespace(), isValidUrl()],
            cpm: [isRequired()],
            site_name: [hasMinLength(3)],
        };

        const userRights = computed(() => store.getters[userGetterTypes.getUserRights]);

        if (website.value) {
            isEdit.value = true;
            formAction = actionTypes.editData;
            id.value = website.value.id;
            aabSiteId.value = website.value.aab_site_id;
            domain.value = website.value.domain;
            cpm.value = website.value.cpm * exchangeRate.value;
            siteStatus.value = website.value.site_status;
            site_name.value = website.value.site_name;
            verified.value = website.value.verified;
            urlMask.value = website.value.url_mask;
            notUrlMask.value = website.value.not_url_mask;
            zohoId.value = website.value.zoho_id;
            performaxId.value = website.value.performax_id;
        }

        const statuses = ["Live", "Preparation", "UnQualified"];

        return {
            id,
            domain,
            formAction,
            cpm,
            isEdit,
            rules,
            publisher,
            aabSiteId,
            errors,
            userRights,
            notUrlMask,
            statuses,
            siteStatus,
            site_name,
            urlMask,
            verified,
            zohoId,
            performaxId,
            exchangeRate,
            currencySymbol,
        };
    },
};
</script>

<style scoped>
.column:nth-of-type(2) {
    padding-left: 2rem;
}
</style>
