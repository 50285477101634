<template>
    <LaStatusLabel :noDot="true" type="info" :label="fee.value / 100 + '%'">
        <template #tooltip>
            <span>Fee source: {{ fee.publisher }}>{{ fee.website }}</span>
        </template>
    </LaStatusLabel>
</template>

<script>
import LaStatusLabel from "@/components/StatusLabel";
import { ref } from "vue";
import store from "@/store/store";
import { actionTypes } from "@/store/Fees/types";

export default {
    name: "FeeLabel",
    components: {
        LaStatusLabel,
    },
    props: {
        web: {
            required: true,
        },
    },
    setup(props) {
        const fee = ref(0);

        if (!fee.value) {
            //console.log("web", props.web.publisher.aab_publisher_id);
            store
                .dispatch(actionTypes.getLastFee, {
                    aabPublisherId: props.web.publisher.aab_publisher_id,
                    aabSiteId: props.web.aab_site_id,
                })
                .then((data) => {
                    //console.log("feelabel", data);
                    fee.value = data;
                });
        }
        return { fee };
    },
};
</script>
