<template>
    <div class="list">
        <div v-for="item in items" :key="item.routeName" class="list-item-container">
            <router-link
                v-if="item.routeDef && canSeeItem(item)"
                class="list-item"
                :class="{ pointer: clickable }"
                :to="item.routeDef"
                data-test="list-item"
            >
                <LaBaseIcon :name="item.icon" />
                <span class="list-item-text">{{ item.label }}</span>
            </router-link>
            <router-link
                v-else-if="item.routeName && canSeeItem(item)"
                class="list-item"
                :class="{ pointer: clickable }"
                :to="{ name: item.routeName }"
                data-test="list-item"
            >
                <LaBaseIcon :name="item.icon" />
                <span class="list-item-text">{{ item.label }}</span>
            </router-link>
            <span
                v-else-if="canSeeItem(item)"
                class="list-item"
                :class="{ pointer: clickable }"
                data-test="list-item"
                :id="item.id"
                @click="emitItem(item)"
            >
                <LaBaseIcon :name="item.icon" />
                <span class="list-item-text">{{ item.label }}</span>
            </span>
        </div>
    </div>
</template>

<script>
import LaBaseIcon from "@/components/BaseIcon";
import { computed } from "vue";
import store from "@/store/store";
import { getterTypes } from "@/store/LoggedUser/types";

export default {
    name: "LaBaseList",
    components: {
        LaBaseIcon,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        clickable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    methods: {
        emitItem(item) {
            this.$emit(item.id, item);
        },
    },
    setup() {
        const isMainUser = computed(() => store.getters[getterTypes.isMainUser]);
        const canSeeItem = (item) => {
            if (item.onlyMainUser) {
                return isMainUser.value;
            }
            return true;
        };
        return { canSeeItem };
    },
};
</script>

<style lang="scss">
.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-self: stretch;
    border-radius: $normal-radius;
    padding: 0.5rem 0;
    &-item {
        position: relative;
        display: flex;
        color: $light-grey;

        padding: 0.8rem 1.5rem;
        &:hover,
        &:active {
            color: $blue;
        }
        &-text {
            margin-left: 0.3rem;
        }
        &-container:last-child {
            margin-top: auto;
        }
    }
    &-item.pointer {
        cursor: pointer;
    }
    &-item.router-link-exact-active:after {
        content: "";
        position: absolute;
        background: $green;
        bottom: 0;
        left: 0;
        top: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        height: 100%;
        width: 3px;
    }
}
body.dark {
    .list {
        &-item {
            &:hover {
                color: $text;
            }

            &-item.router-link-exact-active:after {
                background: $green-2;
                //background: $green-gradient;
            }
        }
    }
}
</style>
