<template>
    <LaBaseForm
        :form-data="{
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        }"
        :vuex-action-mutation="resetPasswordAction"
    >
        <LaBaseInput
            label="Current password"
            v-model="oldPassword"
            name="oldPassword"
            :rules="rules.oldPassword"
            :error="errors.current_password"
            type="password"
        />
        <br />
        <LaNewPasswordInputs
            @changePassword="changePassword($event)"
            @changeConfirmPassword="changeConfirmPassword($event)"
        />
    </LaBaseForm>
</template>

<script>
import { computed, ref } from "vue";
import { actionTypes, getterTypes } from "@/store/LoggedUser/types";
import LaBaseForm from "@/components/BaseForm";
import LaNewPasswordInputs from "@/components/NewPasswordInputs";
import LaBaseInput from "@/components/inputs/BaseInput";
import { isRequired } from "@/helpers/rules";
import store from "@/store/store";

export default {
    name: "LaChangePasswordView",
    components: {
        LaBaseForm,
        LaBaseInput,
        LaNewPasswordInputs,
    },
    setup() {
        const resetPasswordAction = actionTypes.changePassword;
        const errors = computed(() => store.getters[getterTypes.getChangePasswordFormErrors] || {});
        let newPassword = ref("");
        let confirmPassword = ref("");
        const oldPassword = ref("");

        const changePassword = (p) => {
            newPassword.value = p;
        };

        const changeConfirmPassword = (p) => {
            confirmPassword.value = p;
        };

        const rules = {
            oldPassword: [isRequired()],
        };

        return {
            rules,
            newPassword,
            confirmPassword,
            oldPassword,
            resetPasswordAction,
            changePassword,
            changeConfirmPassword,
            errors,
        };
    },
};
</script>

<style scoped></style>
